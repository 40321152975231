<template>
    <div >
        <img src="../../assets/images/settings.svg" style="width: 200px; float: right; margin-right: 50px;" />
        <p style="max-width: 500px;">
            <span class="text-u-c _600">Twoi klienci czekają!</span><br/>
            Miło nam, że jesteś z nami! Mamy nadzieję, że tak jak innym użytkownikom, Swiftresponse przyniesie Ci wiele korzyści oraz pozwoli
            sprawniej zarządzać relacjami z klientem.
        </p>

        <p style="max-width: 500px;">
            <span class="text-u-c _600">Pierwsze kroki</span><br/>
            Oferujemy wiele opcji ułatwiających pracę. Proponujemy Ci przejście przez ten kreator w celu skonfigurowania najważaniejszysch funkcjonalności systemu
        </p>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('next')">Rozpocznij</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Pomiń</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="closePermanently">Zamknij i nie pokazuj więcej</a>
        </p>

        
    </div>
</template>

<script>

import Api from '../../Api.js';
import UserManager from '../../UserManager.js';

export default {
    name: 'SectionStart',

    components: {
    },

    data: function () {
        return {
        }
    },

    created: function() {

    },

    destroyed: function() {
    },

    methods: {
        async closePermanently() {
            this.$emit('close');
            UserManager.wizzardEnabled = false;
            try {
                await Api.storage.put("wasWizzardSeen", 1);
            } catch (error) {}
        }

    }
}
</script>

<style>

</style>