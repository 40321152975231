<template>
  <div class="table-responsive">
    <table class="table b-t">
      <thead class="primary-color">
        <tr>
          <th style="padding-right:0px;"></th>
          
          <th style="padding-right:0px;"></th>
          <th style="padding-left:10px;"></th>
          <th class="p-l-xs" v-if="selectedColumns.indexOf('id') !== -1">#id</th>
          <th v-if="selectedColumns.indexOf('email') !== -1">{{ $t('ticket.table.cEmail') }}</th>
          <th v-if="selectedColumns.indexOf('name') !== -1">{{ $t('ticket.table.cName') }}</th>
          <th style="padding-right:0px; padding-left: 0px;" v-if="selectedColumns.indexOf('subject') !== -1"></th>
          <th class="p-l-xs" v-if="selectedColumns.indexOf('subject') !== -1">{{ $t('ticket.table.subject') }}</th>
          <th v-if="selectedColumns.indexOf('assignee') !== -1">{{ $t('ticket.table.assignee') }}</th>
          <th v-if="selectedColumns.indexOf('status') !== -1">{{ $t('ticket.table.status') }}</th>
          <th v-if="selectedColumns.indexOf('priority') !== -1">{{ $t('ticket.table.priority') }}</th>
          <th v-if="selectedColumns.indexOf('type') !== -1">{{ $t('ticket.table.type') }}</th>
          <th v-if="selectedColumns.indexOf('tags') !== -1">{{ $t('ticket.table.tags') }}</th>
          <th v-if="selectedColumns.indexOf('date') !== -1" style="padding-right:0px;">{{ $t('ticket.table.createDate') }}</th>
          <th style="padding-right:0px; padding-left: 0px; width:1%;"></th>
        </tr>
      </thead>
      <tbody>
        <!-- pin tickets-->
        <tr class="pin" v-bind:class="{ '_500': pinTicket.status == 'new' || pinTicket.status == 'open'}" v-if="pinnedTicked.length > 0"
          v-for="pinTicket in pinnedTicked"
          :key="pinTicket.id" 
          >
          <td class="checkbox p-r-sm">
            <label class="ui-check text-md">
              <input type="checkbox" v-model="pinTicket.selected">
              <i class="dark-white"></i>
            </label>
          </td>
          <td style="padding-left:0px; padding-right:0px; width:30px;">
            <span  v-if="pinTicket.priority == 'low'"  class="label c-green text-white m-r-xs none-radius inline" title="low">&searr;</span>
            <span  v-if="pinTicket.priority == 'medium'"  class="label c-yellow text-white m-r-xs none-radius inline" title="medium">&rarrw;</span>
            <span  v-if="pinTicket.priority == 'hight'"  class="label c-red text-white m-r-xs none-radius inline" title="hight">&nearr;</span>
            <span  v-if="pinTicket.status == 'new'" class="label c-yellow text-white m-r-xs none-radius inline" title="new">N</span>
            <span  v-else-if="pinTicket.status == 'open'" class="label c-green text-white m-r-xs none-radius inline" title="open">0</span>
            <span  v-else-if="pinTicket.status == 'pending'" class="label c-blue text-white m-r-xs none-radius inline" title="pending">P</span>
            <span  v-else-if="pinTicket.status == 'closed'" class="label c-red text-white m-r-xs none-radius inline" title="closed">C</span>
          </td>
          <td class="text-center" style="padding-left:0px; padding-right:0px; width:30px;">
            <a href="#" title="unpin ticket" v-on:click="removeFromPinTickets(pinTicket ,$event)">
              <i class="material-icons md-18 primary-color">pin_drop</i>
            </a>
          </td>
          <td class="p-l-xs text-u-c"  v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('id') !== -1" >
            {{ pinTicket.shortId ? pinTicket.shortId : pinTicket.id.slice(-6) }}
          </td>
          <td v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('email') !== -1">
            <span v-if="pinTicket.channels && pinTicket.channels.find(a => a.type == 'email')">
            {{ pinTicket.channels.find(a => a.type == 'email').value }}
            </span>
          </td>
          <td v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('name') !== -1">
            <div v-if="pinTicket.channels">
              <span v-if="pinTicket.channels[0] && pinTicket.channels[0].type && pinTicket.channels[0].type == 'email'">
              {{pinTicket.channels[0].value}}</span>
              <span v-if="pinTicket.channels[0] && pinTicket.channels[0].type && (pinTicket.channels[0].type == 'ig' || pinTicket.channels[0].type == 'fb')">
              {{pinTicket.channels[0].name}}</span>
              <span v-for="channel in pinTicket.channels">
                <span  title="email" v-if="channel.type == 'email'" class="material-icons md-16 m-l-xs coral">email</span>
                <img title="instagram" v-if="channel.type == 'ig'" class="m-l-xs" style="width: 16px; " src="../../assets/images/instaram-icon.svg">
                <img title="instagram" v-if="channel.type == 'fb'" class="m-l-xs" style="width: 16px; " src="../../assets/images/m5.svg">
              </span>
            </div>
            <div v-else>
              ----
            </div>
          </td>
          <td class="p-r-xs" v-if="selectedColumns.indexOf('subject') !== -1">
            <div class="counter-message" v-on:click="goToTicketDetails(ticket.id)">
            <i class="coral material-icons md-18">chat_bubble_outline</i>
            <b class="label rounded primary">{{pinTicket.messagesCount}}</b>
            </div>
          </td>
          <td class="p-l-xs" v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('subject') !== -1">
            <span v-if="pinTicket.subject">{{ pinTicket.subject | truncate(40) }}</span>
          </td>

          <td v-if="selectedColumns.indexOf('assignee') !== -1">
            <div v-if="pinTicket.employee == 'inProgress'">
                <diamond></diamond>
            </div>
            <div v-else-if="pinTicket.employee">
              <div class="nav-item dropdown">
                <!-- <span class="avatar w-24 m-r-xs">
                  <img src="../../assets/images/a2.jpg" alt="..."> 
                  <i class="on b-white right" style="top: -2px; right: -5px;"></i>
                </span> -->
                <span data-toggle="dropdown" title="see options" class="interact-selector">{{ pinTicket.employee.name }}</span>
                <div class="dropdown-menu dropdown-menu-scale">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#user" ui-toggle-class="modal-open-aside" ui-target="body" data-keyboard="true" v-on:click="clickedTicketId = pinTicket.id">
                    Change assignee
                  </a>
                  <a class="dropdown-item" href="#">Send message</a>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="nav-item dropdown">
                <span data-toggle="dropdown" title="see options" class="interact-selector">----</span>
                <div class="dropdown-menu dropdown-menu-scale">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#user" ui-toggle-class="modal-open-aside" ui-target="body" data-keyboard="true" v-on:click="clickedTicketId = pinTicket.id">
                    Add assignee
                  </a>
                </div>
              </div>
            </div>
          </td>
          <td v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('status') !== -1">
            <div v-if="pinTicket.status.length">
            {{ pinTicket.status }}
            </div>
            <div v-else>
              ----
            </div>
          </td>
          <td v-if="selectedColumns.indexOf('priority') !== -1">
            <div v-if="pinTicket.priority == 'inProgress'">
              <diamond></diamond>
            </div>
            <div v-else>
              <div class="nav-item inline dropdown">
                <span v-if="pinTicket.priority !== 'undefined'" data-toggle="dropdown" title="see options" class="interact-selector">
                {{ pinTicket.priority }}</span>
                <span v-else data-toggle="dropdown" title="see options" class="interact-selector">----</span>
                <div class="dropdown-menu dropdown-menu-scale pull-right">
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'low', id:pinTicket.id} ,$event)">Low</a>
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'medium', id:pinTicket.id} ,$event)">Medium</a>
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'hight', id:pinTicket.id} ,$event)">Hight</a>
                </div>
              </div>
            </div>
          </td>
          <td v-if="selectedColumns.indexOf('type') !== -1">
            <div v-if="pinTicket.type == 'inProgress'">
                <diamond></diamond>
            </div>
            <div v-else>
              <div class="nav-item inline dropdown">
                <span v-if="pinTicket.type !== 'undefined'" data-toggle="dropdown" title="see options" class="interact-selector" >
                {{ pinTicket.type }}</span>
                <span v-else data-toggle="dropdown" title="see options" class="interact-selector" >----</span>
                <div class="dropdown-menu dropdown-menu-scale pull-right">
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'question', id:pinTicket.id} ,$event)">Question</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'problem', id:pinTicket.id} ,$event)">Problem</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'task', id:pinTicket.id} ,$event)">Task</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'spam', id:pinTicket.id} ,$event)">Spam</a>
                </div>
              </div>
            </div>
          </td>
          <td v-if="selectedColumns.indexOf('tags') !== -1" style="max-width: 140px;">
            <div v-if="pinTicket.tags && pinTicket.tags.length > 0">
              <span :class="{'c-green': i == 0 || i > 3, 'c-yellow': i == 1, 'c-blue': i == 2, 'c-red': i == 3}" class="label text-white m-r-xs none-radius inline" v-for="(tag, i) in pinTicket.tags">{{tag}}</span>
            </div>
            <div v-else>
              ----
            </div>
          </td>
          <td v-on:click="goToTicketDetails(pinTicket.id)" v-if="selectedColumns.indexOf('date') !== -1" style="padding-right: 0px;">
            {{ pinTicket.emailDate | moment("DD MMM,  HH:mm") }}
          </td>
          <td style="padding-left: 0px;">
            <span class="m-r-xs text-red pulse comment" title="comment" v-if="inNotificationsArray(pinTicket.id)">
              <i class="material-icons">track_changes</i> 
            </span>
          </td>
        </tr>
        <!-- ./END pin tickets-->

        <tr v-if="pinnedTicked.length > 0"><td colspan="13" class="separator">&nbsp;</td></tr>
        <tr class="custom-gray row-unpined" v-bind:class="{ 'new-open _500': ticket.status == 'new' || ticket.status == 'open'}" v-if="ticketsReady == true"
        v-for="ticket in tickets"
        :key="ticket.id" 
        >
          <td class="checkbox p-r-sm">
            <label class="ui-check text-xs ">
              <input type="checkbox" v-model="ticket.selected">
              <i class="dark-white"></i>
            </label>
          </td>
          
          <td style="padding-left:0px; padding-right:0px; width:30px;">
            <span  v-if="ticket.priority == 'low'"  class="label c-green text-white m-r-xs none-radius inline" title="low">&searr;</span>
            <span  v-if="ticket.priority == 'medium'"  class="label c-yellow text-white m-r-xs none-radius inline" title="medium">&rarrw;</span>
            <span  v-if="ticket.priority == 'hight'"  class="label c-red text-white m-r-xs none-radius inline" title="hight">&nearr;</span>
            <span  v-if="ticket.status == 'new'" class="label c-yellow text-white m-r-xs none-radius inline" title="new">N</span>
            <span  v-else-if="ticket.status == 'open'" class="label c-green text-white m-r-xs none-radius inline" title="open">0</span>
            <span  v-else-if="ticket.status == 'pending'" class="label c-blue text-white m-r-xs none-radius inline" title="pending">P</span>
            <span  v-else-if="ticket.status == 'closed'" class="label c-red text-white m-r-xs none-radius inline" title="closed">C</span>
          </td>
          <td class="text-center" style="padding-left:0px; padding-right:0px; width:30px;">
            <a class="seen-pin" href="#" title="pin ticket"  v-on:click="addToPinTickets(ticket ,$event)">
              <i class="material-icons md-18 primary-color">pin_drop</i>
            </a>
          </td>
          <td class="p-l-xs text-u-c"  v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('id') !== -1" >
            {{ ticket.shortId ? ticket.shortId : ticket.id.slice(-6) }}
          </td>
          <td v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('email') !== -1">
            <span v-if="ticket.channels && ticket.channels.find(a => a.type == 'email')">
            {{ ticket.channels.find(a => a.type == 'email').value }}
            </span>
            <span v-if="!ticket.channels.find(a => a.type == 'email')"> ---- </span>
          </td>
          <td v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('name') !== -1">
            <div v-if="Array.isArray(ticket.channels) && ticket.channels.length > 0">
              <span v-if="ticket.channels[0].type && ticket.channels[0].type == 'email'">
                <span v-if="ticket.channels[0].name">{{ ticket.channels[0].name }}</span>
                <span v-else>{{ ticket.channels[0].value }}</span>
              </span>
              <span v-else-if="ticket.channels[0].type == 'ig' || ticket.channels[0].type == 'fb' || ticket.channels[0].type == 'chat'">
                {{ticket.channels[0].name}}
              </span>
              <span v-for="channel in ticket.channels">
                <TicketChannelIcon :type="channel.type" />
              </span>
            </div>
            <div v-else>
              ----
            </div>
          </td>
          <td class="p-r-xs" v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('subject') !== -1">
            <div class="counter-message">
            <i class="coral material-icons md-18">chat_bubble_outline</i>
            <b class="label rounded primary">{{ticket.messagesCount}}</b>
            </div>
          </td>

          <td style="max-width: 200px;" class="p-l-xs" v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('subject') !== -1">
            <span v-if="ticket.subject">{{ ticket.subject | truncate(40) }}</span>
            <p class="search-result" v-if="ticket.messages && ticket.messages.length">
              <span v-for="(value, key) in parseSearchText(ticket.messages[0].content)" :class="{match: key == 'match'}">{{ value }}</span>
            </p>
          </td>

          <td v-if="selectedColumns.indexOf('assignee') !== -1">
            <div v-if="ticket.employee == 'inProgress'">
                <diamond></diamond>
            </div>
            <div v-else-if="ticket.employee">
              <div class="nav-item dropdown">
                <!-- <span class="avatar w-24 m-r-xs">
                  <img src="../../assets/images/a2.jpg" alt="..."> 
                  <i class="on b-white right" style="top: -2px; right: -5px;"></i>
                </span> -->
                <span data-toggle="dropdown" class="interact-selector" title="see options">{{ ticket.employee.name }}</span>
                <div class="dropdown-menu dropdown-menu-scale">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#user" ui-toggle-class="modal-open-aside" ui-target="body" data-keyboard="true" v-on:click="clickedTicketId = ticket.id">
                    Change assignee
                  </a>
                  <a class="dropdown-item" href="#">Send message</a>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="nav-item dropdown">
                <span data-toggle="dropdown" class="interact-selector" title="see options">----</span>
                <div class="dropdown-menu dropdown-menu-scale ">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#user" ui-toggle-class="modal-open-aside" ui-target="body" data-keyboard="true" v-on:click="clickedTicketId = ticket.id">
                    Add assignee
                  </a>
                </div>
              </div>
            </div>
          </td>
          <td v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('status') !== -1">
            <div v-if="ticket.status.length">
            {{ ticket.status }}
            </div>
            <div v-else>
              ----
            </div>
          </td>
          <td v-if="selectedColumns.indexOf('priority') !== -1">
            <div v-if="ticket.priority == 'inProgress'">
              <diamond></diamond>
            </div>
            <div v-else>
              <div class="nav-item inline dropdown">
                <span v-if="ticket.priority !== 'undefined'" data-toggle="dropdown" class="interact-selector" title="see options">{{ ticket.priority }}</span>
                <span v-else data-toggle="dropdown" class="interact-selector" title="see options">----</span>
                <div class="dropdown-menu dropdown-menu-scale pull-right">
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'low', id:ticket.id} ,$event)">Low</a>
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'medium', id:ticket.id} ,$event)">Medium</a>
                  <a class="dropdown-item" href="#" v-on:click="changePriority({priority:'hight', id:ticket.id} ,$event)">Hight</a>
                </div>
              </div>
            </div>
          </td>

          <td v-if="selectedColumns.indexOf('type') !== -1">
            <div v-if="ticket.type == 'inProgress'">
                <diamond></diamond>
            </div>
            <div v-else>
              <div class="nav-item inline dropdown">
                <span v-if="ticket.type !== 'undefined'" data-toggle="dropdown" title="see options" class="interact-selector">
                {{ ticket.type }}</span>
                <span v-else data-toggle="dropdown" title="see options" class="interact-selector">----</span>
                <div class="dropdown-menu dropdown-menu-scale pull-right">
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'question', id:ticket.id} ,$event)">Question</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'problem', id:ticket.id} ,$event)">Problem</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'task', id:ticket.id} ,$event)">Task</a>
                  <a class="dropdown-item" href="#" v-on:click="changeType({type:'spam', id:ticket.id} ,$event)">Spam</a>
                </div>
              </div>
            </div>
          </td>
          <td v-if="selectedColumns.indexOf('tags') !== -1" style="max-width: 140px;">
            <div v-if="ticket.tags && ticket.tags.length > 0">
              <span :class="{'c-green': i == 0 || i > 3, 'c-yellow': i == 1, 'c-blue': i == 2, 'c-red': i == 3}" class="label text-white m-r-xs none-radius inline" v-for="(tag, i) in ticket.tags">{{tag}}</span>
            </div>
            <div v-else>----</div>
          </td>
          <td v-on:click="goToTicketDetails(ticket.id)" v-if="selectedColumns.indexOf('date') !== -1" style="padding-right: 0px;">
            {{ ticket.emailDate | moment("DD MMM,  HH:mm")  }}
          </td>

          <td style="padding-left: 0px;">
            <span class="m-r-xs text-red pulse comment" title="comment" v-if="inNotificationsArray(ticket.id)">
              <i class="material-icons">track_changes</i> 
            </span>
          </td>

        </tr>
      </tbody>
    </table>
    <div id="user" class="modal fade"  data-backdrop="true">
      <div class="right w-xl white b-l">
        <div class="row-col">
          <a href="" class="pull-right text-muted text-lg p-a-sm m-r-sm" data-dismiss="modal"><i class="material-icons md-20"></i></a>
          <div class="p-a b-b p-x-md"><span class="">Change assignee</span></div>
          <div class="row-row">
            <div class="row-body">
              <div class="row-inner">
                <div class="list-group no-radius no-borders">
                  <div v-if="employees.length > 0">
                    <div v-for="a in employees"
                    :attach="a"
                    :key="a.id">
                      <div class="radio list-group-item">
                        <label class="ui-check">
                          <input :value="a.id" type="radio" v-model="checkedAssigneeId">
                          <i class="dark-white"></i>
                          {{a.name}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-a b-t">
            <a href="#" class="text-muted" v-on:click="onAssigneeChange">Save changes</a> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import EventBus from '../../event-bus.js';
import EmployeeManager from '../../EmployeeManager.js';
import TicketManager from '../../TicketManager.js';
import Api from '../../Api.js';
import {Diamond} from 'vue-loading-spinner';
import FilterStorage from '../../FilterStorage.js';
import UserManager from '../../UserManager.js';
import TicketChannelIcon from '../TicketChannelIcon.vue';

export default {
  name: 'TicketsTable',
  props: {
    tickets: Array,
    selectedColumns: Array,
    pinnedTicked: Array,
  },
  components: {
    Diamond,
    TicketChannelIcon
  },
  data: function () {
    return {
      employees: [],
      ticketStatus: '',
      ticketsReady: false,
      checkedAssigneeId: '',
      clickedTicketId: '',
      pinTickets: [],
      
    }
  },
  created: function() {
    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
      this.assigneeEmpToTicket();
    });
  },
  destroyed: function() {
  },
  methods: {
    inNotificationsArray: function(needle) {
      let idx = UserManager.notifications
        .filter(n => !n.isSeen && n.data.objectType == 'ticket' && (n.data.code == 'mention_in_comment' || n.data.code == 'mention_in_comment_reminder'))
        .map(n => n.data.objectId+"").indexOf(needle+"");
      if (idx != -1) return true;
      return false;
    },
    removeFromPinTickets: function(ticket, event) {
      event.preventDefault();
      TicketManager.unPinTicket(ticket);
    },
    addToPinTickets: function(ticket, event) {
      event.preventDefault();
      TicketManager.pinTicket(ticket);
    },
    goToTicketDetails: function(id) {
      if (this.$ga && parseInt(id) < 10) {
        this.$ga.event('firststeps', 'view_ticket');
      }
      this.$router.push({ path: `/ticket/${id}`});
    },
    assigneeEmpToTicket: function() {
      this.tickets.forEach((ticket) => {
        let empIds = this.employees.map((emp) => {
          return emp.id+"";
        }); 
        let idx = empIds.indexOf(ticket.assignedUserId+"");
        if (idx != -1) {
          this.$set(ticket, 'employee', this.employees[idx]);
        }
      });
      this.ticketsReady = true;
    },
    changePriority: function (ob, event) {
      event.preventDefault();
      let idx = this.tickets.map(t => t.id).indexOf(ob.id);
      if (idx != -1) {this.tickets[idx].priority = 'inProgress';}

      Api.changeTicketPriority(ob.id, ob.priority).then((ticket) => {
        let idx = this.tickets.map(t => t.id).indexOf(ticket.id);
        if (idx != -1) {this.tickets[idx].priority = ticket.priority;}
      }).catch((reson) => {
      });
    },

    changeType: function (ob, event) {
      event.preventDefault();
      let idx = this.tickets.map(t => t.id).indexOf(ob.id);
      if (idx != -1) {this.tickets[idx].type = 'inProgress';}

      Api.changeTicketType(ob.id, ob.type).then((ticket) => {
        let idx = this.tickets.map(t => t.id).indexOf(ticket.id);
        if (idx != -1) {this.tickets[idx].type = ticket.type;}
      }).catch((reson) => {
      });
    },

    onAssigneeChange: function () {
      let idx = this.tickets.map(t => t.id).indexOf(this.clickedTicketId);
      if (idx != -1) {this.tickets[idx].employee = 'inProgress' ;}
      Api.changeTicketAssignee(this.clickedTicketId, this.checkedAssigneeId).then((ticket) => {
        let idx = this.tickets.map(t => t.id+"").indexOf(ticket.id+"");
        let idxPinned = this.pinnedTicked.map(t => t.id+"").indexOf(ticket.id+"");

        if (idx != -1) {
          this.tickets[idx].assignedUserId = ticket.assignedUserId;
          let empIdx = this.employees.map(emp => emp.id+"").indexOf(ticket.assignedUserId+"");
          this.tickets[idx].employee = this.employees[empIdx];
          $('#user').modal('toggle');
        }
        if (idxPinned != -1) {
          this.pinnedTicked[idxPinned].assignedUserId = ticket.assignedUserId;
          let empIdx = this.employees.map(emp => emp.id+"").indexOf(ticket.assignedUserId+"");
          this.pinnedTicked[idxPinned].employee = this.employees[empIdx];
          $('#user').modal('toggle');
        }


      }).catch((reson) => {
        console.error(reson);
      });
    },

    parseSearchText: function (text) {
        if (!text) return { left: null, match: null, right: null};
        let filter = FilterStorage.searchContent;
        let truncateCount = 30;
        let foundIdx = text.search(RegExp(filter, 'i'));
        let r = { left: null, match: null, right: null};

        if (foundIdx == -1) { r.left = text; return r; }    

        r.left = text.substr(0, foundIdx);
        r.match = text.substr(foundIdx, filter.length);
        r.right = text.substr(foundIdx + filter.length);

        if (r.left.length > truncateCount) r.left = '...' + r.left.substr(r.left.length - truncateCount);
        if (r.right.length > truncateCount) r.right = r.right.substr(0, truncateCount) + '...';

        return r;
    }
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .new-open {
    background-color: #ffffff;
  }
  #user {
    .list-group-item:hover {
      background-color: #f6f8f8;
    }
  }
  .spinner .diamond {
    left: 30px!important;
    top: 20px!important;
  }
  td.separator {
    line-height: 0px;
    height: 8px;
    padding: 0px;
    background-color: #C7D2D6;
  }
  .interact-selector {
    background-color: none;
    padding: 2px;
    border-radius: 5px; 
    &:hover {
      background-color: rgba(255, 216, 160, 0.5);
    }
  }

  .search-result {
    overflow: hidden;
    color: #aaa;
    
    span.match {
      color: #999;
      padding: 2px;
      background-color: rgba(255, 216, 160, 0.5);
      border-radius: 5px; 
    }
  }
  .seen-pin {
    display: none;
  }
  .row-unpined:hover {
    td {

      .seen-pin {
        display: block;
      }
    }
  }
  .comment {
    border-radius: 50%;
    padding: 3px;
  }
  .pulse {
    cursor: pointer;
    box-shadow: 0 0 0 rgba(240,80,80, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 14px rgba(240,80,80, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(240,80,80, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
      box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 14px rgba(240,80,80, 0);
        box-shadow: 0 0 0 14px rgba(240,80,80, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(240,80,80, 0);
        box-shadow: 0 0 0 0 rgba(240,80,80, 0);
    }
  }
</style>
