<template>
    <li class="nav-item dropdown m-l-xs m-r-sm text-color">
        <router-link class="nav-link" to="/live-chat">
            <img class="icon" :class="{'text-muted': !hasMessages && !hasWaitingNewChat}" style="width: 22px;margin-bottom: 2px;" src="../../assets/images/conversation.svg">
            <span class="up up-custom text-red" v-if="hasWaitingNewChat">
                <i class="material-icons md-16 pulse">error</i>
            </span>
            <span class="up up-custom text-warn" v-else-if="hasMessages">
                <i class="material-icons md-16 pulse">error</i>
            </span>
        </router-link>
    </li>
</template>

<script>
import SystemSocket from '../../SystemSocket.js';
import UserManager from '../../UserManager.js';
const ring = require("../../assets/sounds/sound1.wav");
const RingAudio = new Audio(ring);
RingAudio.loop = true;

const CODES = [
  'live_chat_waiting',
  'new_livechat_ticket_reply',
];


export default {
    name: "LiveChatNotificator",
    data: function () {
        return {
            isNewMesssage: false,
            notifications: []
        }
    },
    components: {
    },
    created: function() {
        // SystemSocket.socket.on('object', this.handleObjectMsg);
        UserManager.events.on('notification_changed', this.onUserManagerChangeEvent);
        // ubezpiecznie na wypadek kiedy jest juz po pierwszym evencie
        if (UserManager.notifications.length) {
            this.onUserManagerChangeEvent(UserManager.notifications);
        }
    },

    destroyed: function() {
        // SystemSocket.socket.off('object', this.handleObjectMsg);
        UserManager.events.removeListener('notification_changed', this.onUserManagerChangeEvent);
    },

    computed: {
        hasWaitingNewChat: function () {
            return this.notifications.filter(n => n.data.code == "live_chat_waiting").length > 0
        },

        hasMessages: function () {
            return this.notifications.filter(n => n.data.code == "new_livechat_ticket_reply" && !n.isSeen).length > 0
        },
    },

    methods: {
        onUserManagerChangeEvent(notifications) {
            this.notifications.length = 0;
            notifications.forEach(n => this.notifications.push(n));
        },
        
        handleObjectMsg(msg) {
            if (msg.object != 'ticket') return;
            if (msg.verb == 'update') { // na update, bo empsy sa przypisywani po utworzeniu ticketa

                let ticket = msg.data;
                let idx = this.newChats.map(t => t._id).indexOf(ticket._id);
                if (idx != -1) {
                    if (ticket.status != "new") {
                        RingAudio.pause()
                        this.newChats.splice(idx, 1);
                    }
                    return;
                }


                if (ticket.assignedUserId+"" != UserManager.user.userId+"") return;
                if (!Array.isArray(ticket.channels)) return;

                if (ticket.status != "new") return;

                let chatChannel = ticket.channels.find(ch => ch.type == "chat");
                if (!chatChannel) return;

                this.newChats.push(ticket);
                if (RingAudio.paused) {
                    RingAudio.play();
                }
            }
        },

        open() {
            RingAudio.pause()
        },
    }
}
</script>

<style scoped lang="scss">
img.icon.text-muted {
    opacity: 0.2;
}
.icon-bottom {
    position: absolute;
    top: 12px;
    left: 12px;
    display: inline-block;
}
.up-custom {
    position: relative;
    top: -10px;
    min-width: 4px;
    min-height: 4px;
    display: inline-block;
  }

  .ctx-menu {
    border-radius: 0px;
    padding: 0px 0px!important;
    .ctx-link {
        display: block;
        padding: 2px 5px;
    }
    li {
        cursor: pointer;
    }
    li:hover {
        background-color: #E2E8EE;
    }
}
</style>