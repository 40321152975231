<template>
  <div>
    <div class="box-body start-info" id="automatize">
      <div class="box2 sb8">{{ $t('settings.tabPrefernces.boxAutomaticAss.titleAutomatize')}}</div>
    </div>

    <div class="box m-a">
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline c-rwd">{{ $t('settings.tabPrefernces.boxAutomaticAss.automaticAssignment')}}</h6>
        <label class="md-check pull-right">
            <input type="checkbox" v-on:change="setAssigner" v-model="ticketAssignerData.enabled">
            <i class="primary m-r-xs"></i>
        </label>
      </div>

      <div class="box-body">
        <div class="box-body fb-box" v-if="!isLoaded">
          <Spinner></Spinner>
        </div>
        <form class="form-horizontal" v-if="isLoaded">

          <div class="form-group row m-b">
            <div class="col-md-3 col-sm-4">
              <input class="form-control input-time" type="text" v-model="ticketAssignerData.defaultTimePerTicket" v-on:change="setAssigner">
            </div>
            <label class="col-md-9 col-sm-8 form-control-label label-sm m-t-xs">{{ $t('settings.tabPrefernces.boxAutomaticAss.defaultTime')}}</label>
          </div>

          <label class="md-check m-b">
            <input type="checkbox" v-on:change="setAssigner" v-model="ticketAssignerData.keepSameEmployee">
            <i class="primary"></i>
            <span class="m-l-xs">{{ $t('settings.tabPrefernces.boxAutomaticAss.checkboxAssign')}}</span>
          </label>
          <div class="custom-divider m-a-0 m-b"></div>
          <div class="row">
            <label class="col-sm-12 form-control-label">{{ $t('settings.tabPrefernces.boxAutomaticAss.list')}}</label>
          </div>
          <div class="form-group row">

            <div class="col-sm-12 start-info-text coral m-b" 
              v-if="ticketAssignerData.availableEmployees.length == 0 && isLoaded">
              {{ $t('settings.tabPrefernces.boxAutomaticAss.addFirst')}}
            </div>
            <div class="col-sm-12 start-info-text coral m-b"  v-if="isEmpty">
              {{ $t('settings.tabPrefernces.boxAutomaticAss.noUsers')}} 
              {{ $t('settings.tabPrefernces.boxAutomaticAss.click')}}
              <router-link to="/settings/users-settings">{{ $t('settings.tabPrefernces.boxAutomaticAss.here')}}</router-link>             
            </div>
            <div class="col-sm-12" v-if="!isEmpty">
              <select2 :options="optionsEmps" class="form-control" v-model="ticketAssignerData.availableEmployees" multiple="multiple" v-on:input="setAssigner">
              </select2>
            </div>
          </div>
          
        </form>
        
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';

var timeoutHandler = null;

export default {
  name: 'SettingAutomatize',
  components: {
    Spinner,
    Select2
  },
  data: function () {
    return {
      isLoaded: false,
      isEmpty: false,
      optionsEmps: [],
      ticketAssignerData: {}
    }
  },
  created: function() {
    this.getAssigner();

    EmployeeManager.getEmployees().then((employees) => {
      if (employees.length == 0) {
        isEmpty = true;
        return;
      }
      this.optionsEmps = employees.map(e => {
        return {id: e.id, text: e.name};
      });
      
    });
    
    timeoutHandler = setTimeout(() => {
      $('#automatize').fadeOut();
    }, 5000);
  },
  methods: {
    getAssigner: function() {
      Api.getTicketAssignerAvailableEmps().then((data) => {
        this.ticketAssignerData = data;
        if (this.ticketAssignerData.availableEmployees == null) this.ticketAssignerData.availableEmployees = [];
        this.isLoaded = true;
      }).catch((reson) => {
        console.error(reson);
        this.isLoaded = true;
      });
    },
    setAssigner: function() {
      Api.setTicketAssignerAvailableEmps(this.ticketAssignerData).then((data) => {
        this.ticketAssignerData = data;
        this.$notify({group: 'global', type: 'notification-success', text: this.$i18n.t('notifications.changeSuccess')});
      }).catch((reson) => {
        this.$notify({group: 'global', type: 'notification-error', text: this.$i18n.t('notifications.changeError')});
      });
    },
  }
}
</script>
<style scoped lang="scss">
.input-time {
  min-height: 1.8rem;
  padding: .075rem .75rem;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
#automatize {
  min-height: 100px;
  .box2 {
    width: 92%;
  }
}
@media (max-width: 382px) {
.c-rwd {
  font-size: 0.85rem;
}
}
</style>