<template>
  <li class="list-item">
    <a herf="" class="list-left">
      <span v-if="message.empEmail"><gravatar class="w-32 circle" :email="message.empEmail" default-img="identicon"/></span>
      <span v-else-if="message.source && message.source.user && message.source.user.profile_pic_url">
        <img class="w-32 circle" :src="message.source.user.profile_pic_url" />
      </span>
      <span v-else-if="message.author"><gravatar class="w-32 circle" :email="message.author" default-img="identicon"/></span>
      <!-- <gravatar class="w-32 circle" :email="message.author" default-img="identicon"/> -->
      <!-- <span class="w-32 circle primary">
        <i class="fa fa-comment"></i>
      </span> -->
    </a>

    <div class="list-body">
      <small class="pull-right text-muted c-rwd">
        <i class="fa fa-calendar hidden-sm-down"></i> 
        {{message.createdAt | moment("DD MMM YYYY, HH:mm") }}
      </small>
      <div class="m-b title-color _500">
        <span>{{message.author}}</span>
        
        <span v-if="message.deliveryRaports && message.deliveryRaports.length">

          <span v-for="a in message.deliveryRaports" :key="a._id">
            <a class="raport-info" v-if="a.status == 'waiting'" href="#" :title="$t('ticket.ticketTabs.reply.message.sending')">
              <TicketChannelIcon class="text-muted" :type="a.type" />
            </a>
            <a class="raport-info text-green" v-else-if="a.status == 'sent'" href="#" :title="$t('ticket.ticketTabs.reply.message.sentOk')">
              <TicketChannelIcon :type="a.type" />
              <span class="up up-custom">
                <i class="material-icons md-16 text-green">check_circle</i>
              </span>
            </a>
            <a class="raport-info text-red" v-else-if="a.status == 'error'" href="#" :title="$t('ticket.ticketTabs.reply.message.sentError')">
              <TicketChannelIcon :type="a.type" />
              <span class="up up-custom">
                <i class="material-icons md-16">error</i>
              </span>
            </a>
            <!-- przenieść to do attachemtów instagramowych -->
           <!--  <a v-if="a.type == 'ig-attachment' && a.status == 'waiting'" href="#" :title="$t('ticket.ticketTabs.reply.message.sending')">
              <i class="material-icons md-16 m-l-xs text-muted">attach_file</i>
            </a>
            <a v-if="a.type == 'ig-attachment' && a.status == 'sent'" href="#" :title="$t('ticket.ticketTabs.reply.message.sentOk')">
              <i class="material-icons md-16 m-l-xs text-green">attach_file</i>
            </a>
            <a v-if="a.type == 'ig-attachment' && a.status == 'error'" href="#" :title="$t('ticket.ticketTabs.reply.message.sentError')">
              <i class="material-icons md-16 m-l-xs text-red">attach_file</i>
            </a> -->
            <!-- END przenieść to do attachemtów instagramowych -->
          </span>
        </span>
        
        <a v-if="message.contentHtml && !selectIfremVersion" class="text-muted text-xs m-l-xs _400" href="#" v-on:click.prevent="showHtmlVersion()">
          {{$t('ticket.ticketTabs.reply.message.html')}}
        </a>
        <a v-if="message.contentHtml && selectIfremVersion" class="text-muted text-xs m-l-xs _400" href="" v-on:click.prevent="getPlainText">{{$t('ticket.ticketTabs.reply.message.plainText')}}</a>
      </div>

      <blockquote class="primary-color content-msg" v-if="!selectIfremVersion" style="overflow-x: auto;" v-bind:class="{'h-500': showMore }">
        <nl2br tag="p" :text="message.content" v-if="message.content" />
      </blockquote>
      <div class="text-center" v-if="showMore && !selectIfremVersion" style="position: relative; padding-top: 10px;">
        <div class="gradient-text"><hr/></div>
        <button class="btn white btn-sm primary-color none-radius _400" aria-expanded="false" v-on:click.prevent="showMore = false">
          Show more
        </button>
      </div>

      <div class="resizer" v-if="selectIfremVersion">
        <iframe class="resized" style="" :src="iframeSrc"></iframe>
      </div>

      <div class="sl-footer" v-if="message.attachments && message.attachments.length > 0">
        <div v-for="a in message.attachments"
          :attach="a"
          :key="a.id" class="minature-container">
          <a v-bind:href="a.url" target="_blank" class="miniature">

            <div v-if="a.previewUrl" class="img-minature" :style="{'background-image': 'url('+a.previewUrl+')'}"></div>
            <div v-else-if="a.size < 102400" class="img-minature" :style="{'background-image': 'url('+a.url+')'}"></div>
            <div v-else class="img-minature">
              <i class="material-icons md-24 text-muted">insert_photo</i>
            </div>
            <div class="overlay"></div>
            <div class="text-minature text-muted">
              <span>{{(a.name || 'Brak nazwy')  | truncate(15)}}</span>
            </div>
            <button class="btn btn-icon white btn-miature btn-sm none-radius" :title="$t('ticket.ticketTabs.reply.message.titleDownload')">
              <i class="fa fa-download md-20 text-muted"></i>
            </button>
          </a>
        </div>
      </div>

      <div class="sl-footer m-t">
        <a href="" class="btn white btn-xs none-radius" data-toggle="collapse" data-target="#reply-1" :title="$t('ticket.ticketTabs.reply.message.titleForward')"
        v-on:click.prevent="showModal(message.id)">
          <i class="material-icons md-20 text-muted">arrow_forward</i>
        </a>&nbsp;
        <a href="" class="btn white btn-xs none-radius" data-toggle="collapse" data-target="#reply-1" :title="$t('ticket.ticketTabs.reply.message.titleSplit')" v-on:click.prevent="message.selected=true; splitMessage();">
          <i class="material-icons md-20 text-muted"></i>
        </a>

        <label class="m-t-xs md-check pull-right">
          <input type="checkbox" v-model="message.selected">
          <i class="m-r-xs primary"></i>
        </label>
      </div>
    </div>

    <div id="forwad-ticket-modal" class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">

          <div class="modal-body text-center p-lg">

            <div class="form-group row">
              <div class="col-sm-12">
                <input class="form-control custom-input" type="text" v-model="searchTicketId" :placeholder="$t('ticket.ticketTabs.reply.message.enterId')" v-on:input="onInputChange">
              </div>
            </div>

            <div class="pull-left m-b">
              <label class="md-check">
                <input type="checkbox">
                <i class="primary"></i>
                <span class="primary-color _400 m-l-xs">{{$t('ticket.ticketTabs.reply.message.copy')}}</span>
              </label>
            </div>

            <div class="form-group row m-b-xs">
              <div class="col-sm-12 m-t m-b-lg" v-if="inProgress || inSearch">
                <!-- loader -->
                <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, 0%); z-index:10000" >
                  <div style="position: relative;">
                    <rotate-square5></rotate-square5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12" v-if="!isEmptyTicket">
                <div class="box">
                  <div class="box-header p-b-sm text-left">
                     <h2 class="title-color m-b-sm">{{$t('ticket.ticketTabs.reply.message.ticket')}} <span class="text-primary pull-right">#{{ticket.id}}</span></h2>
                     <span class="text-sm text-muted">{{$t('ticket.ticketTabs.reply.message.createDate')}}: {{ticket.emailDate | moment("MMM DD, YYYY")}}</span>
                  </div>
                  <div class="m-a-0 custom-divider"></div>
                  <div class="box-body text-left">
                    
                    <ul class="list-group no-border">
                      <span class="primary-color _500 m-b-sm">{{$t('ticket.ticketTabs.reply.message.infoCustomer')}}:</span>
                      <div class="clear custom-color">
                        <div>
                          <span class="text-sm" v-if="ticket.name">{{ticket.name}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noName')}}</span>
                        </div>
                        <div>
                          <span class="text-sm" v-if="ticket.fromEmail">{{ticket.fromEmail}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noEmail')}}</span>
                        </div>
                        <div>
                          <span class="text-sm" v-if="ticket.phone">{{ticket.phone}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noPhone')}}</span>
                        </div>
                      </div>
                    </ul>
                    <ul class="list-group no-border m-t">
                      <span class="primary-color _500 m-b-sm">{{$t('ticket.ticketTabs.reply.message.infoTicket')}}:</span>
                      <div class="clear custom-color">
                        <div>
                          <span class="text-sm" v-if="ticket.subject"><b>{{$t('ticket.ticketTabs.reply.message.subject')}}:</b> {{ticket.subject}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noSubject')}}</span>
                        </div>
                        <div>
                          <span class="text-sm" v-if="ticket.employee && ticket.employee.name">
                            <b>Assignee:</b> {{ticket.employee.name}}
                          </span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noAssignee')}}</span>
                        </div>
                        <div>
                          <span class="text-sm" v-if="ticket.status"><b>{{$t('ticket.ticketTabs.reply.message.status')}}:</b> {{ticket.status}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noStatus')}}</span>
                        </div>
                        <div>
                          <span class="text-sm" v-if="ticket.priority"><b>{{$t('ticket.ticketTabs.reply.message.priority')}}:</b> {{ticket.priority}}</span>
                          <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noPriority')}}</span>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-if="noTicket">
                <p class="text-muted m-b-xs">{{$t('ticket.ticketTabs.reply.message.noTicket')}}</p>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-sm white none-radius pull-left" v-on:click="closeModal" v-bind:class="{ disabled: inProgress }">
              <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </button>
            <button class="btn btn-sm white none-radius" 
            v-on:click="forwadToOtherTicket" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{$t('btnNames.forward')}}</span>
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div>
    </div>

  </li>
</template>

<script>
import $ from 'jquery'
import Nl2br from 'vue-nl2br'
import EventBus from '../../../event-bus.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Api from '../../../Api.js';
import EmployeeManager from '../../../EmployeeManager.js';
import TicketChannelIcon from '../../TicketChannelIcon.vue';
import Gravatar from 'vue-gravatar';

var timeoutHandler = null;

export default {
  name: 'TicketMessage',
  components: {
    Nl2br,
    TicketChannelIcon,
    RotateSquare5 ,
    Gravatar  
  },
  props: {
    message: Object
  },
  data: function () {
    return {
      iframeSrc: null,
      selectIfremVersion: false,
      inProgress: false,
      inSearch: false,
      isActive: false,
      searchTicketId: '',
      selectedMessageId: '',
      ticket: {},
      isEmptyTicket: true,
      noTicket: false,
      employees: [],
      textSuccess: 'Message has been forward correctly.',
      textError:'There was a problem with forward selected message.',
      showMore: false,
    }
  },
  created: function() {
    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
    });
    
  },
  mounted: function () {
    if (this.message.contentHtml && this.message.content.length > 160) {
      this.showHtmlVersion();
    }
    this.$nextTick(function () {
      let element = this.$el.querySelector('.content-msg');
      if (element && element.clientHeight > 250) {
        this.showMore = true;
      }
    })
  },
  destroyed: function() {
  },
  methods: {
    showHtmlVersion: function() {
      this.iframeSrc = Api.baseUrl+'tickets/messages/htmlContent/'+this.message.id+'?token='+Api.token;
      this.selectIfremVersion = true; 
    },
    getPlainText: function() {
      this.selectIfremVersion = false; 
    },
    forwadToOtherTicket: function() {
      this.inProgress = true;
      Api.forwardMessageToOtherTicket(this.selectedMessageId, this.ticket.id).then((msg) => {
        this.ticket = {};
        this.inProgress = false;
        this.closeModal();
        this.showNotify('notification-success', this.$i18n.t('notifications.actionSuccess'));
      }).catch((r) => {
        this.inProgress = false;
        if (r.response.status == 400) {
          this.showNotify('notification-error', this.$i18n.t('notifications.onlyCustomer'));
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.error'));
        } 
      });

    },
    onInputChange: function() {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        this.inSearch = true;
        this.isEmptyTicket = true;
        this.noTicket = false;

        Api.getTicket(this.searchTicketId).then((ticket) => {

          let empIds = this.employees.map((emp) => {
            return emp.id+"";
          }); 
          let idx = empIds.indexOf(ticket.assignedUserId+"");
          if (idx != -1) {
            ticket.employee = this.employees[idx];
          }

          this.ticket = ticket;
          this.isEmptyTicket = false;
          this.noTicket = false;
          this.inSearch = false;
        }).catch((r) => {
          this.ticket = {};
          this.isEmptyTicket = true;
          this.noTicket = true;
          this.inSearch = false;
        });
      }, 1000);
    },
    splitMessage: function() {
      let message = 'Are you sure you want to split selected message? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        EventBus.$emit('split-selected-messages');
      }).catch(r => r);
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
    showModal: function(id) {
      this.selectedMessageId = id;
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.searchTicketId = '';
      this.selectedMessageId = '';
      this.isEmptyTicket = true;
      this.noTicket = false;

    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      });
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../assets/styles/ticket-message.scss';
.h-500 {
  height: 250px;
  overflow-y: hidden;
} 

.up-custom {
    position: relative;
    top: -7px;
    left: -7px;
    min-width: 4px;
    min-height: 4px;
    display: inline-block;
  }
  .msg-blue {
    color: #0078ff;
  }
  .msg-blue:hover {
    color: #0060cc;
  }
  .msg-blue-bg {
    background-color: #0078ff;
  }
.img-minature {
  background-size: cover;
  background-position: 50% 50%;
}
.resizer { display:flex; margin:0; padding:0; resize:vertical; overflow:hidden; min-height: 300px; }
.resizer > .resized { flex-grow:1; margin:0; padding:0; border:0 }
.gradient-text {
  background-image: linear-gradient(to top, #fff, transparent);
  padding-top: 65px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 32px;
}
@media (max-width: 400px) {
  .c-rwd {
    display: block;
    float: none;
  }
}
.raport-info {
  position: relative;
  border: 0px solid #dedede;
  padding: .1565rem .4rem .18rem .2rem;
  font-size: 0.8rem;
  // box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}
</style>
