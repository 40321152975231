<template>
  <div class="email-settings">
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{ $t('settings.tabPrefernces.title')}}</h6>
      </div>
      <div class="row">

        <div class="col-xl-4 col-lg-6">
          <SettingAutomatize></SettingAutomatize>
        </div>

        <!-- <div class="col-xl-4 col-lg-6">
          <SettingFacebook></SettingFacebook>
        </div> -->

        <div class="col-xl-4 col-lg-6">
          <SettingsMailer></SettingsMailer>
        </div>


        <!-- <div class="col-xl-4 col-lg-6">
          <p class="work-in-progress text-lg"><span>Work in progress</span></p>
          <div class="overlay">
            <div class="box m-a">
              <div class="box-header d-gray">
                <h6 class="text-muted _400 inline">Company logo</h6>
              </div>
              <div class="box-body box-logo">
                <div class="text-drag-file"  style="pointer-events: none;">
                  <span class="title-color">Drag file here</span>
                </div>
              </div>

              <div class="box-body start-info logo">
                <div class="box2 sb10">Drag logo of your company with dimensions: 150px x 30px</div>
              </div>
            </div>
          </div>
        </div> -->
        
      </div>
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';
import SettingFacebook from './SettingFacebook.vue';
import SettingAutomatize from './SettingAutomatize.vue';
import SettingsMailer from './SettingsMailer.vue';

var timeoutHandler = null;

export default {
  name: 'Prefernces',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2,
    SettingFacebook,
    SettingAutomatize,
    SettingsMailer
  },
  data: function () {
    return {
      isLoaded: false,
      availableEmployees: [],
      isAutomatic: false,
      keepSameEmployee: false,
      defaultTimePerTicket: 300,
      isOnlyAssign: false,
      employees: [],
      searchEmployees: '',
      procesing: true,
      company: {},
      package: {progress: 75},
    }
  },
  created: function() {
    this.getTicketAssignerSettings();

    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
      this.isLoaded = true;
    });
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    inArray: function(needle) {
      let length = this.availableEmployees.length;
      for(let i = 0; i < length; i++) {
          if(this.availableEmployees[i] == needle) return true;
      }
      return false;
    },
    setTicketAssignerSettings: function() {

      let data = {
        availableEmployees:this.availableEmployees, 
        enabled:this.isAutomatic, 
        keepSameEmployee:this.keepSameEmployee, 
        defaultTimePerTicket:this.defaultTimePerTicket
      };
      
      Api.setTicketAssignerAvailableEmps(data).then((data) => {

        this.availableEmployees = data.availableEmployees;
        this.isAutomatic = data.enabled;
        this.keepSameEmployee = data.keepSameEmployee;
        this.defaultTimePerTicket = data.defaultTimePerTicket;

        this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
      });
    },
    getTicketAssignerSettings: function() {
      Api.getTicketAssignerAvailableEmps().then((data) => {

        this.availableEmployees = data.availableEmployees;
        this.isAutomatic = data.enabled;
        this.keepSameEmployee = data.keepSameEmployee;
        this.defaultTimePerTicket = data.defaultTimePerTicket;

      }).catch((reson) => {
        console.error(reson);
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  
  .box-logo {
    position: relative;
    min-height: 105px;

    .text-drag-file {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background-color: #fafbfc;
      border: 3px dashed #58666e;
      opacity: .8;
      width: 95%;
      height: 95%;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .logo {
    .box2 {
      right: 12px;
      width: 95%;
    }
  }
  

  .email-settings {
    .box {
      min-height: 268px;
    }
  }

  .progress {
    display: inline-block;
    width: 200px;
    min-height: 20px;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .progress-bar {
    border-radius: 0px;
    padding-top: 0.11rem;
  }

  .package {
    .start-info .box2 {
      width: 92%;
    }
  }
  .overlay {
    position: relative;
    .box {
      height: 100%;
      width: 94%;
      opacity: 0.5;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  .work-in-progress {
    position: absolute;
    left: 27%;
    top: 130px;
    border: 2px solid rgba(196,198,198, 0.6);
    z-index: 99999;
    padding: 12px 10px;
    span {
      color: #fff;
      background-color: rgba(196,198,198, 0.6);
      padding: 10px 8px;
    }
  }
</style>
