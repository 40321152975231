<template>
  <div class="row">
    <div class="box">
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('settings.settings')}}</h6>

      </div>
      <div class="box-divider m-a-0"></div>

      <div class="l-gray p-b">
        <ul class="btn-group nav nav-pills p-t p-l">
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/prefernces">
              <span class="_400">{{$t('settings.tabPrefernces.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/users-settings">
              <span class="_400">{{$t('settings.tabUsers.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/groups-settings">
              <span class="_400">{{$t('settings.groups.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/billing-settings">
              <span class="_400">Billing</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/email-settings">
              <span class="_400" >{{$t('settings.tabEmailBoxes.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/shops-settings">
              <span class="_400" >{{$t('settings.tabShops.title')}}</span>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/tags">
              <span class="_400" >Tags</span>
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/permissions">
              <span class="_400" >{{$t('settings.tabPermissions.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/email-client">
              <span class="_400" >{{$t('settings.tabEmailClient.title')}}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-sm white none-radius primary-color" to="/settings/voip">
              <span class="_400" >{{$t('settings.tabVoip.title')}}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="tab-content m-b-md">
        <!-- <div class="tab-pane animated fadeIn active primary-color" id="prefernces">
          <Prefernces></Prefernces>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="users">
          <UsersSettings></UsersSettings>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="emailsettings">
          <EmailSettingPage></EmailSettingPage>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="shopsettings">
          <ShopsSettingPage></ShopsSettingPage>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="tags">
          <TagsPage></TagsPage>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="roles">
          <RolesPage></RolesPage>
        </div>
        <div class="tab-pane animated fadeIn text-muted" id="emailclient">
          <EmailClient></EmailClient>
        </div> -->
        <div class="tab-pane animated fadeIn active text-muted">
          <router-view></router-view>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import UserManager from '../../UserManager.js'
import Prefernces from './Prefernces.vue'
import UsersSettings from './UsersSettings.vue'
import PermissionsSetting from './PermissionsSetting.vue'
import TagsPage from './TagsPage.vue'
import EmailSettingPage from './EmailSettingPage.vue'
import ShopsSettingPage from './ShopsSettingPage.vue'
import EmailClient from './EmailClient.vue'


export default {
  name: 'SettingPage',
  props: {
    
  },
  components: {
    Prefernces,
    UsersSettings,
    PermissionsSetting,
    TagsPage,
    EmailSettingPage,
    ShopsSettingPage,
    EmailClient
  },
  data: function () {
    return {
     
    }
  },
  created: function() {
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.router-link-active {
  background-image: none;
  -webkit-box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.1);
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.1);
}
@media (max-width: 562px) {
  .nav-item {
    margin-top: 10px;
  }
}
</style>
