<template>
    <div class="box">
        <div class="box-header">
            <h3>LiveChat</h3>
            <small v-if="!editedPage._id">Dodawanie nowego widgetu do LiveChat'u</small>
            <small v-else>Edycja widgeta</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li v-if="editedPage._id" class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!editedPage._id">
            <form role="form">
                <div class="form-group">
                    <label>Nazwa widgetu rozpoznawalna dla Ciebie</label>
                    <input class="form-control" type="text" v-model="editedPage.name">
                </div>
                <a v-if="!isProcessing" class="btn primary none-radius pull-right" @click="create">Dodaj</a>
                <a v-if="!isProcessing" class="" @click="$emit('close')">Anuluj</a>
            </form>
        </div>
        <div class="box-body" v-else>
            <form role="form">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="form-group">
                                <label>Nazwa widgetu rozpoznawalna dla Ciebie</label>
                                <input class="form-control" type="text" v-model="editedPage.name">
                            </div>

                            <div class="form-group">
                                <label>Kod do umieszczenia na stronie</label>
                                <textarea class="form-control form-control-sm widget-code" v-model="widgetCode"></textarea>
                                <span class="help-block text-sm m-b-none text-muted">
                                    Skopiuj powyższy kod i wklej go do kodu strony na której chcesz umieścić widget.<br/>
                                    Kod umieść przed ostatnim znacznikiem <span class="text-red">&lt;/body&gt;</span>
                                </span>
                            </div>
                        </div>
                        
                        <div class="card">
                            <div class="form-group">
                                <label>Kto może obsługwać rozmowy przez chat?</label>
                                <v-select 
                                    multiple
                                    class="form-control"
                                    placeholder="Wszyscy"
                                    v-model="selectedGroups"
                                    :options="groups"
                                    label="title">
                                    <template slot="no-options">
                                        Brak grup zdefinowanych w systemie.
                                        Przejdź do "Ustawienia > Grupy" aby dodać pierwszą!
                                    </template>
                                </v-select>
                            </div>

                            <div class="form-group">
                                <label class="md-check m-t">
                                    <input type="checkbox" v-model="editedPage.autoAssign">
                                    <i class="primary m-r-xs"></i>
                                    <span class="m-l-xs">Automatycznie przypisz pracownika do nowej rozmowy</span>
                                </label>
                            </div>

                            <div class="form-group">
                                <label class="md-check">
                                    <input type="checkbox" v-model="editedPage.leaveMessageIfNoAgents">
                                    <i class="primary m-r-xs"></i>
                                    <span class="m-l-xs">Pozwól na pozostawienie wiadomości kiedy nie ma nikogo aktywnego</span>
                                </label>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="card" :class="{'text-muted': !editedPage.firstMessageToVisitor}">
                            <div class="form-group">
                                <label class="md-check">
                                    <input type="checkbox" v-model="editedPage.firstMessageToVisitor">
                                    <i class="primary m-r-xs"></i>
                                    <span class="m-l-xs">Wyślij pierwszą powitalną wiadomość do odwiedzającego</span>
                                </label>
                            </div>

                            <div class="form-group">
                                <label>Po jakim czasie wysłać pierwszą wiadomość</label>
                                <v-select 
                                    class="form-control"
                                    placeholder="Natychmiast"
                                    v-model="editedPage.firstMessageDelay"
                                    :options="triggerTimes"
                                    :reduce="op => op.time"
                                    label="label" />
                            </div>

                            <div class="form-group">
                                <label>Treść pierwszej wiadomości</label>
                                <textarea class="form-control form-control-sm" v-model="editedPage.firstMessageContent"></textarea>
                                <span class="help-block text-sm m-b-none text-muted">
                                    Powyższa wiadomość zostanie wysłana do odwiedzającego, żeby zachęcić go do konwersacji
                                </span>
                            </div>
                        </div>

                        <div class="card" :class="{'text-muted': !editedPage.schedule.isEnabled}">
                            <div class="form-group">
                                <label class="md-check">
                                    <input type="checkbox" v-model="editedPage.schedule.isEnabled">
                                    <i class="primary m-r-xs"></i>
                                    <span class="m-l-xs">Uruchamiaj czat wg. harmonogramu</span>
                                </label>
                            </div>

                            <div class="form-group" v-for="day in weekDays" :key="day.id">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label class="md-check checkbox-inline m-t-sm">
                                            <input type="checkbox" v-model="editedPage.schedule[day.id+''].isEnabled">
                                            <i class="primary m-r-xs"></i>
                                            <span class="m-l-xs">{{ day.label }}</span>
                                        </label>
                                    </div>
                                    
                                    <div class="col-lg-3">
                                        <label class="m-x m-t-sm"> w godzinach</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input class="form-control" type="text" v-model="editedPage.schedule[day.id+''].from" />
                                    </div>
                                    <div class="col-lg-3">
                                        <input class="form-control" type="text" v-model="editedPage.schedule[day.id+''].to" />
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <label class="md-check checkbox-inline" v-for="day in weekDays" :key="day.id">
                                    <input type="checkbox" :value="day.id" v-model="editedPage.schedule.activeDays">
                                    <i class="primary m-r-xs"></i>
                                    <span class="m-l-xs">{{ day.label }}</span>
                                </label>
                            </div>

                            <div class="form-group">
                                <label>W godzinach</label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input class="form-control" type="text" v-model="editedPage.schedule.activeHours.from" />
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="form-control" type="text" v-model="editedPage.schedule.activeHours.to" />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="save">Zapisz</a>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';
import EmployeeManager from '../../../EmployeeManager.js';

const LIB_URL = 'https://swiftresponse.pl/sw-livechat-widget.js';
const triggerTimes = [
    { label: 'Natychmiast', time: 0 },
    { label: '5 sekund', time: 5 },
    { label: '10 sekund', time: 10 },
    { label: '20 sekund', time: 20 },
    { label: '30 sekund', time: 30 },
    { label: '1 minuta', time: 60 },
    { label: '2 minuty', time: 120 },
    { label: '5 minut', time: 300 },
    { label: '10 minut', time: 600 },
]

const weekDays = [
    { label: 'poniedziałek', id: 1 , activeHours: {from: "8:00", to:"16:00"}},
    { label: 'wtorek', id: 2,  activeHours: {from: "8:00", to:"16:00"} },
    { label: 'środa', id: 3,  activeHours: {from: "8:00", to:"16:00"} },
    { label: 'czwartek', id: 4,  activeHours: {from: "8:00", to:"16:00"} },
    { label: 'piątek', id: 5,  activeHours: {from: "10:00", to:"20:00"} },
    { label: 'sobota', id: 6,  activeHours: {from: "", to:""} },
    { label: 'niedziela', id: 0 ,  activeHours: {from: "", to:""}},
]


export default {
    name: 'EditLiveChatModal',
    props: {
        page: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            editedPage: {
                firstMessageDelay: 0
            },
            selectedGroups: [],
            isProcessing: false,
            triggerTimes: triggerTimes,
            weekDays: weekDays,
            groups: []
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        this.editedPage = JSON.parse(JSON.stringify(this.page)); //deep-copy
        if (!this.editedPage.schedule) this.editedPage.schedule = {};
        if (!this.editedPage.schedule.activeHours) this.editedPage.schedule.activeHours = { from: "08:00", to: "15:00"};
        if (!this.editedPage.schedule.activeDays) this.editedPage.schedule.activeDays = [1,2,3,4,5];

        for (let i = 0; i < weekDays.length; i++) {
            const day = weekDays[i];
            if (!this.editedPage.schedule[day.id+""]) this.editedPage.schedule[day.id+""] = { isEnabled: false, from: "08:00", to: "15:00"};
        }

        this.editedPage.schedule.timezoneOffset = (new Date()).getTimezoneOffset();

        EmployeeManager.getGroups().then(g => {
            this.groups = g;
            if (Array.isArray(this.editedPage.operatingGroups)) {
                this.selectedGroups = this.editedPage.operatingGroups.map(gId => {
                    return EmployeeManager.groups.find(g => g._id == gId);
                });
            }
        }).catch(e => {});

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        widgetCode: function() {
            return `<!--Start of Swiftresponse LiveChat Script-->
<script type="text/javascript">
(function (w,d,s,o,f,js,fjs) {
    w['SWchat-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
}(window, document, 'script', 'SWchat', '${LIB_URL}'));
SWchat('init', { url: 'https://${window.location.host}', pageId: '${this.editedPage._id}' });
<\/script>
<!--End of Swiftresponse LiveChat Script-->`;
        }
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },

        remove: async function (s) {
            
        },

        create: async function (s) {
            this.isProcessing = true;
            try {
                this.editedPage = await Api.tickets.livechat.create(this.editedPage);
                this.$n.success(this.$i18n.t('notifications.addSuccess'));
            } catch (error) {
                this.$n.error(this.$i18n.t('notifications.addError'));
            }
            this.isProcessing = false;
        },

        save: async function (s) {
            this.isProcessing = true;
            this.editedPage.operatingGroups = this.selectedGroups.map(g => g._id || g.id);
            try {
                await Api.tickets.livechat.update(this.editedPage._id, this.editedPage);
                this.$n.success(this.$i18n.t('notifications.changeSuccess'));
                this.$emit('close');
            } catch (error) {
                this.$n.error(this.$i18n.t('notifications.changeError'));
            }
            this.isProcessing = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.widget-code {
    min-height: 150px;
    font-family: monospace;
    font-size: 10px;
}
</style>
