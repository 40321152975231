<template>
    <div class="box live-chats">
        <h6 class="p-a">Aktywne Live Chat'y</h6>
        <div class="box-divider m-a-0"></div>
        <ul class="list-unstyled m-t-n-sm">
            <li class="list-group-item p-x-sm p-y-sm" v-for="a in activeChats" v-bind:key="a._id" v-on:click="openChat(a)" v-bind:class="{dker : selectedChats.map(s => s._id).indexOf(a._id) != -1}">
                <span class="pull-right">
                    <span  v-if="a.status == 'new'" class="label c-yellow text-white m-r-xs none-radius inline" title="new">N</span>
                    <span  v-else-if="a.status == 'open'" class="label c-green text-white m-r-xs none-radius inline" title="open">0</span>
                    <span  v-else-if="a.status == 'pending'" class="label c-blue text-white m-r-xs none-radius inline" title="pending">P</span>
                </span>
                <span v-if="selectedChats.map(s => s._id).indexOf(a._id) != -1" class="pull-left label c-green text-white m-r-xs inline active-label" title="Active"></span>
                <div >
                    <span v-if="a.shortId" class="text-primary text-bold text-u-c">#{{a.shortId}}</span>
                    <span v-if="a.status =='open'"><i class="material-icons md-22 text-yellow m-l-sm"></i></span>
                </div>
                <p class="m-t-xs m-b-0">
                <i class="material-icons md-16 m-r-xs text-muted ">person</i>
                    <small v-if="a.name && a.name.indexOf('@') != -1" class="text-muted ">{{a.name.split("@")[0]}}</small>
                    <small v-else-if="a.name && a.name.indexOf('@') == -1" class="text-muted ">{{a.name}}</small>
                    <small v-else class="text-muted">Klient</small>
                    <span class="pull-right text-red text-xs" v-if="a.channels.find(ch => ch.type =='chat').isDisabled">Nieaktywny</span>
                </p>
                <p class="text-xs m-t-xs m-b-0">
                    <span class="text-muted">{{EmployeeManager.getName(a.assignedUserId)}}</span>
                    <span class="pull-right text-blue">{{ a.emailDate | moment('from') }}</span>
                </p>
            </li>
        </ul>
        <p class="m-l-xs text-muted text-xs text-center" v-if="activeChats.length == 0">Brak aktywnych live chatów.</p>
    </div>
</template>
<script>
import EmployeeManager from '../../EmployeeManager.js';
export default {
    name: "ActivLiveChats",
    props: ["selectedChats", "activeChats"],
    components: {
    },
    data: function () {
        return {
           EmployeeManager:EmployeeManager,
        }
    },

    created: function() {
    },
    destroyed: function() {
    },
    methods: {
        openChat(selectedChat) {
            if(this.selectedChats.length == 4) {
                this.showNotify('notification-error', "Max liczba otwartych chatów to 4!");
                return;
            }
            if(this.selectedChats.map(a => a._id).indexOf(selectedChat._id) != -1) return;
            this.selectedChats.push(selectedChat);
        },
        showNotify (type, text) {
            this.$notify({
                group: 'global',
                type: type,
                text: text,
            })
        },
    }
}
</script>
<style  lang="scss"  scoped>
.live-chats {
    height: calc(100vh - 100px);
    width:100%;
    position: relative;
    border-spacing: 0;
    .list-unstyled {
        position: absolute;
        bottom: 0;
        top: 60px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 0;
    }
    .list-item:hover {
        cursor: pointer;
    }
    .active-label {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 4px;
    }
}
</style>