<template>
    <div class="login-overlay">

        <div v-if="isError" class="alert alert-danger error login-error text-center">
            <a class="close" data-dismiss="alert" href="#">×</a>
            You have entered an incorrect username or password.
        </div>
        <div class="row">
            <div class="col-md-12 m-t p-r-md">
                <div class="btn-group dropdown pull-right">
                    <button class="btn btn-sm dropdown-toggle text-white custom-dropdown _400" data-toggle="dropdown" aria-expanded="false">
                        {{lang}}
                    </button>
                    <div class="dropdown-menu dropdown-menu-scale">
                        <a class="dropdown-item" @click.prevent="changeLanguage('pl')">Polski <i v-if="selectedLang == 'pl'" class="material-icons md-20 coral m-l-xs">check</i></a>
                        <a class="dropdown-item" href="#">Deutsch</a>
                        <a class="dropdown-item" @click.prevent="changeLanguage('en')">English <i v-if="selectedLang == 'en'" class="material-icons md-20 coral m-l-xs">check</i></a>
                        <a class="dropdown-item" href="#">Čeština</a>
                        <a class="dropdown-item" href="#">Français</a>
                        <a class="dropdown-item" href="#">Nederlands</a>
                        <a class="dropdown-item" href="#">Svenska</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3 log-window" v-if="isLogPage">
                <h4 class="text-white _400 text-center">SwiftResponse CRM</h4>
                <p class="text-white _400 m-t-lg m-b-xs">{{ $t('login.sign') }}</p>
                <form class="form-horizontal" @submit.prevent="login">
                    <div class="form-group">
                        <input class="form-control" required="" id="email" type="email" v-model="email" :placeholder="$t('login.address')">
                    </div>
                    <div class="form-group">
                        <input class="form-control" required="" id="password" type="password" v-model="password" :placeholder="$t('login.password')">
                    </div>
                    <button type="submit" class="btn info btn-block none-radius text-white" :disabled="procesing == 1">{{ $t('login.sign') }}</button>
                    <div class="m-t-sm">
                        <a class="text-white _400" href="#" @click="isLogPage = false">{{ $t('login.forgotPass') }}</a>
                    </div>
                    <div class="text-center">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=pl.net.kozminski.swiftresponse&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" rel="noopener"><img style="max-width: 150px;" alt="pobierz z Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"></a>
                    </div>
                </form>
            </div>

            <div v-else class="col-md-3 log-window">
                <h2 class="text-white _400 text-center">{{ $t('login.res') }}</h2>
                <div v-if="!restSuccess && !newPassword">
                    <form class="login" @submit.prevent="reset">
                        <div class="form-group m-t">
                            <input class="form-control" required="" id="email" type="email" v-model="email" placeholder="Twój adres email">
                        </div>
                        <button type="submit" class="btn info btn-block none-radius text-white m-t-md" :disabled="procesing == 1"> {{ $t('login.btnRes') }}
                        </button>
                    </form>
                    <div class="m-t-sm">
                        <a class="text-white _400" href="#" @click="isLogPage = true">
                            <i class="material-icons md-18"></i>
                            {{ $t('login.backBtn') }}
                        </a>
                    </div>
                </div>

                <div v-if="restSuccess && !newPassword && !restError" class="text-center">
                    <p class="text-white _400">
                        {{ $t('login.infoCode') }}
                    </p>
                    <form class="login" @submit.prevent="passwordConfirm">
                        <div class="form-group m-t">
                            <input class="form-control" id="cod" type="text" v-model="code" placeholder="XXXX">
                        </div>
                        <button type="submit" class="btn info btn-block none-radius text-white m-t-md" :disabled="procesing == 1"> {{ $t('login.btnSub') }}
                        </button>
                    </form>
                </div>

                <div v-if="restError">
                    <p class="text-white _400 c-red m-t p-y-xs p-x-sm">
                        {{ $t('login.resErr') }}
                    </p>
                </div>
                <div v-if="codeError">
                    <p class="text-white _400 c-red m-t p-y-xs p-x-sm">
                        {{ $t('login.codeErr') }}
                    </p>
                </div>

                <div v-if="newPassword" class="text-center">
                    <p class="text-white _400">
                        {{ $t('login.newPassInfo') }}
                    </p>
                    <div class="m-t-sm">
                        <a class="text-white _400" href="#" @click="isLogPage = true">
                            <i class="material-icons md-18"></i>
                            {{ $t('login.backBtn') }}
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div class="technical-details">
            <p class="text-white" style="font-size: 0.7rem;line-height: 0.7rem;margin: 0;font-family: monospace;text-align: right;">
                front: {{ rev.hash }} <br>
                build: {{ rev.date }}
            </p>
        </div>
    </div>
</template>

<script>
import Api from './Api.js';
import UserManager from './UserManager.js'
import LanguageManager from './LanguageManager.js'

export default {
    name: 'Login',
    props: {

    },
    components: {
    },
    data: function () {
        return {
            rev: {
                version: __APPLICATION_VERSION__,
                hash: __COMMIT_HASH__,
                date: __BUILD_DATE__
            },
            email: '',
            password: '',
            token: '',
            isError: false,
            procesing: false,
            selectedLang: LanguageManager.i18n.locale,
            lang: 'English',
            isLogPage: true,
            restSuccess: false,
            restError: false,
            newPassword: false,
            codeError: false,
            code: ''
        }
    },
    methods: {
        login: function () {
            this.procesing = true;
            UserManager.logIn(this.email, this.password).then((token) => {
                this.procesing = false;
                if (token) {
                    this.token = token;
                } else {
                    this.isError = true;
                }
            });
        },
        reset: function() {
            this.procesing = true;
            Api.user.resetPassword(this.email).then((bolena) => {
                this.procesing = false;
                this.restSuccess = true;
            }).catch((reson) => {
                this.restError = true;
                this.procesing = false;
            });
        },
        passwordConfirm: function() {
            this.procesing = true;
            Api.user.resetPasswordConfirm(this.code).then((bolean) => {
                this.procesing = false;
                this.newPassword = true;
                this.code = '';
            }).catch((reson) => {
                this.codeError = true;
                this.procesing = false;
            });
        },
        changeLanguage: function(locale) {
            if (locale == 'pl') {
                this.lang = "Polski"
            } else {
                this.lang = 'English';
            }
            LanguageManager.loadLanguageAsync(locale).then(() => {
                this.selectedLang = locale
            });
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropdown-menu {
  min-width: 100px;
}
.login-overlay {
  background-image: url('./assets/images/rer.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;

  .log-window {
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .technical-details {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
.signup-image {
  background-image: url('./assets/images/signup-image.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  float: left;
  max-width: 100%;
  height: 558px;

}
.qt {
  padding-left: 60px;
  padding-right: 30px;
  line-height: 30px;
  position: absolute;
  font-size: 16px;
  bottom: 30px;
}
</style>
