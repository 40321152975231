<template>
    <div >
        <QuickTicketChatBox :ticketid="tId" v-for="tId in openChats" :key="tId" @close="closeBox(tId)" />
    </div>
</template>

<script>
import EmployeeManager from '../../EmployeeManager.js';
import Api from '../../Api.js';
import UserManager from '../../UserManager.js';
import SystemSocket from '../../SystemSocket.js';
import WelcomeWizzardModal from './WelcomeWizzardModal.vue';


export default {
    name: 'WelcomeWizzard',

    components: {
    },

    data: function () {
        return {
            openChats: [],
        }
    },

    created: function() {
        setTimeout(() => {
            this.checkIfWizzardIsApplicable();
        }, 600);
    },

    destroyed: function() {
    },

    methods: {
        checkIfWizzardIsApplicable: async function() {
            try {
                let emps = await EmployeeManager.getEmployees();
                if (emps.length > 1) return; // pomoc wyswietlamy tylko pierwszemu uzytkownikowi

                let wasWizzardSeen = await Api.storage.get("wasWizzardSeen");
                if (wasWizzardSeen) return;

                this.openModal()
                UserManager.wizzardEnabled = true;
            } catch (error) {}
        },

        openModal (message, raport) {
            this.$modal.show(WelcomeWizzardModal, {
            }, {height: 'auto', width: '70%' });
        },
    }
}
</script>

<style>

</style>