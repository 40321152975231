<template>
<div class="m-b">
  
  <small class="m-l text-muted">Sort by:</small>
  <div class="btn-group dropdown">
    <button class="btn dropdown-toggle btn-sm custom-dropdown primary-color" data-toggle="dropdown" aria-expanded="false">{{sortElement}}</button>
    <div class="dropdown-menu dropdown-menu-scale">
      <a class="dropdown-item" href="#" v-on:click.event="sortBy('Date:DESC')">Date (newest first)</a>
      <a class="dropdown-item" href="#" v-on:click.event="sortBy('Date:ASC')">Date (oldest first)</a>
      <a class="dropdown-item" href="#" v-on:click.event="sortBy('Date:ASC')">Read (unread first)</a>
      <a class="dropdown-item" href="#" v-on:click.event="sortBy('Priority')">Correspondent</a>
    </div>
  </div>
  <form class="navbar-form form-inline pull-right pull-none-sm v-m" role="search">
    <div class="form-group l-h m-a-0">
      <div class="input-group search-input-mails">
        <input class="form-control form-control-sm p-x b-a" :placeholder="[[ placeholderText ]]" type="text" v-model="search" v-on:input="onFilterChange">
        <a class="close-btn" href="#" v-on:click.prevent="clearSearch" v-if="search.length > 0 || placeholderText == 'Results of advanced search'">
          <i class="material-icons md-16 text-muted">close</i>
        </a>
        <a class="more-option" href="#" title="Show search options" v-on:click="isActive = true">
          <i class="material-icons md-16 text-muted" >arrow_drop_down</i>
        </a>
      </div>
    </div>
  </form>

  <div class="box extended-search-box m-a" v-if="isActive">
    <div class="box-body">
      <form class="form-horizontal primary-color">
        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label">From</label>
          <div class="col-sm-8 col-md-10">
            <input class="form-control" type="email" v-model="extendedSearch.from">
          </div>
        </div>
        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label">To</label>
          <div class="col-sm-8 col-md-10">
            <input class="form-control" type="email" v-model="extendedSearch.to">
          </div>
        </div>
        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label">Subject</label>
          <div class="col-sm-8 col-md-10">
            <input class="form-control" type="text" v-model="extendedSearch.subject">
          </div>
        </div>
        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label">Content</label>
          <div class="col-sm-8 col-md-10">
            <input class="form-control" type="text" v-model="extendedSearch.body">
          </div>
        </div>

        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label">Where</label>
          <div class="col-sm-8 col-md-10">
            <select2 :options="optionsEngine" class="form-control" v-model="extendedSearch.selectedFolders">
            </select2>
          </div>
        </div>

        <div class="form-group row m-b-xs">
          <label class="col-sm-4 col-md-2 form-control-label"></label>
          <div class="col-sm-8 col-md-10">
            <div class="checkbox m-b-xs">
              <label class="ui-check">
                <input type="checkbox" value="" v-model="extendedSearch.unseen">
                <i class="dark-white"></i>
                Only unread 
              </label>
            </div>
          </div>
        </div>

        <a class="m-t-xs nav-link btn btn-xs white none-radius" v-on:click="advancedSearch" v-bind:class="{ disabled : procesing }">
          <span class="primary-color _400">Search</span>
        </a>
        <a class="m-t-xs nav-link btn btn-xs white none-radius m-l-sm" v-on:click="isActive = false" 
        v-bind:class="{ disabled: procesing }">
          <span class="primary-color _400">Cancel</span>
        </a>
      </form>
    </div>
  </div>

</div>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import Select2 from '../Select2.vue';

var timeoutHandler = null;

export default {
  name: 'SortAndSearchMailsList',
  props: {
  },
  components: {
    Select2
  },
  data: function () {
    return {
      placeholderText: 'Search...',
      procesing: false,
      sortElement: 'Select',
      search: '',
      isActive: false,
      extendedSearch: {},
      optionsEngine: [
        { id: '1', text: 'Search only in this folder' },
        { id: '2', text: 'Search in all folders' },
        { id: '3', text: 'Search in all boxes and folders' },
      ],
    }
  },
  created: function() {
    window.addEventListener('keyup', this.handleKeyUp);
    EventBus.$on('newSearchResultLoaded', this.handleAdvencedSearch);
  },
  destroyed: function() {
    window.removeEventListener('keyup', this.handleKeyUp);
    EventBus.$off('newSearchResultLoaded', this.handleAdvencedSearch);
  },
  methods: {
    sortBy: function() {

    },
    onFilterChange: function() {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        EventBus.$emit('filterMailsChange', {
          search: this.search,
        });
      }, 1200);
    },
    clearSearch: function() {
      if (this.search.length == 0 && !this.extendedSearch) return;
      this.search = '';
      this.placeholderText = "Search...";
      this.onFilterChange();
    },
    advancedSearch: function() {
      this.procesing = true;
      EventBus.$emit('filterMailsChange', {extendedSearch: this.extendedSearch,});
    },
    handleAdvencedSearch: function() {
      this.procesing = false;
      this.isActive = false;
      this.placeholderText = 'Results of advanced search';
      this.extendedSearch = {};
    },
    handleKeyUp: function(e) {
      if (e.keyCode == 27 && this.isActive) {
        this.isActive = false;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.search-input-mails {
  position: relative;
  min-width: 250px;
  a {
    padding: 1px 4px;
  }
  .close-btn {
    position: absolute;
    top: 4px;
    z-index: 1000;
    right: 28px;
  }
  .close-btn:hover {
    background-color: rgba(158, 158, 158, 0.1);
  }
  .more-option {
    @extend .close-btn;
    right: 5px;
  }
  .more-option:hover {
    background-color: rgba(158, 158, 158, 0.1);
  }
}
.extended-search-box {
  position: absolute;
  z-index: 1000;
  min-width: 350px;
  right: -5px;
  top: 15px;
  input {
    height: 26px;
    max-height: 26px;
    min-height: 10px;
  }
  label {
    padding: .1rem .75rem;
  }
  .checkbox {
    margin-left: 10px;
  }
}
</style>
