import { render, staticRenderFns } from "./CannedResponses.vue?vue&type=template&id=60bb8d47&scoped=true"
import script from "./CannedResponses.vue?vue&type=script&lang=js"
export * from "./CannedResponses.vue?vue&type=script&lang=js"
import style0 from "./CannedResponses.vue?vue&type=style&index=0&id=60bb8d47&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bb8d47",
  null
  
)

export default component.exports