import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './assets/lang/en.json'
import axios from 'axios'
import UserManager from './UserManager.js'
import Api from './Api.js'
Vue.use(VueI18n)



function LanguageManager() {
    this.i18n =new VueI18n({
      locale: 'en', // set locale
      fallbackLocale: 'en',
      messages: {en} // set locale messages
    })
    this.loadedLanguages = ['en']
    this.lang = '';

    UserManager.events.on('userLangReady', () =>{
      this.lang = UserManager.selectedLanguage;
      this.loadLanguageAsync(this.lang).then(() => {});
    });

}

LanguageManager.prototype.setI18nLanguage = function(lang) {
    
  this.lang = lang;

  this.i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
};

LanguageManager.prototype.loadLanguageAsync = function(lang) {
    if (this.i18n.locale !== lang) {
        if (!this.loadedLanguages.includes(lang)) {
          return import(/* webpackChunkName: "lang-[request]" */ `./assets/lang/${lang}.json`).then(msgs => {
            this.i18n.setLocaleMessage(lang, msgs.default)
            this.loadedLanguages.push(lang)
            return this.setI18nLanguage(lang)
          })
        } 
    return Promise.resolve(this.setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
};

var instance = new LanguageManager();

export default instance;

// export const i18n = new VueI18n({
//   locale: 'en', // set locale
//   fallbackLocale: 'en',
//   messages: {en} // set locale messages
// })

// const loadedLanguages = ['en']

// function setI18nLanguage (lang) {
    
//   i18n.locale = lang
//   axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// function loadLanguageAsync (lang) {
//     console.log(lang)
//   if (i18n.locale !== lang) {
//     if (!loadedLanguages.includes(lang)) {
//       return import(/* webpackChunkName: "lang-[request]" */ `./assets/lang/${lang}`).then(msgs => {
//         i18n.setLocaleMessage(lang, msgs.default)
//         loadedLanguages.push(lang)
//         return setI18nLanguage(lang)
//       })
//     } 
//     return Promise.resolve(setI18nLanguage(lang))
//   }
//   return Promise.resolve(lang)
// }

// // const lang = 'en'
// loadLanguageAsync('en').then(() => {console.log('hello');})