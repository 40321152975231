import Api from './Api.js';
import SystemSocket from './SystemSocket.js';

let pendingPromise = null;

function EmployeeManager() {
  this.employees = [];
  this.groups = [];
  this._isDataValid = true;

  SystemSocket.socket.on('object', async (msg) => {
    if (msg.object != "group") return;

    // prosty update dostepnych grup, bez zadnej zabawy w podstawienie, itp
    this.groups = await Api.users.getGroups();
  });
}

EmployeeManager.prototype.invalidate = function() {
  this._isDataValid = false;
}

EmployeeManager.prototype.getGroups = async function() {
  if (this.groups.length) return this.groups;
  this.groups = await Api.users.getGroups();
  return this.groups;
}

/**
 * @returns {Promise<any[]>}
 */
EmployeeManager.prototype.getEmployees = function() {
  if (pendingPromise != null) { return pendingPromise; }

  pendingPromise = new Promise((resolve, reject) => {
    if (this.employees.length && this._isDataValid) {
      setTimeout(() => {
          pendingPromise = null;
      }, 1);
      resolve(this.employees);
      return;
    }

    Api.users.getEmployees()
      .then((res) => {
        this.employees = res;
        this._isDataValid = true;
        resolve(this.employees);
        pendingPromise = null;
      })
      .catch((reason) => {
        resolve(this.employees);
        pendingPromise = null;
      });
  });

  return pendingPromise;

};

EmployeeManager.prototype.getById = function(id) {
  return this.employees.find(emp => emp.id+"" == id+"");
};

EmployeeManager.prototype.getName = function(id) {
  let empIds = this.employees.map((emp) => {
    return emp.id+"";
  }); 
  let idx = empIds.indexOf(id+"");
  if (idx != -1) {
    return this.employees[idx].name;
  } else {
    return "Not assignee"
  }
}

EmployeeManager.prototype.getEmail = function(id) {
  let empIds = this.employees.map((emp) => {
    return emp.id+"";
  }); 
  let idx = empIds.indexOf(id+"");
  if (idx != -1) {
    return this.employees[idx].email;
  } else {
    return "Not email"
  }
}

var instance = new EmployeeManager();

export default instance;

