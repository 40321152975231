<template>
<div>
    <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.dailyActivity.title')}}</h6>
    </div>

    <div class="row m-y">

        <div class="col-md-2" style="border-right:1px solid rgba(120, 130, 140, 0.13);">
            <div class="form-group m-r">
                <a class="m-l m-r btn-block btn btn-sm primary none-radius text-white" v-on:click.prevent="search">Szukaj</a>
            </div>
            <p class="primary-color _500 m-l m-t">{{ $t('ticket.filters.chooseDate') }}</p>
            <div class="input-group m-l">
                <div class="datepicker-trigger">
                    <input
                        class="form-control form-control-sm p-x b-a"
                        type="text"
                        id="datepicker-trigger"
                        :placeholder=" $t('ticket.filters.chooseDate')"
                        :value="formatDates(dateOne, dateTwo)"
                    >

                    <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger'"
                        :mode="'single'"
                        :date-one="dateOne"
                        :monthsToShow="1"
                        :showShortcutsMenuTrigger="false"
                        v-on:closed="changeDataColectionByEmps"
                        @date-one-selected="val => { dateOne = val }"
                    />
                </div>
            </div>
            <div class="form-group m-t-md raports">
                <p class="primary-color _500 m-l" style="display: inline;">{{$t('raports.selectTitle')}}:</p>
                <div class="pull-right text-muted text-xs">
                    <a data-toggle="collapse" href="#assignees" aria-expanded="false" aria-controls="assignees">
                    <i class="fa fa-plus"></i>
                </a>
                </div>
                <div class="m-t collapse" id="assignees">
                <div class="form-group l-h m-a-0 m-l">
                    <div class="input-group" style="width: 100%;">
                    <input class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.filters.searchAss')" type="text" v-model="searchEmployees">
                    </div>
                </div>
                <div class="box-body">
                    <ul class="list-unstyled m-t-n-sm">
                    <div v-if="employees.length > 0">

                        <div v-for="a in employees"
                        :attach="a"
                        :key="a.id"
                        v-show="!searchEmployees.length || a.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1">
                        <li class="checkbox" >
                            <label class="ui-check text-xs ">
                            <input type="checkbox" v-on:change="changeDataColectionByEmps"  v-model="selectedEmps" v-bind:value="a.id">
                            <i class="dark-white"></i>
                                {{a.name}}
                            </label>
                        </li>
                        </div>

                    </div>
                    <div v-else class="text-muted">{{ $t('ticket.filters.notEmps') }}</div>
                    </ul>
                    <p class="primary-color _500">{{ $t('ticket.filters.groups') }}</p>
                    <ul class="list-unstyled m-t-n-sm">
                    <li class="checkbox">
                        <label class="ui-check text-xs ">
                        <input type="checkbox" v-on:change="changeDataColectionByEmps" value="1" v-model="selectedEmps" >
                        <i class="dark-white"></i>
                            Design Support
                        </label>
                    </li>
                    <li class="checkbox">
                        <label class="ui-check text-xs ">
                        <input type="checkbox" v-on:change="changeDataColectionByEmps" value="2" v-model="selectedEmps" >
                        <i class="dark-white"></i>
                            Product Support
                        </label>
                    </li>
                    </ul>
                </div>
            </div>
            </div>
        </div>

        <div class="small col-md-10">
            <div class="super-puper-timeline">
            </div>

            <div v-if="!isReady" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, 0%); z-index:10000" >
                <Spinner></Spinner>
            </div>

        </div>
        
    </div>
</div>
</template>
<script>
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';
import { Timeline } from "vis-timeline/standalone"
import Spinner from '../Spinner.vue';
import format from 'date-fns/format';

export default {
  name: 'SentMessages',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    Spinner
  },
  data: function () {
    return {
      employees: [],
      isReady: false,
      selectedEmps: [],
      searchEmployees: '',
      dateFormat: 'D MMM',
      dateOne: '',
      dateTwo: '',
      timeline: null
    }
  },
  created: function() {
    this.getEmployees();
  },

    mounted: function() {
        let container = document.querySelector("div.super-puper-timeline");
        this.timeline = new Timeline(container, [], [], {
            editable: false,
            stack: false
        });
    },

    destroyed: function() {
    },

    methods: {
        changeDataColectionByEmps: function() {

        },
        getEmployees: function() {
            return EmployeeManager.getEmployees().then((employees) => {
                this.employees = employees;
                this.isReady = true;
            });
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = '';
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat);
            }
            return formattedDates;
        },
        search: async function() {
            this.isReady = false;
            let data = {dateFrom: this.dateOne+"T00:00:00", dateTo:this.dateOne+"T23:59:00", groupTime: 300000, userId: this.selectedEmps};
            try {
                let raports = await Api.raport.getUsersActiveHours(data);
                this.isReady = true;
                this.createTimeLine(raports);
            } catch (error) {
                console.log("err", error);
            }
        },
        createTimeLine: function(raports) {
            var groups = [];
            var items = [];

            for (let i = 0; i < this.selectedEmps.length; i++) {
                let grup = {};
                const idEmp = this.selectedEmps[i];
                grup["id"] = idEmp;
                let emp = this.employees.find(e => e._id+"" == idEmp+"");;
                if(emp == null) continue;
                grup["content"] = emp.name;
                groups.push(grup);

                let arrays = raports[idEmp];
                if (!arrays) continue;
                for (let y = 0; y < arrays.length; y++) {
                    var item = {};
                    const el = arrays[y];
                    item["group"] = idEmp;
                    item["start"] = el[0];
                    item["end"] = el[1];
                    items.push(item);
                }
                
            }
            this.timeline.setOptions({
                min: new Date(this.dateOne+"T00:00:00"),
                max: new Date(this.dateOne+"T23:59:00")
            });
            this.timeline.setItems(items);
            this.timeline.setGroups(groups);
            this.timeline.redraw();
            this.timeline.moveTo(new Date(this.dateOne+"T12:00:00"));
        }
        
    }
}
</script>