module.exports = {
    apiUrl: window.location.protocol+'//'+window.location.host+'/api/',
    activitylog: {
        host: window.location.host,
        options: { path: '/api/activity/socket' }
    },
    chat: {
        host: window.location.host,
        options: { path: '/chat/socket.io' }
    },
    push: {
        host: window.location.host,
        options: { path: '/pusher/socket.io' }
    }
};