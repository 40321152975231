<template>
    <div class="box">
        <div class="box-header">
            <h3>Edycja skrzynki odbiorczej</h3>
            <small>Każda wiadomość, która trafi na tą skrzynkę<br/>zostanie automatycznie zamieniona na zgłoszenie</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <label class="md-check">
                    <input type="checkbox" value="automatic" v-model="editedBox.sendReceiveConfirm">
                    <i class="primary m-r-xs"></i>
                    <span class="m-l-xs">Potwierdzenie otrzymania wiadomości dla klienta</span>
                </label>
                <textarea style="min-height: 300px" class="form-control form-control-sm comment-box-input m-t" v-model="editedBox.receiveConfirmContent" ></textarea>
                <span class="help-block text-sm m-b-none text-muted">
                    Możesz użyć znaczników - zostaną one zamieniona na odpowiednią wartość przed wysłaniem:<br/>
                    [ID] - Numer zgłoszenia<br/>
                    [SUBJECT] - Temat wiadomosci od klienta
                </span>

                <label class="md-check m-t">
                    <input type="checkbox" value="a" v-model="editedBox.autoAssign">
                    <i class="primary m-r-xs"></i>
                    <span class="m-l-xs">Automatycznie przypisz pracownika do zgłoszenia</span>
                </label>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'InternalBoxModal',
    props: {
        box: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            editedBox: {},
            address: '',
            host: window.location.host,
            isProcessing: false,
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        this.editedBox = JSON.parse(JSON.stringify(this.box)); //deep-copy

        if (!this.editedBox.receiveConfirmContent) {
            this.editedBox.receiveConfirmContent = "Dziękujemy za przesłaną wiadomość o temacie \"[SUBJECT]\"\n";
            this.editedBox.receiveConfirmContent += "Została ona zarejestrowana pod numerem #[ID]\n\n";
            this.editedBox.receiveConfirmContent += "Postaramy się odpowiedzieć jak najszybciej na Twoje zgłoszenie.\n";
        }
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },
        save: function (s) {
            Api.tickets.updateInternalBox(this.editedBox._id, this.editedBox).then(box => {
                this.$notify({
                    group: 'global',
                    type: 'notification-success',
                    text: this.$i18n.t('notifications.changeSuccess'),
                });
                this.$emit('close');
            }).catch(e => {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: this.$i18n.t('notifications.changeError'),
                });
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
