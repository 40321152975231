<template>
  <div class="">
    <div>
      <div class="box-header d-gray">

        <h6 class="text-muted _400 inline">Tags</h6>
        <div class="pull-right m-l-sm minus-margin">
          <a class="btn btn-sm white none-radius" v-on:click="">
            <span class="primary-color _400">Delete</span>
          </a>&nbsp;
        </div>
        <div class="pull-right minus-margin">
          <div class="input-group">
            <input class="form-control form-control-sm" placeholder="Add new tag..." type="text" v-model="newTagName">
            <span class="input-group-btn">
              <button class="btn white b-a no-shadow text-color none-radius btn-sm" type="button" style="height: 32px;" v-on:click="addNewTag" :disabled="procesing == 1"><span class="primary-color _400">Add</span></button>
            </span>
          </div>
        </div>

      </div>
      <div class="box-body">
        <div class="row">

          <div class="col-lg-2 col-md-3" v-for="(values, key) in tagsAlphabetic" style="max-width: 160px;">
            <div class="box-header d-gray text-center upper-text">{{key}}</div>
            <div class="box-body">
              <!-- <label class="ui-check text-xs" v-for="v in values">
                <input type="checkbox">
                <i class="dark-white"></i>
                {{v.name}}
              </label> -->
              <label class="md-check" v-for="v in values">
                <input type="checkbox">
                <i class="primary"></i>
                 {{v.name}}
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';

export default {
  name: 'TagsPage',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2
  },
  data: function () {
    return {
      tags: [
        { id: 1, name: 'Question'},
        { id: 2, name: 'Problem'},
        { id: 2, name: 'Point'},
        { id: 3, name: 'Task'},
        { id: 3, name: 'Spam'},
      ],
      tagsAlphabetic: {},
      procesing: false,
      newTagName:'',
      textSuccess: 'New user has been successfully added.',
      textError: 'There was a problem with ',
      textEmpty: ' to perform this action.',
      textPermission: 'You do not have permission to ',
    }
  },
  created: function() {
    this.arrayTagsAlphabetic();
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    addNewTag: function() {
      if (!this.newTagName.length) {this.showNotify('notification-error', 'You must provide tag name'+this.textEmpty); return;}
      this.roles.push({id:4, name:this.newTagName});
    },
    arrayTagsAlphabetic: function() {
      
      this.tags.forEach((tag)=> {
        let firstLetter = tag.name.charAt(0).toLowerCase();
        if (!this.tagsAlphabetic[firstLetter]) {
          this.tagsAlphabetic[firstLetter] = [];

        }
        this.tagsAlphabetic[firstLetter].push(tag);

      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  
</style>
