<template>
    <li class="nav-item m-a-0" style="padding-top: 5px;">
        <img title="Piszesz wiadomosc..." :class="{'text-muted': !isTyping}" class="m-l-xs activity-icon" style="width: 16px" src="../../assets/images/pen.svg"><br/>
        <img title="Bezczynność..." :class="{'text-muted': isActive}" class="m-l-xs activity-icon" style="width: 16px" src="../../assets/images/stopwatch.svg">
    </li>
</template>

<script>

const IDLE_TIME_DETECT_SEC = 15;
const NOT_TYPING_DETECT_SEC = 8;

let timeoutH = null;
let keystrokeI = null;

import SystemSocket from '../../SystemSocket.js';

export default {
    name: "ActivityTracker",
    data: function () {
        return {
            isActive: false,
            isTyping: false,
            keystrokesCount: 0,
            typingTimeout: null
        }
    },
    created: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        window.addEventListener('click', this.handleMouseClick);
        window.addEventListener('mousemove', this.handleMouseMove);

        keystrokeI = setInterval(() => {
            if (this.keystrokesCount < 1) return; 
            this.keystrokesCount -= 1;
        }, 1000);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
        window.removeEventListener('click', this.handleMouseClick);
        window.removeEventListener('mousemove', this.handleMouseMove);

        clearInterval(keystrokeI);
    },
    methods: {
        handleKeyUp: function(e) {
            
            if (e.keyCode > 47 && e.keyCode < 91) { // litery i cyfry
                this.keystrokesCount += 1;
                this.setAsActive();

                if (this.keystrokesCount > 3) {
                    if (this.isTyping == false) this.notifyStartTyping(e.target);
                    this.isTyping = true;
                    this.typingTimer();
                }
            }
        },

        notifyStartTyping: function(target) {
            let currRoute = this.$router.currentRoute;
            if (currRoute.name == 'ticket' && currRoute.params.id && target.classList.contains('new-message-area')) {
                SystemSocket.activitySocket.emit('set typing on', {
                    type: "ticket",
                    id: currRoute.params.id
                });
            } else if (currRoute.name == 'live-chat' && target.classList.contains('live-chat-msg') && target.attributes.ticket) {
                SystemSocket.activitySocket.emit('set typing on', {
                    type: "ticket",
                    id: target.attributes.ticket.value
                });
            } else {
                SystemSocket.activitySocket.emit('set typing on');
            }

        }, 

        handleMouseClick: function(e) {
        },

        handleMouseMove: function(e) {
            let distance = Math.abs(e.movementX) + Math.abs(e.movementY);
            if (distance < 10) return;

            this.setAsActive();
        },

        setAsActive: function() {
            if (this.isActive == false) {
                SystemSocket.activitySocket.emit('set active');
            }
            this.isActive = true;
            this.idleTimer();
        },

        typingTimer: function() {
            if (this.typingTimeout) clearTimeout(this.typingTimeout);
            this.typingTimeout = setTimeout(() => {
                this.isTyping = false;
                SystemSocket.activitySocket.emit('set typing off');
            }, NOT_TYPING_DETECT_SEC*1000);
        },

        idleTimer: function() {
            if (timeoutH) clearTimeout(timeoutH);
            timeoutH = setTimeout(() => {
                this.isActive = false;
                SystemSocket.activitySocket.emit('set idle');
            }, IDLE_TIME_DETECT_SEC*1000);
        }
    }
}
</script>

<style scoped>
img.activity-icon.text-muted {
    opacity: 0.2;
}
</style>