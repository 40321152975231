<template>
    <div >
        <rotate-square2 v-if="isSaving" style="position:absolute; right: 15px; top: 0px;"></rotate-square2>
        <p style="max-width: 500px; position:relative;">
            <span class="text-u-c _600">Rozpoznawalność</span><br/>
            Ustaw swoje logo i podpis w wiadomościach, żeby klienci wiedzieli, że to wiadomość od Ciebie
        </p>

        <div class="box-body fb-box" v-if="!isLoading" style="max-width: 500px; max-height: 400px; overflow-y: auto">
            <form class="form-horizontal">
                <div class="form-group row m-b">
                    <label class="col-md-4 form-control-label label-sm m-t-xs">{{ $t('settings.tabPrefernces.mailer.fromName')}}</label>
                    <div class="col-md-8">
                        <input class="form-control input-time" type="text" v-model="configuration.fromName" v-on:input="save">
                    </div>
                </div>

                <div class="form-group row m-b">
                    <label class="col-md-4 form-control-label label-sm m-t-xs">{{ $t('settings.tabPrefernces.mailer.footer')}}</label>
                    <div class="col-md-8">
                        <textarea class="form-control input-time" type="text" v-model="configuration.footerInfo" v-on:input="save"></textarea>
                    </div>
                </div>

                <div class="form-group row m-b">
                    <label class="col-md-4 form-control-label label-sm m-t-xs">{{ $t('settings.tabPrefernces.mailer.logo')}}</label>
                    <div class="col-md-8">
                        <input class="form-control input-time" type="file" ref="logoFile" v-on:change="onFilesInputChange">
                    </div>
                </div>

                <div class="form-group row m-b" v-if="configuration.logoUrl">
                    <img :src="configuration.logoUrl" style="max-height:100px;">
                </div>

            </form>

            <a class="nav-link btn btn-xs white none-radius m-r m-b" v-on:click="sendTestEmail">
                <span class="primary-color _400">{{ $t('settings.tabPrefernces.mailer.send_test_email')}}</span>
            </a>
        </div>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('next')">Dalej</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Pomiń</a>
        </p>

        
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import EmployeeModal from '../SettingsPage/Modals/EmployeeModal.vue';
import {RotateSquare2} from 'vue-loading-spinner'

let timeout = null;

export default {
    name: 'SectionLogo',

    components: {
        RotateSquare2
    },

    data: function () {
        return {
            isLoading: true,
            isSaving: false,
            configuration: {}
        }
    },

    created: async function() {
        try {
            this.configuration = await Api.mailer.getConfiguration();
        } catch (error) {
        }
        this.isLoading = false;
    },

    destroyed: function() {
    },

    computed: {
        test: function() {
        }
    },

    methods: {
        save: function () {
            clearTimeout(timeout);
            timeout = setTimeout(async () => {
                this.isSaving = true;
                await Api.mailer.saveConfiguration(this.configuration);
                this.isSaving = false;
            }, 1000);
        },
        
        onFilesInputChange: function() {
            let files = this.$refs.logoFile.files;
            for (let i = files.length - 1; i >= 0; i--) {
                let url = URL.createObjectURL(files[i]);
                let img = new Image();
                img.onload = (e) => {
                    if (img.width < img.height) {
                        return alert("Sorry, logo must be in horizontal format");
                    }

                    if (img.width > 500 || img.height > 300) {
                        return alert("Sorry, maximum size of logo is 500x300px");
                    }

                    this.handleFileUpload(files[i]);
                    URL.revokeObjectURL(url);
                };
                img.src = url;
            }
        },

        handleFileUpload: async function (file) {
            let formData = new FormData();
            formData.append('file', file);

            this.isSaving = true;
            let fileInfo = await Api.uploadFile(formData, (progressEvent) => {});
            this.configuration.logoUrl = fileInfo.fileUrl;
            await Api.mailer.saveConfiguration(this.configuration);
            this.isSaving = false;
        },

        sendTestEmail: async function () {
            this.isSaving = true;
            try {
                await Api.mailer.sendTestMessage();
            } catch (error) { console.log(error); }
            this.isSaving = false;
        },
    }
}
</script>

<style>

</style>