<template>
    <div class="custom-view-wrapper">
      <p v-if="options.html" v-html="messageBody"></p>
      <p v-else>{{ messageBody }}</p>
      <button class="nav-link btn btn-xs white none-radius" href="#" @click="cancel()">Cancel</button>
      <button class="nav-link btn btn-xs white none-radius pull-right" href="#" @click="proceed()">Confirm</button>
    </div>
</template>

<script>
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'

export default {
  name: 'Confirmation',
  mixins: [ DialogMixin ],
  components: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
