<template>
    <div >
        <img src="../../assets/images/chat.svg" style="width: 200px; float: right; margin-right: 50px;" />
        <p style="max-width: 500px;">
            <span class="text-u-c _600">Twój zespół</span><br/>
            Dodaj użytkowników z Twojego zespołu do Swiftresponse aby jeszcze sprawniej zarządząc komunikacją z klientem!
        </p>

        <p class="m-a" style="max-width: 500px; max-height: 400px; overflow-y: auto">
            <ul class="list inset" v-if="Array.isArray(employees) && employees.length">
                <li class="list-item" v-for="emp in employees" :key="emp._id">
                    <div class="list-body">
                    <div>
                        <strong><i class="material-icons text-success md-18">check</i> {{ emp.name }}</strong>
                        <span v-if="emp.roles.indexOf('admin') > -1"> - Admin</span>
                    </div>
                    <small class="text-muted text-ellipsis">
                        <i class="material-icons text-success md-18">perm_identity</i>
                        {{ emp.email }}
                    </small>
                    </div>
                </li>
                <li class="list-item text-info">
                    <a href="#" @click.prevent="addEmployee"><i class="material-icons  md-18">add</i> Dodaj użytkownika</a>
                </li>
            </ul>
        </p>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('next')">Dalej</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Pomiń</a>
        </p>

        
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import EmployeeModal from '../SettingsPage/Modals/EmployeeModal.vue';

export default {
    name: 'SectionTeam',

    components: {
    },

    data: function () {
        return {
            employees: []
        }
    },

    created: function() {
        EmployeeManager.getEmployees().then((employees) => {
            this.employees = employees;
        });
    },

    destroyed: function() {
    },

    computed: {
        test: function() {
        }
    },

    methods: {
        addEmployee: function() {
            this.$modal.show(EmployeeModal, {}, {
                draggable: true,
                scrollable: true,
                height: 'auto'
            }, {
                'closed': (event) => {
                    EmployeeManager.getEmployees().then((employees) => {
                        this.employees = employees;
                    });
                }
            });
        },
    }
}
</script>

<style>

</style>