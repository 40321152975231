<template>
  <div>
    <div class="box">
      <div class="box-header">
        <h6 class="title-color inline">{{ $t('ticket.linkedShops.shopOrder') }}</h6>
      </div>
      <div class="m-a-0 custom-divider"></div>
      <div class="box-body">
        <Spinner v-if="isLoading"></Spinner>
        <div v-if="isOrders">
          <ul class="list-unstyled" v-if="orderByEmail.length > 0">
            <li v-for="o in orderByEmail">
              <span class="primary-color">
                #{{o.orderId}} - {{o.address.name}}
              </span>
              <!-- <a class="nav-link pull-right coral" title="expand" data-toggle="collapse" v-bind:href="'#order_'+o.orderId" aria-expanded="false" aria-controls="collapseExample" >
                <i class="material-icons md-18">add</i>
              </a> -->
              <a class="nav-link pull-right coral" title="Show details" @click.prevent="showOrderDetails(o)" >
                <i class="material-icons md-18">add</i>
              </a>
              <br/>
              <span class="text-muted text-sm">{{o.shopName}} | {{o.createdAt| moment("DD MMM YYYY")}}</span>
              <br/>
              <span class="text-sm text-muted">{{ $t('ticket.linkedShops.status') }}: <span class="coral">{{o.status}}</span></span>
              <div class="collapse" v-bind:id="'order_'+o.orderId">
                <ul class="list-group no-border">
                  <div class="custom-divider m-a-0 p-t-sm"></div>
                  <li class="list-group-item no-border p-t-xs p-l-xs" >
                    <span class="primary-color m-b-xs">{{ $t('ticket.linkedShops.deliveryAddress') }}:</span>
                    <div class="clear custom-color">
                      <div>
                        <span class="text-sm">{{o.address.name}}</span>
                      </div>
                      <span class="text-sm" v-if="o.address.address">{{o.address.address}}</span>
                      <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noAddress') }}</span>
                      <div>
                        <span class="text-sm" v-if="o.address.postCode">{{o.address.postCode}}</span>
                        <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noPost') }}</span>
                        <span class="text-sm" v-if="o.address.city">{{o.address.city}}</span>
                        <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noCity') }}</span>
                      </div> 
                      <div>
                        <span class="text-sm" v-if="o.address.phone">tel. {{o.address.phone}}</span>
                        <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noPhone') }}</span>
                      </div>
                    </div>
                  </li>
                  <div class="custom-divider m-a-0"></div>
                  <li class="list-group-item no-border p-t-xs p-l-xs">
                    <span class="primary-color m-b-xs">{{ $t('ticket.linkedShops.payment') }}:</span>
                    <div class="clear custom-color">
                      <div>
                        <span class="text-sm">{{o.payment}}</span>
                      </div>
                        <span class="text-sm" v-if="o.total">{{ $t('ticket.linkedShops.totalToPay') }}: {{o.total}} zł</span>
                        <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noTotalPay') }}</span>
                      <div>
                        <span class="text-sm" v-if="o.totalPaid">{{ $t('ticket.linkedShops.total') }}: {{o.totalPaid}} zł</span>
                        <span class="text-sm" v-else>{{ $t('ticket.linkedShops.noTotal') }}</span>
                      </div> 
                    </div>
                  </li>
                </ul>
              </div>
              <div class="box-divider m-a-0 m-b-sm m-t-sm"></div>
            </li>
          </ul>
        </div>
        <div v-if="isEmpty">
          <p class="text-muted text-sm">{{ $t('ticket.linkedShops.noOrders') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import TicketManager from '../../TicketManager.js';
import LinkedOrderInfoModal from './LinkedOrderInfoModal.vue'

export default {
  name: 'TicketLinkedShopInformationByEmail',
  props: {
    ticketData: Object
  },
  components: {
    Spinner
  },
  data: function () {
    return {
      isOrders: false,
      isLoading: false,
      isEmpty: false,
      orderByEmail: []
    }
  },
  created: function() {
    this.loadOrdersByEmail();
  },
  methods: {
    loadOrdersByEmail: function() {
      let email = TicketManager.getChannelValueForTicket(this.ticketData, 'email');
      if (!email) return;
      this.isLoading = true;

      Api.getOrdersfromShopByCustomerEmail(email).then((orderByEmail) => {
        this.isLoading = false;

        if (!orderByEmail.length) {
          this.isEmpty = true; 
          return;
        }

        this.isOrders = true;
        this.orderByEmail = orderByEmail;
        this.orderByEmail.sort(function(a, b) {
          var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      }).catch((reson) => {
      });
    },

    showOrderDetails: function(order) {
      this.$modal.show(LinkedOrderInfoModal, {
        order
      }, {
        draggable: true,
        clickToClose: true,
        resizable: true,
        height: 'auto',
      }, {
        'closed': (event) => {
          // Api.tickets.getMailsConfiguration().then(res => {
          //   this.internalBoxes = res;
          // }).catch(e => {});
        }
      });
    },
  }

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// .v--modal-overlay[data-modal="dupa"] {
//   background: transparent;
//   pointer-events: none;
// }
</style>
