import axios from 'axios';
import config from './config.js';
import EventEmitter from 'events';

function Api() {
    this.baseUrl = config.apiUrl;
    this.token = null;
    this.events = new EventEmitter();
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      }
    };

    this.raport = {
      getUsersActiveHours: (data) => {return this.callCommonEndpoint('activity/logs/usersActiveHours', 'post', data)},
    }

    this.system = {
      getCustomerInfo: () => { return this.callCommonEndpoint('system/customer-info') },
      saveCustomerInfo: (d) => { return this.callCommonEndpoint('system/customer-info', 'put', d) },
      getCostEstimation: () => { return this.callCommonEndpoint('system/month-cost-estimate') },
    }

    this.me = {
      getFromStorage: (key) => { return this.callCommonEndpoint('my/storage/'+key) },
      getSelectedTicketColumns: () => { return this.callCommonEndpoint('my/storage/visible_columns') },
      getStorage: () => { return this.callCommonEndpoint('my/storage/all') },
    }

    this.storage = {
      get: (key) => { return this.callCommonEndpoint('my/storage/'+key) },
      getAll: () => { return this.callCommonEndpoint('my/storage/all') },
      put: (key, value) => { return this.callCommonEndpoint('my/storage', 'post', {key, value}) }
    }

    this.user = {
      register: (data) => {return this.callCommonEndpoint('users/register', 'post', data)},
      disable: (userId) => {return this.callCommonEndpoint('users/'+userId, 'put', {isDisabled:true})},
      resetPassword: (email) => {return this.callCommonEndpoint('users/reset-password', 'post', {email:email})},
      resetPasswordConfirm: (code) => {return this.callCommonEndpoint('users/reset-password-confirm', 'post', {code:code})},
    }

    this.tickets = {
      sendReply: (ticketId, data) => { return this.callCommonEndpoint('tickets/'+ticketId+'/reply', 'post', data) },
      updateContactDetails: (ticketId, data) => { return this.callCommonEndpoint('tickets/'+ticketId+'/contactDetails', 'put', data) },

      getMailsConfiguration: () => { return this.callCommonEndpoint('settings/tickets/mails') },
      createInternalBox: (box) => { return this.callCommonEndpoint('settings/tickets/mails', 'post', box) },
      updateInternalBox: (boxId, data) => { return this.callCommonEndpoint('settings/tickets/mails/'+boxId, 'put', data) },
      
      getIGIntegrations: () => { return this.callCommonEndpoint('settings/tickets/ig') },
      createIGIntegration: (data) => { return this.callCommonEndpoint('settings/tickets/ig', 'put', data) },
      updateIGIntegration: (data) => { return this.callCommonEndpoint('settings/tickets/ig', 'put', data) },
      removeIGIntegration: (igConfId) => { return this.callCommonEndpoint('settings/tickets/ig/'+igConfId, 'delete') },
      
      getFBIntegrations: () => { return this.callCommonEndpoint('settings/tickets/fb') },
      updateFBIntegration: (data) => { return this.callCommonEndpoint('settings/tickets/fb', 'put', data) },
      removeFBIntegration: (pageId) => { return this.callCommonEndpoint('fb/pages/'+pageId, 'delete') },

      livechat: {
        get: () => { return this.callCommonEndpoint('settings/tickets/livechat') },
        create: (data) => { return this.callCommonEndpoint('settings/tickets/livechat', 'post', data) },
        update: (pageId, data) => { return this.callCommonEndpoint('settings/tickets/livechat/'+pageId, 'put', data) },
        remove: (pageId) => { return this.callCommonEndpoint('settings/tickets/livechat/'+pageId, 'delete') },
        setAvailability: (availability) => { return this.callCommonEndpoint('livechat/'+(availability ? 'setMeAvailable' : 'setMeUnavailable'), 'put') },
        getAvailability: (userId) => { return this.callCommonEndpoint('livechat/userAvailability/'+userId) },
        amIChatAware: (userId) => { return this.callCommonEndpoint('livechat/amIChatAware') },
      }
      // removeFBIntegration: (pageId) => { return this.callCommonEndpoint('settings/tickets/fb/'+pageId, 'delete') },
    }

    this.shops = {
      getAll: () => { return this.callCommonEndpoint('settings/store-integration/all') },
      create: (shop) => { return this.callCommonEndpoint('settings/store-integration/', 'post', shop) },
      update: (shopId, data) => { return this.callCommonEndpoint('settings/store-integration/'+shopId, 'put', data) },
      delete: (shopId) => { return this.callCommonEndpoint('settings/store-integration/'+shopId, 'delete') },
    }
    
    this.mailer = {
      getConfiguration: () => { return this.callCommonEndpoint('mailer/config') },
      saveConfiguration: (config) => { return this.callCommonEndpoint('mailer/config', 'post', config) },
      sendTestMessage: () => { return this.callCommonEndpoint('mailer/config/sendTestMessage', 'post') },
    }

    this.mails = {
      createNewDraft: (accountId) => { return this.callCommonEndpoint('mails/compose/newDraft', 'post', {accountId: accountId}) },
      createNewDraftBasedOn: (accountId, mailbox, uid) => { return this.callCommonEndpoint('mails/compose/newFromDraft', 'post', {accountId, mailbox, uid}) },
      createNewDraftAsReply: (accountId, mailbox, uid, replyToAll) => { return this.callCommonEndpoint('mails/compose/newReply', 'post', {accountId, mailbox, uid, replyToAll}) },
      createNewDraftAsFwd: (accountId, mailbox, uid) => { return this.callCommonEndpoint('mails/compose/newFwd', 'post', {accountId, mailbox, uid}) },
      saveDraft: (draft) => { return this.callCommonEndpoint('mails/compose/save/'+draft.id, 'post', draft) },
      sendDraft: (draft, accountId) => { return this.callCommonEndpoint('mails/compose/send/'+draft.id, 'post', {accountId}) },
      saveContact: (contact) => { return this.callCommonEndpoint('mails/contacts/save', 'post', {contact}) },
      searchContact: (q) => { return this.callCommonEndpoint('mails/contacts/search', 'post', {q}) },
      getSearchContactUrl: () => { return this.baseUrl+'mails/contacts/search?token='+this.token; },
      removeAttachment: (draft, filename) => { return this.callCommonEndpoint('mails/compose/attachment/'+draft.id+'/'+filename, 'DELETE', {}) },
      addAttachment: (draft, file, progresCallback) => {
        let formData = new FormData();
        formData.append('file', file);
        return axios.post(this.baseUrl+'mails/compose/attachment/'+draft.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': this.axiosConfig.headers['Authorization']
          },
          onUploadProgress: progresCallback
        }).then(response => response.data);
      }
    }

    this.users = {
      create: (data) => { return this.callCommonEndpoint('users/register', 'post', data) },
      save: (userId, data) => { return this.callCommonEndpoint('users/'+userId, 'put', data) },
      getEmployees: () => {return this.callCommonEndpoint('users/all')},
      getGroups: () => {return this.callCommonEndpoint('groups/all')},
      createGroup: (data) => {return this.callCommonEndpoint('groups/', 'post', data)},
      saveGroup: (gId, data) => {return this.callCommonEndpoint('groups/'+gId, 'put', data)},
      deleteGroup: (gId) => {return this.callCommonEndpoint('groups/'+gId, 'delete')},
    }
    
    this.fb = {
      getPages: () => {return this.callCommonEndpoint('fb/pages')},
      getLastConversations: () => {return this.callCommonEndpoint('fb/conversations/last')},
      getMessagesConversations: (pageId, cId) => {return this.callCommonEndpoint('fb/messages/'+pageId+'/'+cId)},
      sendMessage: (pageId, receiverId, message) => {return this.callCommonEndpoint('fb/messages/'+pageId+'/'+receiverId, 'post', {message:message})},
      updateConverastion: (pageId, cId, data) => {return this.callCommonEndpoint('fb/conversations/'+pageId+'/'+cId, 'put', data)},
      getImgUrl: (pageId, objectId) => {return this.callCommonEndpoint('fb/utils/pic/'+pageId+'/'+objectId)},
      getRegisterUrl: () => {return this.callCommonEndpoint('fb/utils/register_link')},
      typingOn: (pageId, receiverId) => {return this.callCommonEndpoint('fb/messages/'+pageId+'/'+receiverId+'/typingOn', 'post')},
      typingOff: (pageId, receiverId) => {return this.callCommonEndpoint('fb/messages/'+pageId+'/'+receiverId+'/typingOff', 'post')},
      searchConversations: (data) => {return this.callCommonEndpoint('fb/conversations/search', 'post', data)},
      deletePage: (pId) => { return this.callCommonEndpoint('fb/pages/'+pId, 'delete')},
    }

    this.mailboxScanner = {
      findConf: (data) => { return this.callCommonEndpoint('settings/mailbox-scanner/findConfig', 'post', data) },
      save: (boxId, data) => { return this.callCommonEndpoint('settings/mailbox-scanner/'+boxId, 'put', data) },
      delete: (boxId) => { return this.callCommonEndpoint('settings/mailbox-scanner/'+boxId, 'delete') },
      add: (data) => { return this.callCommonEndpoint('settings/mailbox-scanner/', 'post', data) },
      getAll: () => { return this.callCommonEndpoint('settings/mailbox-scanner/all') },
      getStatuses: () => { return this.callCommonEndpoint('settings/mailbox-scanner/statuses') },
    }
}

Api.prototype.setToken = function(token) {
  this.token = token;
  this.axiosConfig.headers['Authorization'] = 'Bearer '+token;

  // Interceptor, ktory ma za zadanie wykrywac, czy przypadkiem auth token nie stracił ważności
  // (tokeny tracą ważność po 24H lub po update systemu)
  axios.interceptors.response.use((response) => {
      return response;
  }, async (error) => { // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error.response.status == 403 && !this.isVerifing) {
        this.isVerifing = true;
        try {
          let r = await this.verifyUser(this.token);
        } catch (e) {
          console.log("User token became invalid");
          this.events.emit("invalid.token");
        }
        this.isVerifing = false;
      }

      return Promise.reject(error);
  });
}


Api.prototype.callCommonEndpoint = function(endpoint, method, data) {
  if (!method) method = 'get';
  return axios({
    method: method,
    url: this.baseUrl+endpoint,
    headers: this.axiosConfig.headers,
    data: JSON.stringify(data)
  }).then((response) => { return response.data; });
};

// mails api
// 
Api.prototype.getMailboxesStatus = function(idBox, folderName) {

  let promise = axios.get(this.baseUrl+'mails/imap/'+idBox+'/boxesStatus/'+folderName, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.putInStorage = function(key, value) {
  let data = {key: key , value: value};

  let promise = axios.post(this.baseUrl+'my/storage', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getUserMailboxes = function() {

  let promise = axios.get(this.baseUrl+'mails/imap/myBoxes', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getUserMailsForSelectedInbox = function(idBox, folderName, count, offset) {
  if (!offset) offset = 0;
  if (!count) count = 50;
  let promise = axios.get(this.baseUrl+'mails/imap/'+idBox+'/'+folderName+'/last/'+count+'/'+offset, {headers: this.axiosConfig.headers})
  .then((response) => { 
    let arrayRes = {};
    arrayRes.data = response.data; 
    arrayRes.total = parseInt(response.headers['x-box-total']); 
    return arrayRes;
   });

  return promise;
};

Api.prototype.getSelectedMailContnent = function(idBox, folderName, uid) {

  let promise = axios.get(this.baseUrl+'mails/imap/'+idBox+'/'+folderName+'/'+uid, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.moveSelectedMailsToFolder = function(idbox, from, to, uids) {
  let data = {from: from, to:to, uids:uids};
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/move', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};


Api.prototype.removeSelectedMails = function(idbox, box, uids) {
  let data = {box: box, uids:uids};
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/remove-mails', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};


Api.prototype.searchMailsInInbox = function(idbox, boxName, search) {
  let data = {box: boxName, header:search};
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/search', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.advancedSearchMailsInInbox = function(idbox, boxName, filters) {
  let data = {
    box: boxName,
    from: filters.from,
    to: filters.to,
    unseen: filters.unseen,
    subject: filters.subject,
    body: filters.body,
  };
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/advanced-search', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.addNewFolderToInbox = function(idbox, name) {
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/'+name, JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.deleteFolderFromInbox = function(idbox, name) {

  let promise = axios.delete(this.baseUrl+'mails/imap/'+idbox+'/'+name, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.setFlags = function(idbox, boxName, uids, flags) {
  let data = {box: boxName, uids:uids, flags:flags};
  let promise = axios.post(this.baseUrl+'mails/imap/'+idbox+'/setFlags', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};
// END mails api
// settings mails api
Api.prototype.getMailboxes = function() {

  let promise = axios.get(this.baseUrl+'mails/mailboxes', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.createMailboxes = function(data) {
  
  let promise = axios.post(this.baseUrl+'mails/mailboxes', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.editMailboxes = function(id, data) {

  let promise = axios.put(this.baseUrl+'mails/mailboxes/'+id, JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.deleteMailboxes = function(id) {

  let promise = axios.delete(this.baseUrl+'mails/mailboxes/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};
Api.prototype.addSignatureForMailboxes = function(id, text) {

  let data = {text: text};
  
  let promise = axios.put(this.baseUrl+'mails/mailboxes/'+id+'/signature', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};
// END settings mails api
Api.prototype.getRaportSendMessages = function(timeUnit) {

  let promise = axios.get(this.baseUrl+'stats/responseCountPerDay/'+timeUnit, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getRaportAverageResponseTimes = function(timeUnit) {

  let promise = axios.get(this.baseUrl+'stats/averageResponseTimes/'+timeUnit, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getRaportNewAndClosedTickets  = function(timeUnit) {

  let promise = axios.get(this.baseUrl+'stats/newAndClosedTickets/'+timeUnit, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getRaportTicketSummaryStatusAndPriority = function() {

  let promise = axios.get(this.baseUrl+'stats/ticketSummary', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getRaportTicketCountsPerEmployee = function() {

  let promise = axios.get(this.baseUrl+'stats/ticketCountsPerEmployee', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getOrdersfromShopByCustomerEmail = function(email) {

  let promise = axios.get(this.baseUrl+'tickets/linked-orders/info-for-email/'+email, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getTicketAssignerAvailableEmps = function() {

  let promise = axios.get(this.baseUrl+'settings/tickets/assigner', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getUserActivity = function(id, keys) {
  let data = {userId: id};
  if (keys) data.logKey = keys;
  let promise = axios.post(this.baseUrl+'activity/search', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getTicketActivityTimeline = function(id) {
  let data = {objectType: "ticket", objectId:id};
  let promise = axios.post(this.baseUrl+'activity/searchByRelation', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.setTicketAssignerAvailableEmps = function(data) {

  let promise = axios.post(this.baseUrl+'settings/tickets/assigner', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.setNotificationsSeenForTicket = function(id) {

  let promise = axios.post(this.baseUrl+'my/notifications/setSeenForTicket/'+id, JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.changeTicketTitle = function(id, title) {

  let promise = axios.put(this.baseUrl+'tickets/'+id+'/contactDetails', JSON.stringify({subject: title}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.forwardMessageToOtherTicket = function(msgId, ticketId) {

  let promise = axios.post(this.baseUrl+'tickets/messages/'+msgId+'/forward-to-ticket/'+ticketId, JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.setColumnForEmployee = function(values) {
  let data = {key:"visible_columns", value:values};

  let promise = axios.post(this.baseUrl+'my/storage', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.setTagsForTicket = function(id, tags) {
  let data = {ticketId: id, tags: tags};

  let promise = axios.put(this.baseUrl+'tickets/tags/setTagsForTicket', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getTagsForTicket = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/tags/byTicket/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.availableTags = function() {

  let promise = axios.get(this.baseUrl+'tickets/tags/availableTags', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.editUserMyself = function(emp) {

  let promise = axios.put(this.baseUrl+'users/me', JSON.stringify(emp), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.editUser = function(emp) {

  let promise = axios.put(this.baseUrl+'users/'+emp.id, JSON.stringify(emp), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.linkTicketWithShopOrder = function(orderNumber, shopId, ticketId) {
  let data = {storeId:shopId, entityId:ticketId, orderId:orderNumber};

  let promise = axios.post(this.baseUrl+'tickets/linked-orders/', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.unlinkTicketWithShopOrder = function(id) {

  let promise = axios.delete(this.baseUrl+'tickets/linked-orders/delete-by-entity/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getLinkedOrders = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/linked-orders/info-for-enity/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.linkTickets = function(tickets) {
  let promise = axios.post(this.baseUrl+'tickets/links/set', JSON.stringify({tickets:tickets}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.unlinkTickets = function(tickets) {
  let promise = axios.post(this.baseUrl+'tickets/links/unset', JSON.stringify({tickets:tickets}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.splitMessages = function(ticket, messagesId) {
  let ticketId = ticket.id;
  let promise = axios.post(this.baseUrl+'tickets/'+ticketId+'/split', JSON.stringify({messagesToMove:messagesId}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.createNewTicket = function(email, name, subject) {
  let data = {fromEmail:email, name:name, subject:subject};
  let promise = axios.post(this.baseUrl+'tickets/new', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.closeTicket = function(ticketId) {
  // let ticketId = ticket.id;
  let promise = axios.post(this.baseUrl+'tickets/'+ticketId+'/close', JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.removeTicket = function(ticketId) {
  // let ticketId = ticket.id;
  let promise = axios.delete(this.baseUrl+'tickets/'+ticketId, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getUserCannedResponses = function() {
  let promise = axios.get(this.baseUrl+'my/canned-responses', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.addCannedResponse = function(title, content) {
  let data = {title: title, content:content};
  let promise = axios.post(this.baseUrl+'my/canned-responses', JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
}; 

Api.prototype.changeCannedResponse = function(title, content, id) {
  let data = {title: title, content:content};
  let promise = axios.put(this.baseUrl+'my/canned-responses/'+id, JSON.stringify(data), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
}; 

Api.prototype.deleteCannedResponse = function(id) {
  let promise = axios.delete(this.baseUrl+'my/canned-responses/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
}; 

Api.prototype.getUserNotifications = function() {
  let promise = axios.get(this.baseUrl+'my/notifications', {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.notificationIsSeen = function(id) {
  let promise = axios.post(this.baseUrl+'my/notifications/setSeen/'+id, JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};


Api.prototype.searchTickets = function(data) {

  let promise = axios.post(this.baseUrl+'tickets/search', JSON.stringify(data), this.axiosConfig)
  .then((response) => { 
    let arrayRes = {};
    arrayRes.data = response.data; 
    arrayRes.total = parseInt(response.headers['x-total-found']); 
    return arrayRes; 
  });

  return promise;
};

Api.prototype.getTicket = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getTicketMessages = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/messages/byTicket/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.getTicketNotes = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/notes/byTicket/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.addTicketNotes = function(id, content) {

  let promise = axios.post(this.baseUrl+'tickets/notes', JSON.stringify({content:content, ticketId:id}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.deleteTicketNotes = function(noteId) {

  let promise = axios.delete(this.baseUrl+'tickets/notes/'+noteId, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

//nowe komenarze!
Api.prototype.getTicketComments = function(id) {

  let promise = axios.get(this.baseUrl+'tickets/comments/byTicket/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.addTicketComments = function(id, content, mentionedUsers) {

  let promise = axios.post(this.baseUrl+'tickets/comments', JSON.stringify({content:content, ticketId:id, mentionedUsers:mentionedUsers}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.reactOnTicketComments = function(id) {

  let promise = axios.post(this.baseUrl+'tickets/comments/react/'+id, JSON.stringify({}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.deleteTicketComments = function(id) {

  let promise = axios.delete(this.baseUrl+'tickets/comments/'+id, {headers: this.axiosConfig.headers})
  .then((response) => { return response.data; });

  return promise;
};
//end nowe komenarze!
//

Api.prototype.changeTicketStatus = function(id, status) {

  let promise = axios.post(this.baseUrl+'tickets/'+id+'/changeStatus/'+status, {}, this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.changeTicketPriority = function(id, priority) {

  let promise = axios.post(this.baseUrl+'tickets/'+id+'/changePriority/'+priority, {}, this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.changeTicketType = function(id, type) {

  let promise = axios.post(this.baseUrl+'tickets/'+id+'/changeType/'+type, {}, this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.changeTicketAssignee = function(id, assigneeId) {

  let promise = axios.post(this.baseUrl+'tickets/'+id+'/changeAssignment/'+assigneeId, {}, this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.logIn = function(email, pass) {

  let promise = axios.post(this.baseUrl+'auth/signin', JSON.stringify({user:email, pass:pass}), this.axiosConfig)
  .then((response) => { return response.data.token; });

  return promise;
};

Api.prototype.verifyUser = function(token) {

  let promise = axios.post(this.baseUrl+'auth/verify', JSON.stringify({token: token}), this.axiosConfig)
  .then((response) => { return response.data; });

  return promise;
};

Api.prototype.uploadFile = function(file, progresCallback) {
  let promise = axios.post(this.baseUrl+'files', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': this.axiosConfig.headers['Authorization']
    },
    onUploadProgress: progresCallback
  })
  .then((response) => { 
    return response.data; 
  })
  .catch(error => {
    throw error.response;
  });

  return promise;
};

var instance = new Api();

export default instance;

