import EmployeeManager from './EmployeeManager.js';


let employees = [];

function translateLogs(logs) {
  let translated = [];
  return EmployeeManager.getEmployees().then((e) => {
    employees = e;

    for (var i = logs.length - 1; i >= 0; i--) {

      if (logs[i].logKey == 'tickets.status_changed') {

        translated.push(_translateStatus(logs[i]));

      } else if (logs[i].logKey == 'tickets.new_comment' && logs[i].data.hasMentionedUsers) {
        translated.push(_tranlateNewCommentWithMentioned(logs[i]));

      } else if (logs[i].logKey == 'tickets.new_comment' && !logs[i].data.hasMentionedUsers) {
        translated.push(_tranlateNewComment(logs[i]));

      } else if (logs[i].logKey == 'tickets.comment_reaction') {
        translated.push(_tranlateCommentReaction(logs[i]));

      } else if (logs[i].logKey == 'tickets.new_reply') {
        translated.push(_tranlateNewReply(logs[i]));

      } else if (logs[i].logKey == 'tickets.priority_changed') {
        translated.push(_tranlatePriority(logs[i]));

      } else if (logs[i].logKey == 'tickets.assignment_changed') {
        translated.push(_tranlateAssignment(logs[i]));

      } else if (logs[i].logKey == 'users.auth') {
        translated.push(logs[i]); // nie ma nic do dodania ;)
      }
    }

    return translated.sort(function(a, b) {
      var dateA = new Date(a.created), dateB = new Date(b.created);
      return dateB - dateA;
      });
  });

}

function _tranlateAssignment(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;

  let idxAuthor = employees.map(e => e.id+"").indexOf(log.userId+"");
  if (idxAuthor != -1) translatedLog.authorName = employees[idxAuthor].name;
  
  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
  }

  let idx = employees.map(e => e.id+"").indexOf(log.data.oldUser+"");
  if (idx != -1) translatedLog.oldUserName = employees[idx].name;
  let index = employees.map(e => e.id+"").indexOf(log.data.newUser+"");
  if (index != -1) translatedLog.newUserName = employees[index].name;
  return translatedLog;
}

function _tranlatePriority(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;
  translatedLog.oldPriority = log.data.oldPriority;
  translatedLog.newPriority = log.data.newPriority;

  let idxAuthor = employees.map(e => e.id+"").indexOf(log.userId+"");
  if (idxAuthor != -1) translatedLog.authorName = employees[idxAuthor].name;

  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
  }

  return translatedLog;
}

function _translateStatus(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;
  translatedLog.oldStatus = log.data.oldStatus;
  translatedLog.newStatus = log.data.newStatus;

  let idxAuthor = employees.map(e => e.id+"").indexOf(log.userId+"");
  if (idxAuthor != -1) translatedLog.authorName = employees[idxAuthor].name;

  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
  }

  return translatedLog;
}

function _tranlateNewCommentWithMentioned(log) {
  let translatedLog = {};
  translatedLog.logKey = 'tickets.new_comment_with_mentioned';
  translatedLog.created = log.created;
  let mentionedUsersNames = [];
  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (!log.relations[i].data) continue; // jeszcze nie wiem dlaczego czasem relacje nie maja pola "data"
    let relation = log.relations[i];
    if (relation.objectType == 'ticket') translatedLog.ticketId = relation.objectId;
    if (relation.objectType == 'comment') {
      let idxAuthor = employees.map(e => e.id+"").indexOf(log.relations[i].data.author+"");
      if (idxAuthor != -1) translatedLog.commentAuthorName = employees[idxAuthor].name;

      translatedLog.commentContent = relation.data.content;
      translatedLog.reactionsLeft = relation.data.reactionsLeft;
      for (var y = relation.data.mentionedUsers.length - 1; y >= 0; y--) {
        let idx = employees.map(e => e.id+"").indexOf(relation.data.mentionedUsers[y]+"");
        if (idx != -1) mentionedUsersNames.push(employees[idx].name);
      }
      translatedLog.mentionedUsersNames = mentionedUsersNames;
    }
  }

  return translatedLog;
}

function _tranlateNewComment(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;
  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
    if (log.relations[i].objectType == 'comment') {
      translatedLog.commentContent = log.relations[i].data.content;

      let idx = employees.map(e => e.id+"").indexOf(log.relations[i].data.author+"");
      if (idx != -1) translatedLog.commentAuthorName = employees[idx].name;
      
    }

  }

  return translatedLog;
}

function _tranlateCommentReaction(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;
  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
    if (log.relations[i].objectType == 'comment') translatedLog.commentContent = log.relations[i].data.content;
  }
  return translatedLog;
}

function _tranlateNewReply(log) {
  let translatedLog = {};
  translatedLog.logKey = log.logKey;
  translatedLog.created = log.created;
  for (var i = log.relations.length - 1; i >= 0; i--) {
    if (log.relations[i].objectType == 'ticket') translatedLog.ticketId = log.relations[i].objectId;
    if (log.relations[i].objectType == 'message') translatedLog.msgAuthor = log.relations[i].data.author;
  }
  return translatedLog;
}

export default {
  translateLogs: translateLogs,
};