<template>
  <div class="row">
    <div class="col-xl-2 col-lg-3 col-md-12 hidden-md-down">
      <TicketFilters></TicketFilters>
    </div>
    <div class="col-xl-10 col-lg-9 col-md-12">
      <SortList v-bind:totalTicketsFromLastSearch="totalTicketsFromLastSearch"></SortList>

      <div class="box">
        <div class="box-header d-gray">
          <BtnGroupList v-if="isSelectedColumnsReady" v-bind:selectedColumns="selectedColumns" v-on:selection-changed="onColumnsSelectionChanged"></BtnGroupList>
        </div>
        <!-- start box -->
        <div class="row p-a-lg start-box" v-if="tickets.length == 0 && pinnedTicked.length == 0 && isTicketReady">
          <div class="col-md-6 text-center">
            <img src="../../assets/images/start-page.png" class="">
          </div>
          <div class="col-md-6 p-a-lg">
            <h4 class="primary-color _300 p-a-lg">
              There are no any tickets yet. Try to <span class="coral">create one</span> using "Add" button above or <router-link class="coral" to="/settings/email-settings">configure your email inbox</router-link> to automatic intercept messages.</h4>   
          </div>
        </div>
        <!-- ./END start box -->

        <div style="position: relative;">
        <TicketsTable v-if="tickets.length > 0 || pinnedTicked.length > 0" v-bind:tickets="tickets" v-bind:selectedColumns="selectedColumns" v-bind:pinnedTicked="pinnedTicked"></TicketsTable>
          <div v-bind:class="{'b-ground':isLoaded}"></div>
        </div>
        <div v-if="isLoaded" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, 0%); z-index:10000" >
          <Spinner></Spinner>
        </div>

        <!-- <Spinner v-if="isLoaded"></Spinner> -->
        <div class="row" v-if="isError">
          <div class="col-md-12 text-center text-muted  m-t m-b-md">
            {{ $t('ticket.error.problemDownloading') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TicketsTable from './TicketsTable.vue'
import TicketFilters from './TicketFilters.vue'
import BtnGroupList from './BtnGroupList.vue'
import SortList from './SortList.vue'
import $ from 'jquery'
import EventBus from '../../event-bus.js';
import Spinner from '../Spinner.vue';
import EmployeeManager from '../../EmployeeManager.js';
import Api from '../../Api.js';
import FilterStorage from '../../FilterStorage.js';
import TicketManager from '../../TicketManager.js';

export default {
  name: 'TicketsList',
  props: {
    
  },
  components: {
    TicketFilters,
    BtnGroupList,
    SortList,
    Spinner,
    TicketsTable
  },
  data: function () {
    return {
      isLoaded: false,
      isTicketReady: false,
      isError: false,
      tickets: [],
      employees: [],
      selectedColumns: ['id','name', 'subject', 'assignee', 'priority', 'date'],
      pinnedTicked: [],
      isSelectedColumnsReady: false,
      totalTicketsFromLastSearch: 0,
    }
  },
  created: function() {
    this.isSelectedColumnsReady = false;
    Api.me.getSelectedTicketColumns().then((columns) => {

      if (columns.value == null) {
        this.setColumnsForEmployee();
      } else {
        let arrayOfStrings = columns.value.split(',');
        this.selectedColumns = arrayOfStrings;
      }

      if (FilterStorage.selectedColumns != null) {
        this.selectedColumns = FilterStorage.selectedColumns;
      }
      this.isSelectedColumnsReady = true;
    });

    this.pinnedTicked.push.apply(this.pinnedTicked, TicketManager.userPinTickets);

    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
      if (TicketManager.currentTickets.length > 0) {
        this.tickets.push.apply(this.tickets, TicketManager.currentTickets);
        if (FilterStorage.sortBy) {
          this.sortBy(FilterStorage.sortBy);
        }
        return;
      }
      
      // ubezpieczenie na wypadek kiedy ktos wejdzie z innej strony (np. settings) po przeladowaniu systemu
      // czyli innymi slowy, kiedy event TicketManager#ready został już wyslany
      if (TicketManager.isReady) this.showTicket();
    });

    TicketManager.events.on('ready', this.showTicket);
    TicketManager.events.on('tickets_changed', this.onTicketsManagerChangeEvent);
    TicketManager.events.on('pinned_tickets_changed', this.onPinnedTicketsManagerChangeEvent);

    EventBus.$on('filterChangeNew', this.showTicket);
    EventBus.$on('pinTicketChange', this.changePinTickets);
    EventBus.$on('sortByNew', this.sortBy);
    EventBus.$on('link-selected-tickets', this.linkSelectedTickets);
    EventBus.$on('close-selected-tickets', this.closeSelectedTickets);
    EventBus.$on('remove-selected-tickets', this.removeSelectedTickets);
  },
  destroyed: function() {
    TicketManager.events.removeListener('tickets_changed', this.onTicketsManagerChangeEvent);
    TicketManager.events.removeListener('pinned_tickets_changed', this.onPinnedTicketsManagerChangeEvent);
    TicketManager.events.removeListener('ready', this.showTicket);
    EventBus.$off('filterChangeNew', this.showTicket);
    EventBus.$off('pinTicketChange', this.changePinTickets);
    EventBus.$off('sortByNew', this.sortBy);
    EventBus.$off('link-selected-tickets', this.linkSelectedTickets);
    EventBus.$off('close-selected-tickets', this.closeSelectedTickets);
    EventBus.$off('remove-selected-tickets', this.removeSelectedTickets);
  },
  methods: {
    setColumnsForEmployee: function() {
      let stringValues = this.selectedColumns.join();
      Api.setColumnForEmployee(stringValues).then((columns) => {
      });
    },
    onColumnsSelectionChanged: function(selection) {
      this.selectedColumns = selection;
      FilterStorage.saveSelectedColumns(selection);
      this.setColumnsForEmployee();
    },
    linkSelectedTickets: function() {
      let tickets = this.getSelectedTicket();
      if(this.checkIfSelectedIsEmpty(tickets) == false) return;

      Api.linkTickets(tickets).then((tickets) => {
        this.removeSelectionFromTickets();
        this.showNotify('notification-success', this.$i18n.t('notifications.linkingSuccess'));
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.error'));
      });
    },
    closeSelected: function(t) {
      Api.closeTicket(t.id).then((ticket) => {
        this.showNotify('notification-success', this.$i18n.t('notifications.closeSuccess')+': #'+ticket.id);
      }).catch((reson) => {
        if (reson.response.status == 403) {
          this.showNotify('notification-error', this.$i18n.t('notifications.permission')+': #'+t.id);
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.error')+': #'+t.id);
        }
      });
    },
    closeSelectedTickets: function() {
      let tickets = this.getSelectedTicket();
      if(this.checkIfSelectedIsEmpty(tickets) == false) return;

      let message = 'Are you sure you want to close selected tickets? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        for (var i = this.tickets.length - 1; i >= 0; i--) {
          if (this.tickets[i].selected) {
            this.closeSelected(this.tickets[i]);
          } 
        }

        for (var i = this.pinnedTicked.length - 1; i >= 0; i--) {
          if (this.pinnedTicked[i].selected) {
            this.closeSelected(this.pinnedTicked[i]);
          } 
        }
      }).catch(r => r);
    },
    removeSelected: function(t) {
      Api.removeTicket(t.id).then((ticket) => {
        this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess')+': #'+t.id);
      }).catch((reson) => {
        if (reson.response.status == 403) {
          this.showNotify('notification-error', this.$i18n.t('notifications.permission')+': #'+t.id);
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.error')+': #'+t.id);
        }
      });
    },
    removeSelectedTickets: function() {
      let tickets = this.getSelectedTicket();
      if(this.checkIfSelectedIsEmpty(tickets) == false) return;

      let message = 'Are you sure you want to remove selected tickets from current list? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        for (var i = this.tickets.length - 1; i >= 0; i--) {
          if (this.tickets[i].selected) {
            this.removeSelected(this.tickets[i]);
          }
        }

        for (var i = this.pinnedTicked.length - 1; i >= 0; i--) {
          if (this.pinnedTicked[i].selected) {
            this.removeSelected(this.pinnedTicked[i]);
          }
        }
      }).catch(r => r);
    },
    checkIfSelectedIsEmpty: function(tickets) {
      if (tickets.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.noSelected'));
        return false;
      }
    },
    getSelectedTicket: function() {
      let tickets = [];

      for (var i = this.tickets.length - 1; i >= 0; i--) {
        if (this.tickets[i].selected) {
          tickets.push(this.tickets[i].id);
        }
      }

      for (var i = this.pinnedTicked.length - 1; i >= 0; i--) {
        if (this.pinnedTicked[i].selected) {
          tickets.push(this.tickets[i].id);
        }
      }
      return tickets;
    },
    removeSelectionFromTickets: function() {
      for (var i = this.tickets.length - 1; i >= 0; i--) {
        if (this.tickets[i].selected) {
          delete this.tickets[i].selected;
        }
      }

      for (var i = this.pinnedTicked.length - 1; i >= 0; i--) {
        if (this.pinnedTicked[i].selected) {
          delete this.pinnedTicked[i].selected;
        }
      }
    },
    onTicketsManagerChangeEvent: function(tickets) {
      this.tickets.length = 0;
      tickets.forEach(t => this.tickets.push(t));

      if (FilterStorage.sortBy && FilterStorage.sortBy.length) {
        this.sortBy(FilterStorage.sortBy);
      }

    },
    onPinnedTicketsManagerChangeEvent: function(tickets) {
      this.pinnedTicked.length = 0;
      tickets.forEach(t => this.pinnedTicked.push(t));
    },
    
    sortBy: function(sortElement) {
      this.tickets.sort(function(a, b) {
        if (sortElement == 'Date:DESC') {
          var dateA = new Date(a.emailDate), dateB = new Date(b.emailDate);
          return dateB - dateA;
        }
        if (sortElement == 'Date:ASC') {
          var dateA = new Date(a.emailDate), dateB = new Date(b.emailDate);
          return dateA - dateB;
        }
        if (sortElement == 'Status') {
          return a.status.localeCompare(b.status);
        }
        if (sortElement == 'Priority') {
          return a.priority.localeCompare(b.priority);
        }
        if (sortElement == 'Customer') {
          return a.name.localeCompare(b.name);
        }
      });
    },
    changePinTickets: function(pinTickets) {
      this.pinTickets = pinTickets;
    },
    showTicket: function (filters) {
      var data = { sort: {"emailDate": -1} };
      this.tickets.length = 0;
      this.isLoaded = true;
      this.isError = false;
      if (filters) {
        if (filters.checkedComunicationChannel && filters.checkedComunicationChannel.length) { data.channels = filters.checkedComunicationChannel;}
        if (filters.checkedAssignees && filters.checkedAssignees.length) { data.assignedUserId = filters.checkedAssignees;}
        if (filters.checkedAssigneesGroup && filters.checkedAssigneesGroup.length) { data.group = filters.checkedAssigneesGroup;}
        if (filters.checkedStatus && filters.checkedStatus.length) { data.status = filters.checkedStatus;}
        if (filters.checkedPriority && filters.checkedPriority.length) { data.priority = filters.checkedPriority;}
        if (filters.checkedType && filters.checkedType.length) { data.type = filters.checkedType;}
        if (filters.dateFrom && filters.dateFrom.length) { data.dateFrom = filters.dateFrom;}
        if (filters.selectedTags && filters.selectedTags.length) { data.tags = filters.selectedTags;}
        if (filters.dateTo && filters.dateTo.length) { data.dateTo = filters.dateTo+'T23:59:59.000Z';}
        if (filters.search && filters.search.length) { data.subject = filters.search;}
        if (filters.searchContent && filters.searchContent.length) { data.content = filters.searchContent;}
        if (filters.sortBy && filters.sortBy == "Date:ASC") { data.sort = {"emailDate": 1}; }
        if (filters.sortBy && filters.sortBy == "Date:DESC") { data.sort = {"emailDate": -1}; }
      }
      
      TicketManager.searchTickets(data).then((tickets) => {
        this.isLoaded = false;
        this.tickets.push.apply(this.tickets, tickets);
        this.isTicketReady = true;
        this.totalTicketsFromLastSearch = TicketManager.totalTicketsFromLastSearch;

        if (FilterStorage.sortBy && FilterStorage.sortBy.length) {
          this.sortBy(FilterStorage.sortBy);
        }

      }).catch((reson) => {
        this.isLoaded = false;
        this.isError = true;
      });

    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
.spinner[data-v-fa81853e]::after{
  background: #0cc2aa;
}
.b-ground {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.1);
}
</style>
