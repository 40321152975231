<template>
  <div class="">
    <div>
      <div class="box-header d-gray">

        <h6 class="text-muted _400 inline" v-if="isTableShow">{{$t('settings.tabEmailClient.title')}}</h6>
        <h6 class="text-muted _400 inline" v-if="isAddingNewEmailClient">{{$t('settings.tabEmailClient.newEmail')}}</h6>
        <h6 class="text-muted _400 inline" v-if="isDetails">{{$t('settings.tabEmailClient.details')}}</h6>

        <div class="pull-right minus-margin" v-if="isTableShow">
          <a class="btn btn-sm white none-radius" v-on:click="goToCartAddingEmailClient">
            <span class="primary-color _400">{{$t('btnNames.add')}}</span>
          </a>&nbsp;
          <a class="btn btn-sm white none-radius" v-on:click="deleteSelected">
            <span class="primary-color _400">{{$t('btnNames.delete')}}</span>
          </a>&nbsp;
        </div>

      </div>
      <table class="table" v-if="isTableShow && mailboxes.length > 0">
        <thead class="primary-color">
          <tr>
            <th style="width: 5%;"></th>   
            <th class="hidden-sm-down">{{$t('settings.tabEmailClient.table.name')}}</th>   
            <th>{{$t('settings.tabEmailClient.table.email')}}</th>   
            <th class="hidden-xs-down">{{$t('settings.tabEmailClient.table.assigned')}}</th>   
          </tr>
        </thead>
        <tbody>
          <tr class="custom-gray" v-for="m in mailboxes"
          :attach="m"
          :key="m._id">
            <td class="checkbox p-r-sm">
              <label class="ui-check text-xs">
                <input type="checkbox" v-bind:value="m._id" v-model="selectedEmailCilents">
                <i class="dark-white"></i>
              </label>
            </td>
            <td class="hidden-sm-down" v-on:click="goToEmailClient(m)">{{m.name}}</td>
            <td v-on:click="goToEmailClient(m)">{{m.email}}</td>
            <td class="hidden-xs-down" v-on:click="goToEmailClient(m)">
                <span class="c-label m-r-xs"  v-for="ass in m.userName" v-if="m.allowedUsers.length > 0">
                  {{ass.name}}
                </span>
              <span v-else>{{$t('settings.tabEmailClient.table.noAssigned')}}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="box-body start-info" v-if="mailboxes.length == 0 && isTableShow && isLoaded">
        <div class="box2 sb10">
          {{$t('settings.tabEmailClient.table.textHowTo')}}
        </div>
      </div>
      <!-- Cart adding new email client -->
      <div class="box-body" v-if="isAddingNewEmailClient">
        <form class="form-horizontal">
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.name')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="newEmailClient.name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.email')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="email" v-model="newEmailClient.email" @input="autoUpdateHost">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.password')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="password" v-model="newEmailClient.pass">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.assigned')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <select2 :options="optionsEmps" class="form-control" v-model="newEmailClient.allowedUsers" multiple="multiple">
              </select2>
            </div>
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="form-group row">
            <label class="col-md-2 col-sm-2 form-control-label text-sm text-muted mt-2">{{$t('settings.tabEmailClient.addAndEditNew.incoming')}}</label>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.hostname')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.imapConf.host">
            </div>  
            <div class="col-md-2 col-sm-2">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.port')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.imapConf.port">
            </div>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.user')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.imapConf.user">
            </div>  
          </div>
          <div class="form-group row">
            <div class="col-md-offset-2 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12">
              <div class="checkbox">
                <label class="ui-check">
                  <input type="checkbox" value="" v-model="newEmailClient.imapConf.tls">
                  <i class="dark-white"></i>
                  {{$t('settings.tabEmailClient.addAndEditNew.tls')}}<br/>
                  <small class="text-muted">{{$t('settings.tabEmailClient.addAndEditNew.tlsText')}}</small>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-sm-2 form-control-label text-sm text-muted mt-2">{{$t('settings.tabEmailClient.addAndEditNew.outgoing')}}</label>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.hostname')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.smtpConf.host">
            </div>  
            <div class="col-md-2 col-sm-2">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.port')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.smtpConf.port">
            </div>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.user')}}</label>
              <input class="form-control" type="text" v-model="newEmailClient.smtpConf.user">
            </div>  
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="row">
          <div class="col-lg-6 col-md-12">
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-left" v-on:click="cancel">
            <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </a>
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-right" v-on:click="addNewEmailClient">
              <span class="primary-color _400">{{$t('btnNames.add')}}</span>
            </a>
          </div>
          </div>
        </form>
      </div>
      <!-- ./END Cart adding new email client -->


      <!-- Cart edit email client -->
      <div class="box-body" v-if="isDetails">
        <form class="form-horizontal">
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.name')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="editedEmailClient.name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.email')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="email" v-model="editedEmailClient.email">
            </div>
          </div>
          
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.password')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="password" v-model="editedEmailClient.pass">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabEmailClient.addAndEditNew.assigned')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <select2 :options="optionsEmps" class="form-control" v-model="editedEmailClient.allowedUsers" multiple="multiple">
              </select2>
            </div>
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="form-group row">
            <label class="col-md-2 col-sm-2 form-control-label text-sm text-muted mt-2">{{$t('settings.tabEmailClient.addAndEditNew.incoming')}}</label>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.hostname')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.imapConf.host">
            </div>  
            <div class="col-md-2 col-sm-2">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.port')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.imapConf.port">
            </div>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.user')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.imapConf.user">
            </div> 
          </div>
          <div class="form-group row">
            <div class="col-md-offset-2 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12">
              <div class="checkbox">
                <label class="ui-check">
                  <input type="checkbox" value="" v-model="editedEmailClient.imapConf.tls">
                  <i class="dark-white"></i>
                  {{$t('settings.tabEmailClient.addAndEditNew.tls')}}<br/>
                  <small class="text-muted">{{$t('settings.tabEmailClient.addAndEditNew.tlsText')}}</small>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-sm-2 form-control-label text-sm text-muted mt-2">{{$t('settings.tabEmailClient.addAndEditNew.outgoing')}} </label>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.hostname')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.smtpConf.host">
            </div>  
            <div class="col-md-2 col-sm-2">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.port')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.smtpConf.port">
            </div>
            <div class="col-md-4 col-sm-4">
              <label>{{$t('settings.tabEmailClient.addAndEditNew.user')}}</label>
              <input class="form-control" type="text" v-model="editedEmailClient.smtpConf.user">
            </div>  
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="row">
          <div class="col-lg-6 col-md-12">
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-left" v-on:click="cancel">
            <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </a>
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-right" v-on:click="editSelectedUser">
              <span class="primary-color _400">{{$t('btnNames.saveChanges')}}</span>
            </a>
          </div>
          </div>
        </form>
      </div>
      <!-- ./END Cart edit email client -->
    </div>  

    

   
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';

export default {
  name: 'EmailClient',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2
  },
  data: function () {
    return {
      isTableShow:true,
      isAddingNewEmailClient:false,
      isDetails: false,
      isLoaded: false,
      mailboxes: [],
      optionsEmps: [],
      employees: [],
      selectedEmailCilents: [],
      newEmailClient: {imapConf: {}, smtpConf: {}},
      editedEmailClient: {imapConf: {}, smtpConf: {}},
      textSuccess: 'Mailbox has been successfully ',
      textError: 'There was a problem with ',
      textEmpty: ' to perform this action.',
      textPermission: 'You do not have permission to ',
    }
  },
  created: function() {
    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
      this.optionsEmps = employees.map(e => {
        return {id: e.id, text: e.name};
      });

      Api.getMailboxes().then((mailboxes) => {
        this.mailboxes = mailboxes;
        this.isLoaded = true;
        this.setEmpsNameForMailBox();
      });

    });
  },
  destroyed: function() {
  },
  computed: {
  },
  methods: {
    setEmpsNameForMailBox: function() {
      let idEmps = this.employees.map(e => e.id+"");
      this.mailboxes.forEach((m) => {
        m.userName = [];
        if (!m.allowedUsers || m.allowedUsers.length == 0) return;
        for (var i = m.allowedUsers.length - 1; i >= 0; i--) {
          let idx = idEmps.indexOf(m.allowedUsers[i]+"");
          if (idx != -1) {
            m.userName.push({id: this.employees[idx].id, name: this.employees[idx].name});
          }
          
        }
      });
    },
    goToEmailClient: function(e) {
      this.isTableShow = false;
      this.isDetails = true;
      if (!e.imapConf) e.imapConf = {}
      if (!e.smtpConf) e.smtpConf = {}
      this.editedEmailClient = e;
    },
    goToCartAddingEmailClient: function() {
      this.isTableShow = false;
      this.isAddingNewEmailClient = true;
      this.isDetails = false;
    },
    cancel: function() {
      this.isAddingNewEmailClient = false;
      this.isDetails = false;
      this.isTableShow = true;
      this.newEmailClient = {imapConf: {}, smtpConf: {}};
    },
    addNewEmailClient: function() {
      if (!this.newEmailClient.name && !this.newEmailClient.email && !this.newEmailClient.pass) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
        return;
      }
      if (this.newEmailClient.pass) {
        this.newEmailClient.imapConf.pass = this.newEmailClient.pass;
        this.newEmailClient.smtpConf.pass = this.newEmailClient.pass;
      }
      Api.createMailboxes(this.newEmailClient).then((mailbox) => {
        this.showNotify('notification-success',this.$i18n.t('notifications.addSuccess'));
        this.mailboxes.push(mailbox);
        this.setEmpsNameForMailBox();
        this.isAddingNewEmailClient = false;
        this.isTableShow = true;
        this.newEmailClient = {imapConf: {}, smtpConf: {}};
      }).catch((reason) => {
        if (reason.response && reason.response.data && reason.response.data.error == 'conf.invalid') {
          this.showNotify('notification-error', this.$i18n.t('settings.tabEmailClient.invalidConf'));
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.addError'));
        }
      });
    },

    autoUpdateHost: function() {
      let emailParts = this.newEmailClient.email.split('@');
      if (emailParts.length < 2) return;

      this.newEmailClient.imapConf.host = emailParts[1];
      this.newEmailClient.imapConf.port = 143;
      this.newEmailClient.imapConf.user = emailParts[0];
      this.newEmailClient.smtpConf.host = emailParts[1];
      this.newEmailClient.smtpConf.port = 25;
      this.newEmailClient.smtpConf.user = emailParts[0];
    },

    editSelectedUser: function() {
      if (!this.editedEmailClient.name.length && !this.editedEmailClient.email.length && !this.editedEmailClient.pass.length) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
        return;
      }
      if (this.editedEmailClient.pass) {
        this.editedEmailClient.imapConf.pass = this.editedEmailClient.pass;
        this.editedEmailClient.smtpConf.pass = this.editedEmailClient.pass;
      }
      Api.editMailboxes(this.editedEmailClient._id, this.editedEmailClient).then((mailbox) => {
        this.showNotify('notification-success',this.$i18n.t('notifications.changeSuccess'));
        let idx = this.mailboxes.map(m => m.id+"").indexOf(this.editedEmailClient.id+"");
        if (idx != -1) {
          this.mailboxes[idx] = mailbox;
        }
        this.setEmpsNameForMailBox();
        this.isDetails = false;
        this.isTableShow = true;
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
      });
    },
    deleteSelected: function() {
      if (this.selectedEmailCilents.length == 0) {this.showNotify('notification-error', this.$i18n.t('notifications.noSelected')); return;}

      let promises = [];
      for (var i = this.selectedEmailCilents.length - 1; i >= 0; i--) {
        let id = this.selectedEmailCilents[i]; 

        let p = Api.deleteMailboxes(id).then((box) => {
          let idx = this.mailboxes.map(m => m._id+"").indexOf(id+"");
          if (idx != -1) this.mailboxes.splice(idx, 1);
        }).catch((reson) => {
          if (reson.response.status == 403) {
            this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
          } else {
            this.showNotify('notification-error', this.$i18n.t('notifications.removeError'));
          }
        });
        promises.push(p);
      }

      Promise.all(promises).then(boxs => {
        this.showNotify('notification-success',this.$i18n.t('notifications.removeSuccess'));
        this.selectedEmailCilents.length = 0;
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .mt-2 {
    margin-top: 2rem;
  }
  .start-info {
    min-height: 130px;
    .box2 {
      right: 75px;
    }
  } 
  .c-label {
    padding: .25em .5em;
    font-size: 85%;
    // line-height: 1;
    vertical-align: baseline;
    // font-weight: 700;
    // background-color: #B7C6D4;
    border: 1px solid #58666e;
    color: #58666e;
    border-radius: .0rem;
  }
</style>
