<template>
  <div>
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.tabTicket.title')}}</h6>
        <div class="pull-right m-l-sm minus-margin">
          <select2 :options="timeUnit" v-model="selectedTimeUnit" class="form-control primary-color" v-on:input="changeDataColectionByTimeUnit">
          </select2>
        </div>
      </div>
      <div class="row">
        <div class="small">
          <bar-chart :chart-data="datacollection" :options="options"></bar-chart>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import BarChart from '../BarChart.js';
import Select2 from '../Select2.vue';

export default {
  name: 'Prefernces',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    BarChart
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales : {
          xAxes : [{
             barPercentage: 0.5,
             maxBarThickness: 30,
          }],
           yAxes : [{
            ticks : {
              beginAtZero : true
            },
            
          }]
        },
        
      },
      datacollection: null,
      timeUnit: [
        { id:'week', text: this.$i18n.t('raports.timeUnit.week') },
        { id:'month', text: this.$i18n.t('raports.timeUnit.month') },
        { id:'3months', text: this.$i18n.t('raports.timeUnit.3months') },
        { id:'year', text: this.$i18n.t('raports.timeUnit.year') },
      ],
      selectedTimeUnit: 'week',
      newAndClosedTickets: [],
      datalabel: [],
      datanew: [],
      dataclosed: []
    }
  },
  created: function() {
    this.getDataColection()
    .then(r => this.assignDataColection());
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    getDataColection: function() {
      return Api.getRaportNewAndClosedTickets(this.selectedTimeUnit).then((newAndClosedTickets) => {
        this.newAndClosedTickets = newAndClosedTickets;
      });
    },
    assignDataColection: function() {
      this.newAndClosedTickets.forEach((n) => 
      { 
        this.datalabel.push(n.day.split('T')[0]);
        this.datanew.push(n.new);
        this.dataclosed.push(n.closed);
      });

      this.datalabel.sort(function(a, b) {
        var dateA = new Date(a), dateB = new Date(b);
        return dateA - dateB;
      });
      
      this.fillData();
    },
    fillData () {
      this.datacollection = {
        labels: this.datalabel,
        datasets: [
          { 
            
            label: this.$i18n.t('raports.tabTicket.new'),
            backgroundColor: '#f0ad4e',
            data: this.datanew
          },
          {
            label: this.$i18n.t('raports.tabTicket.closed'),
            backgroundColor: '#f05050',
            data: this.dataclosed
          }
        ]
      }
    },
    changeDataColectionByTimeUnit: function() {
      this.datalabel = [];
      this.datanew = [];
      this.dataclosed = [];
      this.getDataColection()
      .then(r => this.assignDataColection());
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .small {
    max-width: 95%;
    margin: 5px auto;
  }
</style>
