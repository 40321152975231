<template>
  <div id="app" class="app">
    <div v-if="isLogged" id="content" class="app-content box-shadow-z0">
      <Header v-if="isReady"></Header>
      <div class="app-body">
        <div class="padding" v-if="isReady">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>

      <notifications group="global" :duration="8000" :width="320" position="bottom right">
         <template slot="body" slot-scope="props">
          <div class="box" v-bind:class="[props.item.type]">
            <div class="box-header">
              <p class="notifcation-text">{{props.item.text}}</p>
              <div class="box-tool"><i class="material-icons md-18" @click="props.close">close</i></div>
            </div>
          </div>
        </template>
      </notifications>

      <modals-container/>
      <QuickTicketChat v-if="isReady" />
      <WelcomeWizzard v-if="isReady" />

    </div>

    <div v-if="!isLogged" class="app-content box-shadow-z0" style="overflow: hidden;">
      <Login></Login>
    </div>
  </div>
</template>

<script>
import Header from './components/Header/Header.vue'
import Login from './Login.vue'
import QuickTicketChat from './components/QuickTicketChat.vue'
import WelcomeWizzard from './components/WelcomeWizzard/WelcomeWizzard.vue'
import Api from "./Api";
import UserManager from './UserManager.js'
import EventBus from './event-bus.js';
import SystemNotifications from './SystemNotifications.js'
export default {
  name: 'app',
  components: {
    Header,
    QuickTicketChat,
    WelcomeWizzard,
    Login
  },
  data: function () {
    return {
      isLogged: false,
      isReady: false,
    }
  },
  created: function() {
    this.isLogged = UserManager.isLogged();
    UserManager.events.on('loggedIn', () =>{
      this.isLogged = true;
    });
    UserManager.events.on('loggedOut', () =>{
      this.isLogged = false;
    });
    UserManager.events.on('userReady', () => {
      if (this.$ga) {
        this.$ga.set('userId', UserManager.user.id);
        this.$ga.set('userEmial', UserManager.user.email);
      }
      
      this.isReady = true;
      SystemNotifications.enable(this.$router);
    });

    // w momencie kiedy API wykryje nieprawidłowość obecnego tokena
    // przeladowujemy stronę, co spowoduje, ze user trafi znowu na stronę logowania
    Api.events.on('invalid.token', () => {
      window.location.reload(true);
    })
  },
  destroyed: function() {
  },
  methods: {

    
  }
  
}
</script>

<style lang="scss">

.v-select {
  .vs__dropdown-toggle {
    border-radius: 0px;
    border-color: rgba(120, 130, 140, 0.2);
  }

  .vs__selected {
    border-radius: 0px;
  }

  &.form-control {
    .vs__dropdown-toggle {
        border: 0;
    }
  }
}


@import './assets/styles/animate.min.css';
@import './assets/styles/custom.scss';

@import './assets/styles/select2.css';

@import './assets/styles/bootstrap.min.css';

@import './assets/styles/select2-bootstrap.css';
// @import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// @import './assets/font-awesome/css/font-awesome.min.css';
@import './assets/material-design-icons/material-design-icons.css';
@import './assets/styles/app.css';
@import './assets/styles/font.css';
@import './assets/glyphicons/glyphicons.css';


</style>
