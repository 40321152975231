<template>
    <div class="box m-a-0" style="">
        <div class="box-header">
            <h2 style="font-size: 25px;">{{ currentSectionTitle }}</h2>
            <small>{{ currentSectionSubTitle }}</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body row">
            
            <div class="col-lg-3" style="border-right: 1px dashed #ccc; min-height: 500px;">
                <div class="m-b nav-active-info">
                    <ul class="nav nav-pills nav-stacked" ui-nav="">
                        <li class="nav-item" :class="{'text-muted': section.isSeen, active: activeSection == key}" v-for="(section, key) in sections" :key="key">
                            <a class="nav-link" href="#" @click.prevent="gotoSection(key)">
                                {{ section.title }} <span class="pull-right" v-if="section.isSeen"><i class="material-icons md-18" >done</i></span>
                            </a>
                        </li>
                    </ul>
                </div>
                
            </div>
            <div class="col-lg-9">
                <SectionStart v-if="activeSection == 'start'" @next="gotoSection('inner-email')" @close="$emit('close')" />
                <SectionInnerEmail v-else-if="activeSection == 'inner-email'" @next="gotoSection('company')" @close="$emit('close')" />
                <SectionLogo v-else-if="activeSection == 'company'" @next="gotoSection('team')" @close="$emit('close')" />
                <SectionTeam v-else-if="activeSection == 'team'" @next="gotoSection('automate')" @close="$emit('close')" />
                <SectionAutomate v-else-if="activeSection == 'automate'" @next="$emit('finish')" @close="$emit('close')" />
                <SectionFinish v-else-if="activeSection == 'finish'" @next="$emit('close')" @close="$emit('close')" />
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import SectionStart from './SectionStart.vue';
import SectionInnerEmail from './SectionInnerEmail.vue';
import SectionTeam from './SectionTeam.vue';
import SectionLogo from './SectionLogo.vue';
import SectionAutomate from './SectionAutomate.vue';
import SectionFinish from './SectionFinish.vue';
import {RotateSquare2} from 'vue-loading-spinner';
import Nl2br from 'vue-nl2br'

let lastSection = "start";
const sections = {
    "start": {
        title: "Szybki kreator konfiguracji",
        subtitle: "Przygotuj swój system do pracy na najwyższych obrotach",
        isSeen: false
    },
    "inner-email": {
        title: "Uzyskaj wbudowany adres email",
        subtitle: "Ustaw adres na który mogą pisać Twoi klienci",
        isSeen: false
    },
    "company": {
        title: "Rozpoznawalność na wiadomościach",
        subtitle: "Ustaw nazwę i logo, które zobaczą Twoi klienci w wiadomościach od Ciebie",
        isSeen: false
    },
    "team": {
        title: "Twój zespół",
        subtitle: "Dodaj członków Twojego zespołu",
        isSeen: false
    },
    "automate": {
        title: "Automatyzuj pracę",
        subtitle: "Spraw by system pracował za Ciebie",
        isSeen: false
    },
    "finish": {
        title: "Słowem końcowym",
        subtitle: "Gratulacje, tutaj nie dotarł nawet Chuck Noris",
        isSeen: false
    }
}

export default {
    name: 'WelcomeWizzardModal',
    props: {
    },
    components: {
        RotateSquare2,
        Nl2br,
        SectionStart, SectionInnerEmail, SectionTeam, SectionLogo, SectionAutomate,SectionFinish
    },
    data: function () {
        return {
            activeSection: lastSection,
        }
    },
    created: function() {
    },
    destroyed: function() {
    },
    computed: {
        sections: function() {
            return sections;
        },

        currentSectionTitle: function() {
            if (this.sections[this.activeSection]) return this.sections[this.activeSection].title;
            return "";
        },
        currentSectionSubTitle: function() {
            if (this.sections[this.activeSection]) return this.sections[this.activeSection].subtitle;
            return "";
        }
    },
    methods: {
        gotoSection(section) {
            if (this.activeSection == section) return;
            if (sections[this.activeSection]) {
                sections[this.activeSection].isSeen = true;
            }
            this.activeSection = section;
            lastSection = section;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
pre {
    padding: 10px;
    border-radius: 10px;
    max-height: 200px;
}
</style>
