// import SystemSocket from './SystemSocket.js';
import Push from 'push.js';
import ChatManager from './ChatManager.js';
import EmployeeManager from './EmployeeManager.js';
import SystemSocket from './SystemSocket.js';
import EventBus from './event-bus.js';

let chatIcon = require('./assets/images/chat.png');

let isEnabled = false;
let $router = null;

ChatManager.events.on('some_user_connected', (userId) => {
    if (!isEnabled) return;
    if (!EmployeeManager.employees.length) return;
    
    let user = EmployeeManager.getById(userId);
    if (!user) return;
    Push.create(user.name+" jest online", {
        body: user.name+" właśnie się zalogował(a). Kliknij tutaj, aby przejść do chatu",
        icon: chatIcon,
        timeout: 4000,
        onClick: function () {
            window.focus();
            EventBus.$emit('openUserChat', user);
            this.close();
        }
    });
});

SystemSocket.socket.on('object', (msg) => {
    if (msg.object == 'notification' && msg.verb == 'create') {
        let notif = msg.data;
        if (!notif.data) return;
        if (notif.data.code == 'mention_in_comment') return mentionInComment(notif);
        else if (notif.data.code == 'new_ticket_reply') return newTicketReply(notif);
        else if (notif.data.code == 'live_chat_waiting') return newLiveChat(notif);
    }
});

function newLiveChat(notif) {
    Push.create("Nowa rozmowa na LiveChat", {
        body: "Ktoś napisał na LiveChat. Przyszpisz się do zgłoszenia jeśli chcesz przejąć rozmowę",
        // icon: chatIcon,
        timeout: 8000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'ticket', params: { id: notif.data.objectId } })
            this.close();
        }
    });
}

function newTicketReply(notif) {
    Push.create("Nowa odpowiedź w Twoim zgłoszeniu", {
        body: "Masz nową wiadomość w zgłoszeniu. Kliknij tutaj, aby przejść do tego zgłoszenia",
        // icon: chatIcon,
        timeout: 8000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'ticket', params: { id: notif.data.objectId } })
            this.close();
        }
    });
}

function mentionInComment(notif) {
    Push.create("Zostałeś wspomniany w komentarzu", {
        body: "Ktoś o Tobie wspomniał. Kliknij tutaj, aby przejść do tego komentarza",
        // icon: chatIcon,
        timeout: 8000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'ticket', params: { id: notif.data.objectId } })
            this.close();
        }
    });
}

export default {
    /**
     * Uruchamia systemowe powiadomienia (w postaci dymkow)
     * @param {object} router Vue'owski router, zeby moc kierowac uzytkownikow po klikniecu w dymek
     */
    enable: function(router) {
        isEnabled = true;
        Push.Permission.request();
        if (router) $router = router;
    },
    disable: function() {
        isEnabled = false;
    }
};