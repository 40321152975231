<template>
    <div >
        <rotate-square2 v-if="isSaving" style="position:absolute; right: 15px; top: 0px;"></rotate-square2>
        <p style="max-width: 500px; position:relative;">
            <span class="text-u-c _600">Automatyzacja</span><br/>
            Zleć systemowi aby automatycznie przypisywał nowe zgłoszenia do Twojego zespołu bazując na średnich czasach odpowiedzi poszczególnych użytkowników.
        </p>

        <div class="box-body fb-box" v-if="!isLoading" style="max-width: 500px; min-height: 300px; max-height: 600px; overflow-y: auto">
            <form class="form-horizontal">
                <div class="form-group">
                    <label>Do kogo aumatycznie przypisywać zgłoszenia?</label>
                    <v-select 
                        multiple
                        class="form-control"
                        placeholder="Do wszystkich"
                        v-model="selectedEmployees"
                        :options="employees"
                        label="name"
                        @input="save">
                        <template slot="no-options">
                            Brak użytkowników zdefinowanych w systemie.
                            Przejdź do "Ustawienia > użytkownicy" aby dodać pierwszego!
                        </template>
                    </v-select>
                </div>

            </form>
        </div>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('next')">Dalej</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Pomiń</a>
        </p>

        
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import EmployeeModal from '../SettingsPage/Modals/EmployeeModal.vue';
import {RotateSquare2} from 'vue-loading-spinner'

let timeout = null;

export default {
    name: 'SectionLogo',

    components: {
        RotateSquare2
    },

    data: function () {
        return {
            isLoading: true,
            isSaving: false,
            ticketAssignerData: {},
            employees: [],
            selectedEmployees: []
        }
    },

    created: async function() {
        try {
            this.ticketAssignerData = await Api.getTicketAssignerAvailableEmps();
            this.ticketAssignerData.enabled = true; // na start wlaczamy domyslnie
            if (!this.ticketAssignerData.availableEmployees) this.ticketAssignerData.availableEmployees = [];
            this.employees = await EmployeeManager.getEmployees();
            this.selectedEmployees = this.ticketAssignerData.availableEmployees.map(eId => {
                return this.employees.find(e => e.id == eId);
            });
        } catch (error) {
        }
        this.isLoading = false;
    },

    destroyed: function() {
    },

    computed: {
        test: function() {
        }
    },

    methods: {
        save: function () {
            clearTimeout(timeout);
            timeout = setTimeout(async () => {
                this.isSaving = true;
                this.ticketAssignerData.availableEmployees = this.selectedEmployees.map(e => e._id || e.id);
                await Api.setTicketAssignerAvailableEmps(this.ticketAssignerData)
                this.isSaving = false;
            }, 1000);
        }
    }
}
</script>

<style>

</style>