<template>
    <div class="quick-chat-box" :class="{active: hasActivity}" @click="hasActivity = false;">
        <div v-if="ticket == null">Loading...</div>
        <div v-else class="">
            <div class="quick-chat-box-head">
                <span class="title-color text-strong">
                    <span v-for="channel in ticket.channels">
                        <span  title="email" v-if="channel.type == 'email'" class="material-icons md-16 m-l-xs coral">email</span>
                        <img title="instagram" v-if="channel.type == 'ig'" class="m-l-xs" style="width: 16px; " src="../assets/images/instaram-icon.svg">
                        <img title="instagram" v-if="channel.type == 'fb'" class="m-l-xs" style="width: 16px; " src="../assets/images/m5.svg">
                    </span>
                    <a @click="$router.push({ path: `/ticket/${ticket.id}`}); $emit('close')" class="text-primary text-bold text-u-c">#{{ticket.id.substr(-6)}}  <i class="material-icons md-16">call_made</i></a>
                </span><br/>
                <span style="font-size: 0.7rem;" class="text-sm text-muted">{{ticket.subject}}</span>
                <span style="font-size: 0.7rem;" class="text-sm text-muted" v-if="assignedEmp && !isCollapsed"><br/>Opiekun: {{ assignedEmp.name }}</span>
                <div class="toolbox">
                    <a class="text-blue text-xs m-r-sm" @click="isCollapsed = !isCollapsed">
                        <i v-if="!isCollapsed" class="material-icons md-16">vertical_align_center</i>
                        <i v-else class="material-icons md-16">vertical_align_top</i>
                    </a>
                    <a class="text-warn text-xs" @click="$emit('close')">
                        <i class="material-icons md-16">clear</i>
                    </a>
                </div>
            </div>
            <div class="m-a-0 custom-divider"></div>
            <div v-if="!isCollapsed" class="quick-chat-box-body primary-color p-a-1 hidden-sm-down">
                <div v-if="messages && messages.length > 0">
                    <div class="m-b" v-for="m in sortedMessages" :key="m._id">
                        <div class="p-a p-y-sm text-sm r" :class="{'d-gray': m.userId, 'customer-msg': !m.userId}">
                            <span class="text-muted">
                            <span>{{m.author}} </span>
                            {{$t('header.chat.says')}}:
                            </span>
                            <br/>
                            <nl2br v-if="m.content" tag="p" :text="m.content | truncate(200)" />
                            <a v-if="m.content && m.content.length > 200" @click="$router.push({ path: `/ticket/${ticket.id}`}); $emit('close')" class="text-primary">
                                Czytaj całość <i class="material-icons md-16">call_made</i>
                            </a>
                        </div>
                        <div class="text-muted text-xs m-t-xs">
                            <i class="fa fa-ok text-success"></i>  
                            {{ m.createdAt | moment("DD MMM YYYY, HH:mm") }}
                        </div>
                    </div>
                </div>
            </div>
            <div  v-if="!isCollapsed" class="quick-chat-box-footer">
                <div class="p-a-sm">
                    <!-- <input class="form-control form-control-sm" type="text" /> -->
                    <textarea class="form-control form-control-sm reply-input" ref="replyinput" :placeholder="$t('header.chat.type')"
                    v-on:keyup="autoGrow($event.target)" v-on:keyup.enter.shift="sendMessage" v-model="msg" v-bind:class="{ disabled: isSending }"
                    ></textarea>
                    <span class="text-muted text-sm">Shift + Enter żeby wysłać</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../Api.js';
import Nl2br from 'vue-nl2br'
import interact from 'interactjs';
import SystemSocket from '../SystemSocket.js';
import EmployeeManager from '../EmployeeManager.js';

function dragMoveListener (event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform =
    target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}

export default {
    name: 'QuickTicketChatBox',
    props: ['ticketid'],

    components: {
        Nl2br
    },

    data: function () {
        return {
            position: { x: 0, y: 0 },
            ticket: null,
            isCollapsed: false,
            isSending: false,
            hasActivity: false,
            messages: [],
            msg: '',
            assignedEmp: null,
            lastSentMessage: null
        }
    },

    mounted: async function() {
        interact('.quick-chat-box').draggable({
            inertia: true,
            listeners: {
                move: dragMoveListener
            }
        });
        if (!this.ticketid) return;
        try {
            this.ticket = await Api.getTicket(this.ticketid);
            this.messages = await Api.getTicketMessages(this.ticket.id);
            if (this.ticket.assignedUserId) this.assignedEmp = EmployeeManager.getById(this.ticket.assignedUserId);
            // await Api.setNotificationsSeenForTicket(this.ticketid);
        } catch (error) {
            console.error(error);
        }

        SystemSocket.socket.on('object', this.onSocketUpdate);
    },

    destroyed: function() {
        SystemSocket.socket.off('object', this.onSocketUpdate);
    },

    computed: {
        sortedMessages: function () {
            return this.messages.sort(function(a, b) {
                var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        }
    },

    methods: {
        onSocketUpdate: function(event) {
            if (event.object == 'message' && event.verb == 'create') {
                if (this.ticket.id+"" != event.data.ticketId+"") return;
                
                let idx = this.messages.map(m => m.id+"").indexOf(event.data.id+"");
                if (idx == -1) {
                    this.messages.push(event.data);

                    if (this.lastSentMessage && event.data.id+"" == this.lastSentMessage.id+"") return;
                    this.hasActivity = true;
                }
            }
        },

        sendMessage: async function() {
            if (!this.msg.length) { 
                this.showNotify('notification-error', this.$i18n.t('notifications.empty')); 
                return;
            }

            this.isSending = true;
            try {
                let data = {content: this.msg.trim()};
                this.lastSentMessage = await Api.tickets.sendReply(this.ticket.id, data);
                this.showNotify('notification-success', this.$i18n.t('notifications.sendSuccess'));
                this.msg = '';
            } catch (error) {
                this.showNotify('notification-error', this.$i18n.t('notifications.error')); 
            }
            this.isSending = false;

            let element = this.$el.querySelector('.reply-input');
            element.style.height = "10px";
            
        },

        autoGrow: function(target) {
            target.style.height = (target.scrollHeight)+"px";
        },

        showNotify (type, text) {
            this.$notify({
                group: 'global',
                type: type,
                text: text,
            });
        }
    }
}
</script>

<style lang="scss">
.quick-chat-box {
    position: fixed;
    width: 250px;
    // height: 355px;
    border: solid 1px #ccc;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    touch-action: none;
    user-select: none;
    z-index: 10;
    box-shadow: 0px 0px 10px 2px #00000026;

    // -moz-transition: height 1s ease;
    // -webkit-transition: height 1s ease;
    // -o-transition: height 1s ease;
    // transition: height 1s ease;
    
    &.active {
        // box-shadow: 0px 0px 10px 2px #beccc29e;
        
        .quick-chat-box-head {
            animation: active-animation 3s infinite;
        }
    }
}

.quick-chat-box-head {
    // height: 50px;
    padding: 5px;
    position: relative;
    background-color: #f6f8f8;

    h2 {
        font-size: 16px;
    }

    .toolbox {
        position: absolute;
        right: 5px;
        top: 5px;
    }
}

.quick-chat-box-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 250px;

    .customer-msg {
        background-color: #c9ecce;
    }

}

.quick-chat-box-footer {
    // height: 50px;
    .reply-input {
        resize: none;
        overflow: hidden;
        height: 10px;
        font-size: 12px;
    }

    .text-sm {
        font-size: 10px;
    }
}

@keyframes active-animation {
  0% {
    // box-shadow: 0px 0px 10px 2px #630cc79e;
    background-color: #2bd7569e;
  }
  50% {
    // box-shadow: 0px 0px 10px 2px #2bd75600;
    background-color: #2bd75600;
  }
  100% {
    // box-shadow: 0px 0px 10px 2px #630cc79e;
    background-color: #2bd7569e;
  }
}
</style>