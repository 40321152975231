<template>
<div>
  <!-- <div class="box-header white">
    <div class="t-header priamry-color _500" style="width: 4%;"></div>
    <div class="t-header priamry-color _500"  style="width: 4%;"><i class="material-icons md-18"></i></div>
    <div class="t-header priamry-color _500"  style="width: 60%;">Subject</div>
    <div class="t-header priamry-color _500"  style="width: 22%;">Correspondents</div>
    <div class="t-header priamry-color _500"  style="width: 10%%;">Date</div>
  </div> -->
  <multipane layout="horizontal" :style="{ height: (windowHeight-170)+'px' }" v-on:paneResizeStop="saveHeightMailPanel">
    <div class="table-mails" :style="{ minHeight: '100px', height: panelHeight }">
      <context-menu id="context-menu" ref="ctxMenu" @ctx-open="onCtxOpen">
        <li><a class="ctx-link" href="#" @click.prevent="onCtxSelectOption('show-conversation')">
          {{ $t('mails.showConversation') }}
        </a></li>
        <li><a class="ctx-link" href="#" @click.prevent="onCtxSelectOption('mark-unread')">
        {{ $t('mails.markAsUnread') }}
        </a></li>
        <li v-if="currentCtxMessage" v-bind:class="{'d-none': inArray(currentCtxMessage.flags, '\\Draft')}">
          <a class="ctx-link" href="#" @click.prevent="onCtxSelectOption('reply')" >
          {{ $t('mails.reply') }}
          </a>
        </li>
        <li v-if="currentCtxMessage" v-bind:class="{'d-none': inArray(currentCtxMessage.flags, '\\Draft')}">
          <a class="ctx-link" href="#" @click.prevent="onCtxSelectOption('forward')">
            {{ $t('mails.forward') }}
          </a>
        </li>
        <li><a class="ctx-link" href="#" @click.prevent="onCtxSelectOption('move-to-trash')">
          {{ $t('mails.deleteMessage') }}
        </a></li>
      </context-menu>
      <div class="table-responsive">
      <table class="table b-t custom-table">
        <tbody>
          <tr v-for="m in evenMessages" v-bind:class="{'_600': !inArray(m.flags, '\\Seen'), 'active': m.uid == selected}" 
          @contextmenu.prevent="$refs.ctxMenu.open($event, {message: m})">
            <td class="checkbox p-r-sm mails-row">
              <label class="ui-check text-xs">
                <input type="checkbox" v-model="m.selected">
                <i class="dark-white"></i>
              </label>
            </td>
            <td class="mails-row" v-on:click="seeMsg(m)">
              <span class="attach" v-if="m.attachments && m.attachments.length > 0"> <i class="material-icons md-18"></i></span>
              <span v-if="inArray(m.flags, '\\Answered') == true" v-bind:class="{'reply': inArray(m.flags, '$Forwarded'), 'postion-icon': inArray(m.flags, '\\Answered') && !inArray(m.flags, '$Forwarded')}">
                <i class="material-icons md-16 text-d-blue">reply</i>
              </span>
              <span v-if="inArray(m.flags, '$Forwarded') == true" v-bind:class="{'forward': inArray(m.flags, '\\Answered'), 'postion-icon': inArray(m.flags, '$Forwarded') && !inArray(m.flags, '\\Answered')}">
                <i class="material-icons md-16 text-yellow">forward</i>
              </span>
            </td>
            <td class="mails-row" style="padding-left: 5px;" v-on:click="seeMsg(m)">
              <span v-if="m.subject">{{m.subject | truncate(75)}}</span>
              <span v-else>{{ $t('mails.noSubject') }}</span>
            </td>
            <td class="mails-row" v-on:click="seeMsg(m)">
              <span v-if="(m.box == 'Sent' || m.box == 'Drafts') && m.to">
                <span v-for="k in m.to.value">{{k.name || k.address}}</span>
              </span>
              <span v-else-if="m.box != 'Sent' && m.from">
                <span v-for="v in m.from.value">{{v.name || v.address}}</span>
              </span>
            </td>
            <td class="mails-row" v-on:click="seeMsg(m)">{{m.date | moment("DD-MM-YYYY  HH:mm")}}</td>
          </tr>
          <tr class="pin text-center" v-if="total > infoMessages.slectedFolderMsgs.length">
            <td class="mails-row" colspan="5" v-on:click="showMoreMessages">
                <span class="primary-color _400"><i class="material-icons md-16">keyboard_arrow_down</i> {{ $t('mails.showMore') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <multipane-resizer></multipane-resizer>
    <div class="box-header d-gray custom-border-t" v-if="mailContnet" style="z-index: 0;">
      <div class="row">
        <div class="col-md-1 col-sm-2 col-xs-3 p-r-xs">
          <div class="text-muted text-right">{{ $t('mails.searchInput.subject') }}:</div>
          <div class="text-muted text-right">{{ $t('mails.searchInput.from') }}:</div>
          <div class="text-muted text-right">{{ $t('mails.searchInput.to') }}:</div>
          <div class="text-muted text-right" v-if="mailContnet.cc">Cc:</div>
        </div>

        <div class="col-md-11 col-sm-10 col-xs-9 p-l-xs">

          <div class="pull-right">
            <button class="btn btn-xs white none-radius" v-on:click="EventBus.$emit('write-msg-reply-click', mailContnet);" 
            v-bind:class="{'d-none': inArray(mailContnet.flags, '\\Draft')}">
              <span class="primary-color _400"><i class="material-icons md-16">reply</i> {{ $t('mails.reply') }}</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" v-on:click="EventBus.$emit('write-msg-reply-all-click', mailContnet);" 
            v-bind:class="{'d-none': inArray(mailContnet.flags, '\\Draft') || !hasManyRecipents(mailContnet)}">
              <span class="primary-color _400"><i class="material-icons md-16">reply_all</i> {{ $t('mails.reply-all') }}</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" 
            v-bind:class="{'d-none': inArray(mailContnet.flags, '\\Draft')}"
            v-on:click="EventBus.$emit('write-msg-fwd-click', mailContnet);">
              <span class="primary-color _400"><i class="material-icons md-16">forward</i> {{ $t('btnNames.forward') }}</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius m-r-sm" v-on:click.prevent="EventBus.$emit('write-msg-based-on-draft', mailContnet);"
            v-bind:class="{'d-none': !inArray(mailContnet.flags, '\\Draft')}">
              <span class="text-blue _400"><i class="material-icons md-16">edit</i> {{ $t('btnNames.edit') }}</span>
            </button>
            <button class="btn btn-xs white none-radius" v-on:click="moveMsgToTrash">
              <span class="primary-color _400"><i class="material-icons md-16">delete</i> {{ $t('btnNames.delete') }}</span>
            </button>&nbsp;
          </div>

          <div><b v-if="mailContnet.subject">{{mailContnet.subject}}</b><span class="text-muted" v-else>&lt; {{ $t('mails.noSubject') }} &gt;</span></div>
          <div>
            <span v-if="mailContnet.from"><span v-for="v in mailContnet.from.value">{{v.name}} <span class="text-muted"> &lt;{{v.address}}&gt;</span></span></span>
            <span v-else>{{ $t('mails.none') }}</span>
          </div>
          <div class="pull-right text-muted text-xs">
            <span class="text-right">{{mailContnet.date | moment("DD-MM-YYYY  HH:mm")}}</span>
          </div>
          <div v-if="mailContnet.to"><span v-for="v in mailContnet.to.value">{{v.name}} <span class="text-muted"> &lt;{{v.address}}&gt;</span></span></div>
          <div v-if="mailContnet.cc"><span v-for="c in mailContnet.cc.value">{{c.name}} <span class="text-muted"> &lt;{{c.address}}&gt;</span></span></div>
          
          <div v-if="inArray(mailContnet.flags, '\\Draft')"><span class="text-blue">&lt;&lt; {{ $t('mails.draftMessage') }} &gt;&gt;</span></div>
        </div>
      </div>
    </div>
    <div style="z-index: 0;" class="box-info-with-messages custom-border-t" :style="{ minHeight: '150px'}">
      <Spinner v-if="isLoaded"></Spinner>
      <div v-if="mailContnet" style="height: 100%;">
        <div style="height: 100%; overflow-y: hidden;">
          <iframe  style="width: 100%; border: 0px; min-height: 150px; height: 100%;" :src="getIframeSrc()"></iframe>
        </div>
      </div>
    </div>
    <div class="attachments custom-border-t" v-if="mailContnet && mailContnet.attachments && mailContnet.attachments.length > 0">
      <div class="box-body d-gray">
        <a data-toggle="collapse" href="#attachments" aria-expanded="false" aria-controls="attachments">
          <i class="material-icons md-24"></i>

          <i class="material-icons md-16">attach_file</i>{{mailContnet.attachments.length}} 
          <span v-if="mailContnet.attachments.length == 1">{{ $t('mails.attachment') }} </span>
          <span v-else>{{ $t('mails.attachments') }} </span>   
        </a>
        <span class="text-muted text-xs">{{round(mailContnet.attachments)}} KB</span>
        <div class="pull-right">
          <a class="btn btn-xs white none-radius m-b-xs" :href="getAttachments()" target="_blank">
            <span class="primary-color _400"><i class="material-icons md-16">save</i> {{ $t('mails.saveAll') }}</span>
          </a>&nbsp;
        </div>
      </div>
      <div class="collapse white" id="attachments">
        <div class="box-body p-y-xs">
          <span v-for="a in mailContnet.attachments" v-if="a.params.name || (a.disposition.params && a.disposition.params.filename)">
            <a :href="getAttachmentLink(a.partID)" target="_blank" title="Open the attached file" v-if="a.params.name">{{a.params.name}}</a>
            <a :href="getAttachmentLink(a.partID)" target="_blank" title="Open the attached file" v-else>{{a.disposition.params.filename}}</a>
            <span class="text-muted text-xs m-r m-l-xs">{{Math.round(a.size/1024)}} KB</span>
          </span>
        </div>
      </div>
    </div>
  </multipane>
</div>
</template>
<script>

import $ from 'jquery'
import EventBus from '../../event-bus.js';
import Spinner from '../Spinner.vue';
import EmployeeManager from '../../EmployeeManager.js';
import Api from '../../Api.js';
import Nl2br from 'vue-nl2br'
import contextMenu from 'vue-context-menu'
import { Multipane, MultipaneResizer } from 'vue-multipane';

export default {
  name: 'MailList',
  props: {
    infoMessages: Object
  },
  components: {
    Spinner,
    Nl2br,
    contextMenu,
    Multipane,
    MultipaneResizer
  },
  data: function () {
    return {
      isLoaded: false,
      EventBus: EventBus,
      selected: null,
      idbox: null,
      mailContnet: null,
      currentCtxMessage: null,
      iframeSrc: '',
      total: '',
      panelHeight: '300px'
    }
  },
  created: function() {
    this.idbox = this.infoMessages.idBox;
    this.getHeightMailPanel();
  },
  destroyed: function() {
    
  },
  computed: {
    evenMessages: function () {
      this.total = this.infoMessages.total;

      return this.infoMessages.slectedFolderMsgs.sort(function(a, b) {
        var dateA = new Date(a.date), dateB = new Date(b.date);
        return dateB - dateA;
      });
    }
  },
  methods: {
    saveHeightMailPanel: function(pane, container, size) {
      Api.putInStorage('multipane_mail_height', size).then((boolean) => {
      }).catch((error) => {
      });
    },
    getHeightMailPanel: function() {
      Api.me.getFromStorage('multipane_mail_height').then((res) => {
        if (res) this.panelHeight = res;
      }).catch((error) => {
      });
    },
    round: function(attachments) {
      let size = 0;
      for (var i = attachments.length - 1; i >= 0; i--) {
        size += attachments[i].size;
      }

      return Math.round(size/1024); 
    },
    getIframeSrc: function() {
      return Api.baseUrl+'mails/imap/'+this.infoMessages.idBox+'/mail-text/'+this.mailContnet.box+'/'+this.mailContnet.uid+'?html=1&token='+Api.token;
    },
    getAttachmentLink: function(partId) {
      return Api.baseUrl+'mails/imap/'+this.infoMessages.idBox+'/'+this.mailContnet.box+'/'+this.mailContnet.uid+'/attachment/'+partId+'?token='+Api.token;
    },
    getAttachments: function() {
      return Api.baseUrl+'mails/imap/'+this.infoMessages.idBox+'/'+this.mailContnet.box+'/'+this.mailContnet.uid+'/allAttachments/?token='+Api.token;
    },
    seeMsg: function(m) {
      this.selected = m.uid;
      this.mailContnet = m;
      this.mailContnet.accountId = this.infoMessages.idBox;
      let isSeen  = this.inArray(m.flags, '\\Seen');
      if (!isSeen) this.setMsgAsSeen(m);
    },
    setMsgAsSeen: function(m) {
      let flags = {"\\Seen": true};
      Api.setFlags(this.infoMessages.idBox, m.box, m.uid, flags).then((content) => {
        m.flags = content[0].flags;
        EventBus.$emit('setMsgUnseenCouter');
      });
    },
    moveMsgToTrash: function() {
      let uids = [];
      uids.push(this.mailContnet.uid);

      Api.moveSelectedMailsToFolder(this.infoMessages.idBox, this.mailContnet.box, "Trash", uids).then((move) => {
        let idx = this.infoMessages.slectedFolderMsgs.map(m => m.uid+"").indexOf(this.mailContnet.uid+"");
        if (idx != -1) {
          this.infoMessages.slectedFolderMsgs.splice(idx, 1);
        }
        this.mailContnet = null;
        this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess'));
      });
    },
    showMoreMessages: function() {
      EventBus.$emit('showMoreMessages', {});
    },
    onCtxOpen: function(local) {
      this.currentCtxMessage = local.message;
    },
    onCtxSelectOption: function(option) {
      if (option == 'show-conversation') {
        this.seeMsg(this.currentCtxMessage);
      }
      if (option == 'mark-unread') {
        this.currentCtxMessage.selected = true;
        EventBus.$emit('mark-messages-as-unread');
      }
      if (option == 'reply') {
        EventBus.$emit('write-msg-reply-click', Object.assign(this.currentCtxMessage, {accountId: this.infoMessages.idBox}));
      }
      if (option == 'forward') {
        EventBus.$emit('write-msg-fwd-click', Object.assign(this.currentCtxMessage, {accountId: this.infoMessages.idBox}));
      }
      if (option == 'move-to-trash') {
        this.currentCtxMessage.selected = true;
        EventBus.$emit('move-mails-to-selected-folder', {moveTo:'Trash'});
      }
    },
    inArray: function(stack, needle) {
      if (!Array.isArray(stack)) return false;
      let length = stack.length;
      for(let i = 0; i < length; i++) {
          if(stack[i] == needle) return true;
      }
      return false;
    },

    hasManyRecipents(message) {
      if (message.cc && message.cc.value && message.cc.value.length > 0) return true;
      if (message.to && message.to.value&& message.to.value.length > 1) return true;
      return false;
    },

    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    }
  }
}
</script>
<style scoped lang="scss">
.ctx-menu-container {
  border:none;
}
.ctx-menu {
  border-radius: 0px;
  padding: 0px;
  .ctx-link {
    display: block;
    padding: 2px 5px;
  }
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: #E2E8EE;
  }
}
.t-header {
  display: inline-block;
}
.spinner[data-v-fa81853e]::after{
  background: #0cc2aa;
}
.table-mails {
  height: 350px;
  overflow-y: auto;
  width: 100%;
}
.attachments {
  a:hover {
    color: #0cc2aa;
  }
}
.box-info-with-messages {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
  // min-height: 100px;
}
</style>
