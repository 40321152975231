<template>
    <div class="box m-a-0">
        <div class="box-header">
            <h3>Edytuj kanał komunikacji</h3>
            <small><TicketChannelIcon :type="channel.type" size="20" />&nbsp;{{channel.value}}</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <div class="form-group">
                    <label>Zmień adres email</label>
                    <input class="form-control" type="text" v-model="editedValue">
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer">
            <a class="btn btn-sm success none-radius pull-right" @click="save">Zapisz</a>
            <a class="btn btn-sm white none-radius" @click="$emit('close')">Anuluj</a>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';
import Nl2br from 'vue-nl2br'
import TicketChannelIcon from '../TicketChannelIcon.vue';

export default {
    name: 'EditChannelModal',
    props: {
        ticket: Object,
        channel: Object
    },
    components: {
        TicketChannelIcon,
        RotateSquare2,
        Nl2br
    },
    data: function () {
        return {
            editedValue: "",
            isProcessing: false
        }
    },
    created: function() {
        this.editedValue = this.channel.value;
    },
    destroyed: function() {
    },
    computed: {
    },
    methods: {
        async save() {
            this.isProcessing = true;
            try {
                this.channel.value = this.editedValue;
                await Api.tickets.updateContactDetails(this.ticket._id, {channels: this.ticket.channels});
                this.$n.success(this.$i18n.t('notifications.changeSuccess'));
                this.$emit('close');                
            } catch (error) {
                this.$n.error(this.$i18n.t('notifications.changeError'));
            }
            this.isProcessing = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
pre {
    padding: 10px;
    border-radius: 10px;
    max-height: 200px;
}
</style>
