<template>
  <div>
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.tabStatus.title')}}</h6>
        <div class="pull-right m-l-sm minus-margin">
        </div>
      </div>
      <div class="row">
        <div class="small col-md-6">
          <doughnut-chart :chart-data="datacollectionstatus" :options="options"></doughnut-chart>
        </div>
        <div class="small col-md-6">
          <doughnut-chart :chart-data="datacollectionpriority" :options="optionspriority"></doughnut-chart>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import format from 'date-fns/format';

import DoughnutChart from '../DoughnutChart.js';

export default {
  name: 'Prefernces',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    DoughnutChart
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            position: 'bottom',
            text: this.$i18n.t('raports.tabStatus.statusRaport')
        },
        // tooltips: {
        //   callbacks: {
        //     label: function(tooltipItem, data) {
        //       var dataset = data.datasets[tooltipItem.datasetIndex];
        //       var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
        //         return previousValue + currentValue;
        //       });
        //       var currentValue = dataset.data[tooltipItem.index];
        //       var percentage = Math.floor(((currentValue/total) * 100)+0.5);         
        //       return percentage + "%";
        //     }
        //   }
        // }
      },
      optionspriority: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            position: 'bottom',
            text: this.$i18n.t('raports.tabStatus.priorityRaport')
        },
      },
      datacollectionstatus: null,
      datacollectionpriority: null,
      datastatus: [],
      datapriority: [],
    }
  },
  created: function() {
    this.getStatusAndPriorityRaport();
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    getStatusAndPriorityRaport: function() {
      return Api.getRaportTicketSummaryStatusAndPriority().then((statusAndPriority) => {
        this.datastatus.push(statusAndPriority.byStatus.new);
        this.datastatus.push(statusAndPriority.byStatus.open);
        this.datastatus.push(statusAndPriority.byStatus.pending);
        this.datapriority.push(statusAndPriority.byPriority.low);
        this.datapriority.push(statusAndPriority.byPriority.medium);
        this.datapriority.push(statusAndPriority.byPriority.hight);

        this.fillData();
      });
    },
    fillData () {
      this.datacollectionstatus = {
        labels: [this.$i18n.t('raports.tabStatus.new'), this.$i18n.t('raports.tabStatus.open'), this.$i18n.t('raports.tabStatus.pending')],
        datasets: [
          { 
            backgroundColor: ['#f0ad4e', '#90c844', '#5bc0de'],
            data: this.datastatus
          },
        ]
      }

      this.datacollectionpriority = {
        labels: [this.$i18n.t('raports.tabStatus.low'), this.$i18n.t('raports.tabStatus.medium'), this.$i18n.t('raports.tabStatus.hight')],
        datasets: [
          { 
            backgroundColor: ['#5bc0de', '#90c844', '#f05050'],
            data: this.datapriority
          },
        ]
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .small {
    // max-width: 95%;
    margin: 5px auto;
  }
</style>
