<template>
  <div>
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.tabEmptickets.title')}}</h6>
      </div>
      <div class="row">
        <div class="small col-md-10">
          <horizontal-bar :chart-data="datacollection" :options="options"></horizontal-bar>
        </div>
        <div class="col-md-2">
          <div class="form-group m-t-md raports">
            <label>{{$t('raports.selectTitle')}}:</label>
            <select2 v-if="isReady" :options="ticketCounts" v-model="selectedEmps" class="form-control form-control-sm p-x b-a" v-on:input="changeDataColection" multiple="multiple" style="width: 95%">
            </select2>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import {RotateSquare5} from 'vue-loading-spinner';
import $ from 'jquery'
import Api from '../../Api.js';
import HorizontalBar from '../HorizontalBar.js';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';


export default {
  name: 'EmployeeTickets',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    HorizontalBar
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales : {
          xAxes : [{
            stacked: true,
            ticks : {
              beginAtZero : true
            },
          }],
           yAxes : [{
              stacked: true,
              barPercentage: 0.5,
              maxBarThickness: 30,
          }]
        },
      },
      datacollection: null,
      datalabel: [],
      datanew: [],
      dataopen: [],
      datapending: [],
      selectedEmps: [],
      isReady: false,
      ticketCounts: [],
      employees: [],
    }
  },
  created: function() {
    this.getTicketCountsPerEmployee()
      .then(r => this.getEmployees())
      .then(r => this.assignEmpNameToTicketCounts())
    ;
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    getTicketCountsPerEmployee: function() {
      return Api.getRaportTicketCountsPerEmployee().then((ticketCounts) => {
        this.ticketCounts = ticketCounts;
      });
    },
    getEmployees: function() {
      return EmployeeManager.getEmployees().then((employees) => {
        this.employees = employees;
      });
    },
    assignEmpNameToTicketCounts: function() {
      this.employees.forEach((e) => 
      {
        let idx = this.ticketCounts.map(t => t.idEmployee+'').indexOf(e.id+'');

        if (idx != -1) {
          this.ticketCounts[idx].text = e.name+'';
          this.ticketCounts[idx].id = e.id+'';
          this.datalabel.push(this.ticketCounts[idx].text);
          this.datanew.push(this.ticketCounts[idx].new);
          this.dataopen.push(this.ticketCounts[idx].open);
          this.datapending.push(this.ticketCounts[idx].pending);
        }
      });

      this.isReady = true;
      this.fillData();
    },
    fillData () {
      this.datacollection = {
        labels: this.datalabel,
        datasets: [
          {
            type: 'horizontalBar',
            label: this.$i18n.t('raports.tabEmptickets.new'),
            backgroundColor: '#f0ad4e',
            data: this.datanew
          },
          {
            type: 'horizontalBar',
            label: this.$i18n.t('raports.tabEmptickets.open'),
            backgroundColor: '#90c844',
            data: this.dataopen
          },
          {
            type: 'horizontalBar',
            label: this.$i18n.t('raports.tabEmptickets.pending'),
            backgroundColor: '#5bc0de',
            data: this.datapending
          },
        ],
      }
    },
    changeDataColection() {
      this.datalabel = [];
      this.datanew = [];
      this.dataopen = [];
      this.datapending = [];
      this.dataclose = [];
      if (!this.selectedEmps) {
        this.selectedEmps = [];

        this.employees.forEach((e) => 
        {
          let idx = this.ticketCounts.map(o => o.id+'').indexOf(e.id+'');
          if (idx != -1) {
            this.datalabel.push(this.ticketCounts[idx].text);
            this.datanew.push(this.ticketCounts[idx].new);
            this.dataopen.push(this.ticketCounts[idx].open);
            this.datapending.push(this.ticketCounts[idx].pending);
          }
        });
        this.fillData();
        return; 
      }
      
      this.selectedEmps.forEach((e) => 
      {
        let idx = this.ticketCounts.map(o => o.id+'').indexOf(e+'');
        if (idx != -1) {
          this.datalabel.push(this.ticketCounts[idx].text);
          this.datanew.push(this.ticketCounts[idx].new);
          this.dataopen.push(this.ticketCounts[idx].open);
          this.datapending.push(this.ticketCounts[idx].pending);
        }
      });
      this.fillData();
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .small {
    // max-width: 75%;
    margin: 5px auto;
    position: relative; 
    max-height:500px;
  }
  @media (max-width: 767px) {
    .raports {
      margin-left: 10px;
    }
  }
</style>
