<template>
    <div class="box">
        <div class="box-tool" style="z-index: 9999;">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-blue text-xs" @click="$emit('close')">
                        <i class="material-icons md-16">clear</i>
                        <span class="icon-desc" style="font-size: 0.5rem">( ESC )</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-header  d-gray">
            <div class="row m-t-sm">
                <div class="col-md-6">
                    <h6 class="title-color m-b-xs">{{ $t('modalLinkedOrderInfo.order') }}: <span class="coral">#{{ order.orderId }}</span></h6>
                    <span class="text-muted text-xs"> ( Index: {{ order.orderReference }} )</span>
                </div>
                <div class="col-md-6">
                    <span class="pull-right m-r-lg text-muted text-xs"> {{ order.createdAt | moment("DD MMMM YYYY") }}</span><br/>
                    <span class="pull-right label c-blue text-white m-r-lg none-radius"> {{ order.status }}</span>
                </div>
            </div>
        </div>
        <div data-v-a2bb5b14="" class="box-divider m-a-0"></div>
        <div class="box-body">
            <div class="row m-b m-x-sm">
                <div class="col-md-6 custom-color">
                    <h6 class="_500 primary-color m-b-sm text-small">{{ $t('modalLinkedOrderInfo.customer') }}</h6>
                    <p class="m-b-xs">
                       {{ order.name }}
                    </p>
                    <p class="m-b-xs">
                      {{ order.email }}
                    </p>
                </div>
                <div class="col-md-6 custom-color ">
                    <h6 class="_500 primary-color m-b-sm text-small">{{ $t('modalLinkedOrderInfo.address') }}</h6>
                    <p class="m-b-xs">{{order.address.name}}</p>
                    <p class="m-b-xs">{{order.address.address}}</p>
                    <p class="m-b-xs">{{order.address.postCode}} {{order.address.city}}</p>
                </div>
            </div>
            <div class="row m-b m-x-sm">
                <div class="col-md-12 custom-color custom-box-border l-gray">
                    <div class="m-x-sm m-y-sm">
                        <h6 class="_500 primary-color m-b-sm text-small">{{ $t('modalLinkedOrderInfo.payment') }}</h6>
                        <p class="m-b-xs"> <span class="text-muted _500 text-xs">{{ $t('modalLinkedOrderInfo.paymentMethod') }}:</span> {{order.payment}}</p>
                        <p class="m-b-xs"><span class="text-muted _500 text-xs">{{ $t('modalLinkedOrderInfo.total') }}:</span> {{order.total}} zł</p>
                        <p class="m-b-xs"><span class="text-muted _500 text-xs">{{ $t('modalLinkedOrderInfo.totalPaid') }}:</span> {{order.totalPaid}} zł</p>
                    </div>
                </div>
            </div>

            <div class="row" v-if="order.cart && order.cart.length > 0">
                <div class="col-md-12" >
                    <table class="table cart-table">
                        <thead class="primary-color">
                            <tr>
                                <th>{{ $t('modalLinkedOrderInfo.product') }}</th>
                                <th>{{ $t('modalLinkedOrderInfo.price') }} <span class="text-muted text-xs _400"> (brutto)</span></th>
                                <th>{{ $t('modalLinkedOrderInfo.qty') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-gray" v-for="p in order.cart">
                                <td>{{p.name}}</td>
                                <td>{{p.priceTaxIncl}} zł</td>
                                <td>{{p.qty}}</td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </div>

        <div class="box-footer" v-if="isProcessing">    
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>

        <div class="box-footer" v-else>
            <!-- <a class="btn btn-sm white none-radius pull-right" @click="create">Add one</a>
            <a class="btn btn-sm white none-radius" @click="$emit('close')">Anuluj</a> -->
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'LinkedOrderInfoModal',
    props: {
        order: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            address: '',
            host: window.location.host,
            isProcessing: false,
        }
    },
    created: function() {
        // @todo pobieranie szczegolowych informacji o zamowieniu
    },
    destroyed: function() {
    },
    computed: {
    },
    methods: {
        create: async function () {
            
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-small {
    font-size: 0.85rem;
}

table.cart-table {
    tbody tr td {
        max-width: 300px;
        white-space: normal;
    }
}

.table-container {
    height: 8rem;
    table {
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
    }
    table thead {
        /* head takes the height it requires, 
        and it's not scaled when table is resized */
        flex: 0 0 auto;
        width: calc(100% - 0.9em);
    }
    table tbody {
        /* body takes all the remaining available space */
        flex: 1 1 auto;
        display: block;
        overflow-y: scroll;
    }
    table tbody tr {
        width: 100%;
    }
    table thead, table tbody tr {
        display: table;
        table-layout: fixed;
    }
}
</style>
