import io from 'socket.io-client';
import UserManager from './UserManager.js';
import EventEmitter from 'events';
import config from './config.js';

let ding = require("./assets/sounds/sound3.wav")

function ChatManager() {
  this.socket = io(config.chat.host, config.chat.options);
  this.events = new EventEmitter();
  this.rooms = [];
  this.currentMsgs = [];
  this.counterUnreadMessages = {};
  this.onlineUsers = [];
  this.currentConverstation = null;
  this.isRoom = false;
  this.isLogged = false;
  this.token = null;
  this.perUserDisconnectTime = {};

  this.socket.on('new_message_in_room', (data)=> {

    // console.log("Wpadła nowa wiadomość test do: " + data.room._id);

    if (data.room.isDirectConversation && this.isRoom == false) {

      // console.log("isDirectConversation true i Wiadomość miedzy pracownikami: " + data.room.users);

      let idx = data.room.users.indexOf(UserManager.user.userId+"");
      if (idx == -1) return; 

      let mysiaId = idx == 0 ? data.room.users[1] : data.room.users[0];

      if (mysiaId+"" == this.currentConverstation+"") {
        this.currentMsgs.push(data.message);


        this.events.emit('messages_changed', data.message);
      }
    }

    if (!data.room.isDirectConversation && data.room.isPublic == true 
      && data.room._id+"" == this.currentConverstation+"" 
      && this.isRoom == true) {

      // console.log("jest to pokoj publiczny: " + data.room.name);

      this.currentMsgs.push(data.message);
      this.events.emit('messages_changed', data.message);
    }

    if (!data.room.isDirectConversation && !data.room.isPublic 
      && data.room._id+"" ==  this.currentConverstation+"" 
      && this.isRoom == true) {

      // console.log("jest to pokoj prywatny: " + data.room.name);

      let userIds = data.room.users;
      let idx = userIds.indexOf(UserManager.user.userId+""); 
      if (idx != -1) {
        this.currentMsgs.push(data.message);
        this.events.emit('messages_changed', data.message);
      }
    }

    if (this.currentConverstation+"" == data.room._id+"" && !data.room.isDirectConversation) return ;
    if (data.room.isDirectConversation && data.room.users.indexOf(this.currentConverstation+"") != -1) return;

    if (data.room.isDirectConversation) {

      // console.log("Nieprzeczytana wiadomosc w directConversations miedzy : " + data.room.users);

      let idx = data.room.users.indexOf(UserManager.user.userId+"");
      if (idx == -1) return; // tak na wszelki

      let mysiaId = idx == 0 ? data.room.users[1] : data.room.users[0];
      // console.log("Nieprzeczytana wiadomosc od : ", mysiaId);
      
      let audio = new Audio(ding); // buffers automatically when created
      var playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.then(function () {
        }).catch(function (error) {
            console.log('Failed to play....' + error);
        });
      }


      idx = this.counterUnreadMessages.directConversations.map(d => d.user+'').indexOf(mysiaId+'');
      if (idx == -1) this.counterUnreadMessages.directConversations.push({ user: mysiaId, count: 1 });
      else this.counterUnreadMessages.directConversations[idx].count += 1;

    } else {
      // console.log("Nieprzeczytana wiadomosc w roomie miedzy : " + data.room.name);

      let audio = new Audio(ding); // buffers automatically when created
      var playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.then(function () {
        }).catch(function (error) {
            console.log('Failed to play....' + error);
        });
      }

      let idx = this.counterUnreadMessages.rooms.map(d => d.room+'').indexOf(data.room._id+'');
      if (idx == -1) this.counterUnreadMessages.rooms.push({ room: data.room._id, count: 1 });
      else this.counterUnreadMessages.rooms[idx].count += 1;
    }

    this.events.emit('unread_messages_changed', this.counterUnreadMessages);
  });

  this.socket.on('room_created', (data) => {
    let isCreated = this.checkIsFitToCurrentUser(data);
    if (isCreated) {
      this.rooms.push(data.newRoom);
      this.events.emit('created_new_room', data.newRoom);
    }
  });

  this.socket.on('room_changed', (data) => {
    let isChanged = this.checkIsFitToCurrentUser(data);
    let idx = this.rooms.map(t => t._id+"").indexOf(data.newRoom._id+"");
    if (isChanged) {
      if (idx != -1) {
        this.rooms[idx] = data.newRoom;
      } else {
        this.rooms.push(data.newRoom);
      }
      this.events.emit('changed_new_room', data.newRoom);
    } else if(idx != -1) {
      this.rooms.splice(idx, 1);
    }
  });

  this.socket.on('user_connected', (data) => {
    if (!this.isLogged) return;
    if (data.user+"" == UserManager.user.userId+"") return;
    
    let idx = this.onlineUsers.map(t => t+"").indexOf(data.user+"");
    if (idx == -1) {
      this.onlineUsers.push(data.user);
      this.events.emit('online_users_changed', this.onlineUsers);

      // prosty mechanizm do wykrywania czy to na prawde bylo logowanie, czy tylko reconnect
      // wskutek np. probleow z laczem internetowym
      // dzieki temu np. powiadomienie o zalogowaniu nie wyskoczy czesciej niz 1 na 30sek
      if (this.perUserDisconnectTime[data.user+""]) {
        let reconnectInterval = Date.now() - this.perUserDisconnectTime[data.user+""];
        if (reconnectInterval > 30*1000) this.events.emit('some_user_connected', data.user);
      } else {
        this.events.emit('some_user_connected', data.user);
      }
    }
  });

  this.socket.on('user_disconnected', (data) => {
    this.perUserDisconnectTime[data.user+""] = Date.now();
    let idx = this.onlineUsers.map(t => t+"").indexOf(data.user+"");
    if (idx != -1) {
      this.onlineUsers.splice(idx, 1);
      this.events.emit('online_users_changed', this.onlineUsers);
    }
  });

  this.socket.on('reconnect', (attemptNumber) => {
    if (this.token) this.socket.emit('login', this.token);
  });

}

ChatManager.prototype.setCurrentConverstation = function(id, isRoom) {
  this.currentConverstation = id;
  this.isRoom = isRoom;
  if (isRoom) {
    let roomsConversations = this.counterUnreadMessages.rooms;
    roomsConversations.forEach((r) => {
      if (r.room+"" == id+"") {
        r.count = 0;
      }
    });
  } else {
    let directConversations = this.counterUnreadMessages.directConversations;
    directConversations.forEach((d) => {
      if (d.user+"" == id+"") {
        d.count = 0;
      }
    });
  }

  this.events.emit('unread_messages_changed', this.counterUnreadMessages);
}
  
ChatManager.prototype.setToken = function(token) {
  this.token = token;
  this.socket.emit('login', token, () => {
    
    this.isLogged = true;
    this.events.emit('user_logged');

    this.getRooms().then(rooms => {
      this.events.emit('rooms_loaded', this.rooms);
    });

    this.getUnreadMessagesCount().then((counterUnreadMessages) => {
        this.counterUnreadMessages = counterUnreadMessages;
        this.events.emit('unread_messages_changed', this.counterUnreadMessages);
    }).catch((reson) => {
        console.log(reson);
    }); 

    this.getOnlineUsers().then((onlineUsers) => {
      this.onlineUsers = onlineUsers;
      this.events.emit('online_users_changed', this.onlineUsers);
    }).catch((reson) => {
        console.log(reson);
    });  

  });

}

ChatManager.prototype.checkIsFitToCurrentUser = function(data) {
  if (data.newRoom.isPublic && data.newRoom.isRemoved == false) {
    return true;
  }
  if (!data.newRoom.isPublic && data.newRoom.isRemoved == false) {
    let userIds = data.newRoom.users;
    let idx = userIds.indexOf(UserManager.user.userId+""); 
    if (idx != -1) {
      return true;
    }
  }
  return false;
}

ChatManager.prototype.getRooms = function () {
  return new Promise((resolve, reject) => {

    if (this.rooms.length) {
      resolve(this.rooms);
      return;
    }

    this.socket.emit('getRooms', (rooms) => {
      if (rooms.error) return reject(rooms.error);

      rooms.forEach((r) => {

        if (r.isPublic) {
          this.rooms.push(r);
          return;
        }

        let userIds = r.users;
        let idx = userIds.indexOf(UserManager.user.userId+""); 
        
        if (idx != -1) {
          this.rooms.push(r);
        }
      });

      resolve(this.rooms);
    })

  });
}

ChatManager.prototype.getMessagesInRoom = function (roomId) {

  return new Promise((resolve, reject) => {

    this.socket.emit('getMessagesInRoom', {
      roomId: roomId,
    }, (data) => {
      if (data.error) reject(data.error);
      else resolve(data);
    });

  });
}

ChatManager.prototype.getMessagesWithUser = function (userId) {

  return new Promise((resolve, reject) => {

    this.socket.emit('getMessagesWithUser', {
      userId: userId,
    }, (data) => {
      if (data.error) reject(data.error);
      else resolve(data);
    });

  });
}

ChatManager.prototype.sendMessageToUser = function (userId, content, attachments) {

  return new Promise((resolve, reject) => {

    this.socket.emit('sendDirectMessage', {
      userId: userId,
      content: content,
      attachments: attachments
    }, (data) => {
      if (data.error) reject(data.error);
      else resolve(data);
    });

  });
}

ChatManager.prototype.sendMessageToRoom = function (roomId, content, attachments) {

  return new Promise((resolve, reject) => {
    this.socket.emit('sendMessage', {
      targetRoom: roomId,
      content: content,
      attachments: attachments
    }, (data) => {
      if (data.error) reject(data.error);
      else resolve(data);
    });

  });
}

ChatManager.prototype.addNewRoom = function (room) {

  return new Promise((resolve, reject) => {
    this.socket.emit('addRoom', {
      name: room.name,
      users: room.users,
      isPublic: room.isPublic 
    }, function (data) {
      if (data.error) reject(data.error);
      else resolve(data);
    });
  });
}

ChatManager.prototype.editRoom = function (room) {

  return new Promise((resolve, reject) => {
    this.socket.emit('changeRoom', {
      _id: room._id,
      name: room.name,
      users: room.users,
      isPublic: room.isPublic,
      owner: UserManager.user.userId+""  
    }, function (data) {
      if (data.error) reject(data.error);
      else resolve(data);
    });
  });
}

ChatManager.prototype.removeRoom = function (room) {

  return new Promise((resolve, reject) => {
    this.socket.emit('changeRoom', {
      _id: room._id,
      isRemoved: true, 
    }, function (data) {
      if (data.error) reject(data.error);
      else resolve(data);
    });
  });
}

ChatManager.prototype.getUnreadMessagesCount = function () {

  return new Promise((resolve, reject) => {
    this.socket.emit('getUnreadMessagesCount', function (data) {
      if (data.error) reject(data.error);
      else resolve(data);
    });
  });
}

ChatManager.prototype.getOnlineUsers = function () {

  return new Promise((resolve, reject) => {
    this.socket.emit('getOnlineUsers', function (data) {
      if (data.error) reject(data.error);
      else resolve(data);
    });
  });
}

var instance = new ChatManager();

export default instance;