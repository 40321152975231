<template>
    <div class="box">
        <div class="box-header">
            <h3>Wykryto podobne istniejące zgłoszenie</h3>
            <small>There are more tickets from this client in system. Do you want to transfer current ticket to another? After transfer, current ticket will be automatically removed</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <div class="linked-window p-x-xs" v-if="ticket.links.length > 0">
                <div class="box m-t" v-for="linkedTicket in ticket.links" :key="linkedTicket._id">
                    <div class="p-y-sm p-x">
                        <span class="text-xs">{{ $t('ticket.info.assignee') }}: {{EmployeeManager.getName(linkedTicket.assignedUserId)}}</span>
                        <span class="text-xs pull-right">
                        <i class="material-icons text-yellow" v-if="linkedTicket.status == 'new'">brightness_1</i> 
                        <i class="material-icons text-green" v-if="linkedTicket.status == 'open'">brightness_1</i> 
                        <i class="material-icons text-blue" v-if="linkedTicket.status == 'pending'">brightness_1</i> 
                        <i class="material-icons text-red" v-if="linkedTicket.status == 'closed'">brightness_1</i>  
                        {{$t('ticket.filters.'+linkedTicket.status)}}
                        </span>
                        <div class="text-sm m-y-xs">
                            <a href="#" v-on:click.prevent="goToLinkedTicket(linkedTicket.id)">
                                <span class="text-u-c">#{{linkedTicket.shortId}}</span> - <span v-if="linkedTicket.subject">{{linkedTicket.subject| truncate(50)}}</span>
                            </a> 
                        </div>
                        <div class="m-b-xs">
                            <span class="text-xs text-muted">{{linkedTicket.messagesCount}} wiadomości / </span>
                            <span class="text-xs text-muted">{{linkedTicket.createdAt | moment("DD MMM YYYY | hh:mm")}}</span>
                            <button class="pull-right btn btn-xs white none-radius" type="button"  v-on:click="transferMessage(linkedTicket)" :disabled="isTransfering == 1">
                                <span class="text-muted _400">Transfer</span>
                            </button>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isTransfering">    
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            <!-- <a class="btn btn-sm white none-radius pull-right" @click="create">Add one</a>
            <a class="btn btn-sm white none-radius" @click="$emit('close')">Anuluj</a> -->
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';

export default {
    name: 'ForwardAskModal',
    props: {
        ticket: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            EmployeeManager,
            isTransfering: false,
        }
    },
    created: function() {
    },
    destroyed: function() {
    },
    computed: {
    },
    methods: {
        transferMessage: async function(ticketToTransfer) {
            this.isTransfering = true;
            try {
                let messages = await Api.getTicketMessages(this.ticket._id);
                if (!messages[0]) {
                    this.showNotify('notification-error', this.$i18n.t('notifications.error'));
                    return;
                }

                let msg = await Api.forwardMessageToOtherTicket(messages[0].id, ticketToTransfer.id);
                
                if (this.ticket.assignedUserId+"" == ticketToTransfer.assignedUserId+"" && this.ticket.assignedUserId != null) {
                    this.goToLinkedTicket(ticketToTransfer.id);
                } else {
                    this.$emit('close');
                    this.$router.push({ path: `/list`});    
                }
            } catch (error) {
                this.showNotify('notification-error', this.$i18n.t('notifications.removeError'));
            }
            this.isTransfering = false;
        },

        goToLinkedTicket: function(ticketId) {
            this.$emit('close');
            this.$router.push({ path: `/ticket/${ticketId}`});
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
