<template>
    <div class="form-group row m-b">
        <label class="col-md-4 form-control-label label-sm m-t-xs">{{ label }}</label>
        <div class="col-md-8">
            <v-select 
            class="form-control"
            @input="onNodeChanged"
            :options="nodeOptions"
            label="friendlyName"
            :reduce="node => node._id"
            v-model="selectedId">
                <template slot="list-footer">
                    <li class="vs__dropdown-option" style="margin-top:5px; border-top: 1px dashed #ccc;">
                        <i class="material-icons md-18">add</i> Dodaj nowy węzeł<br/>
                        <div style="padding-left: 5px; margin-left: 8px; border-left: 1px dashed #ccc;">
                            <a href="#" class="text-link" @click.prevent="$emit('new-node', 'playback')"><i class="material-icons md-18">volume_up</i> Komunikat głosowy</a><br/>
                            <a href="#" @click.prevent="$emit('new-node', 'date-condition')"><i class="material-icons md-18">call_split</i> Warunek</a><br/>
                            <a href="#" @click.prevent="$emit('new-node', 'receivers')"><i class="material-icons md-18">contacts</i> Odbiorcy</a><br/>
                        </div>
                    </li>
                </template>

                <template slot="option" slot-scope="option">
                    <i class="material-icons md-18">{{ option.icon }}</i> {{ option.friendlyName }}
                </template>

                <template slot="selected-option" slot-scope="option">
                    <i class="material-icons md-18">{{ option.icon }}</i> {{ option.friendlyName }}
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NodeSelector',
    props: {
        /**
         * Nazwa do wyświetlania obok wybieracza
         * @type {String}
         */
        label: {
            type: String,
            default: 'Wybierz wartość'
        },

        /**
         * Węzły jakie mają być dostępne do wyboru
         * @type {String}
         */
        availableNodes: {
            type: Array,
            default: []
        },

        value: String,
    },
    components: {
    },
    data: function () {
        return {
            selectedId: null
        }
    },

    watch: {
        /**
         * Make sure to update internal
         * value if prop changes outside
         */
        value(val) {
            this.selectedId = val;
        },
    },

    computed: {
        nodeOptions: function() {
            return this.availableNodes.map(n => {
                let icon = "call_end"

                if (n.type == "phone") icon = "dialer_sip";
                else if (n.type == "playback") icon = "volume_up";
                else if (n.type == "date-condition") icon = "call_split";
                else if (n.type == "receivers") icon = "contacts";

                return Object.assign({icon}, n);
            })
        }
    },
    
    created: function() {
       this.selectedId = this.value;
    },
   
    methods: {
        onNodeChanged() {
            this.$emit("input", this.selectedId);
        }
    }
}
</script>
