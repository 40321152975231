<template>
    <div >
        <QuickTicketChatBox :ticketid="tId" v-for="tId in openChats" :key="tId" @close="closeBox(tId)" />
    </div>
</template>

<script>
import EventBus from '../event-bus.js';
import UserManager from '../UserManager.js';
import SystemSocket from '../SystemSocket.js';
import QuickTicketChatBox from './QuickTicketChatBox.vue';


export default {
    name: 'QuickTicketChat',

    components: {
        QuickTicketChatBox
    },

    data: function () {
        return {
            openChats: [],
        }
    },

    created: function() {
        EventBus.$on('openNewQuickChat', this.openNewQuickChat);
        // UserManager.events.on('notification_changed', this.onUserManagerChangeEvent);
        SystemSocket.socket.on('object', this.onSocketUpdate);
    },

    destroyed: function() {
        EventBus.$off('openNewQuickChat', this.openNewQuickChat);
        // UserManager.events.removeListener('notification_changed', this.onUserManagerChangeEvent);
        SystemSocket.socket.off('object', this.onSocketUpdate);
    },

    methods: {
        openNewQuickChat: function(ticketId) {
            // if (this.openChats.indexOf(ticketId) == -1) this.openChats.push(ticketId);
        },

        onSocketUpdate: function(event) {
            // to jest chyba jednak bledne zalozenie, bo nie wykrywa czy dana wiadomosc nalezy do usera
            // if (event.object == 'message' && event.verb == 'create') {
            //     if (event.data.userId) return; // jesli jest userId to znaczy, ze to wiadomosc od prawconika
            //     if (event.data.source.type == 'fb' || event.data.source.type == 'ig') {
            //         this.openNewQuickChat(event.data.ticketId);
            //     }
            // }

            if (event.object == 'notification' && event.verb == 'create') {
                let n = event.data;
                if (!n.data || n.data.code != 'new_ticket_reply') return;
                if (typeof n.data.msgSource != 'string') return;
                if (['fb', 'ig', 'chat'].indexOf(n.data.msgSource) == -1) return; // auto-otwieranie tylko na wiadomosciach "czatowych"
                if (n.data.objectId) this.openNewQuickChat(n.data.objectId);
            }
        },

        closeBox: function(ticketId) {
            let idx = this.openChats.indexOf(ticketId);
            if (idx != -1) this.openChats.splice(idx, 1);
        }
    }
}
</script>

<style>

</style>