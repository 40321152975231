<template>
  <div>
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.tabMessages.title')}}</h6>
        <div class="pull-right m-l-sm minus-margin">
          <select2 :options="timeUnit" v-model="selectedTimeUnit" class="form-control primary-color" v-on:input="changeDataColectionByTimeUnit">
          </select2>
        </div>
      </div>
      <div class="row">
        <div class="small col-md-10">
          <bar-chart :chart-data="datacollection" :options="options"></bar-chart>
        </div>
        <div class="col-md-2">
          <div class="form-group m-t-md raports">
            <label>{{$t('raports.selectTitle')}}:</label>
            <select2 v-if="isReady" :options="employees" v-model="selectedEmps" class="form-control" v-on:input="changeDataColectionByEmps" multiple="multiple" style="width: 95%">
            </select2>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import BarChart from '../BarChart.js';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';

export default {
  name: 'SentMessages',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    BarChart
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales : {
          xAxes : [{
             barPercentage: 0.5,
             maxBarThickness: 30,
          }],
           yAxes : [{
            ticks : {
              beginAtZero : true,
            },
          }]
        },
      },
      datacollection: null,
      timeUnit: [
        { id:'week', text: this.$i18n.t('raports.timeUnit.week') },
        { id:'month', text: this.$i18n.t('raports.timeUnit.month') },
        { id:'3months', text: this.$i18n.t('raports.timeUnit.3months') },
        { id:'year', text: this.$i18n.t('raports.timeUnit.year') },
      ],
      selectedTimeUnit: 'week',
      sentMessages: [],
      datalabel: [],
      employees: [],
      isReady: false,
      selectedEmps: [],
      datasets: [],
    }
  },
  created: function() {
    this.getEmployees()
      .then(r => this.getDataColection())
      .then(r => this.assignDataColection());
  },
  destroyed: function() {
  },
  methods: {
    getDataColection: function() {
      return Api.getRaportSendMessages(this.selectedTimeUnit).then((sentMessages) => {
        this.sentMessages = sentMessages;
        this.sentMessages.forEach((n) => 
        { 
          this.datalabel.push(n.day.split('T')[0]);
        });
      });
    },
    assignDataColection: function() {
      if (this.selectedEmps && this.selectedEmps.length) {
        this.changeDataColectionByEmps(); 
        return;
      }
      this.datasets = [];
      let dataSet = { label: this.$i18n.t('raports.tabMessages.total'), backgroundColor: '#5bc0de', data: [] };
      this.sentMessages.forEach((n) => 
      { 
        dataSet.data.push(n.total);
      });

      this.datalabel.sort(function(a, b) {
        var dateA = new Date(a), dateB = new Date(b);
        return dateA - dateB;
      });
      this.datasets.push(dataSet);

      this.fillData();
    },
    fillData () {
      this.datacollection = {
        labels: this.datalabel,
        datasets: this.datasets,
      }
    },
    changeDataColectionByTimeUnit: function() {
      this.datalabel = [];
      this.getDataColection()
      .then(r => this.assignDataColection());
    },
    changeDataColectionByEmps: function() {
      if (!this.selectedEmps || !this.selectedEmps.length) {
        this.assignDataColection();
        return;
      }
      this.datasets = [];
      for (var i = this.selectedEmps.length - 1; i >= 0; i--) {
        let eId = this.selectedEmps[i];
        let dataSet = { label: 'Unknow emp', backgroundColor: '#567562', data: [] };
        let idx = this.employees.map(e => e.id+"").indexOf(eId+"");
        if (idx != -1) dataSet.label = this.employees[idx].text;

        dataSet.backgroundColor = this.getRandomColor();
        this.sentMessages.forEach((m) => {
          dataSet.data.push(m.perEmployee[eId] ? m.perEmployee[eId] : 0);
        });
        this.datasets.push(dataSet);
      }
      this.fillData();
    },
    getEmployees: function() {
      return EmployeeManager.getEmployees().then((employees) => {
        employees.forEach((e) => {
          this.employees.push({id: e.id, text:e.name});
        });
        this.isReady = true;
      });
    },
    getRandomColor: function() {
      let hex = Math.floor(Math.random() * 0xFFFFFF);
      return "#" + ("000000" + hex.toString(16)).substr(-6);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .small {
    max-width: 95%;
    margin: 5px auto;
  }
  @media (max-width: 767px) {
    .raports {
      margin-left: 10px;
    }
  }
</style>
