<template>
<div class="padding">
  <!-- loader -->
  <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);" v-if="isLoaded">
    <div style="position: relative;">
      <rotate-square5></rotate-square5>
    </div>
  </div>
  <ul class="timeline" v-if="activity.length > 0">

    <li class="tl-item" v-for="a in activity">

      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.status_changed'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.authorName}}</b> {{ $t('ticket.ticketTabs.timeline.statusChange') }} <b>{{a.oldStatus}}</b> {{ $t('ticket.ticketTabs.timeline.to') }} <b>{{a.newStatus}}</b></div>
        </div>
      </div>

      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.priority_changed'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.authorName}}</b> {{ $t('ticket.ticketTabs.timeline.priorityChange') }} <b>{{a.oldPriority}}</b> {{ $t('ticket.ticketTabs.timeline.to') }} <b>{{a.newPriority}}</b> </div>
        </div>
      </div>

      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.assignment_changed'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.authorName}}</b> {{ $t('ticket.ticketTabs.timeline.assignedTicket') }} <b v-if="a.oldUserName">{{a.oldUserName}}</b><b v-else> {{ $t('ticket.ticketTabs.timeline.unassigned') }} </b> to <b>{{a.newUserName}}</b> </div>
        </div>
      </div>

      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.new_reply'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.msgAuthor}}</b> {{ $t('ticket.ticketTabs.timeline.sentNewMsg') }} </div>
        </div>
      </div>

      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.new_comment'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.commentAuthorName}}</b> {{ $t('ticket.ticketTabs.timeline.addedComment') }}</div>
        </div>
      </div>
      <div class="tl-wrap b-primary" v-if="a.logKey == 'tickets.new_comment_with_mentioned'">
        <span class="tl-date text-muted">{{a.created| moment('DD/MM/YY HH:mm')}}</span>
        <div class="tl-content box-color text-color  p-a-sm">
          <span class="arrow b-white left pull-top"></span>
          <div><b>{{a.commentAuthorName}}</b> {{ $t('ticket.ticketTabs.timeline.addedCommentConfirmation') }} 
              <b v-for="n in a.mentionedUsersNames">{{n}} </b> 
          </div>
        </div>
      </div>

    </li>
  </ul>
</div>
</template>


<script>
import Api from '../../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import ActivityTranslator from '../../../ActivityTranslator.js';

export default {
  name: 'TimelineTab',
  props: {
    ticket: Object
  },
  components: {
    RotateSquare5,
  },
  data(){
    return {
      activity: [],
      isLoaded: false,
    }
  },
  created: function() {
    this.isLoaded = true;
    Api.getTicketActivityTimeline(this.ticket.id).then((data) => {
      ActivityTranslator.translateLogs(data).then(translated => {
        this.activity = translated;
        this.isLoaded = false;
      }).catch(e => console.error(e))

    });
  },
  methods: {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .tl-wrap {
    margin-left: 8em;
  }
  .tl-date {
    width: 10.5em;
    margin-left: -13.5em;
  }
</style>
