<template>
  <div class="custom-modal modal fade in todisplay-active" data-backdrop="true" aria-hidden="true">
    <div class="right w-xl white b-l">
      <div class="row-col">
        <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="$emit('close-languageselector')"><i class="material-icons md-20"></i></a>
        <div class="p-a b-b p-x-md">
          <span class="_500 primary-color">Change language</span>
        </div>

        <div class="row-row">
          <div class="row-body">
            <div class="row-inner">
              <ul class="list-group m-b text-muted">
                <a class="list-group-item" v-on:click.prevent="changeLanguage('pl')">Polski <i v-if="selectedLang == 'pl'" class="material-icons md-20 coral">check</i></a>
                <a class="list-group-item" v-on:click.prevent="changeLanguage('en')">English <i v-if="selectedLang == 'en'" class="material-icons md-20 coral">check</i></a>
                <!-- <a class="list-group-item">Čeština <i v-if="selectedLang == 'cz'" class="material-icons md-20 coral">check</i></a>
                <a class="list-group-item">Deutsch <i v-if="selectedLang == 'de'" class="material-icons md-20 coral">check</i></a>
                <a class="list-group-item">Français <i v-if="selectedLang == 'fr'" class="material-icons md-20 coral">check</i></a>
                <a class="list-group-item">Nederlands <i v-if="selectedLang == 'nd'" class="material-icons md-20 coral">check</i></a> -->
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import UserManager from '../../UserManager.js';
import LanguageManager from '../../LanguageManager.js';
import Api from '../../Api.js';


export default {
  name: 'LanguageSelector',
  props: {
    userInfo: Object
  },
  components: {
    
  },
  data: function () {
    return{
      activityModal: false,
      langsModal: false,
      user: UserManager.user,
      selectedLang: LanguageManager.i18n.locale,
    }
  },
  created: function() {
  },
  methods: {
    changeLanguage: function(locale) {
      LanguageManager.loadLanguageAsync(locale).then(() => {
        this.selectedLang = locale;
        Api.putInStorage('user_selected_lang', locale).then((boolean) => {
          this.$emit('close-languageselector');
        }).catch((error) => {});
      });
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.todisplay-active {
  display: block;
  padding-top: 100px;
}
</style>
