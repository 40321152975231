<template>
  <div class="row">
    <MailComposeModal v-bind:availableAccounts="userMailboxes"></MailComposeModal>
    <div class="col-xl-2">
      <div class="box">
        <MailBoxes v-if="!isLoaded" v-bind:userMailboxes="userMailboxes" v-on:mailbox-click="onMailboxChange($event)"></MailBoxes>
        <Spinner v-if="isLoaded"></Spinner>
      </div>
    </div>
    <div class="col-xl-10 col-sm-12">
      <!-- <SortAndSearchMailsList></SortAndSearchMailsList> -->
      <div class="box none-m-b">
        <div class="box-header d-gray">
          <BtnGroup v-bind:userMailboxes="userMailboxes" v-bind:infoMessages="infoMessages"></BtnGroup>
        </div>
        <div class="row" v-if="isError">
          <div class="col-md-12 text-center text-muted  m-t m-b-md">
            {{ $t('mails.error.problemDownloading') }}
          </div>
        </div>
        <Spinner v-if="isMailsLoaded"></Spinner>

        <MailsList v-bind:infoMessages="infoMessages" v-if="infoMessages.slectedFolderMsgs && infoMessages.total && infoMessages.slectedFolderMsgs.length > 0"></MailsList>

        <div class="row text-center p-y" v-if="infoMessages.slectedFolderMsgs && infoMessages.slectedFolderMsgs.length == 0 && noMessages">
          {{ $t('mails.empty.noMessages') }}
        </div>

        <div v-if="userMailboxes.length == 0 && !isLoaded">
          <div class="row p-a-lg start-box">
          <div class="col-md-6 text-center">
            <img src="../../assets/images/envelope.png" class="">
          </div>
          <div class="col-md-6 p-a-lg">
            <h4 class="primary-color _300 p-a-lg" v-if="user != null && user.roles.indexOf('admin') != -1">{{ $t('mails.empty.textNoBoxes') }} {{ $t('mails.empty.tryTo') }} 
              <router-link class="coral" to="/settings/email-client">{{ $t('mails.empty.addOne') }}</router-link>   
              {{ $t('mails.empty.and') }} 
              <router-link class="coral" to="/settings/email-client">{{ $t('mails.empty.assignYourself') }} </router-link>.</h4> 
            <h4 class="primary-color _300 p-a-lg" v-else>{{ $t('mails.empty.anyBoxes') }}</h4>   
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import EventBus from '../../event-bus.js';
import $ from 'jquery'
import Spinner from '../Spinner.vue';
import EmployeeManager from '../../EmployeeManager.js';
import Api from '../../Api.js';
import MailBoxes from './MailBoxes.vue';
import SortAndSearchMailsList from './SortAndSearchMailsList.vue';
import BtnGroup from './BtnGroup.vue';
import MailsList from './MailsList.vue';
import MailComposeModal from './MailComposeModal.vue';
import UserManager from '../../UserManager.js'

export default {
  name: 'MailPage',
  props: {
    
  },
  components: {
    Spinner,
    MailBoxes,
    SortAndSearchMailsList,
    BtnGroup,
    MailComposeModal,
    MailsList
  },
  data: function () {
    return {
      isLoaded: false,
      isMailsLoaded: false,
      isError: false,
      userMailboxes: [],
      messages: [],
      infoMessages: {},
      noMessages: false,
      mailsOnPage: 50,
      offset: '',
      counterShowMore: 0,
      filters: {},
      user: UserManager.user,
    }
  },
  created: function() {

    this.getUserMailboxes();

    EventBus.$on('showSelectedCoutMsgOnPage', this.showSelectedCoutMsgOnPage);
    EventBus.$on('showMoreMessages', this.showMoreMessages);
    EventBus.$on('move-mails-to-selected-folder', this.moveSelectedMails);
    EventBus.$on('folderMailboxChange', this.getUserMailboxes);
    EventBus.$on('filterMailsChange', this.filterMailsChange);
    EventBus.$on('mark-messages-as-read', this.markMessagesAsRead);
    EventBus.$on('mark-messages-as-unread', this.markMessagesAsUnread);
    EventBus.$on('getMessages', this.getMessagesForBox);
    EventBus.$on('newMsgFound', this.updateMessages);
    EventBus.$on('actionEmptyBox', this.emptyBox);
  },
  destroyed: function() {
    EventBus.$off('showSelectedCoutMsgOnPage', this.showSelectedCoutMsgOnPage);
    EventBus.$off('showMoreMessages', this.showMoreMessages);
    EventBus.$off('move-mails-to-selected-folder', this.moveSelectedMails);
    EventBus.$off('folderMailboxChange', this.getUserMailboxes);
    EventBus.$off('filterMailsChange', this.filterMailsChange);
    EventBus.$off('mark-messages-as-read', this.markMessagesAsRead);
    EventBus.$off('mark-messages-as-unread', this.markMessagesAsUnread);
    EventBus.$off('getMessages', this.getMessagesForBox);
    EventBus.$off('newMsgFound', this.updateMessages);
    EventBus.$off('actionEmptyBox', this.emptyBox);
  },
  methods: {
    markMessagesAsRead:function() {
      let flags = {"\\Seen": true};
      this.markMessages(flags);
    },
    markMessagesAsUnread:function() {
      let flags = {"\\Seen": false};
      this.markMessages(flags);
    },
    getSelectedIdMsgs: function() {
      let boxName = '';
      let uids = [];
      let selectedMsgs = {}
      this.infoMessages.slectedFolderMsgs.forEach((m) => { 
        if(m && m.selected) {
            boxName = m.box;
            uids.push(m.uid) 
          }
      });
      if (uids.length == 0) {
        this.noMessages = true;
        this.showNotify('notification-error', this.$i18n.t('notifications.noSelected'));
        return false;
      } else {
        selectedMsgs.boxName = boxName;
        selectedMsgs.uids = uids;
        return selectedMsgs;
      }
    },
    markMessages: function(flags) {

      let selectedIdMsgs = this.getSelectedIdMsgs();
      if (!selectedIdMsgs) return;

      let idBox = this.infoMessages.idBox;
      let boxName = selectedIdMsgs.boxName;
      let uids = selectedIdMsgs.uids;

      Api.setFlags(idBox, boxName, uids, flags).then((messages) => {

        for (var i = uids.length - 1; i >= 0; i--) {
          let idx = this.infoMessages.slectedFolderMsgs.map(m => m.uid+"").indexOf(uids[i]+"");
          let idxNew = messages.map(a => a.uid+"").indexOf(uids[i]+"");
          if (idx != -1 && idxNew != -1) {
            this.infoMessages.slectedFolderMsgs[idx].flags = messages[idxNew].flags;

            this.infoMessages.slectedFolderMsgs.forEach((msg) => 
            { 
              if(msg.selected) msg.selected = false;
                
            });
          }
        }
        EventBus.$emit('setMsgUnseenCouter');
        this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
      });
    },
    moveSelectedMails: function(moveData) {
       
      let selectedIdMsgs = this.getSelectedIdMsgs();
      if (!selectedIdMsgs) return;

      let idBox = this.infoMessages.idBox;
      let boxNameFrom = selectedIdMsgs.boxName;
      let uids = selectedIdMsgs.uids;

      if (boxNameFrom == 'Trash') {
        Api.removeSelectedMails(idBox, boxNameFrom, uids).then((move) => {
          for (var i = uids.length - 1; i >= 0; i--) {
            let idx = this.infoMessages.slectedFolderMsgs.map(m => m.uid+"").indexOf(uids[i]+"");
            if (idx != -1) {
              this.infoMessages.slectedFolderMsgs.splice(idx, 1);
            }
          }
          this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess'));
        });
        return;
      }

      Api.moveSelectedMailsToFolder(idBox, boxNameFrom, moveData.moveTo, uids).then((move) => {
        for (var i = uids.length - 1; i >= 0; i--) {
          let idx = this.infoMessages.slectedFolderMsgs.map(m => m.uid+"").indexOf(uids[i]+"");
          if (idx != -1) {
            this.infoMessages.slectedFolderMsgs.splice(idx, 1);
          }
        }
        this.showNotify('notification-success', (moveData.moveTo == 'Trash' ? this.$i18n.t('notifications.removeSuccess') : this.$i18n.t('notifications.changeSuccess')));
      });
    },
    emptyBox: function() {
      Api.removeSelectedMails(this.infoMessages.idBox, this.infoMessages.boxName, "1:*").then((move) => {
        this.infoMessages.slectedFolderMsgs = [];
        this.noMessages = true;
        this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess'));
      });
    },
    filterMailsChange: function(filters) {
      this.isMailsLoaded = true;
      this.infoMessages.slectedFolderMsgs = [];
      this.filters = filters;
      if (filters.search) {
        Api.searchMailsInInbox(this.infoMessages.idBox, this.infoMessages.boxName, filters.search).then((msgs) => {
          this.isMailsLoaded = false;
          this.infoMessages.slectedFolderMsgs = msgs;
        });
      } else if (filters.extendedSearch) {
        Api.advancedSearchMailsInInbox(this.infoMessages.idBox, this.infoMessages.boxName, filters.extendedSearch).then((msgs) => {
          this.isMailsLoaded = false;
          this.infoMessages.slectedFolderMsgs = msgs;
          EventBus.$emit('newSearchResultLoaded');
        });
      } else {
        Api.getUserMailsForSelectedInbox(this.infoMessages.idBox, this.infoMessages.boxName).then((messages) => {
          this.infoMessages.slectedFolderMsgs = messages.data;
          this.infoMessages.total = messages.total;
          this.isMailsLoaded = false;
        }).catch((error) => {
          this.infoMessages.slectedFolderMsgs = [];
          this.isMailsLoaded = false;
        });
      }
    },
    showSelectedCoutMsgOnPage: function(count) {
      this.mailsOnPage = count.mailsOnPage;
      this.getMessagesForBox();
    },

    onMailboxChange: function(info) {
      this.infoMessages = {
        slectedFolderMsgs: [],
        idBox: info.id, 
        boxName: info.name, 
      }
      // this.infoMessages.slectedFolderMsgs = [];
      // this.infoMessages.idBox = info.id;
      // this.infoMessages.boxName = info.name;
      this.getMessagesForBox();
    },
    updateMessages: function(a) {
      this.isError = false;
      if (a.changedMailboxId != this.infoMessages.idBox && a.changedFolderName != this.infoMessages.boxName ) return;
      if (this.filters.search || this.filters.extendedSearch) return;

      Api.getUserMailsForSelectedInbox(a.changedMailboxId, a.changedFolderName, this.mailsOnPage).then((messages) => {
        this.infoMessages.slectedFolderMsgs = messages.data;
        this.infoMessages.total = messages.total;
      }).catch((error) => {
        this.infoMessages.slectedFolderMsgs = [];
        this.isError = true;
        this.noMessages = false;
      });
    },
    getMessagesForBox: function() {
      this.isError = false;
      this.counterShowMore = 0;
      let idBox = this.infoMessages.idBox;
      let boxName = this.infoMessages.boxName;

      Api.getUserMailsForSelectedInbox(idBox, boxName, this.mailsOnPage).then((messages) => {
        this.infoMessages.slectedFolderMsgs = messages.data;
        this.infoMessages.total = messages.total;
      }).catch((error) => {
        this.infoMessages.slectedFolderMsgs = [];
        this.isError = true;
      });
    },
    showMoreMessages: function(c) {
      this.counterShowMore += 1;
      let idBox = this.infoMessages.idBox;
      let boxName = this.infoMessages.boxName;
      let offset = this.counterShowMore * this.mailsOnPage;

      Api.getUserMailsForSelectedInbox(idBox, boxName, this.mailsOnPage, offset).then((messages) => {
        messages.data.forEach(m => this.infoMessages.slectedFolderMsgs.push(m));
        this.infoMessages.total = messages.total;
      }).catch((error) => {
        this.infoMessages.slectedFolderMsgs = [];
        this.isError = true;
      });

    },
    getUserMailboxes: function() {
      this.isLoaded = true; 
      Api.getUserMailboxes().then((userMailboxes) => {
        this.userMailboxes = userMailboxes;
        this.isLoaded = false;
        setTimeout(function() {
          if (!$('li.inbox').length) return;
          
          $('li.inbox')[0].click();
        }, 500);
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner[data-v-fa81853e]::after{
  background: #0cc2aa;
}

</style>
