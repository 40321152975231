<template>
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <a class="m-t-xs nav-link btn btn-xs white none-radius pull-left m-b" v-on:click="deleteSelectedCannedResponses">
        <span class="primary-color _400"> <i class="material-icons md-18"></i> {{$t('header.profilSettings.profile.cannedResponses.deleteSelected')}}</span>
      </a>
      <a class="m-t-xs nav-link btn btn-xs white none-radius pull-right m-b" v-on:click="showModal">
        <span class="primary-color _400"> <i class="material-icons md-18"></i> {{$t('header.profilSettings.profile.cannedResponses.addResponse')}}</span>
      </a>

      <table class="table custom-table" >
        <thead class="d-gray text-muted">
          <tr>
            <th style="width: 5%;"></th>   
            <th style="width: 20%;">{{$t('header.profilSettings.profile.cannedResponses.title')}}</th>   
            <th class="hidden-sm-down">{{$t('header.profilSettings.profile.cannedResponses.message')}}</th>   
            <th style="width: 15%;" class="hidden-md-down"></th>   
            <th style="width: 5%;"></th>   
          </tr>
        </thead>
        <tbody v-if="cannedResponses.length > 0">

          <tr class="custom-gray" v-for="c in evenCannedResponses" :key="c._id">
            <td class="checkbox">
              <label class="ui-check text-xs ">
                <input v-bind:value="c._id" type="checkbox" v-model="selectedCannedresposnses">
                <i class="dark-white"></i>
              </label>
            </td>
            <td>{{c.title}}</td>
            <td class="res-content hidden-sm-down">
            {{c.content | truncate(70)}}
            <div class="seen-res">
              <nl2br tag="p" :text="c.content" />
            </div>
            </td>
            <td class="text-muted hidden-md-down">
              <i class="fa fa-calendar"></i> {{ c.createdAt | moment("DD MMM YYYY") }}
            </td>
            <td class="text-muted">
              <a :title="$t('btnNames.edit')" v-on:click="showChangeCannedResponse(c)">
                <i class="material-icons md-18">edit</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>


    <div class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-body text-center p-lg">
            <div class="form-group row">
              <div class="col-sm-12">
                <input class="form-control custom-input" type="text" v-model="title" :placeholder="$t('header.profilSettings.profile.cannedResponses.title')">
              </div>
            </div>
            <div class="form-group row">
              
              <div class="col-sm-12">
                <!-- loader -->
                <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);" v-if="inProgress">
                  <div style="position: relative;">
                    <rotate-square5></rotate-square5>
                  </div>
                </div>
                <div class="position: relative;">
                  <textarea ref="fileform" class="form-control" rows="5" v-model="content" >
                  </textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-sm white none-radius pull-left" v-on:click="closeModal" v-bind:class="{ disabled: inProgress }">
              <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </button>
            <button v-if="!isChange" class="btn btn-sm white none-radius" 
            v-on:click="addCannedResponse" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{$t('btnNames.save')}}</span>
            </button>
            <button v-if="isChange" class="btn btn-sm white none-radius" 
            v-on:click="changeCannedResponse" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{$t('btnNames.change')}}</span>
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import UserManager from '../../UserManager.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Nl2br from 'vue-nl2br';

export default {
  name: 'CannedResponses',
  props: {
    
  },
  components: {
    RotateSquare5,
    Nl2br
  },
  data: function () {
    return {
      cannedResponses:[],
      selectedCannedresposnses: [],
      isActive: false,
      inProgress: false,
      isChange: false,
      changeObjId: null,
      title: '',
      content: '',
    }
  },
  created: function() {
    this.cannedResponses.length = 0;
    UserManager.cannedResposnes.forEach(c => this.cannedResponses.push(c));
    UserManager.events.on('canned_resposnes_changed', this.onUserManagerChangeEvent);
  },
  destroyed: function() {
    UserManager.events.removeListener('canned_resposnes_changed', this.onUserManagerChangeEvent);
  },
  computed: {
    evenCannedResponses: function () {
      return this.cannedResponses.sort(function(a, b) {
        var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    }
  },
  methods: {
    onUserManagerChangeEvent: function(cannedRes) {
      this.cannedResponses.length = 0;
      cannedRes.forEach(c => this.cannedResponses.push(c));
    },
    addCannedResponse: function() {
      if (this.title.length == 0 || this.content.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty')); return;
      }
      this.inProgress = true;
      UserManager.addCannedResponse(this.title, this.content).then((data) => {
        this.title = '';
        this.content = '';
        this.isActive = false;
        this.inProgress = false;
        this.showNotify('notification-success',this.$i18n.t('notifications.addSuccess'));
      });
    },
    deleteSelectedCannedResponses: function() {
      if (this.selectedCannedresposnses.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.noSelected')); return;
      }
      for (var i = this.selectedCannedresposnses.length - 1; i >= 0; i--) {
        UserManager.deleteCannedResponse(this.selectedCannedresposnses[i]).then((data) => {
          this.showNotify('notification-success',this.$i18n.t('notifications.removeSuccess'));
        });
      }

    },
    showChangeCannedResponse: function(c) {
      this.title = c.title;
      this.content = c.content;
      this.isActive = true;
      this.isChange = true;
      this.changeObjId = c._id;
    },
    changeCannedResponse: function() {
      if (this.title.length == 0 || this.content.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty')); return;
      }
      this.inProgress = true;
      UserManager.changeCannedResponse(this.title, this.content, this.changeObjId).then((data) => {
        this.title = '';
        this.content = '';
        this.isActive = false;
        this.inProgress = false;
        this.showNotify('notification-success',this.$i18n.t('notifications.changeSuccess'));
      });
    },
    showModal: function() {
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isChange = false;
      this.changeObjId = null;
      this.title = '';
      this.content = '';
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.res-content {
  position: relative;
  cursor: pointer;
  .seen-res {
    position: absolute;
    padding: 10px;
    background-color: #E2E8EE;
    z-index: 100;
    // border: 2px solid #B7C6D4;
    display: none;
  }
}
.res-content:hover {
  .seen-res {
    display: block;
  } 
}
</style>
