<template>
  <div class="box">
    <div class="box-header">
      <h6 class="title-color inline">{{ $t('ticket.files.files') }}</h6>
      <!-- <a href="" class="pull-right text-info text-sm">add</a> -->
    </div>
    <div class="m-a-0 custom-divider"></div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12" v-if="attachments.length > 0">

          <div class="pdf-box m-b-sm" v-for="a in attachments">

            <div class="p-y-sm p-x">
              <div class="pull-left m-r">
                <i class="material-icons text-primary m-y-sm text-2x ">photo_library</i>
              </div>
              <div class="clear">
                <div class="text-info"><a v-bind:href="a.url" target="_blank">{{a.name | truncate(25) }}</a></div>
                <span class="text-muted text-sm"><span class="upper-text">{{a.format}}</span>, {{a.formatSize}} ({{a.author}}, {{ a.createdAt | moment("DD MMM YYYY") }})</span>
              </div>
            </div>
            <!-- <div class="custom-border-t">
              <div class="btn-group primary-color">
                <a class="nav-link btn btn-xs white none-radius none-border" title="download" v-bind:href="a.url" target="_blank">
                  <i class="material-icons md-18"></i>
                </a>
                <a class="nav-link btn btn-xs white none-radius none-border" title="forward">
                  <i class="material-icons md-18"></i>
                </a>
                <a class="nav-link btn btn-xs white none-radius none-border" title="add to message">
                  <i class="material-icons md-18"></i>
                </a>
                <a class="nav-link btn btn-xs white none-radius none-border" title="delete">
                  <i class="material-icons md-18"></i>
                </a>
              </div>
            </div> -->

          </div>
        </div>
        <div class="col-md-12" v-else><span class="text-sm text-muted">{{ $t('ticket.files.noFiles') }}</span></div>
      </div>
    </div>
    <!-- <div class="box-footer d-gray">
      <a href="#" class="text-sm text-muted">download all</a>
      <a href="#" class="text-sm text-muted pull-right">go to files</a>
    </div> -->
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import EventBus from '../../event-bus.js';

export default {
  name: 'TicketFiles',
  props: {
    ticket: Object
  },
  components: {

  },
  data(){
    return {
      messages:[],
      attachments:[], 
    }
  },
  created: function() {
    // Api.getTicketMessages(this.ticket.id).then((messages) => {
    //   this.messages = messages;
    //   this.checkAttachments();
    // });

    // EventBus.$on('addFileToFilesContent', this.getAttachments);
    EventBus.$on('messagesChange', this.messagesChange);
  },
  destroyed: function() {
    // EventBus.$off('addFileToFilesContent', this.getAttachments);
    EventBus.$off('messagesChange', this.messagesChange);
  },
  methods: {
    checkAttachments: function () {
      this.messages.forEach((m) => {
        this.getAttachments(m);
      });
    },
    formatBytes(bytes,decimals) {
      if(bytes == 0) return '0 Bytes';
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    messagesChange: function (messages) {
      this.attachments = [];
      for (var i = messages.length - 1; i >= 0; i--) {
        this.getAttachments(messages[i]);
      }
    },
    getAttachments: function (m) {
      if (m.attachments && m.attachments.length > 0) {
        
        let attachIds = this.attachments.map((attach) => {
          return (attach.id || attach._id)+"";
        }); 

        for (var i = m.attachments.length - 1; i >= 0; i--) {
          let id = m.attachments[i].id || m.attachments[i]._id;
          if (attachIds.indexOf(id+"") != -1) return;

          let formatSize = this.formatBytes(m.attachments[i].size);
          m.attachments[i].formatSize = formatSize;
          m.attachments[i].author = m.author;
          m.attachments[i].format = getTypeForMime(m.attachments[i].mime);
          
          this.attachments.push(m.attachments[i]);
          this.attachments.sort(function(a, b) {
            var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
            return dateB - dateA;
          });
        }
      }
    }
  }

  
}

function getTypeForMime(mime) {
  if (mime.indexOf('application/vnd.oasis.opendocument.spreadsheet') != -1) return 'excell';
  else if (mime.indexOf('image/png') != -1) return 'png';
  else if (mime.indexOf('image/jpeg') != -1) return 'jpg';
  else if (mime.indexOf('application/pdf') != -1) return 'pdf';
  else return '';
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/styles/ticket-files.scss';

</style>
