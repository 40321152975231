<template>
  <li class="nav-item dropdown text-color m-r-xs">
    <a href="" class="nav-link" data-toggle="dropdown"><i class="material-icons"></i> 
      <span class="label up p-a-0 accent"></span>
    </a>
    <div class="dropdown-menu w-xl text-color pull-right p-a-0 animated flipInX">
      <div class="row no-gutter text-primary-hover">
        <div class="col-xs-4 b-r b-b">
          <a class="p-a block text-center"><i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">Alarm</span>
          </a>
        </div>
        <div class="col-xs-4 b-r b-b">
          <a class="p-a block text-center"><i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">Favorite</span>
          </a>
        </div>
        <div class="col-xs-4 b-b">
          <a class="p-a block text-center"><i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">History</span>
          </a>
        </div>
        <div class="col-xs-4 b-r">
          <a class="p-a block text-center">
            <i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">Call</span>
          </a>
        </div>
        <div class="col-xs-4 b-r">
          <a class="p-a block text-center" data-toggle="modal" data-target="#user" ui-toggle-class="modal-open-aside" ui-target="body"><i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">Chat</span>
          </a>
        </div>
        <div class="col-xs-4">
          <a class="p-a block text-center"><i class="material-icons md-24 text-muted m-v-sm"></i> 
            <span class="block">Inbox</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'BoxedMenu',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
