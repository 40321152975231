<template>
  <li class="nav-item dropdown text-color">
    <a href="" class="nav-link dropdown-toggle clear" data-toggle="dropdown">
      <span class="avatar w-32">
        <gravatar :email="user.email" default-img="identicon"/>
        <!-- <img src="../../assets/images/a2.jpg" alt="..."> <i class="busy b-white right"></i> -->
      </span>
    </a>
    <div class="dropdown-menu pull-right dropdown-menu-scale">
      <router-link class="dropdown-item" to="/mails" v-if="user != null">
        <span>Inbox</span> 
        <!-- <span class="label warn m-l-xs">3</span> -->
      </router-link>
      <router-link :to="{ name: 'profile' }" class="dropdown-item" >
        <span>{{$t('header.profilSettings.profile.profile')}}</span>
      </router-link>
      <router-link to="/settings" class="dropdown-item" >
        <span>{{$t('header.profilSettings.settings')}}</span>
      </router-link>
      <a class="dropdown-item" v-on:click.prevent="langsModal = true"><span>{{$t('header.profilSettings.language.changeLang')}}</span></a>
      <a class="dropdown-item" v-on:click.prevent="activityModal = true"><span>{{$t('header.profilSettings.activity.activity')}}</span></a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" @click="signOut()" >{{$t('header.profilSettings.signOut')}}</a>
    </div>
    <LanguageSelector v-if="langsModal" v-bind:userInfo="userInfo" v-on:close-languageselector="langsModal = false"></LanguageSelector>
    <UserActivity v-if="activityModal" v-bind:userInfo="userInfo" v-on:close="activityModal = false"></UserActivity>

  </li>
</template>

<script>
import UserManager from '../../UserManager.js';
import UserActivity from './UserActivity.vue';
import LanguageSelector from './LanguageSelector.vue';
import Gravatar from 'vue-gravatar';

export default {
  name: 'ProfileSettings',
  props: {
    userInfo: Object
  },
  components: {
    UserActivity,
    LanguageSelector,
    Gravatar
  },
  data: function () {
    return{
      activityModal: false,
      langsModal: false,
      user: UserManager.user,
    }
  },
  created: function() {
  },
  methods: {
    signOut: function() {
      UserManager.logOut();
    },
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
