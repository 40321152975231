<template>
<div>
  <!-- okno modalne edycji konta -->
  <div class="custom-modal modal fade" v-bind:class="{ in: isModalActive, todisplay: isModalActive }"  data-backdrop="true" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body text-center p-lg">
         <div class="row primary-color">
           <div class="col-md-12 m-b-xs text-left">
            <label class="md-check">
              <input type="checkbox" value="automatic" v-model="options">
              <i class="primary m-r-xs"></i>
              <span class="m-l-xs">{{ $t('settings.tabPrefernces.boxFacebook.options.automatic')}}</span>
            </label>
            <textarea class="form-control form-control-sm comment-box-input m-t" 
            :placeholder="$t('settings.tabPrefernces.boxFacebook.options.automaticText')" v-model="content" 
            v-bind:class="{disabled: !inArray('automatic')}"
            ></textarea>

            <label class="md-check m-t">
              <input type="checkbox" value="a" v-model="options">
              <i class="primary m-r-xs"></i>
              <span class="m-l-xs">Jakas inna opcja</span>
            </label>
            
          </div>
         </div>
          
        </div>

        <div class="modal-footer">

          <button class="btn btn-sm white none-radius pull-left" v-on:click="isModalActive = false">
            <span class="primary-color _400">{{ $t('btnNames.cancel') }}</span>
          </button>

          <button class="btn btn-sm white none-radius" 
           v-bind:class="{ disabled: inProgress }" >
            <span class="primary-color _400">{{ $t('btnNames.save') }}</span>
          </button>

        </div>
      </div><!-- /.modal-content -->
    </div>
  </div>
  <!-- okno modalne edycji konta -->

  <div class="box m-a ">
    <div class="box-header d-gray">
      <h6 class="text-muted _400 inline">{{ $t('settings.tabPrefernces.boxFacebook.title')}}</h6>
      <!-- <label class="md-check pull-right">
          <input type="checkbox" v-on:change="setFacebook" v-model="isFacebook">
          <i class="primary m-r-xs"></i>
      </label> -->
    </div>
    <div class="box-body fb-box" v-if="isLoaded">
        <Spinner></Spinner>
    </div>
    <!-- ładowanie stron jesli są -->
    <div class="box-body fb-box" v-if="isPages && !isLoaded">

      <div class="box m-b custom-border" v-if="pages.length > 0" v-for="p in pages" :key="p._id">
        <div href="" class="pull-left w-40 m-r-sm m-l m-t-sm" v-if="p.picture">
          <img :src="p.picture.data.url" alt="..." class="w-full img-circle">
        </div>
        <div v-else class="pull-left w-40 m-r-sm m-l m-t-sm">
          <img alt="..." class="w-full img-circle">
        </div>
        <div class="clear">
          <div class="p-l p-y-sm white p-r-sm">
            <h6 class="text-muted">
              {{p.name}}
              <a class="pull-right" :title="$t('btnNames.delete')" v-on:click.prevent="deletePage(p)">
                <i class="material-icons md-24 coral">cancel</i> 
              </a>
            </h6>
            <div>
              <a v-if="p.status == 'ok'" class="nav-link btn btn-xs white none-radius m-r-sm" v-on:click="activeModal(p._id)">
                <span class="primary-color _400">{{ $t('btnNames.edit')}}</span>
              </a>
              <!-- <a class="nav-link btn btn-xs white none-radius m-r" v-on:click="configure(p._id)">
                <span class="primary-color _400">{{ $t('settings.tabPrefernces.boxFacebook.configure')}}</span>
              </a> -->
            </div>
          </div>
        </div>
        <p v-if="p.status != 'ok'" class="c-red text-white text-sm p-x p-y-sm m-a-sm">
          {{ $t('settings.tabPrefernces.boxFacebook.errorPage')}}
        </p>
      </div>

      <a class="nav-link btn btn-xs white none-radius m-r m-b" v-on:click="connect">
        <span class="primary-color _400">{{ $t('settings.tabPrefernces.boxFacebook.add')}}</span>
      </a>
    </div>
    <!-- gdy nie ma dodanych stronz fb -->
    <div v-if="!isPages && !isLoaded">
      <div class="box-body fb-box" >
        <button class="btn btn-outline b-primary text-primary none-radius btn-block" v-on:click="connect">
          {{ $t('settings.tabPrefernces.boxFacebook.btnIntegration')}}
        </button>
      </div>
      <div class="box-body start-info">
        <div class="box2 sb10" style="width: 95%;">{{ $t('settings.tabPrefernces.boxFacebook.startInfo')}}</div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';

let messageHandler = null;

export default {
  name: 'SettingFacebook',
  components: {
    Spinner
  },
  data: function () {
    return {
      isLoaded: false,
      isPages: false,
      isModalActive: false,
      inProgress: false,
      pages: [],
      options: [],
      content: ''
    }
  },
  created: function() {
    this.getPages();
    let _vueComp = this;
    messageHandler = function (ev) {
      if (ev.data == 'closing') {
        window.removeEventListener("message", messageHandler);
        _vueComp.getPages();
      }
    }
  },
  destroyed: function() {
    if (messageHandler) window.removeEventListener("message", messageHandler);
  },
  methods: {
    inArray: function(needle) {
      let length = this.options.length;
      for(let i = 0; i < length; i++) {
        if(this.options[i] == needle) return true;
      }
      return false;
    },
    connect: function() {
      if (messageHandler) window.removeEventListener("message", messageHandler);

      Api.fb.getRegisterUrl().then(res => {
        // w przypadku blokady popup jest nullem
        let popup = window.open(res.url, "FB account connecting", 'height=400,width=400');
        if (popup && window.focus) popup.focus();

        // nasluchujemy na zamkniecie okna
        window.addEventListener("message", messageHandler, false);
      });
    },
    activeModal: function(id) {
      this.isModalActive = true;
    },
    deletePage: function (page) {
      let options = {
        html: true,
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
      };
      let message = this.$t('settings.tabPrefernces.boxFacebook.removeConfirm') + '<br/><h5>' + page.name + '</h5>';
      message += '<br/><span class="text-muted">'+this.$t('settings.tabPrefernces.boxFacebook.removeExplain')+'</span>';
      this.$dialog.confirm(message, options).then(dialog => {
        return Api.fb.deletePage(page.pageId);
      }).then(p => {
        this.getPages();
      }).catch(r => r);
    },
    getPages: function() {
      this.isLoaded = true;
      Api.fb.getPages().then((data) => {
        this.pages = data;
        this.isLoaded = false;
        if (this.pages.length) this.isPages = true;
      })
    },
    configure: function() {
      Api.fb.getRegisterUrl().then(res => {
        let popup = window.open(res.url, "FB account connecting", 'height=400,width=400');
        if (window.focus) popup.focus();
      });
    }
  }
}
</script>
<style scoped lang="scss">
.disabled {
  background-color: rgba(200, 200, 200, 0.1);
}
.fb-box{
  min-height: 215px;
  max-height: 215px;
  overflow: auto;
}
.custom-border {
  border: 1px solid #d3d3d3;
}
</style>