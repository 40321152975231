<template>
  <div class="app-header white box-shadow">

    <div class="navbar">
      <a data-toggle="collapse" data-target="#navbar-2" class="navbar-item pull-right hidden-md-up m-a-0 m-l primary-color">
        <i class="material-icons"></i>
      </a>
      <router-link to="/list" class="navbar-brand primary-color">
        <img src="../../assets/images/logo.svg" alt="Logo"/>
      </router-link>

      <ul class="nav navbar-nav pull-right" style="max-height: 56px;">
        

        <!-- <BoxedMenu/> -->
        <Messenger/>
        <LiveChatNotificator v-if="chatAware" />
        <ChatPage/> 
        <CommentNotifications/> 
        <AlarmInfo/> 
        <ActivityTracker />
        <ProfileSettings v-if="user != null" v-bind:userInfo="user" />

      </ul>

      <div class="collapse navbar-toggleable-sm" id="navbar-2">
        <ul class="nav navbar-nav text-primary-hover primary-color">
          <li class="nav-item">
            <router-link class="nav-link" to="/list">
              <span class="nav-text">{{ $t('header.tickets') }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="user != null">
            <router-link class="nav-link" to="/mails">
              <span class="nav-text">{{ $t('header.mails') }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="user != null && (user.roles.indexOf('admin') != -1 || UserManager.permissions.find(element => element == 'system.settings'))">
            <router-link class="nav-link" to="/settings">
              <span class="nav-text">{{ $t('header.settings') }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="user != null && (user.roles.indexOf('admin') != -1 || UserManager.permissions.find(element => element == 'raport.access'))">
            <router-link class="nav-link" to="/reports">
              <span class="nav-text">{{ $t('header.reports') }}</span>
            </router-link>
          </li>

          <li class="nav-item m-r m-l text-color">
            <a class="nav-link" v-if="user != null && UserManager.wizzardEnabled && (user.roles.indexOf('admin') != -1 || UserManager.permissions.find(element => element == 'system.settings'))" href="#" @click.prevent="openWizzardModal">
              <span class="nav-text text-center">
                <span class="text-xs text-success"><i class="material-icons ">build</i><br/>Wróć do<br/>konfiguracji</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BoxedMenu from './BoxedMenu.vue'
import AlarmInfo from './AlarmInfo.vue'
import LiveChatNotificator from './LiveChatNotificator.vue'
import CommentNotifications from './CommentNotifications.vue'
import ChatPage from './ChatPage.vue'
import Messenger from './Messenger.vue'
import ActivityTracker from './ActivityTracker.vue'
import ProfileSettings from './ProfileSettings.vue'
import Api from '../../Api.js';
import UserManager from '../../UserManager.js'
import SystemSocket from '../../SystemSocket.js'
import WelcomeWizzardModal from '../WelcomeWizzard/WelcomeWizzardModal.vue';

export default {
    name: 'Header',
    props: {
    },
    components: {
        BoxedMenu,
        AlarmInfo,
        LiveChatNotificator,
        CommentNotifications,
        ProfileSettings,
        ActivityTracker,
        ChatPage,
        Messenger,
        WelcomeWizzardModal
    },
    data: function () {
        return {
            user: UserManager.user,
            livechatAvailability: false,
            chatAware: false,
            UserManager: UserManager
        }
    },
    created: function() {
        
        this.checkChatAwarness();
        UserManager.events.on('userReady', async () =>{
            this.user = UserManager.user;
        });

        SystemSocket.socket.on('object', (msg) => {
            if (msg.object == 'group' || msg.object == "livechatpage") this.checkChatAwarness();
        });
    },
    methods: {
        async checkChatAwarness() {
            try {
                let r = await Api.tickets.livechat.amIChatAware();
                if (r.aware) {
                    this.chatAware = true;
                    let av = await Api.tickets.livechat.getAvailability(this.user.userId);
                    this.livechatAvailability = av.available;
                } else {
                    this.chatAware = false;
                }
            } catch (error) { console.error("Chat awarnes error", error); }
        },

        openWizzardModal (message, raport) {
            this.$modal.show(WelcomeWizzardModal, {
            }, {height: 'auto', width: '70%' });
        },

        async onLiveChatAvChanged() {
            try {
                let settingTo = this.livechatAvailability;
                await Api.tickets.livechat.setAvailability(this.livechatAvailability);
                if (settingTo) this.$n.info("Super, to teraz będziesz otrzymywać korespondencje z chatu");
                else this.$n.info("Ok rozumiem... jesteś zajęty/a i nie chcesz otrzymywać korespondencji z chatu");
            } catch (error) {
                
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.live-chat-btn {
  border: 1px solid #0cc2aa;
  padding: 0.4rem 0.5rem 0.5rem 0.5rem;
  color: #0cc2aa;
  label {
    margin-bottom: 0px;
    margin-left: 4px;
  }
}
</style>
