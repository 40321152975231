<template>
    <div>
        <div class="box-header d-gray p-y-sm" v-if="1 == 0">
            <div class="pull-right m-t-sm">
                <a class="btn btn-sm white none-radius">
                    <span class="primary-color _400">{{$t('btnNames.add')}}</span>
                </a>&nbsp;
                <a class="btn btn-sm white none-radius">
                    <span class="primary-color _400">{{$t('btnNames.delete')}}</span>
                </a>&nbsp;
            </div>
            
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-md-6"  style="padding-left: 0px; border-right: 1px solid rgba(120, 130, 140, 0.13); min-height:800px">
                    <div class="graph-visualize" style="height:800px;"></div>
                </div>

                <div class="col-md-6">
                    <div class="box-phone" v-if="selectedNode">
                        <h6 class="title-color _600 inline">Węzeł: {{ selectedNode.friendlyName }}</h6>
                        <a class="text-info pull-right m-l-1" @click.prevent="addNode()"><i class="material-icons md-18">add</i> Nowy węzeł</a>
                        <a class="text-info pull-right m-l-1" @click.prevent="removeNode(selectedNode)">Usuń wezeł</a>
                        <hr/>
                        <div class="box-body">
                            <form class="form-horizontal">
                                <div class="form-group row m-b">
                                    <label class="col-md-4 form-control-label label-sm m-t-xs">Typ węzła</label>
                                    <div class="col-md-8">
                                        <v-select 
                                        @input="onNodeChanged"
                                        class="form-control"
                                        :options="nodeTypes"
                                        :reduce="nType => nType.type"
                                        v-model="selectedNode.type"></v-select>
                                    </div>
                                </div>
                                <div class="form-group row m-b">
                                    <label class="col-md-4 form-control-label label-sm m-t-xs">Przyjazna nazwa</label>
                                    <div class="col-md-8"><input type="text" class="form-control" @input="onNodeChanged" v-model="selectedNode.friendlyName"></div>
                                </div>
                                
                                <div v-if="selectedNode.type =='phone'">
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Numer telefonu</label>
                                        <div class="col-md-8"><input type="text" class="form-control" v-model="selectedNode.settings.phoneNumber"></div>
                                    </div>
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Użytkownik SIP</label>
                                        <div class="col-md-8"><input type="text" class="form-control" v-model="selectedNode.settings.sipUser"></div>
                                    </div>
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Hasło SIP</label>
                                        <div class="col-md-8"><input type="password" class="form-control" v-model="selectedNode.settings.sipPass"></div>
                                    </div>
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Host</label>
                                        <div class="col-md-8"><input type="text" class="form-control" v-model="selectedNode.settings.sipHost"></div>
                                    </div>

                                    <NodeSelector 
                                        @input="onNodeChanged"
                                        @new-node="addNode(selectedNode, 'nextNode', $event)"
                                        label="Kieruj na"
                                        :availableNodes="availableNodes"
                                        v-model="selectedNode.settings.nextNode" />
                                </div>

                                <div v-if="selectedNode.type =='playback'">
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Dodaj plik</label>
                                        <div class="col-md-8">
                                            <div class="input-group">
                                                <input type="text" class="form-control">
                                                <span class="input-group-btn">
                                                    <div class="form-file">
                                                        <input type="file" multiple="multiple">
                                                        <button title="Wgraj plik" class="btn white none-radius">
                                                            <i class="material-icons md-20"></i>
                                                        </button>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <NodeSelector 
                                        @input="onNodeChanged"
                                        @new-node="addNode(selectedNode, 'nextNode', $event)"
                                        label="Kieruj na"
                                        :availableNodes="availableNodes"
                                        v-model="selectedNode.settings.nextNode" />
                                </div>

                                <div v-if="selectedNode.type =='date-condition'">
                                    <div class="form-group">
                                        <label class="md-check checkbox-inline" v-for="day in weekDays" :key="day.id">
                                            <input type="checkbox" :value="day.id" >
                                            <i class="primary m-r-xs"></i>
                                            <span class="m-l-xs">{{ day.label }}</span>
                                        </label>
                                    </div>

                                    <div class="form-group">
                                        <label>W godzinach</label>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <vue-timepicker :minute-interval="10" placeholder="Od"></vue-timepicker>
                                                <span class="m-x">do</span>
                                                <vue-timepicker :minute-interval="10"  placeholder="Do"></vue-timepicker>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <NodeSelector 
                                        @input="onNodeChanged"
                                        @new-node="addNode(selectedNode, 'yesNode', $event)"
                                        label="Jeśli pasuje kieruj na"
                                        :availableNodes="availableNodes"
                                        v-model="selectedNode.settings.yesNode" />

                                    <NodeSelector 
                                        @input="onNodeChanged"
                                        @new-node="addNode(selectedNode, 'nopeNode', $event)"
                                        label="Jeśli NIE pasuje kieruj na"
                                        :availableNodes="availableNodes"
                                        v-model="selectedNode.settings.nopeNode" />
                                </div>

                                <div v-if="selectedNode.type =='receivers'">
                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Przekieruj połączenie do:</label>
                                        <div class="col-md-8">
                                            <v-select 
                                            multiple
                                            class="form-control"
                                            :options="emps"
                                            label="name"
                                            :reduce="emp => emp._id"
                                            v-model="selectedNode.settings.receivers"></v-select>
                                        </div>
                                    </div>

                                    <div class="form-group row m-b">
                                        <label class="col-md-4 form-control-label label-sm m-t-xs">Strategia dzwonienia</label>
                                        <div class="col-md-8">
                                            <v-select 
                                            class="form-control"
                                            :options="ringStrategies"
                                            :reduce="nType => nType.code"
                                            v-model="selectedNode.settings.callStrategy">
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.label }}<br/>
                                                    <span class="text-muted text-sm">{{ option.desc }}</span>
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.label }}<br/>
                                                    <span class="text-muted text-sm">{{ option.desc }}</span>
                                                </div>
                                            </template>
                                            </v-select>
                                        </div>
                                    </div>

                                    <NodeSelector 
                                        @input="onNodeChanged"
                                        @new-node="addNode(selectedNode, 'onNobodyPickupNode', $event)"
                                        label="Jeśli nikt nie odbierze kieruj na"
                                        :availableNodes="availableNodes"
                                        v-model="selectedNode.settings.onNobodyPickupNode" />
                                </div>

                            </form>
                            <pre v-if="1 == 0">{{ selectedNode }}</pre>
                        </div>
                        <hr/>
                    </div>

                    <div class="info-box p-a" v-if="1 == 0">
                        <i class="fa fa-question text-primary p-l-sm"></i>
                        <label class="_600 m-l">Opcje wezłów do wyboru</label>
                        <ul class="nav nav-pills nav-stacked nav-sm m-t " style="border:none;">
                            <li class="nav-item" style="border:none;">
                                <a class="nav-link">
                                    <i class="fa m-r-sm fa-circle text-primary"></i>
                                    <span class="_600">Warunek</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                </a>
                                <a class="nav-link">
                                    <i class="fa m-r-sm fa-circle text-info"></i>
                                    <span class="_600">Komunikat głosowy</span>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                </a>
                                <a class="nav-link">
                                    <i class="fa m-r-sm fa-circle text-warn"></i>
                                    <span class="_600">Automatyczny rozdzielacz połaczeń</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                </a>
                                <a class="nav-link">
                                    <i class="fa m-r-sm fa-circle text-accent"></i>
                                    <span class="_600">Odbiorce połaczenia</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                </a>
                                <a class="nav-link">
                                    <i class="fa m-r-sm fa-circle text-success"></i>
                                    <span class="_600">Wybór numerczny połaczeń</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>

        
    </div>
</template>
<script>
import VoIPGraph from './VoIPGraph';
import EmployeeManager from '../../EmployeeManager.js';
import NodeUser from './voip/NodeUser';
import NodeVoice from './voip/NodeVoice';
import NodeCondition from './voip/NodeCondition';
import NodeSplitter from './voip/NodeSplitter';
import NodeSelector from './voip/NodeSelector.vue';
import VueTimepicker from 'vue2-timepicker'
let graph;

const SAMPLE_NODES = [
    {
        _id: "a1",
        type: "phone",
        friendlyName: "Telefon biuro",
        settings: {
            phoneNumber: "123123123",
            sipUser: "",
            sipPass: "",
            sipHost: "",

            nextNode: "a2"
        },
    },
    {
        _id: "a2",
        type: "playback",
        friendlyName: "Powitanie",
        settings: {
            file: "test.mp3",

            nextNode: "c1"
        },
    },
    {
        _id: "c1",
        type: "date-condition",
        friendlyName: "Czy pn-pt?",
        settings: {
            activeDays: [1,2,3,4,5],
            activeHoursFrom: "08:00",
            activeHoursTo: "15:00",

            nopeNode: null, //null rownoznaczne z rozlaczeniem
            yesNode: "r1"
        },
    },
    {
        _id: "r1",
        type: "receivers",
        friendlyName: "BOK",
        settings: {
            receivers: ["5f7dd817bcc2e1011e4babb2", "5f7dd817bcc2e1011e4babb3"],
            callStrategy: "ringall",
            ringFor: 20,
            backgroundMusic: null,

            onNobodyPickupNode: null, //null rownoznaczne z rozlaczeniem
        },
    }
];

const NODE_TYPES = [
    { type: "phone", label: "Telefon SIP" },
    { type: "playback", label: "Komunikat głosowy" },
    { type: "date-condition", label: "Warunek daty i godziny" },
    { type: "receivers", label: "Odbiorcy połączenia" },
];

const weekDays = [
    { label: 'pn', id: 1 },
    { label: 'wt', id: 2 },
    { label: 'śr', id: 3 },
    { label: 'cz', id: 4 },
    { label: 'pi', id: 5 },
    { label: 'so', id: 6 },
    { label: 'nd', id: 0 },
];

const RING_STRATEGIES = [
    { code: "ringall", label: "Do wszystkich", desc: "dzwoni jednocześnie do wszystkich dostępnych agentów dopóki jeden nie odbierze"},
    { code: "random", label: "Losowo", desc: "dzwoni do losowo wybranego agenta"},
];

export default {
    name: 'VoipPage',
    props: {
        
    },
    components: {
        VueTimepicker,
        NodeUser,
        NodeVoice,
        NodeCondition,
        NodeSplitter,
        NodeSelector
    },
    data: function () {
        return {
            phones: [{id:1, name:" Telefon: "}],
            selectedPhone: 1,

            emps: [],
            nodeTypes: NODE_TYPES,
            nodes: SAMPLE_NODES,
            ringStrategies: RING_STRATEGIES,
            weekDays: weekDays,
            selectedNode: null
        }
    },
    computed: {
        availableNodes: function() {
            return this.nodes
                .filter(n => n._id != (this.selectedNode || {})._id)
                .filter(n => n.type != "phone");
        }
    },
    created: async function() {
        try {
            this.emps = await EmployeeManager.getEmployees();
            
        } catch (error) {
            
        }
    },
    mounted: function() {
        graph = new VoIPGraph(document.querySelector('.graph-visualize'));
        graph.draw(this.nodes);
        graph.onNodeSelected((nodeId) => {
            this.selectedNode = this.nodes.find(n => n._id == nodeId);
        });

        this.selectedNode = this.nodes.find(n => n._id == "a1");
    },
    methods: {
        add: function() {
            console.log("seelcteOpt", this.seelcteOpt);
            this.voipOpt = this.seelcteOpt.id;
        },

        onNodeChanged: function() {
            graph.draw(this.nodes);
        },

        addNode(refNode, pNodeKey, type) {
            let nId = parseInt(Math.random()*10000)+"";
            this.nodes.push({
                _id: nId,
                type: type ? type : "playback",
                friendlyName: "Nowy węzeł",
                settings: {},
            });

            if (refNode) refNode.settings[pNodeKey] = nId;

            graph.draw(this.nodes);
            graph.select(nId);
            this.selectedNode = this.nodes.find(n => n._id == nId);
        },

        removeNode: function(node) {
            let idx = this.nodes.indexOf(node);
            if (idx != -1) this.nodes.splice(idx, 1);
            graph.draw(this.nodes);
        }
    }
}
</script>
<style lang="scss" scoped>
.info-box {
    border: 1px solid rgb(12,194,170);
    background: rgba(12,194,170, 0.1);
}
</style>