<template>
<div class="m-b">
  <div class="btn-group dropdown hidden-lg-down">
    <button class="btn btn-sm dropdown-toggle primary-color custom-dropdown" data-toggle="dropdown" aria-expanded="false">
      {{status}}
    </button>
    <div class="dropdown-menu dropdown-menu-scale">
      <a class="dropdown-item" href="#" v-on:click="statusOnly('new', $event)">{{ $t('ticket.sorts.new') }}</a>
      <a class="dropdown-item" href="#" v-on:click="statusOnly('open', $event)">{{ $t('ticket.sorts.open') }}</a>
      <a class="dropdown-item" href="#" v-on:click="statusOnly('pending', $event)">{{ $t('ticket.sorts.pending') }}</a>
      <a class="dropdown-item" href="#" v-on:click="statusOnly('closed', $event)">{{ $t('ticket.sorts.closed') }}</a>
    </div>
  </div>
  <small class="m-l text-muted hidden-sm-down">{{$t('ticket.sorts.sortBy')}}:</small>
  <div class="btn-group dropdown hidden-sm-down">
    <button class="btn dropdown-toggle btn-sm custom-dropdown primary-color" data-toggle="dropdown" aria-expanded="false">{{sortElement}}</button>
    <div class="dropdown-menu dropdown-menu-scale">
      <a class="dropdown-item" href="#" v-on:click.prevent="sortBy('Date:DESC')">{{ $t('ticket.sorts.dateNew') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="sortBy('Date:ASC')">{{ $t('ticket.sorts.dateOld') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="sortBy('Status')">{{ $t('ticket.sorts.status') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="sortBy('Priority')">{{ $t('ticket.sorts.priority') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="sortBy('Customer')">{{ $t('ticket.sorts.customer') }}</a>
    </div>
  </div>
  <small class="m-l text-muted c-rwd">{{ $t('ticket.sorts.fitToFilter') }}</small>
  <span class="c-yellow text-white m-l-sm none-radius label c-rwd">
    {{totalTicketsFromLastSearch}}
  </span>
  <SearchFormTicketList></SearchFormTicketList>
</div>
  
</template>

<script>
import SearchFormTicketList from './SearchFormTicketList.vue'
import EventBus from '../../event-bus.js';
import FilterStorage from '../../FilterStorage.js';

export default {
  name: 'SortList',
  props: {
    totalTicketsFromLastSearch: Number
  },
  components: {
    SearchFormTicketList
  },
  data: function () {
    return {
      status: '',
      sortElement: '',
    }
  },
  created: function() {
    if (FilterStorage.checkedStatus.length == 0) {
      setTimeout(() => {
        this.status = this.$i18n.t('ticket.sorts.allTickets');
      }, 1000)
    } else {
      this.status = this.$i18n.t('ticket.sorts.'+FilterStorage.checkedStatus[0]);
    }
    if (FilterStorage.sortBy == 'Date:DESC') {
      setTimeout(() => {
        this.sortElement = this.$i18n.t('ticket.sorts.dateNew');
      }, 1000)
    } else {
      setTimeout(() => {
        if (FilterStorage.sortBy == 'Date:ASC') {
          this.sortElement = this.$i18n.t('ticket.sorts.dateOld')
        }
        else if (FilterStorage.sortBy == 'Status') {
          this.sortElement = this.$i18n.t('ticket.sorts.status')
        }
        else if (FilterStorage.sortBy == 'Priority') {
          this.sortElement = this.$i18n.t('ticket.sorts.priority')
        }
        else if (FilterStorage.sortBy == 'Customer') {
          this.sortElement = this.$i18n.t('ticket.sorts.customer')
        }
      }, 1000)
    }

    EventBus.$on('filterChangeNew', this.filterStorageChange);
    if (FilterStorage.sortBy) {
      this.sortElement = FilterStorage.sortBy;
      if (FilterStorage.sortBy == 'Date:ASC') this.sortElement = this.$i18n.t('ticket.sorts.dateOld');
      else if (FilterStorage.sortBy == 'Date:DESC') this.sortElement = this.$i18n.t('ticket.sorts.dateNew');
    }
  },
  destroyed: function() {
    EventBus.$off('filterChangeNew', this.filterStorageChange);
  },
  methods: {
    sortBy: function(sortElement) {
      // this.sortElement = sortElement;
      if (sortElement == 'Date:ASC') {
        // this.sortElement = 'Date (oldest first)'
        this.sortElement = this.$i18n.t('ticket.sorts.dateOld')
      }
      else if (sortElement == 'Date:DESC') {
        // this.sortElement = 'Date (newest first)'
        this.sortElement = this.$i18n.t('ticket.sorts.dateNew')
      }
      else if (sortElement == 'Status') {
        this.sortElement = this.$i18n.t('ticket.sorts.status')
      }
      else if (sortElement == 'Priority') {
        this.sortElement = this.$i18n.t('ticket.sorts.priority')
      }
      else if (sortElement == 'Customer') {
        this.sortElement = this.$i18n.t('ticket.sorts.customer')
      }
      FilterStorage.sortBy = sortElement;
      FilterStorage.sortSelecteFilters();
    },
    statusOnly: function(el, event ) {
      event.preventDefault();
      this.status = this.$i18n.t('ticket.sorts.'+el)
      // var nameCapitalized = el.charAt(0).toUpperCase() + el.slice(1)
      // this.status = nameCapitalized;
      FilterStorage.checkedStatus = [];
      FilterStorage.checkedStatus = [el];
      FilterStorage.filterChange();
    },
    filterStorageChange: function(ob) {
      if (ob.checkedStatus.length == 0) {
        this.status = this.$i18n.t('ticket.sorts.allTickets');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 1260px) {
  .c-rwd {
    display: none;
  }
}
</style>
