<template>

  <li class="nav-item text-color m-l-xs m-r-xs">
    <a class="nav-link" v-on:click="showModal" style="position: relative;">
      <i class="material-icons" v-bind:class="{'text-red': isUnreadMessages}">chat_bubble_outline</i> 
      <span v-if="isUnreadMessages" class="pulse"></span>
    </a>
    <div id="chat-list" class="custom-modal modal fade" v-bind:class="{ in: isActive, 'todisplay-chat': isActive }"  data-backdrop="true" aria-hidden="true" >
      <div class="right w-xl white b-l">
        <div class="row-col">
          <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="closeModal"><i class="material-icons md-20"></i></a>
          <div class="p-a b-b p-x-md">
            <span class="_500 primary-color">{{$t('header.chat.chat')}}</span>
          </div>
          <div class="row-row">

            <div class="row-body">

              <div class="row-inner">
                <div class="form-group l-h m-a-0">
                  <div class="input-group" style="width: 100%;">
                    <input style="border-top: none; border-left: none;" class="form-control form-control-sm p-x b-a" :laceholder="$t('header.chat.searchCo')" type="text" v-model="searchEmployees">
                  </div>
                </div>
                <div class="list-group no-radius no-borders">
                  <div class="p-x-md m-t p-v-xs primary-color _500">
                    {{$t('header.chat.rooms')}}
                    <a class="pull-right" :title="$t('header.chat.add')" v-on:click.prevent="showModalRoom()">
                      <i class="material-icons md-18 primary-color">add</i>
                    </a>
                  </div>
                  <div class="single-room list-group-item p-x-md" v-if="rooms.length > 0" 
                    v-for="r in rooms"
                    :attach="r"
                    :key="r._id">

                    <a class="p-y p-r-xxl" v-on:click.prevent="getMsgSelectedRoom(r._id, r.name)">
                      <i class="fa fa-circle text-green text-xs m-r-xs"></i> 
                      <span> {{r.name}}</span>
                      <div class="counter-message inline" v-if ="unreadMessagesForRooms[r._id]">
                        <b class="label rounded c-red">{{unreadMessagesForRooms[r._id]}}</b>
                      </div>
                    </a>

                    <div class="pull-right edit-room" v-if="r.owner == user.userId">
                      <div class="nav-item inline dropdown">
                        <a data-toggle="dropdown" aria-expanded="false">
                          <i class="material-icons md-18 primary-color"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-scale pull-right none-radius none-margin none-padding" style="min-width: 100px;">
                          <div>
                            <div class="p-l p-y-xs chat-dropdown" v-on:click.prevent="showModalRoom(r)">
                              {{$t('btnNames.edit')}}
                            </div>
                            <div class="p-l p-y-xs chat-dropdown" v-on:click.prevent="deleteSelectedRoom(r)">
                              {{$t('btnNames.delete')}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="p-x-md m-t p-v-xs primary-color _500">{{$t('header.chat.coWorkers')}}</div>
                  
                  <div v-if="employees.length > 0">
                    <a class="list-group-item p-x-md" 
                      v-for="e in employees"
                      :attach="e"
                      :key="e.id"
                      v-show="!searchEmployees.length || e.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1"
                      v-on:click.prevent="getMessagesWithSelectedUser(e.id, e.name)" v-if="e.id != user.userId">
                      
                      <i class="fa fa-circle text-green text-xs m-r-xs" v-if="inArray(e.id) ==  true"></i>
                      <i class="fa fa-circle text-red text-xs m-r-xs" v-if="inArray(e.id) ==  false"></i> 
                      <span> {{e.name}}</span>
                      <div class="counter-message inline" v-if ="unreadMessagesForUsers[e.id]">
                        <b class="label rounded c-red">{{ unreadMessagesForUsers[e.id] }}</b>
                      </div>
                    </a>
                  </div>
                 
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
    <div id="chat" class="custom-modal modal fade" v-bind:class="{ in: isActiveSingleChat, 'todisplay-chat': isActiveSingleChat }" style="right:200px;">
      <div class="right w-xxl white b-l" id="test-chat">
        <div class="row-col">
          <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="closeSingleModal"><i class="material-icons md-20"></i></a>
          <div class="p-a b-b p-x-md">
            <div class="inline primary-color _500">{{modalName}}</div>
          </div>

          <div class="row-row dker">
            <div class="row-body scrollable" id="sroll-chat">
              <div class="row-inner">

                <div class="p-a-md" v-if="messages.length > 0">

                  <div class="m-b" v-for="m in evenMessages" >

                    <div v-if="m.author != user.userId">

                      <div href="" class="pull-left w-40 m-r-sm" >
                        <gravatar class="w-full img-circle" :email="m.emailAuthorMsg" default-img="identicon"/>
                        <!-- <img src="../../assets/images/a2.jpg" alt="..." class="w-full img-circle"> -->
                      </div>

                      <div class="clear">
                        <div class="p-a p-y-sm white r">
                          <span class="text-muted">
                            <span v-if ="employeesNames[m.author]">{{employeesNames[m.author]}}</span>
                            <span v-else>{{$t('header.chat.noName')}}</span>
                            {{$t('header.chat.says')}}:
                          </span><br/>
                          <nl2br v-if="m.content" tag="span" :text="m.content" />
                          <span v-else class="text-muted">(brak tresci)</span>
                        </div>
                        <!-- attachemnt -->
                        <div v-if="m.attachments && m.attachments.length > 0">
                          <div v-for="a in m.attachments" :attach="a" class="text-left" v-if="a != null">
                            <a v-bind:href="a" target="_blank" class="text-muted">
                              <i class="material-icons m-r-xs md-14 coral">attach_file</i> 
                              <span class="text-xs">{{a.split('/').pop()  | truncate(25)}}</span>
                            </a>
                          </div>
                        </div>
                        <!-- attachemnt -->
                        <div class="text-muted text-xs m-t-xs">
                          <i class="fa fa-ok text-success"></i>  
                          {{ m.created | moment('from') }}
                        </div>
                      </div>

                    </div>
                    <div v-else>

                      <div href="" class="pull-right w-40 m-l-sm">
                        <gravatar class="w-40 avatar" :email="user.email" default-img="identicon"/>
                        <!-- <span class="w-40 avatar primary">
                          <span>A</span>
                        </span> -->

                      </div>

                      <div class="clear text-right">
                        <div class="p-a p-y-sm text-white c-green text-left r">
                          <nl2br v-if="m.content" tag="span" :text="m.content" />
                          <span v-else class="text-muted">(brak tresci)</span>
                        </div>
                        <!-- attachemnt -->
                        <div v-if="m.attachments && m.attachments.length > 0">
                          <div v-for="a in m.attachments" :attach="a" class="text-left" v-if="a != null">
                            <a v-bind:href="a" target="_blank" class="text-muted">
                              <i class="material-icons m-r-xs md-14 coral">attach_file</i> 
                              <span class="text-xs">{{a.split('/').pop()  | truncate(25)}}</span>
                            </a>
                          </div>
                        </div>
                        <!-- attachemnt -->
                        <div class="text-muted text-xs m-t-xs">{{ m.created | moment('from') }}</div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="p-a-md" v-else>
                  <div class="m-b text-muted">{{$t('header.chat.noMessages')}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-a b-t dker">
            <div class="form-group l-h m-a-0">
              <!-- btn uplad files -->
              <div class="form-file pull-left">
                <input type="file" ref="files" multiple v-on:change="onFilesInputChange" style="display: none;">
                <a :title="$t('header.chat.upload')" v-on:click.prevent="addFiles">
                  <i class="material-icons m-r-xs m-t-sm coral">attach_file</i> 
                </a>
              </div>
              <!-- btn uplad files -->
              <!-- input content msg -->
              <div class="input-group chat-content">
                <textarea class="form-control form-control-sm" :placeholder="$t('header.chat.type')" id="chat-msg-area" 
                v-on:keyup="autoGrow" @keyup.enter.shift.prevent="" @keyup.enter.exact="sendMessage" v-model="chatMsg" 
                ></textarea>
                <span class="text-muted text-sm"><code>Enter</code> Wyślij / <code>Shift</code> + <code>Enter</code> nowa linia</span>
              </div>
              <!-- input content msg -->
              <!-- files list -->
              <div class="list-group list-group-gap m-t-sm" >
                <div v-for="(file, key) in files">

                  <a class="file-link" target="_blank" v-bind:href="file.fileUrl" 
                  v-bind:class="{ 'text-info': file.isUrlActive, 'disabled': file.isDisabled}">
                    {{ file.file.name | truncate(25)}}
                  </a>
                  <span class="primary-color p-l-xs text-xs">({{ file.file.formatSize }})</span>
                  <a class="primary-color pull-right p-r-sm" href="#" v-on:click="removeFile( key )">
                    <i class="material-icons md-14"></i>
                  </a>
                  
                  <div class="progress m-b-xs none-radius" v-if="file.fileUrl == null"
                  v-bind:class="{ 'none-display': isErrorUpload}" style="width: 50%; height: 0.7rem;">
                    <div class="progress-bar primary none-radius" style="font-size: 0.55rem;" 
                    v-bind:style="{ width: file.progress + '%' }">
                      {{file.progress}} %
                    </div>
                  </div>
                  <div v-if="isErrorUpload"  class="upload-error text-xs">
                    <span class="text-red">{{$t('header.chat.errOccured')}}</span>
                  </div>

                </div>

              </div>
              <!-- files list -->
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div id="room-edit" class="custom-modal modal fade" v-bind:class="{ in: isActiveEditRoom, 'todisplay-chat':  isActiveEditRoom }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center p-lg primary-color">
            <form class="form-horizontal text-left">
              <div class="form-group row">
                <label class="col-sm-3 form-control-label">{{$t('header.chat.name')}}</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" v-model="selectedRoom.name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 form-control-label">{{$t('header.chat.isPublic')}}</label>
                <div class="col-sm-9">
                  <label class="md-check pull-left m-t-xs none-radius">
                    <input type="checkbox" v-model="selectedRoom.isPublic">
                    <i class="primary"></i>
                  </label>
                </div>
              </div>
              <div class="form-group row" v-if="!selectedRoom.isPublic">
                <label class="col-sm-3 form-control-label">{{$t('header.chat.selectCo')}}</label>
                <div class="col-sm-9">
                  <div class="custom-box-selected-emps p-a-sm">
                    <div v-for="a in employees"
                      :attach="a"
                      :key="a.id" v-if="a.id != user.userId">
                      <label class="md-check none-radius m-b-xs">
                        <input type="checkbox" v-model="selectedRoom.users" v-bind:value="a.id">
                        <i class="primary"></i>
                        {{a.name}}
                      </label>
                    </div> 
                  </div>
                </div>
                
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm white none-radius pull-left" v-on:click="closeModalRoom" v-bind:class="{ disabled: inProgress }">
              <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </button>
            <button v-if="!isChangeRoom" class="btn btn-sm white none-radius" 
            v-on:click="addNewRoom" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{$t('btnNames.save')}}</span>
            </button>
            <button v-if="isChangeRoom" class="btn btn-sm white none-radius" 
            v-on:click="changeSelectedRoom" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{$t('btnNames.change')}}</span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </li>
 
</template>

<script>
import UserManager from '../../UserManager.js';
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import ChatManager from '../../ChatManager.js';
import EventBus from '../../event-bus.js';
import $ from 'jquery';
import Gravatar from 'vue-gravatar';
import Nl2br from 'vue-nl2br'



export default {
  name: 'ChatPage',
  props: {
  },
  components: {
    Gravatar,
    Nl2br
  },
  data: function () {
    return {
      rooms: [],
      messages: [],
      employees: [],
      isActive: false,
      isActiveSingleChat:false,
      isActiveEditRoom:false,
      chatMsg: '',
      user: UserManager.user,
      modalName: '',
      isRoom: false,
      selectedID: '',
      isChangeRoom:false,
      inProgress: false,
      isPublic: false,
      checkedEmps: [],
      selectedRoom: {name: '', isPublic: false, users: []},
      employeesNames: {},
      unreadMessagesForUsers: {},
      unreadMessagesForRooms: {},
      currentOpenChatWindow: null,
      isUnreadMessages: false,
      onlineUsers: [],
      searchEmployees: '',
      files: [],
    }
  },
  created: function() {
    this.getEmployees();
    window.addEventListener('keyup', this.handleKeyUp);
    ChatManager.events.on('unread_messages_changed', this.onUnreadMessagesChangeEvent);
    ChatManager.events.on('online_users_changed', this.onOnlineUsersChangeEvent);
    ChatManager.events.on('messages_changed', this.onChatManagerChangeEvent);
    ChatManager.events.on('room_created', this.onChatManagerCreateRoomEvent);
    ChatManager.events.on('room_changed', this.onChatManagerChangeRoomEvent);
    ChatManager.events.on('user_logged', this.onUserLoggedEvent);
    ChatManager.events.on('rooms_loaded', this.onRoomsLoadedEvent);

    EventBus.$on('openUserChat', this.openUserChat);

    // dopiero tutaj ustawiamy token dla chat menegara dlatego, ze dopiero
    // ustaiwenie tokeny powoduje zaladowanie pokoi, online-userow, itp
    if (ChatManager.token == null) ChatManager.setToken(UserManager.token);
  },
  destroyed: function() {
    window.removeEventListener('keyup', this.handleKeyUp);

    EventBus.$off('openUserChat', this.openUserChat);

    ChatManager.events.removeListener('messages_changed', this.onChatManagerChangeEvent);
    ChatManager.events.removeListener('room_created', this.onChatManagerCreateRoomEvent);
    ChatManager.events.removeListener('room_changed', this.onChatManagerChangeRoomEvent);
    ChatManager.events.removeListener('unread_messages_changed', this.onUnreadMessagesChangeEvent);
    ChatManager.events.removeListener('online_users_changed', this.onOnlineUsersChangeEvent);
    ChatManager.events.removeListener('user_logged', this.onUserLoggedEvent);
    ChatManager.events.removeListener('rooms_loaded', this.onRoomsLoadedEvent);
  },
  computed: {
    evenMessages: function () {
      return this.messages.sort(function(a, b) {
        var dateA = new Date(a.created), dateB = new Date(b.created);
        return dateA - dateB;
      });
    }
  },
  methods: {

    openUserChat: function() {
      this.showModal();
    },

    autoGrow: function() {
      let element = document.getElementById('chat-msg-area');
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
    },
    onFilesInputChange(e) {
      let uploadedFiles = this.$refs.files.files;
      this.handleFilesUpload(uploadedFiles);
    },
    addFiles: function() {
      this.$refs.files.click();
    },
    handleFilesUpload(uploadedFiles){
      for( var i = 0; i < uploadedFiles.length; i++ ){

        let formatSize = this.formatBytes(uploadedFiles[i].size);
        uploadedFiles[i].formatSize = formatSize;

        let fObj = {
          file: uploadedFiles[i],
          progress: 0,
          fileUrl: null,
          isUrlActive: false,
          isDisabled: true
        };

        this.files.push(fObj);

        let formData = new FormData();
        formData.append('file', uploadedFiles[i]);

        this.isErrorUpload = false;

        Api.uploadFile(formData, (progressEvent) => {
          fObj.progress = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        })
        .then((ob) => {
          fObj.fileUrl = ob.fileUrl;
          fObj.isUrlActive = true;
          fObj.isDisabled = false;
        }).catch((reson) => {
          this.isErrorUpload = true;
        });
      }
    },
    removeFile( key ){
      this.files.splice( key, 1 );
    },
    formatBytes(bytes,decimals) {
      if(bytes == 0) return '0 Bytes';
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    inArray: function(needle) {
      let length = this.onlineUsers.length;
      for(let i = 0; i < length; i++) {
          if(this.onlineUsers[i] == needle) return true;
      }
      return false;
    },
    onUserLoggedEvent: function() {
      this.user = UserManager.user;
    },
    onRoomsLoadedEvent: function() {
      this.rooms = ChatManager.rooms;
    },
    onOnlineUsersChangeEvent: function(onlineUsers) {
      this.onlineUsers = onlineUsers;
    },
    onUnreadMessagesChangeEvent: function(counterUnreadMessages) {
      let directConversations = counterUnreadMessages.directConversations;
      let totalUnread = 0;
      directConversations.forEach((d) => {
        if (!this.employees.find(e => e.id == d.user)) {
          // prawdopodobnie ten user zostal usuniety z systemu, ale zostaly jakies nieprzeczytane wiadomosci od niego
          // dlatego oznaczamy je jako przeczytane poprzez ich pobranie
          ChatManager.getMessagesWithUser(d.user).then(a => {}).catch(e => {});
          return;
        }

        this.$set(this.unreadMessagesForUsers, d.user, d.count);
        totalUnread += d.count;
      });
      
      let roomsConversations = counterUnreadMessages.rooms;

      roomsConversations.forEach((d) => {
        this.$set(this.unreadMessagesForRooms, d.room, d.count);
        totalUnread += d.count;
      });
      if (totalUnread == 0) {
        this.isUnreadMessages = false;
      } else {
        this.isUnreadMessages = true;
      }
    },
    getEmployees: function() {
      EmployeeManager.getEmployees().then((employees) => {

        this.employees = employees;
        employees.forEach((e) => {
          this.employeesNames[e.id] = e.name;
        });
      });
    },
    deleteSelectedRoom: function(room) {
      let message = 'Are you sure you want to remove '+ room.name+ '? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        ChatManager.removeRoom(room).then((room) => {
        }).catch((reson) => {
        });
      }).catch(r => r);
    },
    addNewRoom: function() {
      if (!this.selectedRoom.name.length) {
        return;
      }
      this.inProgress = true;
      this.selectedRoom.users.push(UserManager.user.userId);
      
      ChatManager.addNewRoom(this.selectedRoom).then((room) => {
        this.inProgress = false;
        this.isActiveEditRoom = false;
        this.selectedRoom  = {name: '', isPublic: false, users: []};
      }).catch((reson) => {
        this.inProgress = false;
        console.error(reson);
      });
    },
    changeSelectedRoom: function() {
      if (!this.selectedRoom.name.length) {
        return;
      }
      this.inProgress = true;
      this.selectedRoom.users.push(UserManager.user.userId);
      
      ChatManager.editRoom(this.selectedRoom).then((room) => {
        this.inProgress = false;
        this.isActiveEditRoom = false;
        this.selectedRoom  = {name: '', isPublic: false, users: []};
      }).catch((reson) => {
        this.inProgress = false;
        console.error(reson);
      });
    },
    onChatManagerChangeEvent: function(message) {
      this.scrollToBottom();
    },
    onChatManagerCreateRoomEvent: function(room) {
    },
    onChatManagerChangeRoomEvent: function(room) {
    },
    getMsgSelectedRoom: function(id, name) {
      ChatManager.getMessagesInRoom(id).then((messages) => {
        ChatManager.currentMsgs = messages;
        this.messages = messages;
        this.setEmployeeToMessage(this.messages);
        this.modalName = name;
        this.isRoom = true;
        this.selectedID = id;
        this.showSingleModal(id);
      }).catch((reson) => {
        console.error(reson);
      });
    },
    getMessagesWithSelectedUser: function(id, name) {
      ChatManager.getMessagesWithUser(id).then((messages) => {
        ChatManager.currentMsgs = messages;
        this.messages = messages;
        this.setEmployeeToMessage(this.messages);
        this.modalName = name;
        this.isRoom = false;
        this.selectedID = id;
        this.showSingleModal(id);
      }).catch((reson) => {
        console.error(reson);
      });
    },
    setEmployeeToMessage: function(messages) {
      let empIds = this.employees.map(e => e.id+"");
      messages.forEach((m) => {
        let idx = empIds.indexOf(m.author+"");
        if (idx != -1) {
          m.authorName = this.employees[idx].name;
          m.emailAuthorMsg = this.employees[idx].email;
        }
      });
    },
    sendMessage: function() {
      if (this.chatMsg.length == 0) {return;}
      if (this.isRoom) {
        this.sendMessageToSelectedRoom();
      } else {
        this.sendMessageToSelectedUser();
      }
    },
    sendMessageToSelectedUser: function() {

      let filesUrl = [];
      for (var i = this.files.length - 1; i >= 0; i--) {
        filesUrl.push(this.files[i].fileUrl);
      }

      ChatManager.sendMessageToUser(this.selectedID, this.chatMsg.trim(), filesUrl).then((message) => {
        this.chatMsg = '';
        this.files = [];
        let element = document.getElementById('chat-msg-area');
        element.style.height = "10px";
      }).catch((reson) => {
        console.error(reson);
      });
    },
    sendMessageToSelectedRoom: function() {
      let filesUrl = [];
      for (var i = this.files.length - 1; i >= 0; i--) {
        filesUrl.push(this.files[i].fileUrl);
      }

      ChatManager.sendMessageToRoom(this.selectedID, this.chatMsg.trim(), filesUrl).then((message) => {
        this.chatMsg = '';
        this.files = [];
        let element = document.getElementById('chat-msg-area');
        element.style.height = "10px";
      }).catch((reson) => {
        console.error(reson);
      });
    },
    handleKeyUp: function(e) {
      if (e.keyCode == 112 && !this.isActive) {
        this.showModal();
      }

      if (e.keyCode == 27 && this.isActive) {
        this.closeModal();
      }
    },
    showModal: function() {
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isActiveSingleChat = false;
      ChatManager.currentConverstation = null;
      this.searchEmployees = '';
    },
    showSingleModal: function(id) {
      this.currentOpenChatWindow = id;
      ChatManager.setCurrentConverstation(id, this.isRoom);
      this.isActiveSingleChat = true;
      this.scrollToBottom();
      // setTimeout(() => {
      //   let objDiv = document.getElementById("sroll-chat");
      //   objDiv.scrollTop = objDiv.scrollHeight;
      //   this.isActiveSingleChat = true;
      // }, 400);

    },
    closeSingleModal: function() {
      this.isActiveSingleChat = false;
      ChatManager.currentConverstation = null;
    },
    scrollToBottom: function() {
      setTimeout(function() {
        let elem = document.getElementById("sroll-chat");
        elem.scrollTop = elem.scrollHeight;
      }, 200);
    },
    showModalRoom: function(room) {
      this.isActiveEditRoom = true;
      if (room) {
        this.isChangeRoom = true;
        this.selectedRoom = room;
      } else {
        this.isChangeRoom = false;
        this.selectedRoom  = {name: '', isPublic: false, users: []};
      }
    },
    closeModalRoom: function() {
      this.isActiveEditRoom = false;
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .chat-content {
    .text-sm { font-size: 10px;}
    code {
      border: 1px solid #e1e1e8;
      padding: 2px 4px;
    }
  }
  #sroll-chat {
    scroll-behavior: smooth;
  }
  #chat-msg-area {
    resize: none;
    overflow: hidden;
    height: 10px;
  }
  .p-r-xxl {
    padding-right: 4rem;
  }
  .pulse {
    position: absolute;
    top: 16px;
    left: 10px;
    display: inline-block;
    // position: relative;
    // top: -6px;
    // left: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #f05050;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(240, 80, 80, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(240, 80, 80, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 14px rgba(240, 80, 80, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(240, 80, 80, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(240, 80, 80, 0.4);
      box-shadow: 0 0 0 0 rgba(240, 80, 80, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 14px rgba(240, 80, 80, 0);
        box-shadow: 0 0 0 14px rgba(240, 80, 80, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(240, 80, 80, 0);
        box-shadow: 0 0 0 0 rgba(240, 80, 80, 0);
    }
  }
  .chat-dropdown {
    cursor: pointer;
  }
  .chat-dropdown:hover {
    background-color: #f6f8f8;
  }
  .custom-box-selected-emps {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid rgba(120, 130, 140, 0.3);
  }
  .edit-room {
    display: none;
  }
  .single-room:hover {
    .edit-room {
      display: block;
    }
  }
  .todisplay-chat {
    display: block;
    // background-color: rgba(0, 0, 0, 0.3);
    padding-top: 100px;
  }
  .custom-modal {
    .modal-content {
      border-radius: 0px;
    }
  }
  #chat {
    .input-group {
      width: 90%;
    }
  }
  #chat-list {
    .modal {
      top: none;

right: none;

bottom: none;

left: none;
    }
    .list-group-item {
      cursor: pointer;
    }
    .list-group-item:hover {
      background-color: #f6f8f8;
    }
  }
  .room {
    background-color: #fafbfc;
  }
  .custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;

  }
  .counter-chat-message {
    position: relative;
    left: 10px;
    top: 2px;
    min-width: 41px;
    b {
      position: relative;
      left: -8px;
      bottom: 8px;
      padding: .20em .4em;

    }
  }
</style>
