<template>
<div>
  <div id="forwad-ticket-modal" class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog" 
      v-bind:class="{ 'modal-md': selectedForm == 'signature', 'modal-sm': selectedForm == 'add', 'modal-sm': selectedForm == 'delete'}">
        <div class="modal-content">
          <div class="modal-body text-center p-lg">
            <form class="form-horizontal primary-color">
              <div class="form-group row" v-if="selectedForm == 'signature'">
                <label class="col-sm-4 form-control-label">{{ $t('mails.modalBoxes.signature') }}</label>
                <div class="col-sm-8">
                  <textarea class="form-control" rows="5" v-model="signature"></textarea>
                </div>  
              </div>
              <div class="form-group row" v-if="selectedForm == 'add'">
                <label class="col-sm-4 form-control-label">{{ $t('mails.modalBoxes.folderName') }}</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="newFolderName" style="max-height: 29px!important; min-height: 29px!important; margin-top: 0.5rem;">
                </div>
              </div>
              <div class="form-group row" v-if="selectedForm == 'add'">
                <label class="col-sm-4 form-control-label">{{ $t('mails.modalBoxes.parentFolder') }}</label>
                <div class="col-sm-8">
                  <select2 :options="optionsMailbox" v-model="subFolderName" class="form-control">
                  </select2>
                </div>
              </div>
              <div class="form-group row" v-if="selectedForm == 'delete'">
                <label class="col-sm-4 form-control-label">{{ $t('mails.modalBoxes.folderRemove') }}</label>
                <div class="col-sm-8">
                  <select2 :options="optionsMailbox" v-model="folderNameToDelete" class="form-control">
                  </select2>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm white none-radius pull-left" v-on:click="closeModal">
              <span class="primary-color _400">{{ $t('btnNames.cancel') }}</span>
            </button>
            <button v-if="selectedForm == 'add'" class="btn btn-sm white none-radius" v-on:click="addNewFolderToInbox">
              <span class="primary-color _400">{{ $t('btnNames.add') }}</span>
            </button>
            <button v-if="selectedForm == 'signature'" class="btn btn-sm white none-radius" v-on:click="seaveAccountSignature">
              <span class="primary-color _400">{{ $t('btnNames.save') }}</span>
            </button>
            <button v-if="selectedForm == 'delete'" class="btn btn-sm white none-radius" v-on:click="deleteFolderFromInbox">
              <span class="primary-color _400">{{ $t('btnNames.remove') }}</span>
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div>
  </div>

  <div v-if="userMailboxes.length > 0">
    <draggable  v-model="orderedAccountsIds" group="people" @start="drag=true" @end="drag=false" @change="onAccountOrderChanged">
      <div v-for="(m, index) in orderedAccounts" :key="index" v-if="m">
        <span v-if="m.status == 'ok'">
          <div class="box-header p-b-xs p-l-xs p-t-xs primary-color">

            <div class="pull-left">
              <a data-toggle="collapse" v-bind:href="'#mailbox'+m._id" aria-expanded="false" @click="onCollapseAccount(m._id)">
              <i class="material-icons md-24"></i>
              </a>
            </div>
            
            <p class=" _500 account-email" style="display: inline;">{{m.email}}</p>

            <div class="pull-right dropdown">
              <a class="text-transparent dropdown-toggle edit-mailbox" :title="$t('mails.titleEdit')" data-toggle="dropdown">
                <i class="material-icons md-16">edit</i>
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" v-on:click.prevent="showModal(m, 'signature')">{{ $t('mails.addSignature') }}</a>
                <a class="dropdown-item" href="#" v-on:click.prevent="showModal(m, 'add')">{{ $t('mails.addFolder') }}</a>
                <a class="dropdown-item" href="#" v-on:click.prevent="showModal(m, 'delete')">{{ $t('mails.deleteFolder') }}</a>
              </div>
            </div>

          </div>

          <div class="folders">
            <div class="collapse" v-bind:id="'mailbox'+m._id" v-bind:class="{'in': inArray(accountsCollapseInIds, m._id)}">

              <ul class="list-unstyled primary-color m-b-xs" v-if="m.boxes">
                <li class="mailbox-link inbox p-l-md" v-if="m.boxes.INBOX" v-on:click="getUserMailsForInbox(m._id, 'INBOX')" 
                  v-bind:class="{'active': 'INBOX'+m._id == selected}">
                  <i class="material-icons md-16"></i>
                  <span v-bind:class="{'text-green _500': m.boxes.INBOX.unseen > 0}"> 
                    Inbox <span v-if="m.boxes.INBOX.unseen > 0">({{m.boxes.INBOX.unseen}})</span>
                  </span>
                </li>
                <li class="mailbox-link p-l-md " v-if="m.boxes.Sent" v-on:click="getUserMailsForInbox(m._id, 'Sent')" 
                v-bind:class="{'active': 'Sent'+m._id == selected}">
                  <i class="material-icons md-16"></i>
                  <span v-bind:class="{'text-green _500': m.boxes.Sent.unseen > 0}"> 
                    {{ $t('mails.sent') }} <span v-if="m.boxes.Sent.unseen > 0">({{m.boxes.Sent.unseen}})</span>
                  </span>
                </li>
                <li class="mailbox-link p-l-md " v-if="m.boxes.Trash" v-on:click="getUserMailsForInbox(m._id, 'Trash')" 
                v-bind:class="{'active': 'Trash'+m._id == selected}">
                  <i class="material-icons md-16"></i>
                  <span v-bind:class="{'text-green _500': m.boxes.Trash.unseen > 0}"> 
                    {{ $t('mails.trash') }} <span v-if="m.boxes.Trash.unseen > 0">({{m.boxes.Trash.unseen}})</span>
                  </span>
                </li>
                <li class="mailbox-link p-l-md " v-if="m.boxes.Drafts" v-on:click="getUserMailsForInbox(m._id, 'Drafts')"
                v-bind:class="{'active': 'Drafts'+m._id == selected}">
                  <i class="material-icons md-16">drafts</i>
                  <span v-bind:class="{'text-green _500': m.boxes.Drafts.unseen > 0}"> 
                    {{ $t('mails.drafts') }} <span v-if="m.boxes.Drafts.unseen > 0">({{m.boxes.Drafts.unseen}})</span>
                  </span>
                </li>

                <div v-for="(b, key) in m.boxes" v-if="key != 'INBOX' && key != 'Sent' && key != 'Trash' && key != 'Drafts'">
                  <li class="mailbox-link"  
                    v-bind:class="{ 'p-l-md': b.children == null, 'active': key+m._id == selected}" v-on:click="getUserMailsForInbox(m._id, key)">

                    <i class="material-icons md-16"></i>
                    <span v-bind:class="{'text-green _500': m.boxes[key].unseen > 0}"> 
                      {{key}} <span v-if="m.boxes[key].unseen > 0">({{m.boxes[key].unseen}})</span>
                    </span>
                    <div class="pull-left" v-if="b.children != null">
                      <a data-toggle="collapse" v-bind:href="'#box'+key+m._id" aria-expanded="false" >
                      <i class="material-icons md-24"></i>
                      </a>
                    </div>
                  </li>
                  <div class="collapse" v-bind:id="'box'+key+m._id">
                    <ul class="list-unstyled primary-color m-b-xs">
                      <li class="mailbox-link p-l-lg" v-for="(c, childrenKey) in b.children" v-on:click="getUserMailsForInbox(m._id,childrenKey, key)" v-bind:class="{'active': childrenKey+m._id == selected}">
                        <i class="material-icons md-16"></i>
                        <span> 
                          {{childrenKey}}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                
              </ul>

            </div>
          </div>
        </span>

        <span v-else>
          <div class="box-header p-b-xs p-l-xs text-red">
            <p class=" _500" style="display: inline;">{{m.email}}</p>
            <div class="pull-left">
              <a data-toggle="collapse" v-bind:href="'#mailbox'+m._id" aria-expanded="false" aria-controls="mailbox">
              <i class="material-icons md-24"></i>
              </a>
            </div>
          </div>
          <div class="folders">
            <div class="collapse in" v-bind:id="'mailbox'+m._id">
              <ul class="list-unstyled text-red m-b-xs">
                <li class="p-l-md">{{ $t('mails.mailboxInactive') }} <span v-if="m.error && m.error.code">{{m.error.code}}</span></li>
                <li class="p-l-md text-small">{{ $t('mails.checkIsConfigure') }}</li>
              </ul>
            </div>
          </div>
        </span>
      </div>
    </draggable>
  </div>
  
</div>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import EmployeeManager from '../../EmployeeManager.js';
import UserManager from '../../UserManager.js'
import $ from 'jquery';
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
import draggable from 'vuedraggable'

export default {
  name: 'MailBoxes',
  props: {
    userMailboxes: Array,
  },
  components: {
    Select2,
    draggable
  },
  data: function () {
    return {
      messages: [],
      isActive: false,
      selected: null,
      newFolderName: '',
      subFolderName: '',
      optionsMailbox: [],
      selectedForm: '',
      folderNameToDelete: '',
      selectedMailboxId: '',
      orderedAccountsIds: [],
      accountsCollapseInIds: [],
      signature: '',
      statusInterval: null,
    }
  },
  created: function() {
    this.getOrderAccountById();
    this.getCollpaseInAccounts();

    this.statusInterval = setInterval(() => {

      for (var i = this.userMailboxes.length - 1; i >= 0; i--) {
        let account = this.userMailboxes[i];
        Api.getMailboxesStatus(account._id, 'INBOX').then((boxStatus) => {
          this.$set(account.boxes[boxStatus.name], 'unseen', boxStatus.messages.unseen);
          this.$forceUpdate();

          if (account.boxes[boxStatus.name].uidnext != boxStatus.uidnext) {
            EventBus.$emit('newMsgFound', {changedMailboxId:this.selectedMailboxId, changedFolderName: this.selectedFolderName});
          }

          account.boxes[boxStatus.name].uidnext = boxStatus.uidnext;
        }).catch((error) => {});
      }

      if (!this.selectedMailboxId || !this.selectedFolderName || this.selectedFolderName == 'INBOX') return;

      Api.getMailboxesStatus(this.selectedMailboxId, this.selectedFolderName).then((boxStatus) => {
        let idx = this.userMailboxes.map(b => b._id+"").indexOf(this.selectedMailboxId+"");
        if (idx != -1) {
          this.$set(this.userMailboxes[idx].boxes[boxStatus.name], 'unseen', boxStatus.messages.unseen);

          if (this.userMailboxes[idx].boxes[boxStatus.name].uidnext != boxStatus.uidnext) {
            EventBus.$emit('newMsgFound', {changedMailboxId:this.selectedMailboxId, changedFolderName: this.selectedFolderName});
          }

          this.userMailboxes[idx].boxes[boxStatus.name].uidnext = boxStatus.uidnext;
        }

        this.$forceUpdate();
       
      }).catch((error) => {});

    }, 15000);

    EventBus.$on('setMsgUnseenCouter', this.setMsgUnseenCouter);
  },
  destroyed: function() {
    clearInterval(this.statusInterval);
    EventBus.$off('setMsgUnseenCouter', this.setMsgUnseenCouter);
  },
  computed: {
    orderedAccounts: function() {
      let acc = this.orderedAccountsIds.map(id => this.getAccountById(id));
      for (let i = this.userMailboxes.length - 1; i >= 0; i--) {
        if (acc.indexOf(this.userMailboxes[i]) == -1) acc.push(this.userMailboxes[i]);
      }
      return acc;
      // return this.orderedAccountsIds.map(id => this.getAccountById(id));
    }
  },
  watch: {
    userMailboxes: function (val, oldVal) {
      for (let i = this.userMailboxes.length - 1; i >= 0; i--) {
        let id = this.userMailboxes[i]._id;
        let account = this.userMailboxes[i];

        Api.getMailboxesStatus(id, 'all').then((boxesStatus) => {
          for (let y = boxesStatus.length - 1; y >= 0; y--) {
            let boxName = boxesStatus[y].name;
            
            if (account.boxes[boxName]) {
              account.boxes[boxName].unseen = boxesStatus[y].messages.unseen
            }
          }
        }).catch((error) => {});

        // if (this.orderedAccountsIds.indexOf(id) == -1) this.orderedAccountsIds.push(id);
      }
    },
  },
  methods: {
    setMsgUnseenCouter: function() {
      if (!this.selectedMailboxId || !this.selectedFolderName) return;
      Api.getMailboxesStatus(this.selectedMailboxId, this.selectedFolderName).then((boxStatus) => {
        let idx = this.userMailboxes.map(b => b._id+"").indexOf(this.selectedMailboxId+"");
        if (idx != -1) {
          this.$set(this.userMailboxes[idx].boxes[boxStatus.name], 'unseen', boxStatus.messages.unseen);
        }

        this.$forceUpdate();
       
      }).catch((error) => {});
    },
    onCollapseAccount: function(id) {
      let idx = this.accountsCollapseInIds.indexOf(id);
      
      if (idx == -1) {
        this.accountsCollapseInIds.push(id);
      } else {
        this.accountsCollapseInIds.splice(idx, 1);
      }
      this.onCollapseAccountChange();
    },
    onCollapseAccountChange: function() {
      Api.putInStorage('collapsein_accounts_ids', JSON.stringify(this.accountsCollapseInIds)).then((boolean) => {
      }).catch((error) => {
      });
    },
    getCollpaseInAccounts: function() {
      Api.me.getFromStorage('collapsein_accounts_ids').then((res) => {
        try {
          let acc = [];
          let ids = JSON.parse(res);
          if (Array.isArray(ids)) acc = ids.filter(el => el != null);

          // console.log('collapsein_accounts_ids prosto ze storga', ids);
          // console.log('this.accountsCollapseInIds', this.accountsCollapseInIds);

          let idsMailbox = this.userMailboxes.map(b => b._id+"");
          // console.log('idsMailbox', idsMailbox);

          for (var i = 0; i < acc.length; i++) {

            let idx = idsMailbox.indexOf(acc[i]+"");
            if (idx != -1) this.accountsCollapseInIds.push(acc[i]);
          }

          // console.log('this.accountsCollapseInIds ostatecznie', this.accountsCollapseInIds);

        } catch (e) {
          console.error(e);
        }
      }).catch((error) => {
      });
    },
    getOrderAccountById: function() {
      Api.me.getFromStorage('order_accounts_ids').then((res) => {
        try {
          let ids = JSON.parse(res);
          
          // console.log('order_accounts_ids porsto ze storage', ids);

          let acc = [];
          if (Array.isArray(ids)) acc = ids.filter(el => el != null);

          let idsMailbox = this.userMailboxes.map(b => b._id+"");
          // console.log('idsMailbox', idsMailbox);

          let orderded = [];
          for (var i = 0; i < acc.length; i++) {
            let idx = idsMailbox.indexOf(acc[i]+"");
            if (idx != -1) {
              orderded.push(acc[i])
            }
          }
          // console.log('ordered po pierwszej iteracji', orderded);
          if (idsMailbox.length > orderded.length) {
            // console.log('idMailbox jest wieksze niz orderded')
            for (var i = 0; i < idsMailbox.length; i++) {
              
              let idx = orderded.indexOf(idsMailbox[i]);
              if (idx == -1) orderded.push(idsMailbox[i]);
            }
            // console.log('ordered po drugiej iteracji', orderded);
          }
          this.orderedAccountsIds = orderded;
          
        } catch (e) {}
      }).catch((error) => {
      });
    },
    onAccountOrderChanged: function() {
      Api.putInStorage('order_accounts_ids', JSON.stringify(this.orderedAccountsIds)).then((boolean) => {
      }).catch((error) => {
      });
    },
    getAccountById: function(id) {
      for (var i = this.userMailboxes.length - 1; i >= 0; i--) {
        if (this.userMailboxes[i]._id == id) return this.userMailboxes[i];
      }
      return null;
    },
    showModal: function(mailbox, form) {
      this.optionsMailbox = [{id: '', text: 'None'}];
      for (let boxName in mailbox.boxes) {
        this.optionsMailbox.push({
          id: boxName,
          text: boxName,
        });

        if (form == 'delete' && mailbox.boxes[boxName].children) {
          for (let childrenBoxName in mailbox.boxes[boxName].children) {
            let n = boxName+'.'+childrenBoxName;
            this.optionsMailbox.push({
              id: n,
              text: n,
            });
          }
        }
      };
      this.selectedForm = form;
      this.selectedMailboxId = mailbox._id;
      this.signature = '';
      if (mailbox.usersSignatures && mailbox.usersSignatures[UserManager.user.userId]) this.signature = mailbox.usersSignatures[UserManager.user.userId];
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.newFolderName = '';
      this.optionsMailbox = [];
      this.selectedForm = '';
      this.folderNameToDelete = '';
      this.selectedMailboxId = '';
    },
    getUserMailsForInbox: function(id, name, parent) {
      this.selected = name+id;
      if (parent) name = parent+'.'+name;

      this.selectedMailboxId = id;
      this.selectedFolderName = name;

      this.$emit('mailbox-click', {id, name});
    },
    deleteFolderFromInbox: function() {
      Api.deleteFolderFromInbox(this.selectedMailboxId, this.folderNameToDelete).then((boolean) => {
        EventBus.$emit('folderMailboxChange');
        this.closeModal();
        this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess'));
      }).catch((error) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.removeError'));
      });
    },
    addNewFolderToInbox: function() {
      let name = this.newFolderName;
      if (this.subFolderName) {name = this.subFolderName+'.'+this.newFolderName}

      Api.addNewFolderToInbox(this.selectedMailboxId, name).then((boolean) => {
        EventBus.$emit('folderMailboxChange');
        this.closeModal();
        this.showNotify('notification-success', this.$i18n.t('notifications.addSuccess'));
      }).catch((error) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.addError'));
      });
    },
    seaveAccountSignature: function() {
      Api.addSignatureForMailboxes(this.selectedMailboxId, this.signature).then((account) => {
        this.closeModal();

        let acc = this.getAccountById(account._id);
        if (acc) acc.usersSignatures = account.usersSignatures;

        this.showNotify('notification-success', this.$i18n.t('notifications.addSuccess'));
      }).catch((error) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.addError'));
      });
    },
    inArray: function(stack, needle) {
      if (!Array.isArray(stack)) return false;
      let length = stack.length;
      for(let i = 0; i < length; i++) {
          if(stack[i] == needle) return true;
      }
      return false;
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.edit-mailbox.dropdown-toggle::after {
  content: none;
}
.text-transparent {
  color: #efefef;
}
.text-transparent:hover {
  color: #58666e;
}
.mailbox-link:hover {
  background-color:#E2E8EE;
  cursor: pointer;
}
.mailbox-link.active {
  background-color: #cbd0d6;
}
.account-email {
  cursor: pointer;
}
.collapse.in {
  height: auto !important;
}

</style>
