<template>
<div>
  <div class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-body text-center p-lg">
            <div class="form-group row">
              <div class="col-sm-12">
                <input class="form-control custom-input" type="text" v-model="title" :placeholder="$t('ticket.tableBtn.modal.subject')">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <input class="form-control custom-input" type="email" v-model="email" :placeholder="$t('ticket.tableBtn.modal.cEmail')">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <input class="form-control custom-input" type="text" v-model="name" :placeholder="$t('ticket.tableBtn.modal.cName')">
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-sm white none-radius pull-left" v-on:click="closeModal" v-bind:class="{ disabled: inProgress }">
              <span class="primary-color _400">{{ $t('btnNames.cancel') }}</span>
            </button>
            <button class="btn btn-sm white none-radius" 
            v-on:click="addNewTicket" v-bind:class="{ disabled: inProgress }" >
              <span class="primary-color _400">{{ $t('btnNames.add') }}</span>
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div>
    </div>


  <div class="checkbox m-r inline">
    <label class="ui-check text-xs">
      <input type="checkbox">
      <i class="dark-white"></i>
    </label>
  </div>
  <button class="btn btn-sm white none-radius hidden-sm-down">
    <span class="primary-color _400">{{ $t('ticket.tableBtn.merge') }}</span>
  </button>&nbsp;
  <button class="btn btn-sm white none-radius hidden-sm-down" v-on:click="linkSelectedTickets($event)">
    <span class="primary-color _400" >{{ $t('ticket.tableBtn.linked') }}</span>
  </button>&nbsp;
  <button class="btn btn-sm white none-radius hidden-sm-down" v-on:click="closeSelectedTickets($event)">
    <span class="primary-color _400">{{ $t('ticket.tableBtn.close') }}</span>
  </button>&nbsp;
  <button class="btn btn-sm white none-radius" v-on:click="removeSelectedTickets($event)" v-bind:class="{'disabled': !UserManager.permissions.find(element => element == 'tickets.delete')}">
    <span class="primary-color _400">{{ $t('ticket.tableBtn.delete') }}</span>
  </button>&nbsp;

  <button class="btn btn-sm white none-radius" v-on:click.prevent="showModal" v-bind:class="{'disabled': !UserManager.permissions.find(element => element == 'tickets.create')}">
    <span class="primary-color _400">{{ $t('ticket.tableBtn.add') }}</span>
  </button>&nbsp;
  <div class="pull-right hidden-md-down">
    <div class="nav-item inline dropdown">
      <a class="nav-link btn btn-sm white none-radius" data-toggle="dropdown" aria-expanded="false">
        <i class="material-icons md-18 text-color"></i>
      </a>

      <div class="dropdown-menu dropdown-menu-scale pull-right">
        <p class="text-muted text-center">{{ $t('ticket.tableBtn.chooseColumn') }}</p>
        <div class="list-group list-group-alt">
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="id" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               Id
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="email" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.cEmail') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="name" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
                {{ $t('ticket.tableBtn.cName') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="subject" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
                {{ $t('ticket.tableBtn.subject') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="assignee" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.assignee') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="status" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.status') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="priority" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.priority') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="type" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.type') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="tags" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.tags') }}
            </label>
          </li>
          <li class="checkbox list-item">
            <label class="ui-check text-xs ">
              <input type="checkbox" value="date" v-on:change="$emit('selection-changed', columns)" v-model="columns">
              <i class="dark-white"></i>
               {{ $t('ticket.tableBtn.createDate') }}
            </label>
          </li>
        </div>
      </div>
    </div>
</div> 
</div>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import Api from '../../Api.js';
import UserManager from '../../UserManager.js';
import EmployeeManager from '../../EmployeeManager.js';

export default {
  name: 'BtnGroupList',
  props: {
    selectedColumns: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      columns: [],
      isActive: false,
      inProgress: false,
      title: '',
      email: '',
      name: '',
      textError:this.$i18n.t('notifications.textError'),
      UserManager: UserManager
    }
  },
  created: function() {
    this.selectedColumns.forEach(s => {
      this.columns.push(s);
    });
  },
  methods: {
    linkSelectedTickets: function (event) {
      event.preventDefault();
      EventBus.$emit('link-selected-tickets');
    },
    closeSelectedTickets: function (event) {
      event.preventDefault();
      EventBus.$emit('close-selected-tickets');
    },
    removeSelectedTickets: function(event) {
      event.preventDefault();
      EventBus.$emit('remove-selected-tickets');
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
    showModal: function() {
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.title = '';
      this.email = '';
      this.name = '';
    },
    addNewTicket: function() {
      this.inProgress = true;
      Api.createNewTicket(this.email, this.name, this.title).then((ticket) => {
        this.inProgress = false;
        this.$router.push({ path: `/ticket/${ticket.id}`});
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.addError'));
        this.closeModal();
        this.inProgress = false;
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
