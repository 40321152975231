<template>
    <div class="box">
        <div class="box-header">
            <h3>Dodaj nową integrację</h3>
            <small>Każda wiadomość, która trafi na ten komunikator<br/>zostanie automatycznie zamieniona na zgłoszenie </small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <div class="form-group">
                    <label for="exampleInputEmail1">Wybierz komunikator</label>
                    <select2 :options="availableIntegrations" class="form-control" v-model="type">
                    </select2>
                </div>

                <div class="box box-body" v-if="type == 'fb'">
                    <p>
                        Po kliknięciu <strong>Dalej</strong> zostanie wyświetlone okienko popup, w którym będziesz mógł zalogować się na swoje
                        konto FB i wybrać strony, które chcesz zintegrować z Swiftresponse
                    </p>
                </div>
                <div class="form-group" v-if="type == 'ig'">
                    <label for="exampleInputEmail1">Nazwa użytkownika</label>
                    <input class="form-control" type="text" v-model="ig.username">
                </div>

                <div class="form-group" v-if="type == 'ig'">
                    <label for="exampleInputEmail1">Hasło</label>
                    <input class="form-control" type="password" v-model="ig.pass">
                    <span v-if="status == 'wrong-pass'" class="help-block text-sm m-b-none text-muted">Błędne hasło</span>
                </div>

                <div class="form-group warn" v-if="type == 'ig' && status == 'need-verification'">
                    <label>Kod do weryfikacji</label>
                    <input class="form-control " type="text" v-model="ig.securityCode">
                </div>

                <div class="form-group" v-if="type == 'ig'">
                    <label for="exampleInputEmail1">Od kiedy pobrać wiadomości</label>
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger"
                            :placeholder=" $t('ticket.filters.chooseDate')"
                            :value="ig.startFrom"
                        >

                        <AirbnbStyleDatepicker
                            style="bottom: 32px; left: 32px; top: unset; box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);"
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'single'"
                            :date-one="ig.startFrom"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @date-one-selected="val => { ig.startFrom = val }"
                        />
                    </div>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            <a class="btn btn-sm white none-radius pull-right" @click="create">Dodaj</a>
            <a class="btn btn-sm white none-radius" @click="$emit('close')">Anuluj</a>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import Select2 from '../../Select2.vue';
import {RotateSquare2} from 'vue-loading-spinner';

let messageHandler = null;

export default {
    name: 'AddIntegrationModal',
    props: {

    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            availableIntegrations: [
                { id: 'ig', text: 'Instagram' },
                { id: 'fb', text: 'Facebook (Messenger)' },
            ],
            type: '',
            ig: {
                startFrom: new Date()
            },
            isProcessing: false,
            status: ''
        }
    },
    created: function() {
        let _vueComp = this;
        messageHandler = function (ev) {
            if (ev.data == 'closing') {
                window.removeEventListener("message", messageHandler);
                _vueComp.$emit('close');
            }
        }
    },
    destroyed: function() {
        if (messageHandler) window.removeEventListener("message", messageHandler);
    },
    computed: {
    },
    methods: {
        create: async function () {
            if (!this.type) {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: 'Nie podano typu integracji 🤔',
                });
                return false;
            }

            if (this.type == 'fb') {
                this.isProcessing = true;
                if (messageHandler) window.removeEventListener("message", messageHandler);

                Api.fb.getRegisterUrl().then(res => {
                    // w przypadku blokady popup jest nullem
                    let popup = window.open(res.url, "FB account connecting", 'height=400,width=400');
                    if (popup && window.focus) popup.focus();

                    // nasluchujemy na zamkniecie okna
                    window.addEventListener("message", messageHandler, false);
                });
            }

            if (this.type == 'ig') {
                this.isProcessing = true;
                try {
                    let box = await Api.tickets.updateIGIntegration(this.ig);
                    if (box && box.status == 'wrong-pass') {
                        this.status = box.status;
                    } else if (box && box.status == 'need-verification') {
                        this.status = box.status;
                    } else {
                        this.$emit('close');
                    }
                } catch (error) {
                    this.$notify({
                        group: 'global',
                        type: 'notification-error',
                        text: 'Niestety nie udało się utworzyć nowej integracji z Instagramem 😭',
                    });                
                }
                this.isProcessing = false;
            }
            
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
