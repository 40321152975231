import UserManager from './UserManager.js';
import EventEmitter from 'events';
import Api from './Api.js';
import SystemSocket from './SystemSocket.js'

let ding = require("./assets/sounds/sound3.wav")

class MessengerManager {
    constructor() {
      this.events = new EventEmitter();
      this.headerConversations = [];
      this.selectedHeaderCoversation = null;


      SystemSocket.socket.on('fb.message', (msg) => {
        this.checkNewMessage(msg);
        // this.events.emit('newMessage', msg);
      });

      SystemSocket.socket.on('fb.page.created', (page) => {
        this.events.emit('page_changed', page);
      });

      SystemSocket.socket.on('fb.page.deleted', (page) => {
        this.events.emit('page_changed', page);
      });
    }

    getLastConversations() {
      return Api.fb.getLastConversations().then((headerConversations) => {
        this.headerConversations = headerConversations;
        return headerConversations;
      });
    }

    getMessagesConversations(pageId, cId) {
      return Api.fb.getMessagesConversations(pageId, cId).then((data) => {
        this.selectedHeaderCoversation = this.headerConversations.filter(el => el.id == cId)[0];
        return data;
      })
    }

    sendMessage(pageId, senderId, message) {
      return Api.fb.sendMessage(pageId, senderId, message).then((data) => {
        return data;
      })
    }

    checkNewMessage(newMessage) {
      let recipient = newMessage.to.data[0].id;
      // jesli odiorca (recipient) jest firma to recipient musi sie zgadzac z page id
      let idx = this.headerConversations.map(c => { return c.pageId+""}).indexOf(recipient+"");
      // odbiorca jest firma
      if (idx != -1) {
        this.setNewMessage(idx, 'weGet', newMessage);
      }
      // idx jest rowny -1 wiec odbiorca (reciptient) jest klientem, a sender to my
      let senderId = newMessage.from.id;
      // szukamy konwersacji do kotrej wysłaiśmy wiadomosc
      let index = this.headerConversations.map(c => { return c.pageId+""}).indexOf(senderId+"");
      if (index != -1) {
        this.setNewMessage(index, 'weSent', newMessage);
      }
    }

    setNewMessage(idx, infoText, newMessage) {
      let conversationWithNewMessage = this.headerConversations[idx];
       // jesli nie ma selectedHeaderConversations to po prostu nie jest zadna właczona
      if (!this.selectedHeaderCoversation) {
        if (infoText == 'weSent') return;
        this.playSound();
        this.events.emit('newMessageOnlyCount', idx)
        return;
      }

      let senderId = this.selectedHeaderCoversation.participants.data.
      filter(el => el.id != this.selectedHeaderCoversation.pageId)[0].id;
      // /jest właczona kjonweracja do koterej wpadła nowa wiadomość my ja otrzymalismy
      if (infoText == "weGet" && this.selectedHeaderCoversation.pageId == conversationWithNewMessage.pageId 
        && senderId == newMessage.from.id) {
        
        this.playSound();
        this.events.emit('newMessage', {msg:newMessage, idx: idx})
      }

      // jest otwarta konwersjaca i my ja wysłalismy
      if (infoText == "weSent" && conversationWithNewMessage.pageId == this.selectedHeaderCoversation.pageId) {
        
        this.events.emit('newMessage', {msg:newMessage})
      }
    }

    playSound() {
      let audio = new Audio(ding); // buffers automatically when created
      var playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.then(function () {
        }).catch(function (error) {
            console.log('Failed to play....' + error);
        });
      }
    }
}

const instance = new MessengerManager();

export default instance;