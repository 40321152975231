<template>
    <div class="box">
        <div class="box-header">
            <h3>Użytkownik <span v-if="editedUser.name">{{ editedUser.name }}</span></h3>
            <small v-if="!editedUser._id">Dodawanie nowego użytkownika</small>
            <small v-else>Edycja użytkownika</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li v-if="editedUser._id" class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li v-else class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="create()">
                        <i class="material-icons md-18">person_add</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <div class="form-group">
                    <label>Nazwa użytkownika</label>
                    <input class="form-control" type="text" v-model="editedUser.name">
                </div>

                <div class="form-group">
                    <label>Email użytkownika</label>
                    <input class="form-control" type="text" v-model="editedUser.email">
                </div>

                <div class="form-group">
                    <label>Hasło</label>
                    <input class="form-control" type="text" v-model="editedUser.password">
                </div>

                <div class="form-group">
                    <label class="md-check m-t">
                        <input type="checkbox" v-model="isAdmin">
                        <i class="primary m-r-xs"></i>
                        <span class="m-l-xs">Konto admina</span>
                    </label>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            <a v-if="editedUser._id" href="#" class="btn btn-sm success none-radius" @click.prevent="save()">Zapisz</a>
            <a v-else href="#" class="btn btn-sm success none-radius" @click.prevent="create()">Dodaj</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Anuluj</a>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';
import EmployeeManager from '../../../EmployeeManager.js';

export default {
    name: 'EmployeeModal',
    props: {
        user: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            editedUser: {
                roles: [],
                permissions: [
                    'tickets.create',
                    'tickets.assignment.change',
                    'tickets.delete',
                    'tickets.reply',
                    'tickets.comments.create',
                    'tickets.comments.read',
                ]
            },
            selectedGroups: [],
            isProcessing: false,
            isAdmin: false
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (this.user) this.editedUser = JSON.parse(JSON.stringify(this.user)); //deep-copy
        if (this.editedUser.roles.indexOf("admin") > -1) this.isAdmin = true;

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },

    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                e.stopPropagation();
                this.$emit('close');
                return false;
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },

        remove: async function (s) {
            
        },

        create: async function (s) {
            this.isProcessing = true;

            this.editedUser.roles = [];
            if (this.isAdmin) {
                this.editedUser.roles = ["admin"];
                this.editedUser.permissions.push("system.settings");
                this.editedUser.permissions.push("raport.access");
            }

            try {
                this.editedUser = await Api.users.create(this.editedUser);
                this.$n.success(this.$i18n.t('notifications.addSuccess'));
                EmployeeManager.invalidate();
                this.$emit('close');
            } catch (error) {
                this.$n.error(this.$i18n.t('notifications.addError'));
            }
            this.isProcessing = false;
        },

        save: async function (s) {
            this.isProcessing = true;

            this.editedUser.roles = [];
            if (this.isAdmin) {
                this.editedUser.roles = ["admin"];
            }

            try {
                this.editedUser = await Api.users.save(this.editedUser._id, this.editedUser);
                EmployeeManager.invalidate();
                this.$n.success(this.$i18n.t('notifications.changeSuccess'));
                this.$emit('close');
            } catch (error) {
                this.$n.error(this.$i18n.t('notifications.changeError'));
            }
            this.isProcessing = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.widget-code {
    min-height: 150px;
    font-family: monospace;
    font-size: 10px;
}
</style>
