<template>
    <span>
        <img title="email" v-if="type == 'email'" class="m-l-xs" :style="styles" src="../assets/images/message.svg">
        <img title="instagram" v-else-if="type == 'ig'" class="m-l-xs" :style="styles" src="../assets/images/instagram.svg">
        <img title="facebook" v-else-if="type == 'fb'" class="m-l-xs" :style="styles" src="../assets/images/facebook_green.svg">
        <img title="livechat" v-else-if="type == 'chat'" class="m-l-xs" :style="styles" src="../assets/images/conversation.svg">
        <img title="email" v-else class="m-l-xs" :style="styles" src="../assets/images/message.svg">
        <span v-if="withTitle">&nbsp;{{ title }}</span>
    </span>
</template>

<script>
export default {
    name: "TicketChannelIcon",
    props: {
        type: String,
        size: String,
        withTitle: Boolean
    },

    computed: {
        styles: function() {
            return {
                width: this.size ? (this.size+'px') : '16px'
            }
        },

        title: function() {
            if (this.type == 'email') return "Email";
            else if (this.type == 'ig') return "Instagram";
            else if (this.type == 'fb') return "Facebook";
            else if (this.type == 'chat') return "LiveChat";
            else return "Nieznane...";
        }
    }
}
</script>

<style>

</style>