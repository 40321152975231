<template>
    <div>
        <h6 class="title-color _600 inline">Sposób rozdzielania połączeń</h6>
        <hr/>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b">
                    <label class="col-md-4 form-control-label label-sm m-t-sm">Wybierz sposób</label>
                    <div class="col-md-8">
                        <v-select 
                            class="form-control"
                            :options="startegy"
                            label="name"
                            >
                        </v-select>
                    </div>
                </div>
                <hr/>
                <ul class="nav nav-pills nav-stacked nav-sm m-t m-b">
                    <li class="nav-item">
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-primary"></i>
                            <span class="_600">ringall</span> dzwoni jednocześnie do wszystkich dostępnych agentów dopóki jeden nie odbierze
                        </a>
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-info"></i>
                            <span class="_600">leastrecent</span>  dzwoni do agenta, do którego skierowane było ostatnie połączenie
                        </a>
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-warn"></i>
                            <span class="_600">fewestcalls</span> dzwoni do najmniej obciążonego agenta biorąc pod uwagę odebrane połączenia
                        </a>
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-accent"></i>
                            <span class="_600">random</span>  dzwoni do losowo wybranego agenta
                        </a>
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-success"></i>
                            <span class="_600">rrmemory</span> dzwoni w pętli do kolejnych dostępnych agentów zaczynając od agenta będącego następcą agenta, który odebrał ostatnie połączenie
                        </a>
                        <a class="nav-link">
                            <i class="fa m-r-sm fa-circle text-warning"></i>
                            <span class="_600">linear</span> dzwoni w pętli do kolejnych dostępnych agentów, kolejność agentów wyznaczana jest przez priorytet, jaki dany agent posiada
                        </a>
                    </li>
                </ul>
            </form>
            <a  class="text-info" @click.prevent="remove">Usuń wezeł</a>
        </div>
        <hr/>
    </div>
</template>
<script>
export default {
    name: 'NodeUser',
    props: {
        
    },
    components: {
        
    },
    data: function () {
        return {
            startegy: [
                {id: 1, name:"ringall"}, 
                {id: 2, name:"leastrecent"}, 
                {id: 3, name:"fewestcalls"},
                {id: 4, name:"random"},
                {id: 5, name:"rrmemory"},
                {id: 6, name:"linear"}
            ],
        }
    },
    created: function() {
    },
    
    methods: {
        remove: function() {}
       
    }
}
</script>