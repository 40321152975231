<template>
  <li class="nav-item dropdown m-l-xs m-r-sm text-color">
    <a href="" class="nav-link" data-toggle="dropdown">
      <i class="material-icons" v-bind:class="{'text-red': counter > 0}">track_changes</i> 
      <span class="label rounded label-sm up warn" v-if="counter > 0" v-bind:class="{'pulse': counter > 0}">{{counter}}</span>
      <span class="label up p-a-0 danger" v-else></span>
    </a>

    <div class="dropdown-menu pull-right w-xl animated fadeInUp no-bg no-border no-shadow" v-if="notifications.length > 0">

      <div class="scrollable notifications-box">

        <ul class="list-group list-group-gap m-a-0">
          <div v-for="n in sortedNotifications" :key="n._id">
            <li class="notif-item list-group-item black lt box-shadow-z0 b" 
            v-if="n.data.code=='mention_in_comment' && n.isSeen == false">
              <span class="pull-right">
                <a class="seen-btn" :title="$t('header.notifications.mark')" v-on:click="markAsSeen($event, n._id)">
                  <i class="material-icons md-18">check</i>
                </a>
              </span> 
              <span class="clear block" >
                {{$t('header.notifications.textMentioned')}}:
                <a href="#" class="text-primary" v-on:click="goToTicketDetails($event, n.data.objectId)">#{{n.data.objectId}}</a> 
                <br><small class="text-muted">{{n.createdAt| moment('from')}}</small>
              </span>
            </li>

            <li class="notif-item list-group-item black lt box-shadow-z0 b" 
            v-else-if="n.data.code=='mention_in_comment_reminder' && n.isSeen == false">
              <span class="pull-right">
                <a class="seen-btn" :title="$t('header.notifications.mark')" v-on:click="markAsSeen($event, n._id)">
                  <i class="material-icons md-18">check</i>
                </a>
              </span> 
              <span class="clear block" >
                {{$t('header.notifications.textReacted')}}:
                <a href="#" class="text-primary" v-on:click="goToTicketDetails($event, n.data.objectId)">#{{n.data.objectId}}</a> 
                <br><small class="text-muted">{{n.createdAt| moment('from')}}</small>
              </span>
            </li>

          </div>
        </ul>
      </div>

    </div>

  </li>
</template>

<script>
import UserManager from '../../UserManager.js';
import Api from '../../Api.js';

export default {
  name: 'CommentNotifications',
  props: {
  },
  components: {
    
  },
  data: function () {
    return {
      notifications: [],
    }
  },
  created: function() {
    UserManager.events.on('notification_changed', this.onUserManagerChangeEvent);

    // ubezpiecznie na wypadek kiedy jest juz po pierwszym evencie
    if (UserManager.notifications.length) {
      this.onUserManagerChangeEvent(UserManager.notifications);
    }
  },
  destroyed: function() {
    UserManager.events.removeListener('notification_changed', this.onUserManagerChangeEvent);
  },
  computed: {
    counter: function () {
      return this.notifications.filter(n => {
        return (n.data.code == 'mention_in_comment_reminder' || n.data.code == 'mention_in_comment') && !n.isSeen
      }).length;
    },

    sortedNotifications: function () {
      return this.notifications.map(n => {
        // zamieniemy objectId na string ze poniewaz stare mysql'owe sa liczbami
        if (n.data && n.data.objectId) n.data.objectId = n.data.objectId+'';
        return n;
      }).sort(function(a, b) {
        var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    }
  },
  methods: {
    onUserManagerChangeEvent: function(notifications) {
      this.notifications.length = 0;
      notifications.forEach(n => this.notifications.push(n));
    },
    goToTicketDetails: function(event, ticketId) {
      event.preventDefault();
      this.$router.push({ path: `/ticket/${ticketId}`});
    },
    markAsSeen: function(event, id) {
      event.preventDefault();
      event.stopPropagation();
      Api.notificationIsSeen(id).then((data) => {});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pulse {
    background: #f05050;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(240,80,80, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 14px rgba(240,80,80, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(240,80,80, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
      box-shadow: 0 0 0 0 rgba(240,80,80, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 14px rgba(240,80,80, 0);
        box-shadow: 0 0 0 14px rgba(240,80,80, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(240,80,80, 0);
        box-shadow: 0 0 0 0 rgba(240,80,80, 0);
    }
  }
.notifications-box {
  max-height: 400px;
}
.notif-item {
  .seen-btn {
    opacity: 0;
  }
}
.notif-item:hover {
  .seen-btn {
    opacity: 1;
  } 
}
</style>
