<template>
<div class="ticket-filters">
  <div class="box primary-color">
    <div class="box-header">
      <p class="primary-color _500" style="display: inline;">{{ $t('ticket.filters.assignees') }}</p>
      <div class="pull-right text-muted text-xs">
        <a data-toggle="collapse" href="#assignees" aria-expanded="false" aria-controls="assignees">
        <i class="fa fa-plus"></i>
      </a>
      </div>
    </div>
    <div class="collapse" id="assignees">
      <div class="form-group l-h m-a-0">
        <div class="input-group" style="width: 100%;">
          <input style="border-left: none; border-right: none;" class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.filters.searchAss')" type="text" v-model="searchEmployees">
        </div>
      </div>
      <div class="box-body">
        <ul class="list-unstyled m-t-n-sm">
          <div v-if="employees.length > 0">

            <li class="checkbox" >
              <label class="ui-check text-xs ">
                <input type="checkbox" v-on:change="onFilterChange"  v-model="checkedAssignees" v-bind:value="null">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.notAss') }}
              </label>
            </li>

            <div v-for="a in employees"
            :attach="a"
            :key="a.id"
            v-show="!searchEmployees.length || a.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1">
              <li class="checkbox" >
                <label class="ui-check text-xs ">
                  <input type="checkbox" v-on:change="onFilterChange"  v-model="checkedAssignees" v-bind:value="a.id">
                  <i class="dark-white"></i>
                    {{a.name}}
                </label>
              </li>
            </div>

          </div>
          <div v-else class="text-muted">{{ $t('ticket.filters.notEmps') }}</div>
        </ul>
        <p class="primary-color _500">{{ $t('ticket.filters.groups') }}</p>
        <ul class="list-unstyled m-t-n-sm">
          <li class="checkbox">
            <label class="ui-check text-xs ">
              <input type="checkbox" v-on:change="onFilterChange" value="1" v-model="checkedAssigneesGroup" >
              <i class="dark-white"></i>
                Design Support
            </label>
          </li>
          <li class="checkbox">
            <label class="ui-check text-xs ">
              <input type="checkbox" v-on:change="onFilterChange" value="2" v-model="checkedAssigneesGroup" >
              <i class="dark-white"></i>
                Product Support
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="box-body">
      <p class="primary-color _500">{{ $t('ticket.filters.com_channel.title') }}</p>
      <ul class="list-unstyled m-t-n-sm">
        <div class="row primary-color">
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="ig" v-model="checkedComunicationChannel">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.com_channel.ig') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="fb" v-model="checkedComunicationChannel">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.com_channel.fb') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="email" v-model="checkedComunicationChannel">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.com_channel.email') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="chat" v-model="checkedComunicationChannel">
                <i class="dark-white"></i>
                  Live chat
              </label>
            </li>
          </div>
        </div>
      </ul>

      <p class="primary-color _500">{{ $t('ticket.filters.status') }}</p>
      <ul class="list-unstyled m-t-n-sm">
        <div class="row primary-color">
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="new" v-model="checkedStatus">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.new') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="open" v-model="checkedStatus">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.open') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="pending" v-model="checkedStatus">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.pending') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="closed" v-model="checkedStatus">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.closed') }}
              </label>
            </li>
          </div>
        </div>
      </ul>
      <p class="primary-color _500">{{ $t('ticket.filters.priority') }}</p>
      <ul class="list-unstyled m-t-n-sm">
        <div class="row primary-color">
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="low" v-model="checkedPriority">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.low') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="medium" v-model="checkedPriority">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.medium') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="hight" v-model="checkedPriority">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.hight') }}
              </label>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div class="box m-b-sm">
    <div class="box-body">

      <p class="primary-color _500">{{ $t('ticket.filters.type') }}</p>
      <ul class="list-unstyled m-t-n-sm">
        <div class="row primary-color">
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="question" v-model="checkedType">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.question') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="problem" v-model="checkedType">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.problem') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="task" v-model="checkedType">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.task') }}
              </label>
            </li>
          </div>
          <div class="col-xl-6 c-rwd">
            <li class="checkbox">
              <label class="ui-check text-xs">
                <input type="checkbox" v-on:change="onFilterChange" value="spam" v-model="checkedType">
                <i class="dark-white"></i>
                  {{ $t('ticket.filters.spam') }}
              </label>
            </li>
          </div>
        </div>
      </ul>

      <p class="primary-color _500">{{ $t('ticket.filters.createDate') }}</p>
      <div class="input-group">
        <div class="datepicker-trigger">
          <input
            class="form-control form-control-sm p-x b-a"
            type="text"
            id="datepicker-trigger"
            :placeholder=" $t('ticket.filters.chooseDate')"
            :value="formatDates(dateOne, dateTwo)"
          >

          <AirbnbStyleDatepicker
            :trigger-element-id="'datepicker-trigger'"
            :mode="'range'"
            :date-one="dateOne"
            :date-two="dateTwo"
            :monthsToShow="1"
            :showShortcutsMenuTrigger="false"
            v-on:closed="onFilterChange"
            @date-one-selected="val => { dateOne = val }"
            @date-two-selected="val => { dateTwo = val }"
          />
        </div>
      </div>

      <p class="primary-color _500 m-t">{{ $t('ticket.filters.ticketTags') }}</p>
      <select2 :options="optionsTags" class="form-control select2-filter" v-model="selectedTags" multiple="multiple" v-on:input="onFilterChange">
      </select2>

    </div>
  </div>
  <!-- <a class="pull-left text-info text-sm" href="#" v-on:click="clearAll">{{ $t('ticket.filters.clear') }}</a> -->
  <a class="nav-link btn btn-xs btn-block none-radius m-r m-b blue " v-on:click="clearAll">
    <span data-v-5175a609="" class="text-white _400">{{ $t('ticket.filters.clear') }}</span>
  </a>
</div>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import EmployeeManager from '../../EmployeeManager.js';
import $ from 'jquery';
import format from 'date-fns/format';
import FilterStorage from '../../FilterStorage.js';
import Select2 from '../Select2.vue';
import Api from '../../Api.js';

export default {
  name: 'TicketFilters',
  props: {
    
  },
  components: {
    Select2
  },
  data: function () {
    return {
      isError: false,
      employees: [],
      checkedAssignees: [],
      checkedAssigneesGroup: [],
      checkedStatus: [],
      checkedComunicationChannel: [],
      checkedPriority: [],
      checkedType: [],
      selectedTags: [],
      optionsTags: [],
      dateFormat: 'D MMM',
      dateOne: '',
      dateTwo: '',
      searchEmployees: '',
    }
  },
  created: function() {
    this.setFilters();
    this.getAvailableTags();
    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
    });
    EventBus.$on('addStatus', this.addStatus);
    EventBus.$on('filterChangeNew', this.filterStorageChange);
  },
  destroyed: function() {
    EventBus.$off('addStatus', this.addStatus);
    EventBus.$off('filterChangeNew', this.filterStorageChange);
  },
  methods: {
    onFilterChange: function() {
      FilterStorage.checkedComunicationChannel = this.checkedComunicationChannel;
      FilterStorage.checkedAssignees = this.checkedAssignees;
      FilterStorage.checkedAssigneesGroup = this.checkedAssigneesGroup;
      FilterStorage.checkedStatus = this.checkedStatus;
      FilterStorage.checkedPriority = this.checkedPriority;
      FilterStorage.checkedType = this.checkedType;
      FilterStorage.dateFrom = this.dateOne;
      FilterStorage.dateTo = this.dateTwo;
      FilterStorage.selectedTags = this.selectedTags;
      FilterStorage.filterChange();
    },
    filterStorageChange: function(ob) {
      this.checkedStatus = ob.checkedStatus;
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    addStatus: function(status) {
      this.checkedStatus = [];
      this.checkedStatus.push(status);
      this.onFilterChange();
    },
    getAvailableTags: function(status) {
      Api.availableTags().then((availableTags) => {
        this.optionsTags = availableTags;
      }).catch((reson) => {
        // this.showNotify('notification-error', this.$i18n.t('notifications.error'));
      });
    },
    clearAll: function() {
      this.checkedAssignees = [];
      this.checkedAssigneesGroup = [];
      this.checkedStatus = [];
      this.checkedPriority = [];
      this.checkedType = [];
      this.dateOne = '';
      this.dateTwo = '';
      this.selectedTags = [];
      this.checkedComunicationChannel = [];
      this.onFilterChange();
    },
    setFilters: function() {
      this.checkedComunicationChannel = FilterStorage.checkedComunicationChannel;
      this.checkedAssignees = FilterStorage.checkedAssignees;
      this.checkedAssigneesGroup = FilterStorage.checkedAssigneesGroup;
      this.checkedStatus = FilterStorage.checkedStatus;
      this.checkedPriority = FilterStorage.checkedPriority;
      this.checkedType = FilterStorage.checkedType;
      this.dateOne = FilterStorage.dateFrom;
      this.dateTwo = FilterStorage.dateTo;
      this.selectedTags = FilterStorage.selectedTags;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 1560px) {
  .c-rwd {
    width: 100%;
  }
}
</style>
