<template>
  <div class="row">
    <div class="box" v-if="user != null">

      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('header.profilSettings.profile.profileSettings')}}</h6>
        <div class="pull-right">
          <span class="label text-white m-r-sm" v-for="role in user.roles" v-bind:class="{ 'c-green': role == 'admin' }">
           {{role}}
          </span>
        </div>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="l-gray p-b">
        <ul class="btn-group nav nav-pills p-t p-l">
          <li class="nav-item">
            <button class="btn btn-sm white none-radius active primary-color" data-toggle="tab" data-target="#profile"><span class="_400">{{$t('header.profilSettings.profile.profile')}}</span></button>
          </li>
          <!-- <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#statistic"><span class="_400">Statistic</span></button>
          </li> -->
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#response"><span class="_400" >{{$t('header.profilSettings.profile.cannedResponses.cannedResponses')}}</span></button>
          </li>
        </ul>
      </div>
      <div class="box-divider m-a-0 m-b"></div>
      <div class="tab-content p-a m-b-md">
        <div class="tab-pane animated fadeIn active primary-color" id="profile">
          <form class="form-horizontal">
            <div class="form-group row">
              <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('header.profilSettings.profile.email')}}</label>
              <div class="col-lg-5 col-md-6 col-sm-9">
                <input class="form-control" type="email" v-model="user.email">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('header.profilSettings.profile.name')}}</label>
              <div class="col-lg-5 col-md-6 col-sm-9">
                <input class="form-control" type="text" v-model="user.name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('header.profilSettings.profile.password')}}</label>
              <div class="col-lg-5 col-md-6 col-sm-9">
                <div class="col-sm-3 m-t-sm p-l-xs">
                  <a class="text-info text-sm" href="#" 
                  v-on:click.prevent="showInputPass" 
                  v-bind:class="{ 'custom-hide': isShow == true}">
                    {{$t('btnNames.change')}}
                  </a>
                </div>
                <input class="form-control" type="password" v-model="user.password" v-bind:class="{ 'custom-hide': isShow == false, 'display': isShow == true}">
              </div>
            </div>
            <div class="control-group form-group row">
              <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">Avatar</label>
              <div class="col-sm-2">
                <span class="pull-left m-r w-40">
                  <gravatar class="img-thumbnail none-radius" :email="user.email" default-img="identicon"/>
                  <!-- <img class="img-thumbnail none-radius" src="../../assets/images/a2.jpg" alt="..." > -->
                </span>
                <!-- <div class="form-file">
                  <input type="file" style="width:35%;">
                  <a class="m-t-xs nav-link btn btn-sm white none-radius"><span class="primary-color _400">Upload</span></a>
                </div> -->
              </div>
            </div>
            <!-- <div class="form-group row">
              <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('header.profilSettings.profile.signature')}}</label>
              <div class="col-lg-5 col-md-6 col-sm-9">
                <textarea class="form-control" rows="3">
                </textarea>
              </div>
            </div> -->
            <div class="box-divider m-a-0 m-b"></div>

            <a class="m-t-xs nav-link btn btn-sm white none-radius" v-on:click="save"><span class="primary-color _400">{{$t('btnNames.saveChanges')}}</span></a>
          </form>
        </div>
        <!-- <div class="tab-pane animated fadeIn text-muted" id="statistic">
          <div class="row">
            <div class="col-md-4">
              <table class="table custom-table">
                <thead class="text-muted d-gray">
                  <tr>
                    <th colspan="3">Email Send</th>   
                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-gray">
                    <td>Today</td>
                    <td>30</td>
                    <td class="text-success">
                      <i class="fa fa-level-up"></i> 40%
                    </td>
                  </tr>
                  <tr class="l-gray">
                    <td>This week</td>
                    <td>60</td>
                    <td class="text-success">
                      <i class="fa fa-level-up"></i> 20%
                    </td>
                  </tr>
                  <tr class="l-gray">                    
                    <td>This month</td>
                    <td>80</td>
                    <td class="text-warning">
                      <i class="fa fa-level-down"></i> 5%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <p>Place on charts with statistics of closed open tikets...</p>
            </div>
          </div>
        </div> -->
        <div class="tab-pane animated fadeIn text-muted" id="response">
          <CannedResponses></CannedResponses>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import UserManager from '../../UserManager.js'
import CannedResponses from './CannedResponses.vue'
import Select2 from '../Select2.vue';
import Gravatar from 'vue-gravatar';

export default {
  name: 'EmployeeProfile',
  props: {
    
  },
  components: {
    CannedResponses,
    Select2,
    Gravatar
  },
  data: function () {
    return {
      user: UserManager.user,
      isShow: false,
    }
  },
  created: function() {
    UserManager.events.on('userReady', () =>{
      this.user = UserManager.user;
    });

      
  },
  methods: {
    showInputPass: function() {
      this.isShow = true;
    },
    save: function() {
      if (this.user.name.length == 0 || this.user.email.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
        return;
      }

      Api.editUserMyself(this.user).then((user) => {
        this.user = user;
        this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.display {
  display: block;
}
.custom-hide {
  display: none;
}

</style>
