<template>
  <div class="roles p-b-sm">
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('settings.tabPermissions.title')}}</h6>
      </div>
      <div class="box-body" v-if="isProcesing">
          <Spinner></Spinner>
      </div>
      <!-- <table class="table table-bordered" v-if="!isProcesing">
        <thead class="primary-color">
          <tr>
            <th  style="width: 5%;">{{$t('settings.tabPermissions.ability')}}</th>   
            <th class="rotateth" v-for="e in employees">
              <label class="rotate"> 
                {{e.name}}
              </label>
            </th>   
          </tr>
        </thead>

        <tbody>
          <tr v-for="(title, code) in perms">
            <td class="text-muted">{{ title }}</td>
            <td v-for="e in employees">
              <label class="md-check">
                <input type="checkbox" :value="code" v-model="e.permissions"  @change="permissionsChnage($event, e)">
                <i class="primary m-t-xs"></i>
              </label>
            </td>
            
          </tr>
        </tbody>
      </table> -->

      <table class="table table-bordered" v-if="!isProcesing">
        <thead>
          <tr>
            <th style="width: 20%; position: relative;">

              <label class="text-muted _400 rotate" style="position: absolute; top:100px; right:0px;">
                {{$t('settings.tabPermissions.ability')}}
              </label>

              <div class="form-group l-h m-a-0">
                <div class="input-group" style="width: 100%;">
                  <input class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.filters.searchAss')" type="text" v-model="searchEmployees">
                </div>
              </div>
            </th>
            <th class="rotateth" v-for="(title, code) in perms">
              <label class="rotate text-muted _400">{{ title }}</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in employees">
            <td class="primary-color _500" 
            :attach="e"
            :key="e.id"
            v-show="!searchEmployees.length || e.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1">
            {{e.name}}
            </td>
            <td v-for="(title, code) in perms" class="text-center"
            v-show="!searchEmployees.length || e.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1">
              <label class="md-check">
                <input type="checkbox" :value="code" v-model="e.permissions"  @change="permissionsChnage($event, e)">
                <i class="primary m-t-xs"></i>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="start-info-text coral m-a text-center">
        Set each user's permission level according to roles assigned to him.
      </div> -->
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import UserManager from '../../UserManager.js';
import Select2 from '../Select2.vue';
import Spinner from '../Spinner.vue';

export default {
  name: 'PermissionsSetting',
  props: {
    
  },
  components: {
    RotateSquare5,
    Spinner,
    Select2
  },
  data: function () {
    return {
      perms: {
        'tickets.create': this.$i18n.t('settings.tabPermissions.tickets-create'),
        'tickets.assignment.change': this.$i18n.t('settings.tabPermissions.tickets-assignment-change'),
        'tickets.delete': this.$i18n.t('settings.tabPermissions.tickets-delete'),
        'tickets.reply': this.$i18n.t('settings.tabPermissions.tickets-reply'),
        'tickets.comments.create': this.$i18n.t('settings.tabPermissions.tickets-comments-create'),
        'tickets.comments.read': this.$i18n.t('settings.tabPermissions.tickets-comments-read'),
        'system.settings': this.$i18n.t('settings.tabPermissions.system-settings'),
        'raport.access': this.$i18n.t('settings.tabPermissions.raport-access')
      },
      employees: [],

      isProcesing: true,
      searchEmployees: ''
    }
  },
  created: function() {
    this.getEmployees();
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    permissionsChnage: function($event, emp) {
      let availableCodes = Object.keys(this.perms);
      emp.permissions = emp.permissions.filter(p => p.length != 0 && availableCodes.indexOf(p) > -1);
      Api.editUser({id: emp.id, permissions: emp.permissions}).then((employee) => {
        UserManager.permissions = emp.permissions;
      }).catch((e) => {
        console.error('error zapisu permissions', e)
      })
    },
    getEmployees: async function() {
      this.isProcesing = true;
      this.employees = await EmployeeManager.getEmployees();
      this.isProcesing = false;
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .roles {
    .table > thead > tr > th {
      border-color: #E3E3EC;
      border-bottom: none;
    }
  }
  .rotateth {
    // width: 10px;
    padding: 100px 0px;
    text-align: center;
    // position: relative;
  }
  .rotate {
    // position: absolute;
    // left: -30px;
    // bottom: 50px;
    transform: rotate(-90deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

  }
</style>
