<template>
  <div class="row">
    <div class="box">
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.reports')}}</h6>

      </div>
      <div class="box-divider m-a-0"></div>

      <div class="l-gray p-b">
        <ul class="btn-group nav nav-pills p-t p-l">
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'tickets'" 
            v-bind:class="{ 'active': currentTab == 'tickets'}">
              <span class="_400">{{$t('raports.tabTicket.tickets')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'response'" 
            v-bind:class="{ 'active': currentTab == 'response'}">
              <span class="_400" >{{$t('raports.tabResponse.response')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'emptickets'" 
            v-bind:class="{ 'active': currentTab == 'emptickets'}">
              <span class="_400">{{$t('raports.tabEmptickets.title')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'status'" 
            v-bind:class="{ 'active': currentTab == 'status'}">
              <span class="_400" >{{$t('raports.tabStatus.title')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'messages'" 
            v-bind:class="{ 'active': currentTab == 'messages'}">
              <span class="_400" >{{$t('raports.tabMessages.title')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'daily'" 
            v-bind:class="{ 'active': currentTab == 'messages'}">
              <span class="_400" >{{$t('raports.dailyActivity.title')}}</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'emp'" 
            v-bind:class="{ 'active': currentTab == 'messages'}">
              <span class="_400" >{{$t('raports.empActivity.title')}}</span>
            </button>
          </li>
          <!-- <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#tags">
              <span class="_400" >First response time</span>
            </button>
          </li> -->
          <!-- 
          
          <li class="nav-item">
            <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#roles">
              <span class="_400" >Activity</span>
            </button>
          </li> -->
        </ul>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="tab-content m-b-md">
        <div class="tab-pane animated fadeIn active primary-color">
          <TicketsRaport v-if="currentTab == 'tickets'"></TicketsRaport>
          <LastResponse v-if="currentTab == 'response'"></LastResponse>
          <EmployeeTickets v-if="currentTab == 'emptickets'"></EmployeeTickets>
          <StatusPriorityRaport v-if="currentTab == 'status'"></StatusPriorityRaport>
          <SentMessages v-if="currentTab == 'messages'"></SentMessages>
          <DailyActivity v-if="currentTab == 'daily'"></DailyActivity>
          <EmpActivity v-if="currentTab == 'emp'"></EmpActivity>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import UserManager from '../../UserManager.js'
import TicketsRaport from './TicketsRaport.vue'
import LastResponse from './LastResponse.vue'
import EmployeeTickets from './EmployeeTickets.vue'
import StatusPriorityRaport from './StatusPriorityRaport.vue'
import SentMessages from './SentMessages.vue'
import DailyActivity from './DailyActivity.vue'
import EmpActivity from './EmpActivity.vue'


export default {
  name: 'ReportsPage',
  props: {
    
  },
  components: {
    TicketsRaport,
    LastResponse,
    EmployeeTickets,
    StatusPriorityRaport,
    SentMessages,
    DailyActivity,
    EmpActivity
  },
  data: function () {
    return {
      currentTab: 'tickets'
    }
  },
  created: function() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 776px) {
  .nav-item {
    margin-top: 10px;
  }
}

</style>
