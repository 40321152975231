<template>
  <div class="box">
    <div class="box-header">
      <h6 class="title-color inline">{{ $t('ticket.notes.comments') }}</h6>
    </div>
    <div class="m-a-0 custom-divider"></div>
    <div class="box-body">
      <form>
        <div class="comment-box">
          <textarea id="comment-mesage-area" class="form-control form-control-sm comment-box-input" :placeholder="$t('ticket.notes.addComment')" 
          v-on:keypress="pickEmployee" v-on:keyup="autoGrow" v-model="noteContent" style="overflow: hidden; overflow-wrap: break-word; height: 99px;" 
          ></textarea>
          <div class="comment-box-options">

            <a class="comment-box-options-item js-comment-mention-member" href="#" :title="$t('ticket.notes.titleMention')" v-on:click.prevent="showModal">
              <i class="material-icons md-20 text-muted"></i>
            </a>

            <div class="box emp-box" v-if="isActive">
              <div class="text-muted p-x-sm p-y-sm">
              <span>{{ $t('ticket.notes.mention') }}</span>
              <a href="" class="pull-right text-sm" v-on:click.prevent="closeModal"><i class="material-icons md-18">close</i></a>
              </div>
              <div class="box-divider m-a-0"></div>
              <div class="form-group l-h m-a-0">
                <div class="input-group" style="width: 100%;">
                  <input style="border-top: none; border-left: none; border-right: none;" class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.notes.searchCo')" type="text" v-model="searchEmployees">
                </div>
              </div>
              <div class="list-group no-radius no-borders">
                <div v-if="allEmps.length > 0">
                  <a class="list-group-item p-x-md" 
                    v-for="e in allEmps"
                    :attach="e"
                    :key="e.id"
                    v-if="!searchEmployees.length || e.name.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1"
                    v-on:click.prevent="addToTextarea(e.name)"
                    ref="printerSelect"
                    :to-put="e.name"
                    >
                    <span> {{e.name}}</span>
                  </a>

                  <a class="list-group-item p-x-md" 
                    v-for="e in groups"
                    :attach="e"
                    :key="'G'+e.id"
                    v-if="!searchEmployees.length || e.title.toUpperCase().indexOf(searchEmployees.toUpperCase()) != -1"
                    v-on:click.prevent="addToTextarea(e.title)"
                    ref="printerSelect"
                    :to-put="e.title"
                    >
                    <span> {{e.title}} <span class="text-muted">(Grupa)</span></span>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
        <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" v-on:click="addComment" :disabled="procesing == 1" v-bind:class="{'disabled': !UserManager.permissions.find(element => element == 'tickets.comments.create')}">{{ $t('btnNames.add') }}</button>
      </form>

      <div class="box-divider m-a-0 m-t"></div>

      <ul class="list inset text-color" v-if="comments.length > 0 && commentIsReady == true">

        <li class="list-item p-x-xs" v-for="comment in evenComments">
          <!-- Data i nazwisko tego kto wystawił komentarz -->
          <small class="block text-muted m-b-sm">
            <span v-if="comment.employee">{{comment.employee.name}}</span>
            <span v-else>Employee undefined</span>
            <a v-if="comment.employee && comment.employee.id == userId" class="nav-link primary-color m-l-xs" :title="$t('ticket.notes.titleDelete')" v-on:click="deleteComment(comment._id)" :disabled="procesing == 1">
              <i class="material-icons md-16">close</i>
            </a>
            <span class="pull-right c-rwd"> 
            {{comment.created | moment("DD MMM YYYY | HH:mm")}}
            </span>

          </small>

          <div class="content-comment p-y-sm p-x-sm primary-color" 
          v-bind:class="{ 'unread-comment': inArray(userId, comment.mentionedUsers) && 
          !inArray(userId, comment.reactedUsers)}">

            <span class="pull-right" v-if="inArray(userId, comment.mentionedUsers) 
            && !inArray(userId, comment.reactedUsers)">
              <a class="seen-btn" :title="$t('ticket.notes.titleMark')" v-on:click.prevent="markAsSeen(comment._id)">
                <i class="material-icons md-24 coral">check</i>
              </a>
            </span>

            <p class="essence" v-html="comment.htmlContent"></p>

            <div class="reaction m-t-sm">
              <span class="label primary none-radius label-xs"><i class="material-icons">check</i> 
              {{comment.reactedUsers.length}}/{{comment.mentionedUsers.length}}
              </span>

              <span v-if="comment.reactedNames && comment.reactedNames.length" >
              <div class="text-xs on-reaction text-left" v-for="n in comment.reactedNames">
                <span><b>{{n}}</b> {{$t('ticket.notes.reacted')}}.</span>
              </div>
              </span>
            </div>

          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import UserManager from '../../UserManager.js';
import SystemSocket from '../../SystemSocket.js'

export default {
  name: 'TicketTrelloNotes',
  props: {
    ticketData: Object
  },
  data: function () {
    return {
      groups: [],
      employees: [],
      allEmps: [],
      notesIsReady: false,
      noteContent: '',
      procesing: false,
      isActive: false,
      searchEmployees: '',
      comments: [],
      commentIsReady:false,
      userId: null,
      UserManager: UserManager
      
    }
  },
  created: function() {
    this.userId = UserManager.user.userId;
    Api.getTicketComments(this.ticketData.id).then((comments) => {
      this.comments = comments;
      this.insertSubstringToContent();
      EmployeeManager.getGroups().then((groups) => {
        this.groups = groups;
      });
      EmployeeManager.getEmployees().then((employees) => {
        this.employees = employees;
        this.allEmps = employees;
        this.addNamesMentionedAndReacted();
        this.assigneeEmpToComments();
      });
    });

    SystemSocket.socket.on('object', this.onCommentChange);
  },
  destroyed: function() {
    SystemSocket.socket.off('object', this.onCommentChange);
  },
  computed: {
    evenComments: function () {
      return this.comments.sort(function(a, b) {
        var dateA = new Date(a.created), dateB = new Date(b.created);
        return dateB - dateA;
      });
    }
  },
  methods: {
    onCommentChange: function (msg) {
      if (msg.object != 'comment') { return; }
      if (msg.verb == 'create') {
        if (this.ticketData.id+"" == msg.data.ticketId+"") {
          this.comments.push(msg.data);
          this.insertSubstringToContent();
          this.assigneeEmpToComments();
          this.addNamesMentionedAndReacted();
        }
      }
      if (msg.verb == 'update') {
        let idx = this.comments.map(c => c._id+"").indexOf(msg.data._id+"");
        if (idx != -1) {
          this.$set(this.comments, idx, msg.data);
          this.insertSubstringToContent();
          this.assigneeEmpToComments();
          this.addNamesMentionedAndReacted();
        }
      }
      if (msg.verb == 'delete') {
        let idx = this.comments.map(c => c._id+"").indexOf(msg.data._id+"");
        if (idx != -1) this.comments.splice(idx, 1);
      }
    },
    addNamesMentionedAndReacted: function() {
      let empIds = this.employees.map(emp => emp.id+"");
      this.comments.forEach((c) => {
        if (!c.reactedNames) c.reactedNames = [];
        c.reactedUsers.forEach((idEmp) => {
          let idx = empIds.indexOf(idEmp+"");
          if (idx != -1) {
            c.reactedNames.push(this.employees[idx].name);
          }
        });
      });
    },
    insertSubstringToContent: function() {
      let tagsToReplace = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;'
      };
      this.comments.forEach((c) => {
        c.htmlContent = c.content.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
        c.htmlContent = c.htmlContent.replace(/@[^\s.]*/g, '<b>$&</b>');
      });
      return;
    },
    inArray: function(needle, stack) {
      let length = stack.length;
      for(let i = 0; i < length; i++) {
          if(stack[i] == needle) return true;
      }
      return false;
    },
    assigneeEmpToComments: function() {
      this.comments.forEach((c) => {
        let empIds = this.employees.map((emp) => {
          return emp.id+"";
        }); 
        let idx = empIds.indexOf(c.author+"");
        if (idx != -1) {
          this.$set(c, 'employee', this.employees[idx]);
        }
      });
      this.commentIsReady = true;
    },
    addComment: function() {
      if (this.noteContent.length == 0) return;
      this.procesing = true;
      let r = this.getMentionedUsersFromTxt(this.noteContent);
      Api.addTicketComments(this.ticketData.id, this.noteContent, r).then((comment)=> {
        this.assigneeEmpToComments();
        this.noteContent = '';
        this.procesing = false;
        this.showNotify('notification-success',this.$i18n.t('notifications.addSuccess'));
      });
    },
    getMentionedUsersFromTxt: function(text) {
      let res = [];
      let mentions = text.match(/@[^\s.]*/g);
      if (!mentions) return res;

      let empsNames = this.employees.map(e => {
          return {
              id: e.id,
              name: e.name.replace(/\s/g, "")
          };
      });

      mentions.forEach(m => {
          let name = m.slice(1);
          let idx = empsNames.map(e => e.name).indexOf(name);
          if (idx != -1) return res.push(empsNames[idx].id);

          idx = EmployeeManager.groups.map(g => g.title.replace(/\s/g, "")).indexOf(name);
          if (idx == -1) return;
          let group = EmployeeManager.groups[idx];
          res = res.concat(group.employees || []);
      });

      return res;
    },
    markAsSeen: function(id) {
      Api.reactOnTicketComments(id).then((comment)=> {});
    },
    deleteComment: function (id) {
      this.procesing = true;
      Api.deleteTicketComments(id).then((deleteNote)=> {
        this.procesing = false;
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      });
    },
    pickEmployee: function(e) {
      if (e.keyCode == 13 && this.isActive == true) {
         e.preventDefault();
         e.stopPropagation();
         this.addToTextarea(this.$refs.printerSelect[0].getAttribute("to-put"));
         return;
      }
    },
    autoGrow: function(e) {
      let element = document.getElementById('comment-mesage-area');
      let word = this.getWord(this.noteContent, element.selectionStart);
      if (word && word[0] == '@') {
        this.searchEmployees = word.slice(1);
        this.isActive = true;
      } else {
        this.searchEmployees = '';
        this.isActive = false;
      }

      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
    },
    addToTextarea: function(eName) {
      let element = document.getElementById('comment-mesage-area');
      let word = this.getWord(this.noteContent, element.selectionStart, true);
      let a = this.noteContent;
      let b = '@'+eName.replace(/\s/g, "");
      let pos = element.selectionStart;

      if (word.word && word.word[0] == '@') {
        pos = word.left;
        a = a.substr(0, word.left) + a.substr(word.right+1);
      }

      var output = [a.slice(0, pos), b+' ', a.slice(pos)].join('');
      this.noteContent = output;

      element.focus();
      this.searchEmployees = '';
      this.isActive = false;
      
    },
    showModal: function() {
      this.isActive = true;
    },
    closeModal: function() {
      this.isActive = false;
    },
    getWord: function(str, pos, withBounds) {
      str = String(str);
      pos = Number(pos) >>> 0;

      // Search for the word's beginning and end.
      var left = str.slice(0, pos).search(/\S+$/),
          right = str.slice(pos).search(/\s/);

      // The last word in the string is a special case.
      if (right < 0 && !withBounds) {
          return str.slice(left);
      }

      if (withBounds) {
        return {
          word: str.slice(left, right + pos),
          left: left,
          right: right + pos
        };
      }
      // Return the word, using the located bounds to extract it from the string.
      return str.slice(left, right + pos);
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.comment-box {
  position: relative;

  .comment-box-input {
    background: #FFFFFF;
    box-shadow: none;
    height: 75px;
    min-height: 75px;
    width: 100%;
    resize: none;
  } 

  .comment-box-options {
    position: absolute;
    bottom: 5px;
    right: 5px;

    .comment-box-options-item {
      padding:3px;
    }
    .comment-box-options-item:hover {
      background-color: rgba(158, 158, 158, 0.1);
    }
  }
}
.emp-box {
  position: absolute;
  top: 27px;
  right: -4px;
  min-width: 200px;
  z-index: 1000;
}
.unread-comment {
  // background-color: #f6f8f8;
  background-color: rgb(255, 243, 152);
  // background-color: rgba(12,194,170, 0.7);
  // background-color: rgba(91,192,222, 0.7);
}

.content-comment {
  border: 1px solid rgba(158, 158, 158, 0.1);
  position: relative;
  .essence {
    b {
      color: #0cc2aa;
    }
  }
  .seen-btn {
    opacity: 0;
  }
  .reaction {
    position: absolute;
    bottom: -10px;
    right: 8px;
    span {
      cursor: pointer;
    }
    i {
      font-size: 16px;
    }
    .on-reaction {
      position: absolute;
      right: 0px;
      bottom: -40px;
      background-color: #fff;
      box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
      padding: 5px;
      min-width: 220px;
      display: none;
      z-index: 1000;
    }
  }
  .reaction:hover {
    .on-reaction {
      display: block;
    }
  }

}
.content-comment:hover {
  .seen-btn {
    opacity: 1;
  }

}
.md-16 {
  font-size: 16px;
}
@media (max-width: 1120px) {
  .c-rwd {
    display: block;
    float: none;
  }
}
</style>
