<template>
  <div>
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.tabResponse.title')}}</h6>
        <div class="pull-right m-l-sm minus-margin">
          <select2 :options="timeUnit" v-model="selectedTimeUnit" class="form-control primary-color" v-on:input="changeDataColectionByTimeUnit">
          </select2>
        </div>
      </div>
      <div class="row">
        <div class="small col-md-10">
          <horizontal-bar :chart-data="datacollection" :options="options"></horizontal-bar>
        </div>
        <div class="col-md-2">
          <div class="form-group m-t-md raports">
            <label>{{$t('raports.selectTitle')}}:</label>
            <select2 v-if="isReady" :options="responsesTime" v-model="selectedEmps" class="form-control" v-on:input="changeDataColection" multiple="multiple" style="width: 95%">
            </select2>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import {RotateSquare5} from 'vue-loading-spinner';
import $ from 'jquery'
import Api from '../../Api.js';
import HorizontalBar from '../HorizontalBar.js';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';


export default {
  name: 'Prefernces',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    HorizontalBar
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales : {
          xAxes : [{
            ticks : {
              beginAtZero : true
            },
          }],
           yAxes : [{
              barPercentage: 0.5,
              maxBarThickness: 30,
          }]
        },
      },
      datacollection: null,
      datalabel: [],
      data: [],
      selectedEmps: [],
      timeUnit: [
        { id:'week', text: this.$i18n.t('raports.timeUnit.week') },
        { id:'month', text: this.$i18n.t('raports.timeUnit.month') },
        { id:'3months', text: this.$i18n.t('raports.timeUnit.3months') },
        { id:'year', text: this.$i18n.t('raports.timeUnit.year') },
      ],
      selectedTimeUnit: 'week',
      isReady: false,
      employees: [],
      responsesTime: [],
    }
  },
  created: function() {
    this.getAverageResponseTimes()
      .then(r => this.getEmployees())
      .then(r => this.assignEmpNameToResposneTime())
    ;
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    changeDataColectionByTimeUnit: function() {
      this.datalabel = [];
      this.data = [];
      this.isReady = false;
      this.getAverageResponseTimes()
        .then(r => this.assignEmpNameToResposneTime())
        .then(r => {
          if (this.selectedEmps.length) {
            this.changeDataColection();
          }
        })
      ;

    },
    getAverageResponseTimes: function() {
      return Api.getRaportAverageResponseTimes(this.selectedTimeUnit).then((responsesTime) => {
        this.responsesTime = responsesTime;
      });
    },
    getEmployees: function() {
      return EmployeeManager.getEmployees().then((employees) => {
        this.employees = employees;
      });
    },
    assignEmpNameToResposneTime: function() {
      this.employees.forEach((e) => 
      {
        let idx = this.responsesTime.map(t => t.idEmployee+'').indexOf(e.id+'');

        if (idx != -1) {
          this.responsesTime[idx].text = e.name+'';
          this.responsesTime[idx].id = e.id+'';
          this.datalabel.push(this.responsesTime[idx].text);
          let time = Math.floor(this.responsesTime[idx].averageTime / 60);
          this.data.push(time);
        }
      });

      this.isReady = true;
      this.fillData();
    },
    fillData () {
      this.datacollection = {
        labels: this.datalabel,
        datasets: [{
            type: 'horizontalBar',
            label: this.$i18n.t('raports.tabResponse.averageRes'),
            backgroundColor: '#5bc0de',
            data: this.data,
        }],
      }
    },
    changeDataColection() {
      if (!this.selectedEmps) {
        this.selectedEmps = [];
        this.datalabel = [];
        this.data = [];
        this.isReady = false;
        this.getAverageResponseTimes()
          .then(r => this.assignEmpNameToResposneTime())
        ;
        return; 
      }

      this.datalabel = [];
      this.data = [];

      this.selectedEmps.forEach((e) => 
      {
        let idx = this.responsesTime.map(o => o.idEmployee+'').indexOf(e+'');
        if (idx != -1) {
          this.datalabel.push(this.responsesTime[idx].text);
          let time = Math.floor(this.responsesTime[idx].averageTime / 60);
          this.data.push(time);
        }
      });

      this.fillData();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .small {
    // max-width: 95%;
    margin: 5px auto;
    position: relative; 
    max-height:500px;
  }
  @media (max-width: 767px) {
    .raports {
      margin-left: 10px;
    }
  }
</style>
