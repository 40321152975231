<template>
    <div class="box">
        <div class="box-header">
            <h3>Integracja ze sklepem</h3>
            <small>Podłącz swój sklep aby szybko pobierać<br/>informacje o zamówieniach klienta</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <div class="form-group">
                    <label for="exampleInputEmail1">Silnik sklepu</label>
                    <select2 :options="optionsEngine" class="form-control" v-model="editedShop.storeEngine"></select2>
                </div>

                <div class="form-group">
                    <label>Przyjazna nazwa sklepu</label>
                    <input class="form-control" type="text" v-model="editedShop.storeName">
                </div>

                <div class="form-group" v-if="!isShopEngine(editedShop, 'baselinker')">
                    <label>Adres API</label>
                    <input class="form-control" type="text" v-model="editedShop.apiUrl">
                    <span class="help-block text-sm m-b-none text-muted" v-if="isShopEngine(editedShop, 'prestashop')">Np. https://moj-sklep.pl/api</span>
                    <span class="help-block text-sm m-b-none text-muted" v-else-if="isShopEngine(editedShop, 'shoper')">Np. https://sklep123456.shoparena.pl</span>
                    <span class="help-block text-sm m-b-none text-muted" v-else-if="isShopEngine(editedShop, 'home_esklep')">Np. https://moj-sklep.pl</span>
                </div>

                <div class="form-group" v-if="isShopEngine(editedShop, ['prestashop', 'baselinker'])">
                    <label>Klucz API</label>
                    <input class="form-control" type="text" v-model="editedShop.apiKey">
                </div>

                <div class="form-group" v-if="isShopEngine(editedShop, ['shoper', 'home_esklep'])">
                    <label>Użytkownik</label>
                    <input class="form-control" type="text" v-model="editedShop.username">
                </div>

                <div class="form-group" v-if="isShopEngine(editedShop, ['shoper', 'home_esklep'])">
                    <label>Hasło</label>
                    <input class="form-control" type="password" v-model="editedShop.password">
                </div>
            </form>
            <div class="row p-a-1">
                <div class="col-lg-4 col-lg-offset-8">
                    <a href="#" class="text-blue" v-if="!confirmingRemove" @click.prevent="confirmingRemove = true">Usuń integrację</a>
                    <span v-if="confirmingRemove" class="text-yellow">
                        Czy jesteś pewien?<br/>
                        <a href="#" class="text-green p-r-1" @click.prevent="remove()">Tak</a>
                        <a href="#" class="text-red" @click.prevent="confirmingRemove = false">Nie</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'EditShopIntegrationModal',
    props: {
        shop: Object
    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            optionsEngine: [
                { id: 'prestashop', text: 'Prestashop' },
                { id: 'shoper', text: 'Shoper' },
                { id: 'home_esklep', text: 'Home.pl - eSklep' },
                { id: 'baselinker', text: 'Baselinker' }
            ],
            editedShop: {},
            isProcessing: false,
            confirmingRemove: false,
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        this.editedShop = JSON.parse(JSON.stringify(this.shop)); //deep-copy
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },

        remove: async function (s) {
            Api.shops.delete(this.editedShop._id).then(shop => {
                this.$notify({
                    group: 'global',
                    type: 'notification-success',
                    text: this.$i18n.t('notifications.removeSuccess'),
                });
                this.$emit('close');
            }).catch(e => {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: this.$i18n.t('notifications.removeError'),
                });
            });
        },

        save: function () {
            if (this.editedShop.apiUrl) {
                this.editedShop.apiUrl = this.editedShop.apiUrl.replace(/\/$/, "");
            }

            this.procesing = true;
            let prom = null;
            if (this.editedShop._id) {
                prom = Api.shops.update(this.editedShop._id, this.editedShop);
            } else {
                prom = Api.shops.create(this.editedShop);
            }

            prom.then((shop) => {
                this.$notify({
                    group: 'global',
                    type: 'notification-success',
                    text: this.$i18n.t('notifications.changeSuccess'),
                });
                this.$emit('close');
            }).catch((reson) => {
                this.procesing = false;
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: this.$i18n.t('notifications.changeError'),
                });
            });
        },

        isShopEngine: function (shop, test) {
            if (!shop || !shop.storeEngine) return false;
            if (typeof test == "string") return shop.storeEngine == test;
            if (Array.isArray(test)) return test.indexOf(shop.storeEngine) > -1;
            return false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
