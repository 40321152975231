<template>
    <div class="ticket-informations ">
        <div class="box">
            <div class="box-header p-b-sm">
                <h2 class="title-color m-b-sm">{{ $t('ticket.info.ticket') }} <span class="text-primary pull-right text-u-c">#{{ticketData.id.substr(-6)}}</span></h2>
                <span class="text-sm text-muted">{{ $t('ticket.info.createDate') }}: {{ticketData.emailDate | moment("DD MMMM YYYY")}}</span>
                <span class="text-sm text-muted" v-if="ticketData.source"><br>Źródło: {{ ticketSource }}</span>
            </div>
            <div class="m-a-0 custom-divider"/>
            <ul class="list-group no-border channels-list">
                <li class="list-group-item no-border m-b-sm" v-if="Array.isArray(ticketData.channels)">
                    <div class="clear custom-color">
                        <h6 class="_400 m-b">{{ $t('ticket.info.channelsTitle') }}:</h6>
                        <p v-for="channel in ticketData.channels" :key="channel._id" :class="{disabled: channel.isDisabled}">
                            <TicketChannelIcon :type="channel.type" size="20" />&nbsp;
                            <span v-if="channel.type == 'email'">{{channel.value}}</span>
                            <span v-else-if="channel.type == 'chat'">{{channel.name}}</span>
                            <span v-else-if="channel.type == 'ig'">{{channel.account}}</span>
                            <span v-else-if="channel.type == 'fb'"><a v-if="channel.fbUser" :href="'https://facebook.com/'+channel.fbUser.id" target="_blank">{{channel.name}}</a></span>
                            <span v-if="channel.isDisabled">&nbsp;(Nieaktywny)</span>
                            <a href="#" class="text-sm text-blue" @click.prevent="changeChannelValue(channel)" v-if="channel.type == 'email'">&nbsp;(Edytuj)</a>
                            <a href="#" class="text-sm text-blue" @click.prevent="copyToClipboard(channel.value)" v-if="channel.type == 'email'">&nbsp;(Skopiuj)</a>
                        </p>
                    </div>
                    <a href="#" class="pull-right text-muted text-sm">{{ $t('btnNames.change') }}</a>
                </li>

            </ul>
            <div class="box-body primary-color pt-xs hidden-sm-down">
                <div class="custom-divider m-a-0 m-b-sm"/>
                <span>{{ $t('ticket.info.linkedTicket') }} ({{counterLinkedTicets}})</span>
                <div class="linked-window p-x-xs" v-if="evenLinkedTickets.length > 0">

                    <div class="box m-t" v-for="linkedTicket in evenLinkedTickets" :key="linkedTicket.id">
                        <div class="p-y-sm p-x">
                            <span class="text-xs">{{ $t('ticket.info.assignee') }}: {{EmployeeManager.getName(linkedTicket.assignedUserId)}}</span>
                            <span class="text-xs pull-right">
                                <i class="material-icons text-yellow" v-if="linkedTicket.status == 'new'">brightness_1</i>
                                <i class="material-icons text-green" v-if="linkedTicket.status == 'open'">brightness_1</i>
                                <i class="material-icons text-blue" v-if="linkedTicket.status == 'pending'">brightness_1</i>
                                <i class="material-icons text-red" v-if="linkedTicket.status == 'closed'">brightness_1</i>
                                {{$t('ticket.filters.'+linkedTicket.status)}}
                            </span>
                            <div class="text-sm m-y-xs">
                                <a href="#" @click="goToLinkedTicket($event, linkedTicket.id)">
                                    <span class="text-u-c">#{{linkedTicket.shortId}}</span> - <span v-if="linkedTicket.subject">{{linkedTicket.subject| truncate(50)}}</span>
                                </a>
                            </div>
                            <div>
                                <span class="text-xs text-muted">{{linkedTicket.messagesCount}} wiad. / </span>
                                <span class="text-xs text-muted">{{linkedTicket.createdAt | moment("DD MMM YYYY | hh:mm")}}</span>
                                <a href="#" class="pull-right text-muted" :title="$t('ticket.info.titleRemove')"
                                   @click="removeTicketFromLinked($event, linkedTicket.id)">
                                    <i class="material-icons md-18">delete</i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-divider m-a-0 m-t-sm m-b-md"/>

                <form role="form">

                    <div class="form-group">
                        <label for="status" class="_500">{{ $t('ticket.info.status') }}</label>
                        <p class="custom-box-border">
                            <span>
                                <i class="material-icons text-yellow" v-if="ticketData.status == 'new'">brightness_1</i>
                                <i class="material-icons text-green" v-if="ticketData.status == 'open'">brightness_1</i>
                                <i class="material-icons text-blue" v-if="ticketData.status == 'pending'">brightness_1</i>
                                <i class="material-icons text-red" v-if="ticketData.status == 'closed'">brightness_1</i>
                                {{ $t('ticket.filters.'+ticketData.status) }}
                            </span>
                        </p>
                    </div>

                    <div class="form-group" v-if="UserManager.permissions.find(element => element == 'tickets.assignment.change')">
                        <label for="assignee" class="_500">{{ $t('ticket.info.assignee') }}</label>
                        <select2  :options="optionsEmployee" v-model="ticketData.assignedUserId" class="form-control" @input="changeAssignee"/>
                    </div>

                    <div class="form-group" v-if="!UserManager.permissions.find(element => element == 'tickets.assignment.change')">
                        <label for="assignee" class="_500">{{ $t('ticket.info.assignee') }}</label>
                        <p class="custom-box-border">{{EmployeeManager.getName(ticketData.assignedUserId)}}</p>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="priority" class="_500">{{ $t('ticket.info.priority') }}</label>
                                <select2 :options="optionsPriority" v-model="ticketData.priority" :template="priorityTemplate" class="form-control" @input="changePriority"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="type" class="_500">{{ $t('ticket.info.type') }}</label>
                                <select2 :options="optionsType" v-model="ticketData.type" class="form-control" @input="changeType" :template="typeTemplate"/>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="type" class="_500">{{ $t('ticket.info.ticketTags') }}</label>
                                <select2 :options="optionsTags" v-model="ticketTags" class="form-control" @input="changeTags" :template="typeTemplate" :tags="true" multiple="multiple"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="box-footer d-gray">
                <router-link class="nav-link text-muted" to="/list">
                    <span class="text-sm">{{ $t('ticket.info.back') }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';
import TicketChannelIcon from '../TicketChannelIcon.vue';
import EditChannelModal from './EditChannelModal.vue';
import Api from '../../Api.js';
import UserManager from '../../UserManager.js';

function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export default {
    name: 'TicketInformation',
    props: {
        ticketData: Object
    },
    components: {
        Select2,
        TicketChannelIcon
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    data: function () {
        return {
            UserManager: UserManager,
            counterLinkedTicets: 0,
            linkedTickets: [],
            isChangeCustomerData: false,
            selectedEmployee: null,
            optionsEmployee: [],
            optionsPriority: [
                { id: 'low', text: this.$i18n.t('ticket.filters.low') },
                { id: 'medium', text: this.$i18n.t('ticket.filters.medium') },
                { id: 'hight', text: this.$i18n.t('ticket.filters.hight') }
            ],
            optionsType: [
                { id: 'question', text: this.$i18n.t('ticket.filters.question') },
                { id: 'problem', text: this.$i18n.t('ticket.filters.problem') },
                { id: 'task', text: this.$i18n.t('ticket.filters.task') },
                { id: 'spam', text: this.$i18n.t('ticket.filters.spam') }
            ],
            optionsTags: [],
            ticketTags: [],
            EmployeeManager: EmployeeManager,
            priorityTemplate: function (op) {
                let icon = '';
                if (op.id == 'low') icon = '<i class="material-icons text-blue">looks_one</i>';
                if (op.id == 'medium') icon = '<i class="material-icons text-green">looks_two</i>';
                if (op.id == 'hight') icon = '<i class="material-icons text-red">looks_3</i>';

                return $('<span>' + icon + ' ' + op.text + '</span>');
            },
            typeTemplate: function (op) {
                let icon = '';
                if (op.id == 'question') icon = '<i class="material-icons text-blue">question_answer</i>';
                if (op.id == 'problem') icon = '<i class="material-icons text-red">report_problem</i>';
                if (op.id == 'task') icon = '<i class="material-icons text-green">assignment</i>';
                if (op.id == 'spam') icon = '<i class="material-icons text-yellow">email</i>';

                return $('<span>' + icon + ' ' + op.text + '</span>');
            }
        }
    },
    created: function() {
        EmployeeManager.getEmployees().then((employees) => {
            this.optionsEmployee = employees.map(e => {
                return { id: e.id, text: e.name };
            })
            this.setSelectedValue();
        });
        this.getLinkedTickets();
        this.availableTags();
        this.getTagsForTicket();
    },
    computed: {
        ticketSource: function () {
            if (!this.ticketData.source) return '';
            const parts = this.ticketData.source.split(':');
            if (parts[0] == 'internalbox') {
                return parts[1] + '@' + window.location.host;
            }
            return '';
        },
        evenLinkedTickets: function () {
            return this.linkedTickets.filter(t => !t.isRemoved);
        }
    },
    methods: {
        copyToClipboard: function(text) {
            try {
                copyToClipboard(text)
                this.$n.success("Adres skopiowany do schowka");
            } catch (error) {
                this.$n.error("Nie udało się skopiować tekstu");
            }
        },

        getLinkedTickets: function () {
            this.linkedTickets.push(...this.ticketData.links);
            this.counterLinkedTicets = this.ticketData.links.filter(t => !t.isRemoved).length;
        },
        goToLinkedTicket: function(event, ticketId) {
            event.preventDefault();
            this.$router.push({ path: `/ticket/${ticketId}` });
        },
        removeTicketFromLinked: function (event, ticketId) {
            event.preventDefault();
            const tickets = [this.ticketData.id + "", ticketId + ""];

            Api.unlinkTickets(tickets).then((tickets) => {
                const idx = this.linkedTickets.map(l => l.id + "").indexOf(ticketId + "");
                this.linkedTickets.splice(idx, 1);

                this.counterLinkedTicets = this.linkedTickets.length;
            }).catch((reson) => {
                console.error(reson);
            });
        },
        setSelectedValue: function() {
            this.selectedEmployee = this.ticketData.assignedUserId + "";
            this.selectedStatus = this.ticketData.status;
            this.selectedPriority = this.ticketData.priority;
            this.selectedType = this.ticketData.type;
        },
        changePriority: function (priority) {
            Api.changeTicketPriority(this.ticketData.id, priority).then((ticket) => {
                this.ticketData.priority = ticket.priority;
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        changeTags: function(tags) {
            if (!tags) tags = [];
            Api.setTagsForTicket(this.ticketData.id, tags).then((ticketTags) => {
                this.ticketTags = ticketTags.map(a => typeof a == 'string' ? a : a.tag); // w starej wersji bylo a.tag, teraz zwrane jest od razu tablica stringow
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        availableTags: function() {
            Api.availableTags().then((availableTags) => {
                this.optionsTags = availableTags;
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.error'));
            });
        },
        getTagsForTicket: function() {
            this.ticketTags = this.ticketData.tags;
            // Api.getTagsForTicket(this.ticketData.id).then((ticketTags) => {
            //   this.ticketTags = ticketTags.map(a => typeof a == 'string' ? a : a.tag); // w starej wersji bylo a.tag, teraz zwrane jest od razu tablica stringow
            // }).catch((reson) => {
            //   this.showNotify('notification-error', this.$i18n.t('notifications.error'));
            // });
        },
        changeType: function (type) {
            Api.changeTicketType(this.ticketData.id, type).then((ticket) => {
                this.ticketData.type = ticket.type;
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        changeAssignee: function (assigneeId) {
            Api.changeTicketAssignee(this.ticketData.id, assigneeId).then((ticket) => {
                this.ticketData.assignedUserId = ticket.assignedUserId;
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        showNotify (type, text) {
            this.$notify({
                group: 'global',
                type: type,
                text: text
            })
        },
        changeCustomerData: function (event) {
            event.preventDefault();
            this.isChangeCustomerData = true;
        },

        changeChannelValue: function (channel) {
            this.$modal.show(EditChannelModal, {
                channel,
                ticket: this.ticketData
            }, { height: 'auto' });
        },

        cancelSaveCustomerData: function (event) {
            event.preventDefault();
            this.isChangeCustomerData = false;
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.linked-window {
  max-height: 450px;
  overflow-y: auto;
  a:hover {
    color: #080808;
  }
}

.channels-list {
  .disabled {
    text-decoration: line-through;
    color: #ccc;
  }
}

</style>
