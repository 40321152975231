import { Network } from "vis-network/peer/esm/vis-network";
//import vis from 'vis-network';

// import conIcon from '../../assets/images/';

const splitIcon = require('../../assets/images/voip/baseline_call_split_black_24dp.png');
const contactIcon = require('../../assets/images/voip/baseline_contact_phone_black_24dp.png');
const dialerSipIcon = require('../../assets/images/voip/baseline_dialer_sip_black_24dp.png');
const volumeUpIcon = require('../../assets/images/voip/baseline_volume_up_black_24dp.png');

class VoIPGraph {
    constructor(container) {
        this.nodeSelectedCB = null;
        this.network = new Network(container, {}, {
            nodes: {
                size: 30,
                margin: 12,
                color: {
                    background: '#fff',
                    border: "#000"
                },
                shapeProperties: {
                    borderRadius: 0
                }
            },
            layout: {
                hierarchical: {
                    enabled:false,
                    levelSeparation: 150,
                    nodeSpacing: 500,
                    treeSpacing: 500,
                    blockShifting: false,
                    edgeMinimization: false,
                    parentCentralization: false,
                    direction: 'UD',        // UD, DU, LR, RL
                    sortMethod: 'hubsize',  // hubsize, directed
                    shakeTowards: 'leaves'  // roots, leaves
                }
            },
            edges: {
                length: 200,
                scaling: {
                    min: 20,
                    max: 50
                },
                smooth: true
            },
            physics: {
                enabled: true
            }
        });

        this.network.on("select", (params) => {
            if (typeof this.nodeSelectedCB == "function") this.nodeSelectedCB(params.nodes[0]);
        });

    }

    onNodeSelected(cb) {
        this.nodeSelectedCB = cb;
    }

    getIconForNodeType(nType) {
        let base = {
            face: "'Material Icons'",
            code: "block",
            size: 50,
            color: "#555555",
        };

        if (nType == "phone") { base.code = "dialer_sip"; base.color = "green" }
        else if (nType == "playback") base.code = "volume_up";
        else if (nType == "date-condition") base.code = "call_split";
        else if (nType == "receivers") base.code = "contacts";

        return base;
    }

    getImageForNodeType(nType) {
        let imgPath = require('../../assets/images/voip/baseline_call_end_black_24dp.png');
        if (nType == "phone") imgPath = require('../../assets/images/voip/baseline_dialer_sip_black_24dp.png');
        else if (nType == "playback") imgPath = require('../../assets/images/voip/baseline_volume_up_black_24dp.png');
        else if (nType == "date-condition") imgPath = require('../../assets/images/voip/baseline_call_split_black_24dp.png');
        else if (nType == "receivers") imgPath = require('../../assets/images/voip/baseline_contact_phone_black_24dp.png');
        
        return imgPath;
    }

    select(nodeId) {
        this.network.selectNodes([nodeId]);
    }

    draw(voipNodes) {
        
        let nodes = [];
        let edges = [];

        for (let i = 0; i < voipNodes.length; i++) {
            const vn = voipNodes[i];
            
            nodes.push({
                id: vn._id,
                label: vn.friendlyName,
                // shape: "icon",
                shape: "circularImage",
                group: vn.type,
                image: this.getImageForNodeType(vn.type),
                imagePadding: 5,
                size: vn.type == "phone" ? 35 : 25,
                mass: vn.type == "phone" ? 5 : 1,
                level: vn.type == "phone" ? 1 : 2,
                // icon: this.getIconForNodeType(vn.type),
                // level: 2,
                shadow: true,
                font: { color: "#222222", size: 15 }
            });

            if (vn.type == "phone" || vn.type == "playback") {
                edges.push({
                    from: vn._id,
                    to: vn.settings.nextNode ? vn.settings.nextNode : "hangup",
                    arrows: "to", dashes: false
                });
            } else if (vn.type == "date-condition") {
                edges.push({
                    from: vn._id,
                    to: vn.settings.nopeNode ? vn.settings.nopeNode : "hangup",
                    arrows: "to", dashes: false,
                    font: {size: 20, color: "red"},
                    color: "red",
                    label: "nie"
                });
                
                edges.push({
                    from: vn._id,
                    to: vn.settings.yesNode ? vn.settings.yesNode : "hangup",
                    arrows: "to", dashes: false,
                    font: {size: 20, color: "green"},
                    color: "green",
                    label: "tak"
                });
            } else if (vn.type == "receivers") {
                edges.push({
                    from: vn._id,
                    to: vn.settings.onNobodyPickupNode ? vn.settings.onNobodyPickupNode : "hangup",
                    arrows: "to", dashes: false,
                    label: "Jeśli nikt nie odbierze"
                });
            }
        }

        nodes.push({
            id: "hangup",
            group: "hangup",
            label: "Rozłącz",
            shape: "circularImage",
            image: this.getImageForNodeType("hangup"),
            imagePadding: 5,
            level: 3,
            font: { color: "red", size: 15 }
        });

        console.log(nodes);
        
        this.network.setData({
            nodes,
            edges
        });
    }

    updateNode(voipNode) {
        let visNode = this.network.body.nodes[voipNode._id];
        if (!visNode) return;

        // @todo
    }
}

export default VoIPGraph;