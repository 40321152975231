<template>
<div id="emp-activity" class="custom-modal modal fade in todisplay-active" data-backdrop="true" aria-hidden="true">
  <div class="right w-xl white b-l">
    <div class="row-col">
      <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="$emit('close')"><i class="material-icons md-20"></i></a>
      <div class="p-a b-b p-x-md">
        <span class="_500 primary-color">{{$t('header.profilSettings.activity.activity')}}</span>
      </div>
      <div class="row-row">
        <div class="row-body">
          <div class="row-inner">
            <div class="list-group no-radius no-borders d-gray">
              <div class="p-x-md primary-color _500 b-b p-a">
                {{$t('header.profilSettings.activity.filters')}}
                <a class="pull-right" data-toggle="collapse" href="#activity-fiters" aria-expanded="false" aria-controls="activity-fiters">
                  <i class="material-icons md-18 primary-color">add</i>
                </a>
                <div class="collapse p-b-xs" id="activity-fiters">
                  <ul class="list-unstyled m-t-md">

                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox" v-on:change="onFilterChange"  v-model="checkedActivityFilters" value="tickets.new_comment">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.all')}}
                      </label>
                    </li>

                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox"  v-on:change="onFilterChange"  v-model="checkedActivityFilters" value="tickets.reaction">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.commentsWaiting')}}
                      </label>
                    </li>

                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox"  v-on:change="onFilterChange" v-model="checkedActivityFilters" value="tickets.status_changed">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.status')}}
                      </label>
                    </li>
                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox"  v-on:change="onFilterChange" v-model="checkedActivityFilters" value="tickets.priority_changed">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.priority')}}
                      </label>
                    </li>
                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox" v-on:change="onFilterChange" v-model="checkedActivityFilters" value="tickets.new_reply">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.ticketMsg')}}
                      </label>
                    </li>
                    <li class="checkbox primary-color">
                      <label class="ui-check text-xs">
                        <input type="checkbox" v-on:change="onFilterChange" v-model="checkedActivityFilters" value="tickets.assignment_changed">
                        <i class="dark-white"></i>
                          {{$t('header.profilSettings.activity.ticketAssign')}}
                      </label>
                    </li>
                  </ul>
                  
                </div>
              </div>
            </div>
            <div class="list-group no-radius no-borders l-gray">
              <Spinner v-if="isLoaded"></Spinner>
              <ul class="list-unstyled p-x-md" v-if="userActivity.length > 0">
                <li class="b-b p-y-sm" v-for="a in userActivity" >

                  <span v-if="a.logKey == 'tickets.status_changed'">
                    <div class="primary-color" >
                      {{$t('header.profilSettings.activity.changedStatus')}} <b>{{$t('ticket.filters.'+a.oldStatus)}}</b>
                       {{$t('header.profilSettings.activity.to')}} <b>{{$t('ticket.filters.'+a.newStatus)}}</b> {{$t('header.profilSettings.activity.for')}} 
                      <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                  </span>

                  <span v-if="a.logKey == 'tickets.new_comment_with_mentioned'">
                    <div class="primary-color" >
                      {{$t('header.profilSettings.activity.addedCommentCofirmation')}} 
                      <b v-for="n in a.mentionedUsersNames">{{n}}</b> 
                      {{$t('header.profilSettings.activity.for')}}  <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                    <div class="comment-box m-t-sm">{{a.commentContent | truncate(60)}}</div>
                  </span>

                  <span v-if="a.logKey == 'tickets.new_comment'">
                    <div class="primary-color" >
                      {{$t('header.profilSettings.activity.addedComment')}} <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                    <div class="comment-box m-t-sm">{{a.commentContent | truncate(60)}}</div>
                  </span>

                  <span v-if="a.logKey == 'tickets.comment_reaction'">
                    <div class="primary-color" >
                      {{$t('header.profilSettings.activity.reacted')}} 
                      <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                    <div class="comment-box m-t-sm">{{a.commentContent | truncate(60)}}</div>
                  </span>

                  <span v-if="a.logKey == 'tickets.new_reply'">
                    <div class="primary-color">{{$t('header.profilSettings.activity.sent')}}
                      <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                  </span>

                  <span v-if="a.logKey == 'tickets.priority_changed'">
                    <div class="primary-color">{{$t('header.profilSettings.activity.changedPriority')}} 
                      <b>{{$t('ticket.filters.'+a.oldPriority)}}</b> {{$t('header.profilSettings.activity.to')}} 
                      <b>{{$t('ticket.filters.'+a.newPriority)}}</b> {{$t('header.profilSettings.activity.for')}} 
                      <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}}</a>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                  </span>

                  <span v-if="a.logKey == 'tickets.assignment_changed'">
                    <div class="primary-color">{{$t('header.profilSettings.activity.assignedTicket')}} <a href="#" class="coral" v-on:click="goToTicketDetails(a.ticketId)">#{{a.ticketId}} </a> <span v-if="a.oldUserName">{{$t('header.profilSettings.activity.from')}} </span>
                      <b >{{a.oldUserName}}</b> {{$t('header.profilSettings.activity.to2')}} <b>{{a.newUserName}}</b>
                    </div>
                  <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                  </span>

                  <span v-if="a.logKey == 'users.auth'">
                    <div class="primary-color">
                      {{$t('header.profilSettings.activity.auth')}}
                      <span v-id="a.data && a.data.ip"><br/>IP: {{a.data.ip}}</span>
                    </div>
                    <small class="text-muted text-xs">{{a.created| moment('DD MMM YYYY HH:mm')}}</small>
                  </span>
                </li>

              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ActivityTranslator from '../../ActivityTranslator.js';
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';

export default {
  name: 'UserActivity',
  props: {
    userInfo: Object
  },
  components: {
    Spinner,
  },
  data: function () {
    return{
      userActivity: [],
      checkedActivityFilters: [],
      isLoaded: false,
      isCommentWaitingForReaction: false,
    }
  },
  created: function() {
    this.getUserActivity();
  },
  methods: {
    goToTicketDetails: function(id) {
      this.$router.push({ path: `/ticket/${id}`});
    },
    getUserActivity: function() {
      this.isLoaded = true;
      Api.getUserActivity(this.userInfo.userId).then((data) => {
        ActivityTranslator.translateLogs(data).then(translated => {
          this.userActivity = translated;
          this.isLoaded = false;
        }).catch(e => console.error(e))

      });
    },
    onFilterChange: function() {
      this.userActivity = [];

      if (this.checkedActivityFilters.length == 0) {
        this.getUserActivity(); 
        return;
      }

      let keysToFind = this.checkedActivityFilters.map(r => {
        if (r == 'tickets.reaction') return 'tickets.new_comment';
        return r;
      });
      this.isCommentWaitingForReaction = (this.checkedActivityFilters.indexOf('tickets.reaction') != -1 && 
                                          this.checkedActivityFilters.indexOf('tickets.new_comment') == -1);

      this.isLoaded = true;

      Api.getUserActivity(this.userInfo.userId, keysToFind).then((data) => {
        ActivityTranslator.translateLogs(data).then(translated => {

          if (this.isCommentWaitingForReaction) {
            translated = translated.filter(t => {
              if (t.logKey != 'tickets.new_comment_with_mentioned' && t.logKey != 'tickets.new_comment') return true;
              return t.reactionsLeft;
            });
          }

          this.isLoaded = false;
          this.userActivity = translated;
        }).catch(e => console.error(e))
      });
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.todisplay-active {
  display: block;
  padding-top: 100px;
}
.comment-box {
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #fff;
}
</style>
