<template>
  <div class="box">
    <div class="box-header d-gray">
      <h6 class="text-muted _400 inline">Grupy</h6>
      <div class="pull-right m-l-sm minus-margin">
          <a class="btn btn-sm white none-radius" v-on:click.prevent="createGroup()">
            <span class="primary-color _400">{{$t('btnNames.add')}}</span>
          </a>&nbsp;
        </div>
    </div>

    <div class="box-body row" v-if="isReady">
      <div class="col-xl-4 col-lg-6 col-md-6" v-for="g in EmployeeManager.groups" :key="g._id">
        <div class="box m-a">
          <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">{{ g.title }}</h6>
            <div class="pull-right m-l-sm minus-margin">
              <a class="nav-link btn btn-xs white none-radius" v-on:click="deleteGroup(g)">
                <span class="primary-color _400">{{ $t('settings.groups.remove') }}</span>
              </a>
            </div>
          </div>
          <div class="box-body">
            <form class="form-horizontal">
              <div class="form-group row">
                <label class="col-sm-4 form-control-label">{{ $t('settings.groups.groupName') }}</label>
                <div class="col-sm-8">
                  <input class="form-control" type="text" v-model="g.title" v-on:input="saveGroup(g)">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <span>{{ $t('settings.groups.assignedUsers') }}:</span>
                  <select2 :options="optionsEmps" class="form-control" v-model="g.employees" multiple="multiple" v-on:input="saveGroup(g)"></select2>
                </div>
              </div>
            </form>
            
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';

let saveTimeout = null;

export default {
  name: 'ShopsSettingPage',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2
  },
  data: function () {
    return {
      EmployeeManager,
      optionsEmps: [],
      isReady: false
    }
  },
  mounted: function() {
    EmployeeManager.getGroups().then(g => {
      return EmployeeManager.getEmployees();
    }).then(employees => {
      this.optionsEmps = employees.map(e => {
        return {id: e._id, text: e.name};
      });
      this.isReady = true;
    });
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    deleteGroup: function(group) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: this.$t('settings.groups.removeConfirm') + ' "' + group.title + '"'
      };
      this.$dialog.confirm(options.message, options).then(async dialog => {
        await Api.users.deleteGroup(group._id);
      }).catch(r => r);
    },

    createGroup: async function() {
      await Api.users.createGroup({title: this.$t('settings.groups.defaultGroupName')});
      this.$notify({group: 'global', type: 'notification-success', text: this.$i18n.t('notifications.addSuccess')});
    },

    saveGroup: function(group) {
      if (saveTimeout) clearTimeout(saveTimeout);
      saveTimeout = setTimeout(async () => {
        await Api.users.saveGroup(group._id, group);
        this.$notify({group: 'global', type: 'notification-success', text: this.$i18n.t('notifications.changeSuccess')});
      }, 1000);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
