import $ from 'jquery';
import EventBus from './event-bus.js';
import Api from './Api.js';
import EventEmitter from 'events';
import EmployeeManager from './EmployeeManager.js';
import UserManager from './UserManager.js';
import SystemSocket from './SystemSocket.js'

const TICKETS_LIMIT = 60;
const PIN_STORAGE_KEY = "pinned_tickets_ids";

function TicketManager() {
  this.currentTickets = [];
  this.userPinTickets = [];
  this.lastFilters = {};
  this.totalTicketsFromLastSearch = 0;
  this.isReady = false;
  this.events = new EventEmitter();

  if (UserManager.user != null) {
    this.getPinnedTickets().then(t => {
      this.isReady = true;
      this.events.emit('ready');
    });
  } else {
    UserManager.events.on('userReady', () => {
      this.getPinnedTickets().then(t => {
        this.isReady = true;
        this.events.emit('ready');
      });
    });
  }

  SystemSocket.socket.on('object', (msg) => {
    if (msg.object != 'ticket') { return; }

    if (msg.verb == 'create' && this.isTicketFitToFilters(msg.data)) {
      this.currentTickets.push(msg.data);
      this.setEmpToTickets(this.currentTickets).then(tickets => {
        this.events.emit('tickets_changed', this.currentTickets);
      });

      return;
    }

    this.checkTicketsColetion(msg.data);
  });

}

TicketManager.prototype.isTicketFitToFilters = function(ticket) {
  let lf = this.lastFilters;
  if (this.lastFilters.assignedUserId && this.lastFilters.assignedUserId.map(a => a+"").indexOf(ticket.assignedUserId+"") == -1) return false;
  if (this.lastFilters.status && this.lastFilters.status.indexOf(ticket.status) == -1) return false;
  if (this.lastFilters.priority && this.lastFilters.priority.indexOf(ticket.priority) == -1) return false;
  if (this.lastFilters.type && this.lastFilters.type.indexOf(ticket.type) == -1) return false;
  if (lf.channels && lf.channels.length > 0) {
    for (let i = 0; i < lf.channels.length; i++) {
      const ch = lf.channels[i];
      if (ticket.channels.map(c => c.type).indexOf(ch) == -1) return false;
    }
  }

  if (this.lastFilters.content && this.lastFilters.content.length) return false;
  
  // @todo poprawic pod nowy system channeli
  if (this.lastFilters.subject) {
    if (ticket.fromEmail.indexOf(this.lastFilters.subject) == -1  
      && ticket.subject.indexOf(this.lastFilters.subject) == -1 
      && ticket.name.indexOf(this.lastFilters.subject) == -1) {
      return false;
    }
  }

  if (this.lastFilters.dateFrom && this.lastFilters.dateTo) {
    let from = new Date(this.lastFilters.dateFrom);
    let to = new Date(this.lastFilters.dateTo);
    let date = new Date(ticket.emailDate);
    if (date < from || date > to ) return false;
  }

  if (Array.isArray(this.lastFilters.tags) && !this.lastFilters.tags.filter(t => ticket.tags.indexOf(t) !== -1).length < 1) return false;

  if (ticket.isRemoved) return false;

  return true;
  
};

TicketManager.prototype.getPinnedTickets = async function() {
  let res = await Api.storage.get(PIN_STORAGE_KEY);
  if (!Array.isArray(res)) res = []; // stary sposob zapisywal stringa, w nowym moze to byc JS obiekt
  let searchReply = await Api.searchTickets({ids: res});
  let tickets = searchReply.data;
  tickets = await this.setEmpToTickets(tickets);

  this.userPinTickets = tickets;
  this.currentTickets = filterByPinnedTickets(this.currentTickets, this.userPinTickets);
  this.events.emit('tickets_changed', this.currentTickets);
  this.events.emit('pinned_tickets_changed', this.userPinTickets);

  return tickets;
};

TicketManager.prototype.searchTickets = async function(filters) {
  var data = filters !== undefined ? filters : {};
  this.lastFilters = data;
  let ticketsRes = await Api.searchTickets(data);
  this.totalTicketsFromLastSearch = ticketsRes.total;
  let filteredTciekts = filterByPinnedTickets(ticketsRes.data, this.userPinTickets);
  let tickets = await this.setEmpToTickets(filteredTciekts);
  this.currentTickets = tickets;
  return this.currentTickets;
};

TicketManager.prototype.setEmpToTickets = function(tickets) {
  return EmployeeManager.getEmployees().then((employees) => {
    tickets.forEach((ticket) => {
      let empIds = employees.map((emp) => {
        return emp.id+"";
      }); 
      let idx = empIds.indexOf(ticket.assignedUserId+"");
      if (idx != -1) ticket.employee = employees[idx];
    });

    return tickets;
  });
};

TicketManager.prototype.pinTicket = async function(ticket) {
  let res = await Api.storage.get(PIN_STORAGE_KEY);
  if (!Array.isArray(res)) res = []; // stary sposob zapisywal stringa, w nowym moze to byc JS obiekt

  if (res.indexOf(ticket._id) != -1) return;

  res.push(ticket._id);
  await Api.storage.put(PIN_STORAGE_KEY, res);

  // nie wiem za bardzo po co byly te ponizsze linie, ale na wszelki zostawiam
  // let t = await Api.pinTicket(ticket.id);
  // let tickets = await this.setEmpToTickets([t]);
  // this.userPinTickets.push(tickets[0]);

  this.userPinTickets.push(ticket);
  this.currentTickets = filterByPinnedTickets(this.currentTickets, this.userPinTickets);
  this.events.emit('tickets_changed', this.currentTickets);
  this.events.emit('pinned_tickets_changed', this.userPinTickets);
};

TicketManager.prototype.unPinTicket = async function(ticket) {
  let res = await Api.storage.get(PIN_STORAGE_KEY);
  if (!Array.isArray(res)) res = []; // stary sposob zapisywal stringa, w nowym moze to byc JS obiekt

  let idx = res.indexOf(ticket._id);
  if (idx == -1) return;

  res.splice(idx, 1);
  await Api.storage.put(PIN_STORAGE_KEY, res);

  let tidx = this.userPinTickets.map(t => t._id).indexOf(ticket._id);
  if (tidx != -1) this.userPinTickets.splice(tidx, 1);

  if (this.isTicketFitToFilters(ticket)) this.currentTickets.push(ticket);
    
  this.events.emit('tickets_changed', this.currentTickets);
  this.events.emit('pinned_tickets_changed', this.userPinTickets);
};

TicketManager.prototype.isTicketPinned = function(ticketId) {
  let pinTicketIds = this.userPinTickets.map((pinTicket) => {
      return pinTicket.id+"";
  }); 

  let idx = pinTicketIds.indexOf(ticketId+"");
  if (idx != -1) { 
    return true;
  } else {
    return false;
  }
};

TicketManager.prototype.checkTicketsColetion = function(ticket) {
  let idx = this.currentTickets.map(t => t.id+"").indexOf(ticket.id+"");
  let idxPin = this.userPinTickets.map(t => t.id+"").indexOf(ticket.id+"");

  if (idxPin != -1 && ticket.isRemoved == false) {
    this.userPinTickets[idxPin] = ticket;
    this.setEmpToTickets([this.userPinTickets[idxPin]]).then(tickets => {
      this.events.emit('pinned_tickets_changed', this.userPinTickets);
    });
    return;
  }

  if (idxPin != -1 && ticket.isRemoved == true) {
    this.userPinTickets.splice(idxPin, 1);
    this.events.emit('pinned_tickets_changed', this.userPinTickets);
    return;
  }

  // brak w kolekcji, ale po zmianie pasuje do naszych filtrow,
  // wiec dodajemy go do kolekcji
  if (idx == -1 && this.isTicketFitToFilters(ticket) && ticket.isRemoved == false) {
    this.currentTickets.push(ticket);

    // po dodaniu takiego nowego ticketa sortujemy i usuwamy najstarsze elementy. Eliminuje to problem,
    // kiedy 500 updateowanych ticketow typu "Mail delivery sender" chce sie dokleic do listy
    this.currentTickets.sort((a, b) => {
      var dateA = new Date(a.emailDate), dateB = new Date(b.emailDate);
      return dateB - dateA;
    });
    if (this.currentTickets.length > TICKETS_LIMIT) this.currentTickets.length = TICKETS_LIMIT;
    this.setEmpToTickets(this.currentTickets).then(tickets => {
      this.events.emit('tickets_changed', this.currentTickets);
    });
    return;
  }

  if (idx != -1 || ticket.isRemoved == true) {
    if (this.isTicketFitToFilters(ticket)) {
      this.currentTickets[idx] = ticket;
      this.setEmpToTickets([this.currentTickets[idx]]).then(tickets => {
        this.events.emit('tickets_changed', this.currentTickets);
      });
    } else {
      console.log('Nie pasuje do filtrow');
      this.currentTickets.splice(idx, 1);
      this.events.emit('tickets_changed', this.currentTickets);
    }

  }
  
}

/**
 * W kanalach komunikacji (email, ig, fb) danego watku szuka kanalu wg typu i zwraca jego wartosc
 * @param {object} ticket dany watek
 * @param {string} channelType email, ig, fb
 * @returns {string}
 */
TicketManager.prototype.getChannelValueForTicket = function(ticket, channelType) {
  if (!Array.isArray(ticket.channels) || !ticket.channels.length) return null;
  let channel = ticket.channels.find(ch => ch.type == channelType);
  return channel ? channel.value : null;
};

function filterByPinnedTickets(tickets, pinnedTickets) {
  let pinnedIds = pinnedTickets.map(t => t.id+"");
  let filteredTickets = [];
  tickets.forEach(t => {

    if (pinnedIds.indexOf(t.id+"") == -1) filteredTickets.push(t);
  });
  return filteredTickets;
}

var instance = new TicketManager();

export default instance;