<template>
<div>
    <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">{{$t('raports.empActivity.title')}}</h6>
    </div>

    <div class="row m-y">

        <div class="col-md-2" style="border-right:1px solid rgba(120, 130, 140, 0.13);">
            <div class="form-group m-r">
                <a class="m-l m-r btn-block btn btn-sm primary none-radius text-white" v-on:click.prevent="search">Szukaj</a>
            </div>
            <p class="primary-color _500 m-l m-t">{{ $t('ticket.filters.chooseDate') }}</p>
            <div class="input-group m-l">
                <div class="datepicker-trigger">
                    <input
                        class="form-control form-control-sm p-x b-a"
                        type="text"
                        id="datepicker-trigger"
                        :placeholder=" $t('ticket.filters.chooseDate')"
                        :value="formatDates(dateOne, dateTwo)"
                    >

                    <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger'"
                        :mode="'range'"
                        :date-one="dateOne"
                        :date-two="dateTwo"
                        :monthsToShow="1"
                        :showShortcutsMenuTrigger="false"
                        v-on:closed="changeDataColectionByEmps"
                        @date-one-selected="val => { dateOne = val }"
                         @date-two-selected="val => { dateTwo = val }"
                    />
                </div>
            </div>
            <div class="form-group m-t-md raports m-l">
                <p class="primary-color _500">{{$t('raports.selectTitle')}}:</p>
                <select2 v-if="isReady" :options="employees" v-model="selectedEmps" class="form-control" v-on:input="changeDataColectionByEmps" style="width: 95%">
                </select2>
            </div>
        </div>

        <div class="small col-md-10">
            <div class="super-puper-timeline">
            </div>

            <div v-if="!isReady" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, 0%); z-index:10000" >
                <Spinner></Spinner>
            </div>

        </div>
        
    </div>
</div>
</template>
<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';
import { Timeline } from "vis-timeline/standalone"
import Spinner from '../Spinner.vue';
import format from 'date-fns/format';

export default {
  name: 'SentMessages',
  props: {
  },
  components: {
    RotateSquare5,
    Select2,
    Spinner
  },
  data: function () {
    return {
      employees: [],
      isReady: false,
      selectedEmps: [],
      searchEmployees: '',
      dateFormat: 'D MMM',
      dateOne: '',
      dateTwo: '',
      timeline: null
    }
  },
  created: function() {
        this.getEmployees();
  },
  mounted: function() {
        let container = document.querySelector("div.super-puper-timeline");
        this.timeline = new Timeline(container, [], [], {
            editable: false,
            stack: false
        });
    },
    destroyed: function() {
    },
    methods: {
        changeDataColectionByEmps: function() {

        },
        getEmployees: function() {
            return EmployeeManager.getEmployees().then((employees) => {
                employees.forEach((e) => {
                    this.employees.push({id: e.id, text:e.name});
                });
                this.isReady = true;
            });
        },
        formatDates(dateOne, dateTwo) {
            console.log(dateOne);
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat);
            }
            return formattedDates;
        },
        search: async function() {
            this.isReady = false;
            let data = {dateFrom: this.dateOne+"T00:00:00", dateTo:this.dateTwo+"T23:59:00", groupTime: 300000, userId: this.selectedEmps};
            try {
                let raports = await Api.raport.getUsersActiveHours(data);
                this.isReady = true;
                this.createTimeLine(raports);
            } catch (error) {
                console.log("err", error);
            }
        },
        createTimeLine: function(raports) {
            var groups = [];
            const fakeDay = "2021-01-01";
            let arrdays = this.getDates(this.dateOne, this.dateTwo);

            for (let i = 0; i < arrdays.length; i++) {
                let date = arrdays[i].toISOString().split('T')[0];
   
                groups.push({
                    id: date,
                    content: date
                });
            }

            let times = raports[this.selectedEmps];
            if(!times) return;

            let items = times.map(timeEntry => {
                let startDateTime = timeEntry[0].split('T');
                let endDateTime = timeEntry[1].split('T');

                return {
                    group: startDateTime[0],
                    start: new Date(fakeDay+"T"+startDateTime[1]),
                    end: new Date(fakeDay+"T"+endDateTime[1])
                }
            });

            this.timeline.setOptions({
                min: new Date(fakeDay+"T00:00:00"),
                max: new Date(fakeDay+"T23:59:00"),
                format: {
                majorLabels: function (date, scale, step) {
                    return "";
                    // return a custom label or "" depending on the date
                }
                }
            });

            this.timeline.setItems(items);
            this.timeline.setGroups(groups);
            this.timeline.redraw();
            this.timeline.moveTo(new Date(fakeDay+"T12:00:00"));
        },
        getDates: function(startDate, stopDate) {
            var dateArray = [];
            var currentDate = new Date(startDate);
            var endDate = new Date(stopDate);
            while (currentDate <= endDate) {
                dateArray.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate()+1);
            }
            return dateArray;
        },
        
    }
}
</script>
<style scoped lang="scss">

</style>
