<template>
    <div>
        <h6 class="title-color _600 inline">Kieruj poączenie zależnie od dni i godzin</h6>
        <hr/>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group">
                    <label class="md-check checkbox-inline" v-for="day in weekDays" :key="day.id">
                        <input type="checkbox" :value="day.id" >
                        <i class="primary m-r-xs"></i>
                        <span class="m-l-xs">{{ day.label }}</span>
                    </label>
                </div>

                <div class="form-group">
                    <label>W godzinach</label>
                    <div class="row">
                        <div class="col-lg-8">
                            <vue-timepicker :minute-interval="10" placeholder="Od"></vue-timepicker>
                            <span class="m-x">do</span>
                            <vue-timepicker :minute-interval="10"  placeholder="Do"></vue-timepicker>
                        </div>
                        
                    </div>
                </div>
                <a  class="text-info" @click.prevent="">Usuń wezeł</a>
            </form>
        </div>
        <hr/>
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'

const weekDays = [
    { label: 'pn', id: 1 },
    { label: 'wt', id: 2 },
    { label: 'śr', id: 3 },
    { label: 'cz', id: 4 },
    { label: 'pi', id: 5 },
    { label: 'so', id: 6 },
    { label: 'nd', id: 0 },
]

export default {
    name: 'NodeCondition',
    props: {
        
    },
    components: {
       VueTimepicker
    },
    data: function () {
        return {
            weekDays: weekDays,
        }
    },
    created: function() {
       
    },
   
    methods: {
        
    }
}
</script>
