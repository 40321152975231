<template>
  <div class="row">
    <div class="col-lg-3 col-md-5">
      <TicketInformation v-if="ticket.id" v-bind:ticketData="ticket"></TicketInformation>
      <TicketLinkedShopInformationByEmail v-if="ticket.id" v-bind:ticketData="ticket"></TicketLinkedShopInformationByEmail>
    </div>
    <div class="col-lg-6 col-md-7">
      <TicketTabs v-if="ticket.id" v-bind:ticket="ticket"></TicketTabs>
    </div>
    <div class="col-lg-3 col-md-12">
      <TicketTrelloNotes v-if="ticket.id" v-bind:ticketData="ticket"></TicketTrelloNotes>
      <TicketFiles v-if="ticket.id" v-bind:ticket="ticket"></TicketFiles>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ForwardAskModal from './ForwardAskModal.vue'
import TicketInformation from './TicketInformation.vue'
import TicketLinkedShopInformationByEmail from './TicketLinkedShopInformationByEmail.vue'
import TicketTabs from './TicketTabs/TicketTabs.vue'
import TicketTrelloNotes from './TicketTrelloNotes.vue'
import TicketFiles from './TicketFiles.vue'
import Api from '../../Api.js';
import SystemSocket from '../../SystemSocket.js'
import {RotateSquare5} from 'vue-loading-spinner';

export default {
  name: 'TicketDetails',
  props: {
  },
  components: {
    RotateSquare5,
    TicketInformation,
    TicketLinkedShopInformationByEmail,
    TicketTabs,
    TicketTrelloNotes,
    TicketFiles,
  },
  data: function () {
    return {
      ticket: {},
    }
  },
  created: async function() {
    try {
      this.ticket = await Api.getTicket(this.$route.params.id);
      await Api.setNotificationsSeenForTicket(this.$route.params.id);
    } catch (error) {
      console.error(error);
    }

    if (this.ticket && this.ticket.status == 'new' && !this.ticket.isRemoved && Array.isArray(this.ticket.links) && this.ticket.links.length > 0) {
      this.$modal.show(ForwardAskModal, {
        ticket: this.ticket
      }, {
        draggable: true,
        clickToClose: true,
        resizable: true,
        height: 'auto',
      }, {
        'closed': (event) => {}
      });
    }

    SystemSocket.socket.on('object', this.onTicketChange);
  },
  computed: {

  },
  destroyed: function() {
    SystemSocket.socket.off('object', this.onTicketChange);
  },
  methods: {
    onTicketChange: function (msg) {
      if (msg.object != 'ticket') { return; }
      if (this.ticket.id+"" != msg.data.id+"") return;
      Object.assign(this.ticket, msg.data);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.linked-window {
  max-height: 250px;
  overflow-y: auto;
  a:hover {
    color: #080808;
  }
}
</style>
