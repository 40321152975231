<template>
 <li class="nav-item text-color m-l-sm m-r-xs" v-if="!noConversation">

  <a class="nav-link msg-blue" style="position: relative;" v-on:click="open" >
    <img style="width: 16px;margin-bottom: 2px;" src="../../assets/images/m5.svg">
    <span class="up up-custom" v-if="isNewMesssage">
      <i class="material-icons md-16" v-bind:class="{'pulse': isNewMesssage}">error</i>
    </span>
  </a> 
 <!--  <a class="nav-link msg-blue" v-on:click="open" style="position: relative;">
    <span style="font-size: 17px;" >
      <i class="fab fa-facebook-messenger" v-bind:class="{'pulse': isNewMesssage}"></i>
    </span>
  </a> -->
  <!-- okno modalne powiazania ticketa -->
  <div class="custom-modal modal fade" v-bind:class="{ in: isActiveAssociate, 'todisplay': isActiveAssociate }"  data-backdrop="true" aria-hidden="true" style="padding-right: 200px;">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center p-lg">
          <div class="form-group row">
            <div class="col-sm-12">
              <input class="form-control custom-input" type="text" v-model="searchTicketId" :placeholder="$t('ticket.ticketTabs.reply.message.enterId')" v-on:input="onSearchTicketId">
            </div>
          </div>
          <div class="form-group row m-b-xs">
            <div class="col-sm-12 m-t m-b-lg" v-if="inProgress || inSearch">
              <!-- loader -->
              <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, 0%); z-index:10000" >
                <div style="position: relative;">
                  <rotate-square5></rotate-square5>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="searchTicket && !noTicket">
              <div class="box">
                <div class="box-header p-b-sm text-left">
                   <h2 class="title-color m-b-sm">{{$t('ticket.ticketTabs.reply.message.ticket')}} <span class="text-primary pull-right">#{{searchTicket.id}}</span></h2>
                   <span class="text-sm text-muted">{{$t('ticket.ticketTabs.reply.message.createDate')}}: {{searchTicket.emailDate | moment("MMM DD, YYYY")}}</span>
                </div>
                <div class="m-a-0 custom-divider"></div>
                <div class="box-body text-left">
                  <ul class="list-group no-border">
                    <span class="primary-color _500 m-b-sm">{{$t('ticket.ticketTabs.reply.message.infoCustomer')}}:</span>
                    <div class="clear custom-color">
                      <div>
                        <span class="text-sm" v-if="searchTicket.name">{{searchTicket.name}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noName')}}</span>
                      </div>
                      <div>
                        <span class="text-sm" v-if="searchTicket.fromEmail">{{searchTicket.fromEmail}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noEmail')}}</span>
                      </div>
                      <div>
                        <span class="text-sm" v-if="searchTicket.phone">{{searchTicket.phone}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noPhone')}}</span>
                      </div>
                    </div>
                  </ul>
                  <ul class="list-group no-border m-t">
                    <span class="primary-color _500 m-b-sm">{{$t('ticket.ticketTabs.reply.message.infoTicket')}}:</span>
                    <div class="clear custom-color">
                      <div>
                        <span class="text-sm" v-if="searchTicket.subject"><b>{{$t('ticket.ticketTabs.reply.message.subject')}}:</b> {{searchTicket.subject}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noSubject')}}</span>
                      </div>
                      <div>
                        <span class="text-sm" v-if="searchTicket.assignedUserId">
                          <b>Assignee:</b> {{employeesNames[searchTicket.assignedUserId]}}
                        </span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noAssignee')}}</span>
                      </div>
                      <div>
                        <span class="text-sm" v-if="searchTicket.status"><b>{{$t('ticket.ticketTabs.reply.message.status')}}:</b> {{searchTicket.status}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noStatus')}}</span>
                      </div>
                      <div>
                        <span class="text-sm" v-if="searchTicket.priority"><b>{{$t('ticket.ticketTabs.reply.message.priority')}}:</b> {{searchTicket.priority}}</span>
                        <span class="text-sm" v-else>{{$t('ticket.ticketTabs.reply.message.noPriority')}}</span>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="noTicket">
              <p class="text-muted m-b-xs">{{$t('ticket.ticketTabs.reply.message.noTicket')}}</p>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-sm white none-radius pull-left" v-on:click="isActiveAssociate =false" v-bind:class="{ disabled: inProgress }">
            <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
          </button>
          <button class="btn btn-sm white none-radius" 
          v-on:click="associateTicket" v-bind:class="{ disabled: inProgress }" >
            <span class="primary-color _400">{{$t('header.messanger.associate')}}</span>
          </button>
        </div>
      </div><!-- /.modal-content -->
    </div>
  </div>

  <!-- okno kontaktów tz headerConversations -->
  <div class="custom-modal modal fade" v-bind:class="{ in: isActive, 'todisplay-messager': isActive }"  data-backdrop="true" aria-hidden="true">
    <div class="right w-xl white b-l">
      <div class="row-col">
        <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="close">
          <i class="material-icons md-20"></i>
        </a>
        <div class="p-a b-b p-x-md">
          <span class="_500 msg-blue">{{ $t('header.messanger.title') }}</span>
        </div>
        <div class="row-row">
          <div class="row-body" v-bind:style="{height: windowH + 'px' }">
            <!-- szukajka -->
            <div class="row-inner">
              <div class="form-group l-h m-a-0">
                <div class="input-group" style="width: 100%;">
                  <input style="border-top: none; border-left: none;" class="form-control form-control-sm p-x b-a" 
                  :placeholder="$t('header.messanger.search')" type="text" v-model="search">
                </div>
              </div>
            </div>
            <!-- szukajka -->
            <div class="list-group no-radius no-borders" >
              <div class="p-x-md m-t p-v-xs primary-color _500">{{ $t('header.messanger.conversations') }}</div>

              <div v-if="headerConversations.length > 0">
                <a class="list-group-item p-x-md" 
                  v-for="c in evenHeaderConversations"
                  v-show="!search.length || 
                  c.participants.data.filter(el => el.id != c.pageId)[0].name.toUpperCase().indexOf(search.toUpperCase()) != -1"
                  v-on:click.prevent="getMessagesWithSelected(c.id, c.pageId)">
                  <span v-if="c.participants.data.filter(el => el.id != c.pageId).length">
                   {{c.participants.data.filter(el => el.id != c.pageId)[0].name }}
                  </span>

                  <div class="counter-message inline" v-if="c.unread_count">
                    <b class="label rounded msg-blue-bg">{{c.unread_count}}</b>
                  </div>

                  <div v-if ="c.assignedUserId" class="text-xs text-muted">
                    {{$t('header.messanger.assignee')}}: {{employeesNames[c.assignedUserId]}}
                  </div>
                  <div v-if ="!c.assignedUserId" class="text-xs text-muted">
                    {{$t('header.messanger.assignee')}}: {{$t('header.messanger.none')}}
                  </div>
                </a>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END okno kontaktów -->

  <!-- okno rozmowy -->
  <div v-if="selectedHeaderCoversation" id="conversation-window" class="custom-modal modal fade" v-bind:class="{ in: isConversation, 'todisplay-messager': isConversation }" >
    <div class="right w-xxxl white b-l">
      <div class="row-col">
        <a class="pull-right primary-color p-a-sm m-r-sm" v-on:click="closeConversation">
          <i class="material-icons md-20"></i>
        </a>

        <!-- Dane tikceta którego dotyczy konwersacja -->
        <div class="p-b-xs b-b p-x-md p-t-md d-gray">
          <div class="inline primary-color" v-if="ticket.id">
            <h6><a><span class="msg-blue">#{{ticket.id}}</span> {{ticket.subject}}</a></h6>
            <span class="text-xs" v-if="employeesNames[ticket.assignedUserId]">
            {{ $t('ticket.info.assignee') }}: {{employeesNames[ticket.assignedUserId]}}
            </span>
            <span class="text-xs" v-else>{{ $t('ticket.info.assignee') }}: {{$t('ticket.ticketTabs.reply.message.noAssignee')}}</span>
            <span class="text-xs pull-right">
              <i class="material-icons text-yellow" v-if="ticket.status == 'new'">brightness_1</i> 
              <i class="material-icons text-green" v-if="ticket.status == 'open'">brightness_1</i> 
              <i class="material-icons text-blue" v-if="ticket.status == 'pending'">brightness_1</i> 
              <i class="material-icons text-red" v-if="ticket.status == 'closed'">brightness_1</i>  
              {{$t('ticket.sorts.'+ticket.status)}}
            </span>
            <p class="m-b-xs m-t-xs"><a class="msg-blue" href="" v-on:click.prevent="isActiveAssociate=true">{{$t('btnNames.change')}}</a></p>
          </div>
          <div v-else>
            <a class="msg-blue" href="" v-on:click.prevent="isActiveAssociate=true">{{$t('header.messanger.associateText')}}</a>
          </div>
        </div>

        <!-- Dane konta na fb do kotrego jest podpieta konwersacja -->
        <div class="b-b p-y p-x-xs p-b-xs">
          <div href="" class="pull-left w-40 m-l m-t-sm" >
            <img :src="pageUrl" alt="..." class="w-full img-circle">
          </div>
          <h6 class="primary-color m-t-sm m-b-xs" style="margin-left: 4rem">
          {{selectedHeaderCoversation.participants.data.filter(el => el.id == selectedHeaderCoversation.pageId)[0].name }}
          </h6>
          <div>
          <form class="form-horizontal">
            <div class="form-group row">
              <label class="col-sm-2 form-control-label text-muted" style="padding-right: 0px; padding-left: 8px;">
                {{$t('header.messanger.assignee')}}:
              </label>
              <div class="col-sm-7 m-t-xs" style="padding-left: 0px;">
                <select2 :options="optionsEmployee" class="form-control" style ="width: 100%;" v-model="selectedHeaderCoversation.assignedUserId" v-on:input="changeAssignee">
                </select2>
              </div>
            </div>
          </form>
          </div>
        </div>

        <!-- tresc konwersacji -->
        <div class="row-row">
          <div class="row-body scrollable" id="scroll">
            <div class="row-inner">
              <div class="p-a-md" v-if="messages.length > 0">

                <div class="m-b" v-for="m in evenMessages">
                  <!-- jesli id conversacji jest inne niz wybranej strony to ejst to klient -->
                  <div v-if="m.from.id != selectedHeaderCoversation.pageId">
                    <div href="" class="pull-left w-40 m-r-sm" >
                      <img :src="customerUrl" alt="..." class="w-full img-circle">
                    </div>

                    <div class="clear">
                      <div class="p-a p-y-sm d-gray r">
                        <span class="text-muted">
                          <span>{{m.from.name}} </span>
                          {{$t('header.chat.says')}}:
                        </span>
                        <br/>
                        <nl2br v-if="m.message" tag="p" :text="m.message" />

                        <!-- attachemnt -->
                        <div v-if="m.attachments && m.attachments.data.length > 0">
                          <div v-for="a in m.attachments.data" :attach="a" v-if="a != null">
                            <a v-if="a.image_data" v-bind:href="a.image_data.url" target="_blank" >
                              <img class="img-fluid" :src="a.image_data.preview_url" v-if="a.image_data.animated_gif_preview_url">
                              <img class="img-fluid" :src="a.image_data.preview_url" v-else>
                            </a>
                            <a class="msg-blue" v-else v-bind:href="a.file_url" target="_blank">
                              {{a.name}}
                            </a>
                          </div>
                        </div>
                        <!-- attachemnt -->

                      </div>

                      <div class="text-muted text-xs m-t-xs">
                        <i class="fa fa-ok text-success"></i>  
                        {{ m.created_time | moment('from') }}
                      </div>

                    </div>
                  </div>

                  <!-- jesli id jest takie samo to naewno jest z naszej podlacznoej stroiny -->
                  <div v-if="m.from.id == selectedHeaderCoversation.pageId">
                    <div href="" class="pull-right w-40 m-l-sm">
                      <gravatar v-if="m.author == 'user' && m.userId" class="w-full img-circle" 
                      :email="employeesEmails[m.userId]" default-img="identicon"/>
                      <img v-else :src="pageUrl" alt="..." class="w-full img-circle">
                    </div>
                    <div class="clear text-right">

                      <div class="p-a p-y-sm msg-blue-bg text-white text-left r">
                        <span class="text-muted" v-if="m.author == 'user' && m.userId">
                          <span>{{employeesNames[m.userId]}} </span>
                          {{$t('header.chat.says')}}:<br/>
                        </span>
                        <nl2br v-if="m.message" tag="p" :text="m.message" />

                        <!-- attachemnt -->
                        <div v-if="m.attachments && m.attachments.data.length > 0">
                          <div v-for="a in m.attachments.data" :attach="a" v-if="a != null">
                            <a v-if="a.image_data" v-bind:href="a.image_data.url" target="_blank" >
                              <img class="img-fluid" :src="a.image_data.preview_url" v-if="a.image_data.animated_gif_preview_url">
                              <img class="img-fluid" :src="a.image_data.preview_url" v-else>
                            </a>
                            <a class="text-white" v-else v-bind:href="a.file_url" target="_blank">
                              <i class="material-icons md-16">filter</i> {{a.name}}
                            </a>
                          </div>
                        </div>
                        <!-- attachemnt -->

                      </div>
                      <div class="text-muted text-xs m-t-xs">{{ m.created_time | moment('from') }}</div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="p-a-md" v-else>
                <div class="m-b text-muted">{{$t('header.chat.noMessages')}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- pisanie wiadomosci -->
        <div class="p-a b-t dker">
          <div class="form-group l-h m-a-0">
            <!-- btn uplad files -->
            <div class="form-file pull-left">
              <input type="file" ref="files" multiple v-on:change="onFilesInputChange" style="display: none;">
              <a :title="$t('header.chat.upload')" v-on:click.prevent="addFiles">
                <i class="material-icons m-r-xs m-t-sm msg-blue">attach_file</i> 
              </a>
            </div>
            <!-- btn uplad files -->
            <!-- input content msg -->
            <div class="input-group">
              <textarea class="form-control form-control-sm" :placeholder="$t('header.chat.type')" id="msg-area" 
              v-on:keyup="autoGrow" v-on:keyup.enter="sendMessage" v-model="msg" v-bind:class="{ disabled: isLoaded }"
              ></textarea>
              <span v-if="isLoaded" class="text-muted" style="position: absolute; left: 10px; bottom: 10px; z-index: 999999;">
                {{$t('header.messanger.sending')}}
              </span>
            </div>
            <!-- input content msg -->
            <!-- files list -->
            <div class="list-group list-group-gap m-t-sm" >
              <div v-for="(file, key) in files">

                <a class="file-link" target="_blank" v-bind:href="file.fileUrl" 
                v-bind:class="{ 'text-info': file.isUrlActive, 'disabled': file.isDisabled}">
                  {{ file.file.name | truncate(25)}}
                </a>
                <span class="primary-color p-l-xs text-xs">({{ file.file.formatSize }})</span>
                <a class="primary-color pull-right p-r-sm" href="#" v-on:click="removeFile( key )">
                  <i class="material-icons md-14"></i>
                </a>
                
                <div class="progress m-b-xs none-radius" v-if="file.fileUrl == null"
                v-bind:class="{ 'none-display': isErrorUpload}" style="width: 50%; height: 0.7rem;">
                  <div class="progress-bar primary none-radius" style="font-size: 0.55rem;" 
                  v-bind:style="{ width: file.progress + '%' }">
                    {{file.progress}} %
                  </div>
                </div>
                <div v-if="isErrorUpload"  class="upload-error text-xs">
                  <span class="text-red">{{$t('header.chat.errOccured')}}</span>
                </div>

              </div>

            </div>
            <!-- files list -->
          </div>
        </div>
        <!-- END pisanie wiadomosci -->
      </div>
    </div>


  </div>
 </li>
</template>

<script>
import Gravatar from 'vue-gravatar';
import Select2 from '../Select2.vue';
import EmployeeManager from '../../EmployeeManager.js';
import MessengerManager from '../../MessengerManager.js';
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import $ from 'jquery';
import Nl2br from 'vue-nl2br'
import EventBus from '../../event-bus.js';

var timeoutHandler = null;

export default {
  name: 'Messanger',
  components: {
    Gravatar,
    Select2,
    RotateSquare5,
    Nl2br
  },
  data: function () {
    return {
      employees: [],
      employeesNames: {},
      employeesEmails: {},
      headerConversations: [],
      ticket: {},
      selectedHeaderCoversation: null,
      optionsEmployee: [],
      isActiveAssociate: false,
      searchTicketId: '',
      inSearch: false,
      noTicket:false,
      inProgress:false,
      searchTicket: null,
      isActive: false,
      isConversation: false,
      search:'',
      msg: '',
      messages:[],
      pageUrl:'',
      customerUrl:'',
      windowH: '',
      isLoaded: false,
      isNewMesssage: false,
      typingLock: false,
      noConversation: false,
      files: [],
      isErrorUpload: false
    }
  },
  created: function() {
    this.getEmployees();
    this.getLastHeaderConversations(); 
    let h = window.innerHeight;
    this.windowH = h - 54;

    MessengerManager.events.on('newMessageOnlyCount', this.addCount);
    MessengerManager.events.on('newMessage', this.addNewMessage);
    MessengerManager.events.on('page_changed', this.getLastHeaderConversations);
    window.addEventListener('keyup', this.handleKeyUp);
    EventBus.$on('openConversation', this.openConversation);
  },
  destroyed: function() {
    MessengerManager.events.removeListener('newMessageOnlyCount', this.addCount);
    MessengerManager.events.removeListener('newMessage', this.addNewMessage);
    MessengerManager.events.removeListener('page_changed', this.getLastHeaderConversations);
    window.removeEventListener('keyup', this.handleKeyUp);
    EventBus.$off('openConversation', this.openConversation);
  },
  computed: {
    evenHeaderConversations: function () {
      return this.headerConversations.sort(function(a, b) {
        var dateA = new Date(a.updated_time), dateB = new Date(b.updated_time);
        return dateB - dateA;
      });
    },
    evenMessages: function () {
      return this.messages.sort(function(a, b) {
        var dateA = new Date(a.created_time), dateB = new Date(b.created_time);
        return dateA - dateB;
      });
    }
  },
  methods: {
    openConversation(c) {
      this.isActive = true;
      this.getMessagesWithSelected(c.id, c.pageId);

    },
    addCount(idx) {
      this.headerConversations[idx].unread_count += 1;
      if (this.isActive) return;
      this.isNewMesssage = true;
    },
    addNewMessage(data) {
      if (data.hasOwnProperty('idx')) {
        this.messages.push(data.msg);
        this.headerConversations[data.idx].unread_count += 1;
        this.scrollToBottom();
        return;
      }
      this.messages.push(data.msg);
      this.scrollToBottom();
    },
    getLastHeaderConversations: function() {
      this.noConversation = true;
      MessengerManager.getLastConversations().then((headerConversations) => {
        this.headerConversations = headerConversations;
        if (this.headerConversations.length > 0) this.noConversation = false;
      })
    },
    sendMessage: async function() {
      let pageId = this.selectedHeaderCoversation.pageId;
      let reciverId = this.selectedHeaderCoversation.participants.data.filter(el => el.id != this.selectedHeaderCoversation.pageId)[0].id;

      this.isLoaded = true;
      try {
        await Api.fb.typingOff(pageId, reciverId);

        if (this.msg) {
          await MessengerManager.sendMessage(pageId, reciverId, this.msg);
        }

        if (this.files.length) {
          for (var i = this.files.length - 1; i >= 0; i--) {
            if (!this.files[i].fileType) continue;
            let type = this.files[i].fileType.split('/')[0];
            if (type != 'image') type = 'file';

            let data = {
              "attachment": {
                  "type": type,
                  "payload": {
                      "url": this.files[i].fileUrl,
                      "is_reusable": true
                  }
              }
            }
            await MessengerManager.sendMessage(pageId, reciverId, data);

          }
        }

      } catch (e) {
        this.$notify({group: 'global', type: 'notification-error', text: this.$i18n.t('header.messanger.errorSend')})
      }

      this.msg = '';
      this.files.length = 0;
      this.isLoaded = false;

    },
    getMessagesWithSelected: function(id, pageId) {
      MessengerManager.getMessagesConversations(pageId, id).then((messages) => {
        this.messages = messages;
        this.ticket = {};
        this.selectedHeaderCoversation = this.headerConversations.filter(el => el.id == id)[0];
        

        let pageId = this.selectedHeaderCoversation.pageId;
        let senderId = this.selectedHeaderCoversation.participants.data.filter(el => el.id != this.selectedHeaderCoversation.pageId)[0].id;
        let promises = [];

        let p = Api.fb.getImgUrl(pageId, 'me').then((data) => {
          this.pageUrl = data.url;
        });
        let d = Api.fb.getImgUrl(pageId, senderId).then((data) => {
          this.customerUrl = data.url;
        }).catch((reson) => {
          this.customerUrl = '';
        });
        
        promises.push(p,d);

        Promise.all(promises).then((c) => {
          if (this.selectedHeaderCoversation.relations && this.selectedHeaderCoversation.relations.length) {
            Api.getTicket(this.selectedHeaderCoversation.relations[0].objectId).then((ticket) =>{
              this.ticket = ticket;
              this.scrollToBottom();
              this.isConversation = true;
            });
          } else {
            this.scrollToBottom();
            this.isConversation = true;
          }
        })
      });
      
    },
    changeAssignee: function() {
      let data = {assignedUserId: this.selectedHeaderCoversation.assignedUserId};
      Api.fb.updateConverastion(this.selectedHeaderCoversation.pageId, this.selectedHeaderCoversation.id, data).then((conversation) => {
      });
    },
    removeFile( key ){
      this.files.splice( key, 1 );
    },
    addFiles: function() {
      this.$refs.files.click();
    },
    onFilesInputChange: function() {
      let uploadedFiles = this.$refs.files.files;
      this.handleFilesUpload(uploadedFiles);
    },
    formatBytes(bytes,decimals) {
      if(bytes == 0) return '0 Bytes';
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    handleFilesUpload(uploadedFiles){
      for( var i = 0; i < uploadedFiles.length; i++ ){

        let formatSize = this.formatBytes(uploadedFiles[i].size);
        uploadedFiles[i].formatSize = formatSize;

        let fObj = {
          file: uploadedFiles[i],
          progress: 0,
          fileUrl: null,
          isUrlActive: false,
          isDisabled: true
        };

        this.files.push(fObj);

        let formData = new FormData();
        formData.append('file', uploadedFiles[i]);

        this.isErrorUpload = false;

        Api.uploadFile(formData, (progressEvent) => {
          fObj.progress = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        })
        .then((ob) => {
          fObj.fileUrl = ob.fileUrl;
          fObj.fileType = ob.fileMeta ? ob.fileMeta.mime : '';
          fObj.isUrlActive = true;
          fObj.isDisabled = false;
        }).catch((reson) => {
          this.isErrorUpload = true;
        });
      }
    },
    onSearchTicketId: function() {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        this.inSearch = true;
        this.noTicket = false;

        Api.getTicket(this.searchTicketId).then((ticket) => {
          this.searchTicket = ticket;
          this.noTicket = false;
          this.inSearch = false;
        }).catch((r) => {
          this.searchTicket = {};
          this.noTicket = true;
          this.inSearch = false;
        });
      }, 1000);
    },
    associateTicket: function() {
      let data = { relations: [{objectId:this.searchTicket.id, objectType: "ticket"}] };
      Api.fb.updateConverastion(this.selectedHeaderCoversation.pageId, this.selectedHeaderCoversation.id, data)
      .then((conversation) => {
        this.ticket = this.searchTicket;
        this.selectedHeaderCoversation.relations[0].objectId = this.searchTicket.id;
        this.isActiveAssociate = false;
        this.searchTicket = null;
        this.searchTicketId = '';
      })
    },
    open: function() {
      this.isActive = true;
      this.isNewMesssage = false;
    },
    close: function() {
      this.isActive = false;
      this.isConversation = false;
      MessengerManager.selectedHeaderCoversation = null;
    },
    closeConversation: function() {
      this.isConversation = false;
      MessengerManager.selectedHeaderCoversation = null;
    },
    handleKeyUp: function(e) {
      if (e.keyCode == 27 && this.isActive) {
        this.close();
      }
    },
    getEmployees: function() {
      EmployeeManager.getEmployees().then((employees) => {
        this.employees = employees;
        employees.forEach((e) => {
          this.employeesNames[e.id] = e.name;
          this.employeesEmails[e.id] = e.email;
        });

        this.optionsEmployee = employees.map(e => {
          return {id: e.id, text: e.name};
        })
      });
    },
    autoGrow: function() {
      let element = document.getElementById('msg-area');
      element.style.height = "60px";
      element.style.height = (element.scrollHeight)+"px";

      if (!this.typingLock) {
        let pageId = this.selectedHeaderCoversation.pageId;
        let reciverId = this.selectedHeaderCoversation.participants.data.filter(el => el.id != this.selectedHeaderCoversation.pageId)[0].id;
        Api.fb.typingOn(pageId, reciverId).then((data) =>{});
        this.typingLock = true;
        setTimeout(() => {
          this.typingLock = false;
          Api.fb.typingOff(pageId, reciverId).then((data) =>{});
        }, 3000);
      }
    },
    scrollToBottom: function() {
      setTimeout(function() {
        $('#scroll').stop().animate({
          scrollTop: $('#scroll')[0].scrollHeight
        }, 1000);
      }, 200);
    },
  }
}
</script>
<style scoped lang="scss">
  .up-custom {
    position: relative;
    top: -10px;
    min-width: 4px;
    min-height: 4px;
    display: inline-block;
  }
  .msg-blue {
    color: #0078ff;
  }
  .msg-blue:hover {
    color: #0060cc;
  }
  .msg-blue-bg {
    background-color: #0078ff;
  }
  #msg-area {
    resize: none;
    overflow: hidden;
    height: 60px;
    width: 360px;
  }
  .todisplay-messager {
    display: block;
    width: 10px;
    height: 10px;
  }
  #conversation-window.modal {
    position: absolute;
    left: 20px;
    font-size: 0.8rem;
    .right {
      right: 280px;
    }
    .w-xxxl{
      width: 420px;
    }
  }
  #msg-area.disabled {
    background-color: #efefef;
  }

  .pulse {
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0,120,255, 0.4);
    border-radius: 50%;
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0,120,255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 14px rgba(0,120,255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,120,255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0,120,255, 0.4);
      box-shadow: 0 0 0 0 rgba(0,120,255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 14px rgba(0,120,255, 0);
        box-shadow: 0 0 0 14px rgba(0,120,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0,120,255, 0);
        box-shadow: 0 0 0 0 rgba(0,120,255, 0);
    }
  }
</style>