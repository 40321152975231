import Vue from 'vue'
import VueRouter from 'vue-router'
import VueTruncate from 'vue-truncate-filter'
// Daty wyswietlają się teraz tylko po polsku DO POPRAWY!
const moment = require('moment')
require('moment/locale/pl')
import VueMoment from 'vue-moment'

import Notifications from 'vue-notification'
import App from './App.vue'
import $ from 'jquery'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/libjs/bootstrap.js'
import 'pace-js'
// import './assets/libjs/pace.min.js'
// import './assets/libjs/tether.min.js'
import './assets/libjs/underscore-min.js'
import VueCharts from 'vue-chartjs'

import "vue-wysiwyg/dist/vueWysiwyg.css";

import VueLocalStorage from 'vue-localstorage'
import VTooltip from 'v-tooltip'
import TicketsList from './components/TicketsList/TicketsList.vue'
import MailsPage from './components/MailsPage/MailsPage.vue'

import SettingsPage from './components/SettingsPage/SettingsPage.vue'
import Prefernces from './components/SettingsPage/Prefernces.vue'
import UsersSettings from './components/SettingsPage/UsersSettings.vue'
import UserGroups from './components/SettingsPage/UserGroups.vue'
import Billing from './components/SettingsPage/Billing.vue'
import EmailSettingPage from './components/SettingsPage/EmailSettingPage.vue'
import VoipPage from './components/SettingsPage/VoipPage.vue'
import ShopsSettingPage from './components/SettingsPage/ShopsSettingPage.vue'
import TagsPage from './components/SettingsPage/TagsPage.vue'
import PermissionsSetting from './components/SettingsPage/PermissionsSetting.vue'
import EmailClient from './components/SettingsPage/EmailClient.vue'
import LiveChat from './components/LiveChat/LiveChat.vue'

import ReportsPage from './components/ReportsPage/ReportsPage.vue'
import TicketDetails from './components/TicketDetails/TicketDetails.vue'
import EmployeeProfile from './components/EmployeeDetails/EmployeeProfile.vue'

import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

import Login from './Login.vue'

import Confirmation from './components/Confirmation.vue';

import wysiwyg from "vue-wysiwyg";
import ToggleButton from 'vue-js-toggle-button'

import LanguageManager from './LanguageManager.js'
const i18n = LanguageManager.i18n;
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.use(VueTimepicker);
Vue.use(wysiwyg, {forcePlainTextOnPaste:false,});

import VueWindowSize from 'vue-window-size';
 
Vue.use(VueWindowSize);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueTruncate);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VTooltip)
Vue.use(ToggleButton)
// VUE Dialog
import VuejsDialog from "vuejs-dialog"
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
Vue.use(VuejsDialog);
const VIEW_NAME = 'my-unique-confirmation';
Vue.dialog.registerComponent(VIEW_NAME, Confirmation);

import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: true, transition: 'nice-modal-fade' } });

Vue.use(VueMoment, {moment});

Vue.use(VueLocalStorage);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

Vue.prototype.$n = {
  success: (text) => {
    Vue.notify({group: 'global', type: 'notification-success', text});
  },
  info: (text) => {
    Vue.notify({group: 'global', type: 'notification-info', text});
  },
  error: (text) => {
    Vue.notify({group: 'global', type: 'notification-error', text});
  }
};


const datepickerOptions = {
    colors: {
    selected: '#0cc2aa',
    inRange: '#66e2da',
    selectedText: '#fff',
    text: '#58666e',
    inRangeBorder: '#33dacd',
    disabled: '#fff',
  },
}

Vue.use(AirbnbStyleDatepicker, datepickerOptions)

const routes = [
  {path: '/', redirect: '/list'},
  // { path: '/', component: Login },
  { path: '/list', component: TicketsList },
  { path: '/mails', component: MailsPage },
  { path: '/ticket/:id', component: TicketDetails, name: 'ticket', props: true },
  { path: '/profile', component: EmployeeProfile, name: 'profile', props: true },
  { path: '/live-chat', component: LiveChat, name: 'live-chat' },
  { path: '/settings', component: SettingsPage, 
    children: [
      {path: '/settings', beforeEnter: (to, from, next) => next('/settings/prefernces')},
      { path: 'prefernces', component: Prefernces},
      { path: 'users-settings', component: UsersSettings },
      { path: 'groups-settings', component: UserGroups },
      { path: 'billing-settings', component: Billing },
      { path: 'email-settings', component: EmailSettingPage },
      { path: 'voip', component: VoipPage },
      { path: 'shops-settings', component: ShopsSettingPage },
      { path: 'tags', component: TagsPage },
      { path: 'permissions', component: PermissionsSetting },
      { path: 'email-client', component: EmailClient },
    ]
  },
  { path: '/reports', component: ReportsPage},
  {
    path: '/getstarted', component: function () {
      return import(/* webpackChunkName: "gettingstarted" */ './components/GettingStarted/GettingStarted.vue')
    }
  },

]

const router = new VueRouter({
  routes // short for `routes: routes`
});

import VueAnalytics from 'vue-analytics'
if (__GA_ID__ && window.location.host.indexOf('amelia.') == -1) {
  Vue.use(VueAnalytics, {
    id: __GA_ID__,
    router,
    fields: {
      instance: window.location.host.split('.')[0]
    }
  });
}

new Vue({
  i18n,
  localStorage: {
    isLogged: {
      type: Boolean,
      default: false
    }
  },
  render: h => h(App),
  router: router
}).$mount('#app')
