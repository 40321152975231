<template>
<form class="navbar-form form-inline pull-right pull-none-sm v-m" role="search" style="display: inline; text-align: right;">
  <div class="form-group l-h m-a-0" style="display: inline;">
    <div class="input-group search-input">
      <input class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.sorts.searchSub')" type="text" v-model="search" v-on:input="onFilterChange">
       <a class="close-btn" href="#" v-on:click.prevent="clearSearch"><i class="material-icons md-16">close</i></a>
    </div>
  </div>

  <div class="form-group l-h m-l" style="display: inline;">
    <div class="input-group search-input">
      <input class="form-control form-control-sm p-x b-a" :placeholder="$t('ticket.sorts.searchContent')" type="text" v-model="searchContent" v-on:input="onFilterChange">
       <a class="close-btn" href="#" v-on:click.prevent="clearSearchContent"><i class="material-icons md-16">close</i></a>
    </div>
  </div>

</form>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import FilterStorage from '../../FilterStorage.js';

var timeoutHandler = null;

export default {
  name: 'SearchFormTicketList',
  props: {

  },
  components: {
  },
  data: function () {
    return {
      search: '',
      searchContent: '',
    }
  },
  created: function() {
    if (FilterStorage.search) {
      this.search = FilterStorage.search;
    }
    if (FilterStorage.searchContent) {
      this.searchContent = FilterStorage.searchContent;
    }
  },
  methods: {
    onFilterChange: function() {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        FilterStorage.search = this.search;
        FilterStorage.searchContent = this.searchContent;
        FilterStorage.filterChange();
      }, 1000);
    },
    clearSearch: function() {
      if (this.search.length == 0) return;
      this.search = '';
      this.onFilterChange();
    },
    clearSearchContent: function() {
      if (this.searchContent.length == 0) return;
      this.searchContent = '';
      this.onFilterChange();

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
