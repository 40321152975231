import $ from 'jquery'
import Api from './Api.js';
import SystemSocket from './SystemSocket.js';
import ChatManager from './ChatManager.js';
import EmployeeManager from './EmployeeManager.js';
import EventEmitter from 'events';

let ding = require("./assets/sounds/sound4.wav");

function UserManager() {
    this.user = null;
    this.wizzardEnabled = false;
    this.token = localStorage.getItem('token');
    this.events = new EventEmitter();
    this.notifications = [];
    this.cannedResposnes = [];
    this.selectedLanguage = ''
    this.permissions = [];

    if (this.token) {
      Api.verifyUser(this.token).then((userInfo) => {
        this.user = userInfo;
        Api.setToken(this.token);
        SystemSocket.setToken(this.token);
        // ChatManager.setToken(this.token);
        Api.getUserNotifications().then((data) => {
          this.notifications = data;
          this.events.emit('notification_changed', this.notifications);
        }).catch(e => console.log("DUPA"));
        Api.getUserCannedResponses().then((data) => {
          this.cannedResposnes = data;
          this.events.emit('canned_resposnes_changed', this.cannedResposnes);
        });
        this.getUserLanguage();

        return EmployeeManager.getEmployees();
      }).then((employees) => {
        let empIds = employees.map((emp) => {
          return emp.id+"";
        }); 
        let idx = empIds.indexOf(this.user.userId+"");
        if (idx != -1) this.permissions = employees[idx].permissions;  

        this.events.emit('userReady', this.user);
      }).catch((reson) => {
        this.logOut();
      });
    }


    SystemSocket.socket.on('object', (msg) => {
      if (msg.object != 'notification') { return; }

      if (msg.verb == 'create') {
        let audio = new Audio(ding); 
        var playPromise = audio.play();
        this.notifications.push(msg.data);
        this.events.emit('notification_changed', this.notifications);
        return;
      }

      if (msg.verb == 'update') {
        let idx = this.notifications.map(n => n._id).indexOf(msg.data._id);
        if (idx != -1) this.notifications.splice(idx, 1);
        this.notifications.push(msg.data);
        this.events.emit('notification_changed', this.notifications);
        return;
      }

      if (msg.verb == 'delete') {
        let idx = this.notifications.map(n => n._id).indexOf(msg.data._id);
        if (idx != -1) this.notifications.splice(idx, 1);
        this.events.emit('notification_changed', this.notifications);
      }
    });
}

UserManager.prototype.logIn = function(email, pass) {
  return Api.logIn(email, pass).then((token) => {
    this.token = token;
    Api.setToken(token);
    localStorage.setItem('token', this.token);
    this.events.emit('loggedIn', this.token);
    
    Api.verifyUser(this.token).then((userInfo) => {
      SystemSocket.setToken(token);
      // ChatManager. setToken(token);
      this.user = userInfo;

      Api.getUserNotifications().then((data) => {
        this.notifications = data;
        this.events.emit('notification_changed', this.notifications);
      });
      Api.getUserCannedResponses().then((data) => {
        this.cannedResposnes = data;
        this.events.emit('canned_resposnes_changed', this.cannedResposnes);
      });
      this.getUserLanguage();
      return EmployeeManager.getEmployees();
    }).then((employees) => {
        let empIds = employees.map((emp) => {
          return emp.id+"";
        }); 
        let idx = empIds.indexOf(this.user.userId+"");
        if (idx != -1) this.permissions = employees[idx].permissions;
        this.events.emit('userReady', this.user);
    }).catch((reson) => {
      console.log(reson);
    });

    return token;
    
  }).catch((reson) => {
    console.log(reson);
  });

};

UserManager.prototype.logOut = function() {
  this.token = null;
  localStorage.removeItem('token');
  this.events.emit('loggedOut');
};

UserManager.prototype.isLogged = function() {

  return this.token != null;
};

UserManager.prototype.getUserLanguage = async function() {
  this.selectedLanguage = await Api.me.getFromStorage('user_selected_lang');
  if (!this.selectedLanguage) {
    let boolean = await Api.putInStorage('user_selected_lang', 'en');
    this.selectedLanguage = 'en';
  }
  this.events.emit('userLangReady');
}

UserManager.prototype.getUserLanguageOld = function() {
  Api.me.getFromStorage('user_selected_lang').then((res) => {
    console.log("pobieram ze storaga jezyk", res);
    let langReady = false;
    if (!res) {
      Api.putInStorage('user_selected_lang', 'en').then((boolean) => {
        this.selectedLanguage = 'en';
        langReady = true;
      }).catch((error) => {});
    } else {
      this.selectedLanguage = res;
      langReady = true;
    }
    if (langReady) this.events.emit('userLangReady');
  }).catch((error) => {});
}

UserManager.prototype.addCannedResponse = function(title, content) {
  let promise = new Promise((resolve, reject)=>{

    Api.addCannedResponse(title, content).then((data) => {
      this.cannedResposnes = data;
      this.events.emit('canned_resposnes_changed', this.cannedResposnes);
      resolve(this.cannedResposnes);
    }).catch((reson) => {
      reject(reson);
    });
  });
  
  return promise;
};

UserManager.prototype.deleteCannedResponse = async function(id) {
  await Api.deleteCannedResponse(id);
  let idx = this.cannedResposnes.map(c => c._id+"").indexOf(id+"");
  if (idx != -1) this.cannedResposnes.splice(idx, 1);

  this.events.emit('canned_resposnes_changed', this.cannedResposnes);

  return this.cannedResposnes;
};

UserManager.prototype.changeCannedResponse = async function(title, content, id) {
  let data = await Api.changeCannedResponse(title, content, id);
  let idx = this.cannedResposnes.map(c => c._id+"").indexOf(id+"");
  if (idx != -1) this.cannedResposnes.splice(idx, 1);
  this.cannedResposnes.push(data);
  this.events.emit('canned_resposnes_changed', this.cannedResposnes);

  return this.cannedResposnes;
};

var instance = new UserManager();

export default instance;