<template>
    <div >
        <p style="max-width: 500px;">
            <span class="text-u-c _600">To by było na tyle</span><br/>
            Super! Udało Ci się przejść przez podstawową konfigurację Swiftresponse.<br/>
            Pamiętaj, że wszystkie ustawienia, które tutaj zawarliśmy (ale również wiele wiele więcej) znajdziesz również w zakładce "Ustawienia"
        </p>

        <p style="max-width: 500px;">
            <span class="text-u-c _600">Baza wiedzy</span><br/>
            Gdyby coś sprawiało Ci problem zajrzyj do naszej <a href="https://swiftresponse.pl/knowledge-base" class="text-blue" target="_blank" title="Baza wiedzy">bazy wiedzy</a>
        </p>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('close')">Zamknij</a>
        </p>
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import EmployeeModal from '../SettingsPage/Modals/EmployeeModal.vue';


export default {
    name: 'SectionFinish',

    components: {
    },

    data: function () {
        return {
        }
    },

    created: async function() {
    },

    destroyed: function() {
    },

    computed: {
        test: function() {
        }
    },

    methods: {
    }
}
</script>

<style>

</style>