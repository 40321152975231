<template>
  <div class="email-settings">
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">Integracje z zewnętrzymi sklepami</h6>
      </div>

      <div class="row m-b-1">
        <div class="col-lg-4 col-md-4">
          <p class="m-a">
            <strong>Podłącz swój sklep do Swiftresponse</strong><br/>
            Po podłączeniu sklepu Swiftresponse przy każdym zgłoszeniu będzie sprawdzał, czy dany klient złożył zamówienie
            w sklepie. Jeśli tak, to wyświetli Ci te informacje w osobnym oknie. Wszystko możesz mieć w jednym miejscu!
          </p>
        </div>
        <div class="col-lg-8 col-md-8">
          <p class="m-a">
            <ul class="list inset" v-if="Array.isArray(shops) && shops.length">
              <li class="list-item" v-for="shop in shops" :key="shop._id">
                <div class="list-body">
                  <div>
                    <strong><i class="material-icons text-success md-18">check</i> {{ shop.storeName }}</strong> | {{ shop.storeEngine }} <a class="text-info text-sm" @click.prevent="editIntegration(shop)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-warning md-18">link</i>&nbsp;
                    <span v-if="shop.storeEngine == 'baselinker'">https://api.baselinker.com/connector.php</span>
                    <span v-else>{{ shop.apiUrl }}</span>
                  </small>
                </div>
              </li>
              <li class="list-item text-info">
                <a href="#" @click.prevent="editIntegration()"><i class="material-icons  md-18">add</i> Podłącz kolejny sklep</a>
                </li>
            </ul>
            <ul class="list inset" v-else>
              <li class="list-item p-a-0"><i class="material-icons text-warn md-18">cancel</i> Nie masz jeszcze żadnego sklepu</li>
              <li class="list-item p-a-0 text-info"><a href="#" @click.prevent="editIntegration()"><i class="material-icons  md-18">add</i> Podłącz pierwszy</a></li>
            </ul>
          </p>
        </div>  
      </div>

    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import EditShopIntegrationModal from './EditShopIntegrationModal.vue';
import Select2 from '../Select2.vue';

export default {
  name: 'ShopsSettingPage',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2
  },
  data: function () {
    return {
      shops: [],
      isLoaded: false
    }
  },
  created: function() {
    this.getShops();
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    getShops: function() {
      Api.shops.getAll().then((shops) => {
        this.shops = shops;
        this.isLoaded = true;
      }).catch((reson) => {
        this.showNotify('notification-error', this.$i18n.t('notifications.error'));
      });
    },

    editIntegration: function(shop) {
      this.$modal.show(EditShopIntegrationModal, {
        shop: shop
      }, {
        draggable: true,
        clickToClose: false,
        resizable: true,
        height: '600px'
      }, {
        'closed': (event) => {
          this.getShops();
        }
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .conection {
    background-color: rgba(12,194,170, 0.7);
  }
  .conection-error {
    background-color: rgba(240,80, 80, 0.7);
  }
  .conection-success {
    background-color: rgba(144,200,68, 0.7);
  }
  .start-info {
    min-height: 150px;
    .box2 {
      right: 85px;
    }
  } 
</style>
