<template>
    <div>
        <h6 class="title-color _600 inline">Odbiorca połaczenia</h6>
        <hr/>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b">
                    <label class="col-md-4 form-control-label label-sm m-t-xs">Wybierz</label>
                    <div class="col-md-8">
                        <v-select 
                            class="form-control"
                            :options="emps"
                            label="name"
                            v-model="seelcteEmps"
                            >
                        </v-select>
                    </div>
                </div>
                <a  class="text-info" @click.prevent="remove">Usuń wezeł</a>
            </form>
        </div>
        <hr/>
    </div>
</template>
<script>
import EmployeeManager from '../../../EmployeeManager.js';
export default {
    name: 'NodeUser',
    props: {
        
    },
    components: {
        
    },
    data: function () {
        return {
            seelcteEmps:null,
            emps: [],
        }
    },
    created: async function() {
        try {
            this.emps = await EmployeeManager.getEmployees();
            
        } catch (error) {
            
        }
    },
    
    methods: {
        remove: function() {}
       
    }
}
</script>