<template>
    <div class="box">
        <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">{{ $t('settings.billing.title') }}</h6>
        </div>

        <div class="box-body fb-box" v-if="!isReady">
            <Spinner></Spinner>
        </div>

        <div class="box-body row" v-if="isReady">
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="box m-a">
                    <div class="box-header d-gray">
                        <h6 class="text-muted _400 inline">{{ $t('settings.billing.invoiceData') }} <rotate-square2 v-if="isSavingCustomerInfo" style="position:absolute; right: 15px; top: 0px;"></rotate-square2></h6>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-sm-4 form-control-label">{{ $t('settings.billing.company') }}</label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model="customerInfo.invoice.company" v-on:input="saveCustomerInfo">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 form-control-label">{{ $t('settings.billing.taxNo') }}</label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model="customerInfo.invoice.taxNo">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 form-control-label">{{ $t('settings.billing.address') }}</label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model="customerInfo.invoice.address">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 form-control-label">{{ $t('settings.billing.zipCode') }}</label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model="customerInfo.invoice.zipCode">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 form-control-label">{{ $t('settings.billing.city') }}</label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model="customerInfo.invoice.city">
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="box m-a">
                    <div class="box-header d-gray">
                        <h6 class="text-muted _400 inline">{{ $t('settings.billing.plannedCost.title') }}</h6>
                    </div>
                    <div class="box-body">
                        <div class="row" v-if="this.plannedCost.usersLogins">
                            <span class="text-right col-xl-8 col-lg-8">{{$t('settings.billing.plannedCost.users')}}:</span>
                            <span class="text-muted col-xl-3 col-lg-4">{{Object.keys(this.plannedCost.usersLogins).length }}</span>
                        </div>
                        <div class="row" v-if="this.plannedCost.monthlyFee">
                            <span class="text-right col-xl-8 col-lg-8">{{$t('settings.billing.plannedCost.monthlyFee')}}:</span>
                            <span class="text-muted col-xl-4 col-lg-4">
                                {{Object.keys(this.plannedCost.usersLogins).length * this.plannedCost.monthlyFee *parseFloat(this.plannedCost.ratio).toFixed(2) }}
                                <span class="m-l" v-if="this.plannedCost.feeCurrency">
                                    {{this.plannedCost.feeCurrency}}
                                </span>
                            </span>
                        </div>
                        <div class="row" v-if="this.plannedCost.ratio">
                            <span class="text-right col-xl-8 col-lg-8">{{$t('settings.billing.plannedCost.ratio')}}:</span>
                            <span class="text-muted col-xl-4 col-lg-4">
                                {{ parseFloat(this.plannedCost.ratio).toFixed(2) }}*<br/>
                            </span>
                            <span class="text-muted text-xs col-xl-12 m-t-sm text-right">
                                *{{$t('settings.billing.plannedCost.ratioTxt')}}
                            </span>
                        </div>
                        <div class="row m-t-md box-c m-x-sm" v-if="this.plannedCost.trialEndsOn">
                            <span class="text-right col-xl-8 col-lg-8">
                                {{$t('settings.billing.plannedCost.date')}}:
                            </span>
                            <span class="col-xl-4 col-lg-4">
                                {{ this.plannedCost.trialEndsOn | moment("DD MMMM YYYY") }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';

let saveTimeout = null;

export default {
    name: 'BillingSettingsPage',
    props: {
        
    },
    components: {
        Spinner,
        RotateSquare5,
        Select2
    },
    data: function () {
        return {
            EmployeeManager,
            customerInfo: { invoice: {} },
            plannedCost: {},
            isSavingCustomerInfo: false,
            isReady: false
        }
    },
    mounted: async function() {
        try {
            let info = await Api.system.getCustomerInfo();
            if (!info.invoice) info.invoice = {};
            this.customerInfo = info;

            this.plannedCost = await Api.system.getCostEstimation();
        } catch (error) {}
        this.isReady = true;
    },
    destroyed: function() {
    },
    computed: {
    },
    methods: {
        saveCustomerInfo: function () {
            clearTimeout(saveTimeout);
            saveTimeout = setTimeout(async () => {
                this.isSavingCustomerInfo = true;
                try {
                    await Api.system.saveCustomerInfo(this.customerInfo);
                    this.$notify({group: 'global', type: 'notification-success', text: this.$i18n.t('notifications.changeSuccess')});
                } catch (e) { }
                this.isSavingCustomerInfo = false;
            }, 1000);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-c {
    border: 1px solid #5bc0de;
    color: #5bc0de;
    padding: 10px 20px;
}
</style>
