<template>
    <div class="box">
        <div class="box-header">
            <h3>Edycja integracji</h3>
            <small>Każda wiadomość, która trafi na ten komunikator<br/>zostanie automatycznie zamieniona na zgłoszenie</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">

            <div v-if="type == 'fb'" class="box box-body">
                <p>Nazwa konta: {{ editedBox.name }}<br/><span class="text-muted text-sm">PageID: {{ editedBox.pageId }}</span></p>
            </div>

            <div v-if="type == 'ig' && editedBox.status == 'invalid-user'" class="box box-body box-error danger">
                <p>Integracja nie jest podłączona<br/>
                Nie odnaleziono takieg konta na Instagramie</p>
            </div>

            <div v-if="type == 'ig' && editedBox.status == 'wrong-pass'" class="box box-body box-error danger">
                <p>Integracja nie jest podłączonaa<br/>
                Podane hasło jest niepoprawne</p>
            </div>

            <div v-if="type == 'ig' && editedBox.status == 'error'" class="box box-body box-error danger">
                <p>Integracja nie jest podłączonaa<br/>
                Wystąpił bliżej nieokreślony bład</p>
            </div>

            <form role="form">
                <div class="form-group" v-if="type == 'ig' && editedBox.status == 'need-verification'">
                    <label for="exampleInputEmail1">Wprowadź kod weryfikacji</label>
                    <input class="form-control" type="text" v-model="editedBox.securityCode">
                </div>

                <div class="form-group" v-else-if="type == 'ig' && editedBox.status != 'logged'">
                    <label for="exampleInputEmail1">Wprowadź nowe hasło</label>
                    <input class="form-control" type="password" v-model="editedBox.pass">
                </div>

                <label class="md-check">
                    <input type="checkbox" value="automatic" v-model="editedBox.sendReceiveConfirm">
                    <i class="primary m-r-xs"></i>
                    <span class="m-l-xs">Potwierdzenie otrzymania wiadomości dla klienta</span>
                </label>
                <textarea style="min-height: 300px" class="form-control form-control-sm comment-box-input m-t" v-model="editedBox.receiveConfirmContent" ></textarea>
                <span class="help-block text-sm m-b-none text-muted">
                    Możesz użyć znaczników - zostaną one zamieniona na odpowiednią wartość przed wysłaniem:<br/>
                    [ID] - Numer zgłoszenia<br/>
                    <!-- [SUBJECT] - Temat wiadomosci od klienta -->
                </span>

                <label class="md-check m-t">
                    <input type="checkbox" value="a" v-model="editedBox.autoAssign">
                    <i class="primary m-r-xs"></i>
                    <span class="m-l-xs">Automatycznie przypisz pracownika do zgłoszenia</span>
                </label>
            </form>
            <div class="row p-a-1">
                <div class="col-lg-4 col-lg-offset-8">
                    <a href="#" class="text-blue" v-if="!confirmingRemove" @click.prevent="confirmingRemove = true">Usuń integrację</a>
                    <span v-if="confirmingRemove" class="text-yellow">
                        Czy jesteś pewien?<br/>
                        <a href="#" class="text-green p-r-1" @click.prevent="remove()">Tak</a>
                        <a href="#" class="text-red" @click.prevent="confirmingRemove = false">Nie</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'InternalBoxModal',
    props: {
        type: String,
        conf: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            editedBox: {},
            address: '',
            host: window.location.host,
            isProcessing: false,
            confirmingRemove: false,
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        this.editedBox = JSON.parse(JSON.stringify(this.conf)); //deep-copy

        if (!this.editedBox.receiveConfirmContent) {
            this.editedBox.receiveConfirmContent = "Dziękujemy za przesłaną wiadomość!\n";
            this.editedBox.receiveConfirmContent += "Została ona zarejestrowana w naszym systemie pod numerem #[ID]\n\n";
            this.editedBox.receiveConfirmContent += "Postaramy się odpowiedzieć jak najszybciej na Twoje zgłoszenie.\n";
        }
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },

        remove: async function (s) {
            let method = this.type == 'fb' ? 'removeFBIntegration' : 'removeIGIntegration';
            let key = this.type == 'fb' ? this.editedBox.pageId : this.editedBox.username;
            Api.tickets[method](key).then(box => {
                this.$notify({
                    group: 'global',
                    type: 'notification-success',
                    text: this.$i18n.t('notifications.removeSuccess'),
                });
                this.$emit('close');
            }).catch(e => {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: this.$i18n.t('notifications.removeError'),
                });
            });
        },

        save: function (s) {
            let method = this.type == 'fb' ? 'updateFBIntegration' : 'updateIGIntegration';
            Api.tickets[method](this.editedBox).then(box => {
                this.$notify({
                    group: 'global',
                    type: 'notification-success',
                    text: this.$t('notifications.changeSuccess'),
                });
                this.$emit('close');
            }).catch(e => {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: this.$t('notifications.changeError'),
                });
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
