<template>
    <svg v-bind:style="styles" class="spinner spinner--circle-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g class="anim-0">
        <circle cx="50" cy="50" r="50" v-bind:fill="background" />
      </g>
      <g class="anim-1">
        <circle cx="50" cy="50" r="5" fill="white" />
      </g>
      <g class="anim-2">
        <circle cx="75" cy="50" r="5" fill="white" />
        <line x1="25" y1="50" x2="75" y2="50" stroke="white" stroke-width="3" />
      </g>
      <g class="anim-3">
        <circle cx="50" cy="25" r="5" fill="white" />
        <line x1="50" y1="25" x2="25" y2="75" stroke="white" stroke-width="3" />
        <line x1="50" y1="25" x2="75" y2="75" stroke="white" stroke-width="3" />
      </g>
      <g class="anim-4">
        <circle cx="75" cy="25" r="5" fill="white" />
        <line x1="75" y1="25" x2="25" y2="25" stroke="white" stroke-width="3" />
      </g>
    </svg>
</template>
<script>
export default {
  props: {
    size: {
      default: '40px'
    },
    background: {
      default: '#41b883'
    }
  },
  computed: {
    styles () {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  /* Generated with Bounce.js. Edit at http://goo.gl/hYAzd9 */
  @keyframes circle-6-rotateAll {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.777778% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    78.518519% { transform: matrix3d(0.80259, 0.59653, 0, 0, -0.59653, 0.80259, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    79.259259% { transform: matrix3d(0.52673, 0.85004, 0, 0, -0.85004, 0.52673, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80% { transform: matrix3d(0.60234, 0.79824, 0, 0, -0.79824, 0.60234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80.740741% { transform: matrix3d(0.72936, 0.68413, 0, 0, -0.68413, 0.72936, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    81.481481% { transform: matrix3d(0.7498, 0.66167, 0, 0, -0.66167, 0.7498, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.222222% { transform: matrix3d(0.71527, 0.69885, 0, 0, -0.69885, 0.71527, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.962963% { transform: matrix3d(0.69404, 0.71994, 0, 0, -0.71994, 0.69404, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.703704% { transform: matrix3d(0.69926, 0.71487, 0, 0, -0.71487, 0.69926, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    84.444444% { transform: matrix3d(0.7089, 0.70531, 0, 0, -0.70531, 0.7089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.185185% { transform: matrix3d(0.7106, 0.7036, 0, 0, -0.7036, 0.7106, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.925926% { transform: matrix3d(0.70776, 0.70645, 0, 0, -0.70645, 0.70776, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    86.666667% { transform: matrix3d(0.70608, 0.70813, 0, 0, -0.70813, 0.70608, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    87.407407% { transform: matrix3d(0.70649, 0.70773, 0, 0, -0.70773, 0.70649, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.148148% { transform: matrix3d(0.70725, 0.70696, 0, 0, -0.70696, 0.70725, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.888889% { transform: matrix3d(0.70738, 0.70683, 0, 0, -0.70683, 0.70738, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.70711, 0.70711, 0, 0, -0.70711, 0.70711, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: matrix3d(0.70711, 0.70711, 0, 0, -0.70711, 0.70711, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/DEm6fe */

  @keyframes circle-6-anim-1-circle {
    0% { transform: scale(0); }
    0.740741% { transform: matrix3d(0.32778, 0, 0, 0, 0, 0.32778, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    1.481481% { transform: matrix3d(0.69875, 0, 0, 0, 0, 0.69875, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    2.222222% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    2.962963% { transform: matrix3d(1.1815, 0, 0, 0, 0, 1.1815, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    3.703704% { transform: matrix3d(1.24402, 0, 0, 0, 0, 1.24402, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    4.444444% { transform: matrix3d(1.21871, 0, 0, 0, 0, 1.21871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    5.185185% { transform: matrix3d(1.14702, 0, 0, 0, 0, 1.14702, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    5.925926% { transform: matrix3d(1.06589, 0, 0, 0, 0, 1.06589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.666667% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    7.407407% { transform: matrix3d(0.9603, 0, 0, 0, 0, 0.9603, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    8.148148% { transform: matrix3d(0.94663, 0, 0, 0, 0, 0.94663, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    8.888889% { transform: matrix3d(0.95217, 0, 0, 0, 0, 0.95217, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    9.62963% { transform: matrix3d(0.96784, 0, 0, 0, 0, 0.96784, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.37037% { transform: matrix3d(0.98559, 0, 0, 0, 0, 0.98559, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    11.111111% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    11.851852% { transform: matrix3d(1.00868, 0, 0, 0, 0, 1.00868, 0, 0, 0, 0, 1, 0, -9.51676, 0, 0, 1); }
    12.592593% { transform: matrix3d(1.01167, 0, 0, 0, 0, 1.01167, 0, 0, 0, 0, 1, 0, -18.75576, 0, 0, 1); }
    13.333333% { transform: matrix3d(1.01046, 0, 0, 0, 0, 1.01046, 0, 0, 0, 0, 1, 0, -25.26155, 0, 0, 1); }
    14.074074% { transform: matrix3d(1.00703, 0, 0, 0, 0, 1.00703, 0, 0, 0, 0, 1, 0, -28.53849, 0, 0, 1); }
    14.814815% { transform: matrix3d(1.00315, 0, 0, 0, 0, 1.00315, 0, 0, 0, 0, 1, 0, -29.2499, 0, 0, 1); }
    15.555556% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -28.45173, 0, 0, 1); }
    16.296296% { transform: matrix3d(0.9981, 0, 0, 0, 0, 0.9981, 0, 0, 0, 0, 1, 0, -27.09752, 0, 0, 1); }
    17.037037% { transform: matrix3d(0.99745, 0, 0, 0, 0, 0.99745, 0, 0, 0, 0, 1, 0, -25.82591, 0, 0, 1); }
    17.777778% { transform: matrix3d(0.99771, 0, 0, 0, 0, 0.99771, 0, 0, 0, 0, 1, 0, -24.9428, 0, 0, 1); }
    18.518519% { transform: matrix3d(0.99846, 0, 0, 0, 0, 0.99846, 0, 0, 0, 0, 1, 0, -24.50122, 0, 0, 1); }
    19.259259% { transform: matrix3d(0.99931, 0, 0, 0, 0, 0.99931, 0, 0, 0, 0, 1, 0, -24.40907, 0, 0, 1); }
    20% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.52342, 0, 0, 1); }
    20.740741% { transform: matrix3d(1.00042, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, -24.71354, 0, 0, 1); }
    21.481481% { transform: matrix3d(1.00056, 0, 0, 0, 0, 1.00056, 0, 0, 0, 0, 1, 0, -24.89073, 0, 0, 1); }
    22.222222% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    22.962963% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.06366, 0, 0, 1); }
    23.703704% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.07926, 0, 0, 1); }
    24.444444% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.0658, 0, 0, 1); }
    25.185185% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.04097, 0, 0, 1); }
    25.925926% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.017, 0, 0, 1); }
    26.666667% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    27.407407% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.99121, 0, 0, 1); }
    28.148148% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.98906, 0, 0, 1); }
    28.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.99091, 0, 0, 1); }
    29.62963% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.99434, 0, 0, 1); }
    30.37037% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.99765, 0, 0, 1); }
    31.111111% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    31.851852% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.00121, 0, 0, 1); }
    32.592593% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.00151, 0, 0, 1); }
    33.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.32628, 0, 0, 0, 0, 0.32628, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.10646, 0, 0, 0, 0, 0.10646, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.03474, 0, 0, 0, 0, 0.03474, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.01133, 0, 0, 0, 0, 0.01133, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0037, 0, 0, 0, 0, 0.0037, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.00121, 0, 0, 0, 0, 0.00121, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00039, 0, 0, 0, 0, 0.00039, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    94.814815% { transform: matrix3d(0.00013, 0, 0, 0, 0, 0.00013, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    95.555556% { transform: matrix3d(0.00004, 0, 0, 0, 0, 0.00004, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    96.296296% { transform: matrix3d(0.00001, 0, 0, 0, 0, 0.00001, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    97.037037% { transform: scale(0) translateX(-25px); }
    100% { transform: scale(0) translateX(-25px); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/e10ASq */

  @keyframes circle-6-anim-3-circle {
    0% { transform: scale(0); }
    44.444444% { transform: scale(0); }
    45.185185% { transform: matrix3d(0.32778, 0, 0, 0, 0, 0.32778, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    45.925926% { transform: matrix3d(0.69875, 0, 0, 0, 0, 0.69875, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    46.666667% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    47.407407% { transform: matrix3d(1.1815, 0, 0, 0, 0, 1.1815, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    48.148148% { transform: matrix3d(1.24402, 0, 0, 0, 0, 1.24402, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    48.888889% { transform: matrix3d(1.21871, 0, 0, 0, 0, 1.21871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    49.62963% { transform: matrix3d(1.14702, 0, 0, 0, 0, 1.14702, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    50.37037% { transform: matrix3d(1.06589, 0, 0, 0, 0, 1.06589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.111111% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.851852% { transform: matrix3d(0.9603, 0, 0, 0, 0, 0.9603, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.592593% { transform: matrix3d(0.94663, 0, 0, 0, 0, 0.94663, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    53.333333% { transform: matrix3d(0.95217, 0, 0, 0, 0, 0.95217, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.074074% { transform: matrix3d(0.96784, 0, 0, 0, 0, 0.96784, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.814815% { transform: matrix3d(0.98559, 0, 0, 0, 0, 0.98559, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    55.555556% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.296296% { transform: matrix3d(1.00868, 0, 0, 0, 0, 1.00868, 0, 0, 0, 0, 1, 0, -15.05076, 0, 0, 1); }
    57.037037% { transform: matrix3d(1.01167, 0, 0, 0, 0, 1.01167, 0, 0, 0, 0, 1, 0, -26.2515, 0, 0, 1); }
    57.777778% { transform: matrix3d(1.01046, 0, 0, 0, 0, 1.01046, 0, 0, 0, 0, 1, 0, -29.73137, 0, 0, 1); }
    58.518519% { transform: matrix3d(1.00703, 0, 0, 0, 0, 1.00703, 0, 0, 0, 0, 1, 0, -28.42084, 0, 0, 1); }
    59.259259% { transform: matrix3d(1.00315, 0, 0, 0, 0, 1.00315, 0, 0, 0, 0, 1, 0, -26.07434, 0, 0, 1); }
    60% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.63046, 0, 0, 1); }
    60.740741% { transform: matrix3d(0.9981, 0, 0, 0, 0, 0.9981, 0, 0, 0, 0, 1, 0, -24.29556, 0, 0, 1); }
    61.481481% { transform: matrix3d(0.99745, 0, 0, 0, 0, 0.99745, 0, 0, 0, 0, 1, 0, -24.54061, 0, 0, 1); }
    62.222222% { transform: matrix3d(0.99771, 0, 0, 0, 0, 0.99771, 0, 0, 0, 0, 1, 0, -24.86216, 0, 0, 1); }
    62.962963% { transform: matrix3d(0.99846, 0, 0, 0, 0, 0.99846, 0, 0, 0, 0, 1, 0, -25.04022, 0, 0, 1); }
    63.703704% { transform: matrix3d(0.99931, 0, 0, 0, 0, 0.99931, 0, 0, 0, 0, 1, 0, -25.07557, 0, 0, 1); }
    64.444444% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25.04628, 0, 0, 1); }
    65.185185% { transform: matrix3d(1.00042, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, -25.01399, 0, 0, 1); }
    65.925926% { transform: matrix3d(1.00056, 0, 0, 0, 0, 1.00056, 0, 0, 0, 0, 1, 0, -25.00006, 0, 0, 1); }
    66.666667% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -24.98749, 0, 0, 1); }
    67.407407% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.32628, 0, 0, 0, 0, 0.32628, 0, 0, 0, 0, 1, 0, -8.15699, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.10646, 0, 0, 0, 0, 0.10646, 0, 0, 0, 0, 1, 0, -2.66146, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.03474, 0, 0, 0, 0, 0.03474, 0, 0, 0, 0, 1, 0, -0.86838, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.01133, 0, 0, 0, 0, 0.01133, 0, 0, 0, 0, 1, 0, -0.28334, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0037, 0, 0, 0, 0, 0.0037, 0, 0, 0, 0, 1, 0, -0.09245, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.00121, 0, 0, 0, 0, 0.00121, 0, 0, 0, 0, 1, 0, -0.03016, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00039, 0, 0, 0, 0, 0.00039, 0, 0, 0, 0, 1, 0, -0.00984, 0, 0, 1); }
    94.814815% { transform: matrix3d(0.00013, 0, 0, 0, 0, 0.00013, 0, 0, 0, 0, 1, 0, -0.00321, 0, 0, 1); }
    95.555556% { transform: matrix3d(0.00004, 0, 0, 0, 0, 0.00004, 0, 0, 0, 0, 1, 0, -0.00105, 0, 0, 1); }
    96.296296% { transform: matrix3d(0.00001, 0, 0, 0, 0, 0.00001, 0, 0, 0, 0, 1, 0, -0.00034, 0, 0, 1); }
    97.037037% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, -0.00011, 0, 0, 1); }
    97.777778% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, -0.00004, 0, 0, 1); }
    98.518519% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, -0.00001, 0, 0, 1); }
    99.259259% { transform: scale(0); }
    100% { transform: scale(0); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/uIqwYq */

  @keyframes circle-6-anim-2-circle {
    0% { transform: scale(0); }
    22.222222% { transform: scale(0); }
    22.962963% { transform: matrix3d(0.32778, 0, 0, 0, 0, 0.32778, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    23.703704% { transform: matrix3d(0.69875, 0, 0, 0, 0, 0.69875, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    24.444444% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.185185% { transform: matrix3d(1.1815, 0, 0, 0, 0, 1.1815, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.925926% { transform: matrix3d(1.24402, 0, 0, 0, 0, 1.24402, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    26.666667% { transform: matrix3d(1.21871, 0, 0, 0, 0, 1.21871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    27.407407% { transform: matrix3d(1.14702, 0, 0, 0, 0, 1.14702, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    28.148148% { transform: matrix3d(1.06589, 0, 0, 0, 0, 1.06589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    28.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.62963% { transform: matrix3d(0.9603, 0, 0, 0, 0, 0.9603, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    30.37037% { transform: matrix3d(0.94663, 0, 0, 0, 0, 0.94663, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.111111% { transform: matrix3d(0.95217, 0, 0, 0, 0, 0.95217, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.851852% { transform: matrix3d(0.96784, 0, 0, 0, 0, 0.96784, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    32.592593% { transform: matrix3d(0.98559, 0, 0, 0, 0, 0.98559, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    33.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    34.074074% { transform: matrix3d(1.00868, 0, 0, 0, 0, 1.00868, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    34.814815% { transform: matrix3d(1.01167, 0, 0, 0, 0, 1.01167, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.555556% { transform: matrix3d(1.01046, 0, 0, 0, 0, 1.01046, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.296296% { transform: matrix3d(1.00703, 0, 0, 0, 0, 1.00703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    37.037037% { transform: matrix3d(1.00315, 0, 0, 0, 0, 1.00315, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    37.777778% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    38.518519% { transform: matrix3d(0.9981, 0, 0, 0, 0, 0.9981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    39.259259% { transform: matrix3d(0.99745, 0, 0, 0, 0, 0.99745, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    40% { transform: matrix3d(0.99771, 0, 0, 0, 0, 0.99771, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    40.740741% { transform: matrix3d(0.99846, 0, 0, 0, 0, 0.99846, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.481481% { transform: matrix3d(0.99931, 0, 0, 0, 0, 0.99931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    42.222222% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    42.962963% { transform: matrix3d(1.00042, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    43.703704% { transform: matrix3d(1.00056, 0, 0, 0, 0, 1.00056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.444444% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.32628, 0, 0, 0, 0, 0.32628, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.10646, 0, 0, 0, 0, 0.10646, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.03474, 0, 0, 0, 0, 0.03474, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.01133, 0, 0, 0, 0, 0.01133, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0037, 0, 0, 0, 0, 0.0037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.00121, 0, 0, 0, 0, 0.00121, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00039, 0, 0, 0, 0, 0.00039, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.814815% { transform: matrix3d(0.00013, 0, 0, 0, 0, 0.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    95.555556% { transform: matrix3d(0.00004, 0, 0, 0, 0, 0.00004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    96.296296% { transform: matrix3d(0.00001, 0, 0, 0, 0, 0.00001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.037037% { transform: scale(0); }
    100% { transform: scale(0); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/Kdj66E */

  @keyframes circle-6-anim-4-circle {
    0% { transform: scale(0); }
    66.666667% { transform: scale(0); }
    67.407407% { transform: matrix3d(0.32778, 0, 0, 0, 0, 0.32778, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    68.148148% { transform: matrix3d(0.69875, 0, 0, 0, 0, 0.69875, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    68.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    69.62963% { transform: matrix3d(1.1815, 0, 0, 0, 0, 1.1815, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    70.37037% { transform: matrix3d(1.24402, 0, 0, 0, 0, 1.24402, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    71.111111% { transform: matrix3d(1.21871, 0, 0, 0, 0, 1.21871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    71.851852% { transform: matrix3d(1.14702, 0, 0, 0, 0, 1.14702, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    72.592593% { transform: matrix3d(1.06589, 0, 0, 0, 0, 1.06589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    73.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    74.074074% { transform: matrix3d(0.9603, 0, 0, 0, 0, 0.9603, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    74.814815% { transform: matrix3d(0.94663, 0, 0, 0, 0, 0.94663, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.555556% { transform: matrix3d(0.95217, 0, 0, 0, 0, 0.95217, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    76.296296% { transform: matrix3d(0.96784, 0, 0, 0, 0, 0.96784, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.037037% { transform: matrix3d(0.98559, 0, 0, 0, 0, 0.98559, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.777778% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    78.518519% { transform: matrix3d(1.00868, 0, 0, 0, 0, 1.00868, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    79.259259% { transform: matrix3d(1.01167, 0, 0, 0, 0, 1.01167, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80% { transform: matrix3d(1.01046, 0, 0, 0, 0, 1.01046, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80.740741% { transform: matrix3d(1.00703, 0, 0, 0, 0, 1.00703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    81.481481% { transform: matrix3d(1.00315, 0, 0, 0, 0, 1.00315, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.222222% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.962963% { transform: matrix3d(0.9981, 0, 0, 0, 0, 0.9981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.703704% { transform: matrix3d(0.99745, 0, 0, 0, 0, 0.99745, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    84.444444% { transform: matrix3d(0.99771, 0, 0, 0, 0, 0.99771, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.185185% { transform: matrix3d(0.99846, 0, 0, 0, 0, 0.99846, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.925926% { transform: matrix3d(0.99931, 0, 0, 0, 0, 0.99931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    86.666667% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    87.407407% { transform: matrix3d(1.00042, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.148148% { transform: matrix3d(1.00056, 0, 0, 0, 0, 1.00056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.32628, 0, 0, 0, 0, 0.32628, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.10646, 0, 0, 0, 0, 0.10646, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.03474, 0, 0, 0, 0, 0.03474, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.01133, 0, 0, 0, 0, 0.01133, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0037, 0, 0, 0, 0, 0.0037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.00121, 0, 0, 0, 0, 0.00121, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00039, 0, 0, 0, 0, 0.00039, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.814815% { transform: matrix3d(0.00013, 0, 0, 0, 0, 0.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    95.555556% { transform: matrix3d(0.00004, 0, 0, 0, 0, 0.00004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    96.296296% { transform: matrix3d(0.00001, 0, 0, 0, 0, 0.00001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.037037% { transform: scale(0); }
    100% { transform: scale(0); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/2SMi6x */

  @keyframes circle-6-g-move-bottom {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    33.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    34.074074% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 16.35397, 0, 1); }
    34.814815% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.69808, 0, 1); }
    35.555556% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 27.79251, 0, 1); }
    36.296296% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 26.74505, 0, 1); }
    37.037037% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.46104, 0, 1); }
    37.777778% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 24.85273, 0, 1); }
    38.518519% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 24.77498, 0, 1); }
    39.259259% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 24.8837, 0, 1); }
    40% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 24.97967, 0, 1); }
    40.740741% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.017, 0, 1); }
    41.481481% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.01719, 0, 1); }
    42.222222% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.00735, 0, 1); }
    42.962963% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25.00049, 0, 1); }
    43.703704% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 24.99838, 0, 1); }
    44.444444% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/pKqSyP */

  @keyframes circle-6-line-grow-horizontal-1 {
    0% { transform: scaleX(0); }
    27.407407% { transform: scaleX(0); }
    28.148148% { transform: matrix3d(0.34323, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    28.888889% { transform: matrix3d(0.88518, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.62963% { transform: matrix3d(1.09602, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    30.37037% { transform: matrix3d(1.09709, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.111111% { transform: matrix3d(1.04151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.851852% { transform: matrix3d(1.00277, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    32.592593% { transform: matrix3d(0.99083, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    33.333333% { transform: matrix3d(0.99292, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    34.074074% { transform: matrix3d(0.99755, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    34.814815% { transform: matrix3d(1.0002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.555556% { transform: matrix3d(1.00079, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.296296% { transform: matrix3d(1.00049, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    37.037037% { transform: matrix3d(1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    37.777778% { transform: matrix3d(0.99996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    38.518519% { transform: matrix3d(0.99994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    39.259259% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.32628, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.10646, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.03474, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.01133, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.00121, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00039, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.814815% { transform: matrix3d(0.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    95.555556% { transform: matrix3d(0.00004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    96.296296% { transform: matrix3d(0.00001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.037037% { transform: scaleX(0); }
    100% { transform: scaleX(0); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/NHi83y */

  @keyframes circle-6-line-grow-horizontal-2 {
    0% { transform: scaleX(0); }
    71.851852% { transform: scaleX(0); }
    72.592593% { transform: matrix3d(0.34323, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    73.333333% { transform: matrix3d(0.88518, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    74.074074% { transform: matrix3d(1.09602, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    74.814815% { transform: matrix3d(1.09709, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.555556% { transform: matrix3d(1.04151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    76.296296% { transform: matrix3d(1.00277, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.037037% { transform: matrix3d(0.99083, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.777778% { transform: matrix3d(0.99292, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    78.518519% { transform: matrix3d(0.99755, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    79.259259% { transform: matrix3d(1.0002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80% { transform: matrix3d(1.00079, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80.740741% { transform: matrix3d(1.00049, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    81.481481% { transform: matrix3d(1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.222222% { transform: matrix3d(0.99996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    82.962963% { transform: matrix3d(0.99994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.703704% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.62963% { transform: matrix3d(0.58933, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.37037% { transform: matrix3d(0.33162, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.111111% { transform: matrix3d(0.17694, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.851852% { transform: matrix3d(0.08817, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    92.592593% { transform: matrix3d(0.0397, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.01478, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.074074% { transform: matrix3d(0.00301, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    94.814815% { transform: matrix3d(-0.00182, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    95.555556% { transform: matrix3d(-0.00323, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    96.296296% { transform: matrix3d(-0.00315, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.037037% { transform: matrix3d(-0.00254, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.777778% { transform: matrix3d(-0.00185, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    98.518519% { transform: matrix3d(-0.00126, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    99.259259% { transform: matrix3d(-0.00081, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: scaleX(0); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/21oylQ */

  @keyframes circle-6-line-grow-vertical-1 {
    0% { transform: scaleY(0); }
    49.62963% { transform: scaleY(0); }
    50.37037% { transform: matrix3d(1, 0, 0, 0, 0, 0.34323, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.111111% { transform: matrix3d(1, 0, 0, 0, 0, 0.88518, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.851852% { transform: matrix3d(1, 0, 0, 0, 0, 1.09602, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.592593% { transform: matrix3d(1, 0, 0, 0, 0, 1.09709, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    53.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1.04151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.074074% { transform: matrix3d(1, 0, 0, 0, 0, 1.00277, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.814815% { transform: matrix3d(1, 0, 0, 0, 0, 0.99083, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    55.555556% { transform: matrix3d(1, 0, 0, 0, 0, 0.99292, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.296296% { transform: matrix3d(1, 0, 0, 0, 0.28328, 0.99755, 0, 0, 0, 0, 1, 0, -14.92121, 0, 0, 1); }
    57.037037% { transform: matrix3d(1, 0, 0, 0, 0.52069, 1.0002, 0, 0, 0, 0, 1, 0, -25.94861, 0, 0, 1); }
    57.777778% { transform: matrix3d(1, 0, 0, 0, 0.60536, 1.00079, 0, 0, 0, 0, 1, 0, -29.42354, 0, 0, 1); }
    58.518519% { transform: matrix3d(1, 0, 0, 0, 0.57539, 1.00049, 0, 0, 0, 0, 1, 0, -28.22236, 0, 0, 1); }
    59.259259% { transform: matrix3d(1, 0, 0, 0, 0.52172, 1.00013, 0, 0, 0, 0, 1, 0, -25.99242, 0, 0, 1); }
    60% { transform: matrix3d(1, 0, 0, 0, 0.49007, 0.99996, 0, 0, 0, 0, 1, 0, -24.63046, 0, 0, 1); }
    60.740741% { transform: matrix3d(1, 0, 0, 0, 0.48347, 0.99994, 0, 0, 0, 0, 1, 0, -24.34178, 0, 0, 1); }
    61.481481% { transform: matrix3d(1, 0, 0, 0, 0.48945, 1, 0, 0, 0, 0, 1, 0, -24.60342, 0, 0, 1); }
    62.222222% { transform: matrix3d(1, 0, 0, 0, 0.49672, 1, 0, 0, 0, 0, 1, 0, -24.91918, 0, 0, 1); }
    62.962963% { transform: matrix3d(1, 0, 0, 0, 0.5004, 1, 0, 0, 0, 0, 1, 0, -25.07879, 0, 0, 1); }
    63.703704% { transform: matrix3d(1, 0, 0, 0, 0.50073, 1, 0, 0, 0, 0, 1, 0, -25.09287, 0, 0, 1); }
    64.444444% { transform: matrix3d(1, 0, 0, 0, 0.49965, 1, 0, 0, 0, 0, 1, 0, -25.04628, 0, 0, 1); }
    65.185185% { transform: matrix3d(1, 0, 0, 0, 0.49866, 1, 0, 0, 0, 0, 1, 0, -25.0036, 0, 0, 1); }
    65.925926% { transform: matrix3d(1, 0, 0, 0, 0.49826, 1, 0, 0, 0, 0, 1, 0, -24.98611, 0, 0, 1); }
    66.666667% { transform: matrix3d(1, 0, 0, 0, 0.49829, 1, 0, 0, 0, 0, 1, 0, -24.98749, 0, 0, 1); }
    67.407407% { transform: matrix3d(1, 0, 0, 0, 0.49858, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, 0.49858, 1, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    89.62963% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.51685, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    90.37037% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.26714, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    91.111111% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.13807, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    91.851852% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.07136, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    92.592593% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.03688, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    93.333333% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.01906, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    94.074074% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00985, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    94.814815% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00509, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    95.555556% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00263, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    96.296296% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00136, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    97.037037% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.0007, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    97.777778% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00036, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    98.518519% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.00019, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    99.259259% { transform: matrix3d(1, 0, 0, 0, 0.49858, 0.0001, 0, 0, 0, 0, 1, 0, -25, 0, 0, 1); }
    100% { transform: scaleY(0) skewX(26.5deg); }
  }

  /* Generated with Bounce.js. Edit at http://goo.gl/taI49K */

  @keyframes circle-6-line-grow-vertical-2 {
    0% { transform: scaleY(0); }
    49.62963% { transform: scaleY(0); }
    50.37037% { transform: matrix3d(1, 0, 0, 0, 0, 0.34323, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.111111% { transform: matrix3d(1, 0, 0, 0, 0, 0.88518, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    51.851852% { transform: matrix3d(1, 0, 0, 0, 0, 1.09602, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.592593% { transform: matrix3d(1, 0, 0, 0, 0, 1.09709, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    53.333333% { transform: matrix3d(1, 0, 0, 0, 0, 1.04151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.074074% { transform: matrix3d(1, 0, 0, 0, 0, 1.00277, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.814815% { transform: matrix3d(1, 0, 0, 0, 0, 0.99083, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    55.555556% { transform: matrix3d(1, 0, 0, 0, 0, 0.99292, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.296296% { transform: matrix3d(1, 0, 0, 0, -0.28328, 0.99755, 0, 0, 0, 0, 1, 0, 14.92121, 0, 0, 1); }
    57.037037% { transform: matrix3d(1, 0, 0, 0, -0.52069, 1.0002, 0, 0, 0, 0, 1, 0, 25.94861, 0, 0, 1); }
    57.777778% { transform: matrix3d(1, 0, 0, 0, -0.60536, 1.00079, 0, 0, 0, 0, 1, 0, 29.42354, 0, 0, 1); }
    58.518519% { transform: matrix3d(1, 0, 0, 0, -0.57539, 1.00049, 0, 0, 0, 0, 1, 0, 28.22236, 0, 0, 1); }
    59.259259% { transform: matrix3d(1, 0, 0, 0, -0.52172, 1.00013, 0, 0, 0, 0, 1, 0, 25.99242, 0, 0, 1); }
    60% { transform: matrix3d(1, 0, 0, 0, -0.49007, 0.99996, 0, 0, 0, 0, 1, 0, 24.63046, 0, 0, 1); }
    60.740741% { transform: matrix3d(1, 0, 0, 0, -0.48347, 0.99994, 0, 0, 0, 0, 1, 0, 24.34178, 0, 0, 1); }
    61.481481% { transform: matrix3d(1, 0, 0, 0, -0.48945, 1, 0, 0, 0, 0, 1, 0, 24.60342, 0, 0, 1); }
    62.222222% { transform: matrix3d(1, 0, 0, 0, -0.49672, 1, 0, 0, 0, 0, 1, 0, 24.91918, 0, 0, 1); }
    62.962963% { transform: matrix3d(1, 0, 0, 0, -0.5004, 1, 0, 0, 0, 0, 1, 0, 25.07879, 0, 0, 1); }
    63.703704% { transform: matrix3d(1, 0, 0, 0, -0.50073, 1, 0, 0, 0, 0, 1, 0, 25.09287, 0, 0, 1); }
    64.444444% { transform: matrix3d(1, 0, 0, 0, -0.49965, 1, 0, 0, 0, 0, 1, 0, 25.04628, 0, 0, 1); }
    65.185185% { transform: matrix3d(1, 0, 0, 0, -0.49866, 1, 0, 0, 0, 0, 1, 0, 25.0036, 0, 0, 1); }
    65.925926% { transform: matrix3d(1, 0, 0, 0, -0.49826, 1, 0, 0, 0, 0, 1, 0, 24.98611, 0, 0, 1); }
    66.666667% { transform: matrix3d(1, 0, 0, 0, -0.49829, 1, 0, 0, 0, 0, 1, 0, 24.98749, 0, 0, 1); }
    67.407407% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    68.148148% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    68.888889% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    69.62963% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    70.37037% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    71.111111% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    71.851852% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    72.592593% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    73.333333% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    74.074074% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    74.814815% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    75.555556% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    76.296296% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    77.037037% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    77.777778% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    78.518519% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    79.259259% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    80% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    80.740741% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    81.481481% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    82.222222% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    82.962963% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    83.703704% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    84.444444% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    85.185185% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    85.925926% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    86.666667% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    87.407407% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    88.148148% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    88.888889% { transform: matrix3d(1, 0, 0, 0, -0.49858, 1, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    89.62963% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.51685, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    90.37037% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.26714, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    91.111111% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.13807, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    91.851852% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.07136, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    92.592593% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.03688, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    93.333333% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.01906, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    94.074074% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00985, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    94.814815% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00509, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    95.555556% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00263, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    96.296296% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00136, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    97.037037% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.0007, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    97.777778% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00036, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    98.518519% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.00019, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    99.259259% { transform: matrix3d(1, 0, 0, 0, -0.49858, 0.0001, 0, 0, 0, 0, 1, 0, 25, 0, 0, 1); }
    100% { transform: scaleY(0) skewX(-26.5deg); }
  }
  .spinner {
    animation: circle-6-rotateAll 4.5s linear infinite both;
  }

  .anim-1 {
    animation: circle-6-g-move-bottom 4.5s linear infinite both;
    circle {
      animation: circle-6-anim-1-circle 4.5s linear infinite both;
      transform-origin: 50% 50%;
    }
  }

  .anim-2 {
    animation: circle-6-g-move-bottom 4.5s linear infinite both;
    circle {
      animation: circle-6-anim-2-circle 4.5s linear infinite both;
      transform-origin: 50% 50%;
    }

    line {
      animation: circle-6-line-grow-horizontal-1 4.5s linear infinite both;
      transform-origin: 100% 50%;
    }
  }

  .anim-3 {
    circle {
      animation: circle-6-anim-3-circle 4.5s linear infinite both;
      transform-origin: 50% 50%;
    }

    line:first-of-type {
      animation: circle-6-line-grow-vertical-1 4.5s linear infinite both;
      transform-origin: 50% 0%;
    }

    line:last-of-type {
      animation: circle-6-line-grow-vertical-2 4.5s linear infinite both;
      transform-origin: 50% 0%;
    }
  }

  .anim-4 {
    circle {
      animation: circle-6-anim-4-circle 4.5s linear infinite both;
      transform-origin: 50% 50%;
    }

    line {
      animation: circle-6-line-grow-horizontal-2 4.5s linear infinite both;
      transform-origin: 100% 50%;
    }
  }
</style>
