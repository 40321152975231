<template>
  <div class="">
    <div>
      <div class="box-header d-gray">

        <h6 class="text-muted _400 inline" v-if="isTableShow">{{$t('settings.tabUsers.title')}}</h6>
        <h6 class="text-muted _400 inline" v-if="isAddingNewUser">{{$t('settings.tabUsers.tabNewUser.title')}}</h6>
        <h6 class="text-muted _400 inline" v-if="isEditUser">{{$t('settings.tabUsers.tabUserDetails.title')}}</h6>

        <div class="pull-right minus-margin" v-if="isTableShow">
          <a class="btn btn-sm white none-radius" v-on:click="goToCartAddingUser">
            <span class="primary-color _400">{{$t('btnNames.add')}}</span>
          </a>&nbsp;
          <a class="btn btn-sm white none-radius" v-on:click="disableSelectedUsers">
            <span class="primary-color _400">{{$t('btnNames.disable')}}</span>
          </a>&nbsp;
        </div>

      </div>
      <table class="table" v-if="isTableShow && employees.length > 0">
        <thead class="primary-color">
          <tr>
            <th style="width: 5%;"></th>   
            <th style="width: 5%;"></th>   
            <th class="hidden-xs-down">{{$t('settings.tabUsers.tabUsers.name')}}</th>   
            <th>{{$t('settings.tabUsers.tabUsers.email')}}</th>   
            <th class="hidden-sm-down">{{$t('settings.tabUsers.tabUsers.lastLog')}}</th>   
            <th class="hidden-sm-down">{{$t('settings.tabUsers.tabUsers.roles')}}</th>   
          </tr>
        </thead>
        <tbody>
          <tr class="custom-gray" v-for="e in evenEmployees"
          :attach="e"
          :key="e.id" 
          v-bind:class="{ pin: e.isDisabled }">
            <td class="checkbox p-r-sm">
              <label class="ui-check text-xs">
                <input type="checkbox" v-bind:value="e.id" v-model="selectedUsers">
                <i class="dark-white"></i>
              </label>
            </td>
            <td>
              <span  v-if="e.isDisabled"  class="label label-sm text-white none-radius inline" :title="$t('settings.tabUsers.tabUsers.disabled')">D</span>
            </td>
            <td class="hidden-xs-down" v-on:click="goToUserDetails(e)">{{e.name}}</td>
            <td v-on:click="goToUserDetails(e)">{{e.email}}</td>
            <td class="hidden-sm-down" v-on:click="goToUserDetails(e)">
              <small class="text-muted" v-if="e.lastLoginAt">
                <i class="material-icons md-18"></i>{{e.lastLoginAt| moment('from')}} 
              </small>
              <small class="text-muted" v-else>{{$t('settings.tabUsers.tabUsers.neverLogged')}}</small>
            </td>
            <td class="hidden-sm-down" v-on:click="goToUserDetails(e)">
              <span class="label text-white m-r-sm" v-for="role in e.roles">{{role}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="box-body start-info" v-if="employees.length == 0 && isTableShow && isLoaded">
        <div class="box2 sb10">{{$t('settings.tabUsers.tabUsers.noUsers')}}<br/> {{$t('settings.tabUsers.tabUsers.addText')}}</div>
      </div>

      <!-- Cart adding new user -->
      <div class="box-body" v-if="isAddingNewUser">
        <form class="form-horizontal">
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabNewUser.name')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="emp.name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabNewUser.email')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="email" v-model="emp.email">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabNewUser.password')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="password" v-model="emp.password">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabNewUser.phone')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="emp.phone">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label"></label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <div class="checkbox">
                <label class="ui-check">
                  <input type="checkbox" v-model="addingAdmin">
                  <i class="dark-white"></i>
                  {{$t('settings.tabUsers.tabUserDetails.adminAccount')}}<br/>
                  <small class="text-muted">{{$t('settings.tabUsers.tabUserDetails.adminAccountDesc')}}</small>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row" style="display: none;">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label"></label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <div class="checkbox">
                <label class="ui-check">
                  <input type="checkbox" value="">
                  <i class="dark-white"></i>
                  {{$t('settings.tabUsers.tabNewUser.secureLogin')}}<br/>
                  <small class="text-muted">{{$t('settings.tabUsers.tabNewUser.verificationText')}}</small>
                </label>
              </div>
            </div>
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-left" v-on:click="cancel">
            <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </a>
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-right" v-on:click="addNewUser">
              <span class="primary-color _400">{{$t('btnNames.add')}}</span>
            </a>
          </div>
          </div>
        </form>
      </div>
      <!-- ./END Cart adding new user -->

      <!-- Cart edit  user -->
      <div class="box-body" v-if="isEditUser">
        <form class="form-horizontal">
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabUserDetails.name')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="editedEmployee.name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabUserDetails.email')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="email" v-model="editedEmployee.email">
            </div>
          </div>
          
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabUserDetails.password')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="password" v-model="editedEmployee.password" placeholder="Wprowadź tylko jeśli chcesz zmienić">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabUserDetails.roles')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <select2 :options="optionsRoles" class="form-control" v-model="editedEmployee.roles" multiple="multiple">
              </select2>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label">{{$t('settings.tabUsers.tabUserDetails.phone')}}</label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <input class="form-control" type="text" v-model="editedEmployee.phone">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-1 col-md-2 col-sm-3 form-control-label"></label>
            <div class="col-lg-5 col-md-6 col-sm-9">
              <div class="checkbox">
                <label class="ui-check">
                  <input type="checkbox" v-model="editedEmployee.isDisabled">
                  <i class="dark-white"></i>
                  {{$t('settings.tabUsers.tabUserDetails.disable')}}<br/>
                  <small class="text-muted">{{$t('settings.tabUsers.tabUserDetails.preventsText')}}</small>
                </label>
              </div>
            </div>
          </div>
          <div class="box-divider m-a-0 m-b"></div>
          <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-left" v-on:click="cancel">
            <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
            </a>
            <a class="m-t-xs nav-link btn btn-sm white none-radius pull-right" v-on:click="editSelectedUser">
              <span class="primary-color _400">{{$t('btnNames.saveChanges')}}</span>
            </a>
          </div>
          </div>
        </form>
      </div>
      <!-- ./END Cart edit  user -->
    </div>  

   
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare5} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';

export default {
  name: 'UserSettings',
  props: {
    
  },
  components: {
    RotateSquare5,
    Select2
  },
  data: function () {
    return {
      isLoaded: false,
      isTableShow:true,
      isAddingNewUser:false,
      isEditUser: false,
      employees: [],
      selectedUsers: [],
      emp: {},
      editedEmployee: {},
      addingAdmin: false,
      optionsRoles: [
        { id: 'admin', text: 'Admin' },
        // { id: 'designer', text: 'Designer' },
        // { id: 'bok', text: 'BOK' },
      ],
    }
  },
  created: function() {
    this.isAddingNewUser = false;
    EmployeeManager.getEmployees().then((employees) => {
      this.employees = employees;
      this.isLoaded = true;
    });
  },
  destroyed: function() {
  },
  computed: {
    evenEmployees: function () {
      return this.employees.sort(function(a, b) {
        var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    },
  },
  methods: {
    goToUserDetails: function(e) {
      this.isTableShow = false;
      this.isEditUser = true;
      this.editedEmployee = e;
      this.editedEmployee.password = '';
      // this.$router.push({ path: `/user-details/${e.id}`});
    },
    goToCartAddingUser: function() {
      this.isTableShow = false;
      this.isAddingNewUser = true;
      this.isEditUser = false;
    },
    cancel: function() {
      this.isAddingNewUser = false;
      this.isEditUser = false;
      this.isTableShow = true;
    },
    addNewUser: function() {

      if (Object.keys(this.emp).length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty')); 
        return;
      }

      if (this.addingAdmin) {
        this.emp.roles = ['admin'];
        this.emp.permissions = [
            'tickets.create',
            'tickets.assignment.change',
            'tickets.delete',
            'tickets.reply',
            'tickets.comments.create',
            'tickets.comments.read',
            'system.settings',
            'raport.access'
        ];
      }

      Api.user.register(this.emp).then((emp) => {
        this.showNotify('notification-success',this.$i18n.t('notifications.addSuccess'));
        this.employees.push(emp);
        this.emp = {};
        this.isAddingNewUser = false;
        this.isTableShow = true;
      }).catch((reson) => {
        if (reson.response.status == 403) {
          this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.error'));
        }
      });
    },
    editSelectedUser: function() {
      Api.editUser(this.editedEmployee).then((emp) => {
        this.showNotify('notification-success',this.$i18n.t('notifications.changeSuccess'));
        this.editedEmployee = {};
        this.isEditUser = false;
        this.isTableShow = true;
      }).catch((reson) => {
        if (reson.response.status == 403) {
          this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
        } else {
          this.showNotify('notification-error', this.$i18n.t('notifications.error'));
        }
      });
    },
    disableSelectedUsers: function() {
      if (this.selectedUsers.length == 0) {this.showNotify('notification-error', this.$i18n.t('notifications.noSelected')); return;}

      let promises = [];
      for (var i = this.selectedUsers.length - 1; i >= 0; i--) {
        let userId = this.selectedUsers[i]; 

        let p = Api.user.disable(userId).then((emp) => {
          let idx = this.employees.map(e => e.id+"").indexOf(userId+"");
          if (idx != -1) this.employees[idx].isDisabled = true;
        }).catch((reson) => {
          if (reson.response.status == 403) {
            this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
          } else {
            this.showNotify('notification-error',  this.$i18n.t('notifications.error'));
          }
        });
        promises.push(p);
      }

      Promise.all(promises).then(emps => {
        this.showNotify('notification-success', this.$i18n.t('notifications.removeSuccess'));
        this.selectedUsers.length = 0;
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.start-info {
  .box2 {
    right: 75px;
  }
}

</style>
