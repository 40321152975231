import { render, staticRenderFns } from "./LiveChatNotificator.vue?vue&type=template&id=8871b616&scoped=true"
import script from "./LiveChatNotificator.vue?vue&type=script&lang=js"
export * from "./LiveChatNotificator.vue?vue&type=script&lang=js"
import style0 from "./LiveChatNotificator.vue?vue&type=style&index=0&id=8871b616&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8871b616",
  null
  
)

export default component.exports