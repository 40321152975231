<template>
    <div class="box">
        <div class="box-header">
            <h3>Utwórz nową skrzynkę odbiorczą</h3>
            <small>Każda wiadomość, która trafi na tą skrzynkę<br/>zostanie automatycznie zamieniona na zgłoszenie </small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form role="form">
                <div class="form-group">
                    <label for="exampleInputEmail1">Adres email</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="address">
                        <span class="input-group-addon">@{{ host }}</span>
                    </div>
                    <span class="help-block m-b-none text-muted">np. "kontakt", "biuro", "pomoc", "wsparcie", "bok"</span>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            <a class="btn btn-sm white none-radius pull-right" @click="create">Add one</a>
            <a class="btn btn-sm white none-radius" @click="$emit('close')">Anuluj</a>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'InternalBoxModal',
    props: {

    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            address: '',
            host: window.location.host,
            isProcessing: false,
        }
    },
    created: function() {
    },
    destroyed: function() {
    },
    computed: {
    },
    methods: {
        create: async function () {
            if (!this.address) {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: 'Nie podano adresu',
                });
                return false;
            }
            this.isProcessing = true;
            try {
                let box = await Api.tickets.createInternalBox({
                    address: this.address
                });
                this.$emit('created');
                this.$emit('close');
            } catch (error) {
                this.$notify({
                    group: 'global',
                    type: 'notification-error',
                    text: 'Nie udało się utworzyć nowego adresu',
                });                
            }
            this.isProcessing = false;
            
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
