<template>
    <div >
        <img src="../../assets/images/message.svg" style="width: 200px; float: right; margin-right: 50px;" />
        <p style="max-width: 500px;">
            <span class="text-u-c _600">Odbieraj wiadomości</span><br/>
            Adresy, na które Twoi klienci mogą wysyłać wiadomości.
            Każda wiadomość, która trafi na tą skrzynkę zostanie automatycznie zamieniona na zgłoszenie.<br/><br/>
            Pierwszą skrzynkę <strong>tworzymy za Ciebie</strong> i jest już gotowa do użytku. Ale jak chcesz możesz dodać kolejną
        </p>

        <p class="m-a" style="max-width: 500px;">
            <ul class="list inset" v-if="Array.isArray(internalBoxes) && internalBoxes.length">
                <li class="list-item" v-for="box in internalBoxes" :key="box._id">
                    <div class="list-body">
                    <div>
                        <strong><i class="material-icons text-success md-18">check</i> {{ box.address }}</strong>@{{ host }}
                    </div>
                    <small class="text-muted text-ellipsis">
                        <i class="material-icons text-success md-18" v-if="box.autoAssign">check_circle</i>
                        <i class="material-icons text-warning md-18" v-else>cancel</i>
                        Auto. przypisywanie |

                        <i class="material-icons text-success md-18" v-if="box.sendReceiveConfirm">check_circle</i>
                        <i class="material-icons text-warning md-18" v-else>cancel</i>
                        Potwierdzenie otrzymania wiadomosci
                    </small>
                    </div>
                </li>
                <li class="list-item text-info">
                    <a href="#" @click.prevent="addInternalBox"><i class="material-icons  md-18">add</i> Dodaj kolejną</a>
                </li>
            </ul>
        </p>

        <p style="max-width: 500px;" v-if="Array.isArray(internalBoxes) && internalBoxes.length">
            <span class="text-u-c _600">Przetestuj działanie</span><br/>
            Chcesz zobaczyć jak to działa w praktyce? Nic prostszego!<br/><a :href="defaultInnerEmailHref" class="text-primary">Wyślij dowolną wiadomość</a> na jedną z powyższych skrzynek
        </p>
        
        <p>
            <a href="#" class="btn btn-sm success none-radius" @click.prevent="$emit('next')">Dalej</a>
            <a href="#" class="m-l text-muted btn btn-sm white none-radius" @click.prevent="$emit('close')">Pomiń</a>
        </p>

        
    </div>
</template>

<script>
import Api from '../../Api.js';
import InternalBoxModal from '../SettingsPage/Modals/InternalBoxModal.vue';

export default {
    name: 'SectionInnerEmail',

    components: {
    },

    data: function () {
        return {
            host: window.location.host,
            internalBoxes: []
        }
    },

    created: function() {
        Api.tickets.getMailsConfiguration().then(res => {
            this.internalBoxes = res;
        }).catch(e => {});
    },

    destroyed: function() {
    },

    computed: {
        defaultInnerEmailHref: function() {
            return "mailto:"+this.internalBoxes[0].address+"@"+this.host+"?subject=Testowa wiadomosc";
        }
    },

    methods: {
        addInternalBox: function() {
            this.$modal.show(InternalBoxModal, {}, {
                draggable: true
            }, {
                'closed': (event) => {
                    Api.tickets.getMailsConfiguration().then(res => {
                        this.internalBoxes = res;
                    }).catch(e => {});
                }
            });
        },
    }
}
</script>

<style>

</style>