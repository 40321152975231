<template>
    <div class="row">
        <div class="col-md-2">
            <ActiveLiveChats :selectedChats="activie" :activeChats="activeChats" v-if="isReady"></ActiveLiveChats>
        </div>
        <div class="col-md-10">
            <div class="row">
                <LiveChatWindow v-for="a in activie" :key="a.id" :chat="a"></LiveChatWindow>
            </div>
        </div>
    </div>
</template>
<script>
import ActiveLiveChats from './ActiveLiveChats.vue'
import LiveChatWindow from './LiveChatWindow.vue'
import EventBus from '../../event-bus.js';
import Api from '../../Api.js';
import SystemSocket from '../../SystemSocket.js';

let activeChats = []; 

export default {
    name: "LiveChat",
    components: {
        ActiveLiveChats,
        LiveChatWindow
    },
    data: function () {
        return {
           activie: [],
           selectedChats: [],
           chats: [],
           isReady: false,
        }
    },
    created: function() {
        EventBus.$on('activeLiveChatChange', this.activeWindowChange);
        EventBus.$on('closeLiveChat', this.closeLiveChat);
        this.getActiveChats();
        this.activie = activeChats;
        
        SystemSocket.socket.on('object', this.onSocketUpdate);
    },
    destroyed: function() {
        EventBus.$off('activeLiveChatChange', this.activeWindowChange);
        EventBus.$off('closeLiveChat', this.closeLiveChat);
        activeChats = this.activie;
        
        SystemSocket.socket.off('object', this.onSocketUpdate);
    },
    computed: {
        activeChats: function() {
            return this.chats.filter(m => m.status != "closed").sort((a, b) => {
                var dateA = new Date(a.emailDate), dateB = new Date(b.emailDate);
                return dateB - dateA;
            });
        }
    },
    methods: {
        activeWindowChange: function(obj) {
            this.activie = obj.activeLiveChats;
        },
        closeLiveChat: function(obj) {
            this.activie = this.activie.filter(a => a.id != obj.chat.id);
            if(obj.chat.status == 'closed') {
                let idx = this.chats.map(c => c._id+"").indexOf(obj.chat.id+"");
                if(idx != -1) this.chats.splice(idx, 1);
            }
        },
        getActiveChats: async function() {
            let filters = {};
            filters.channels = ["chat"];
            try {
                let ticketsRes = await Api.searchTickets(filters);
                this.chats = ticketsRes.data;
                console.log(this.chats)
                this.isReady = true;
            } catch (error) {
                this.isReady = true;
                console.log("error", error)
            }
        },
        onSocketUpdate: function(event) {
            if (event.object == 'ticket' && event.verb == 'create') {
                let ticket = event.data;
                if (ticket.channels.map(ch => ch.type).indexOf("chat") == -1) return;
                this.chats.push(ticket);
            } else if (event.object == 'ticket' && event.verb == 'update') {
                let ticket = event.data;
                let t = this.chats.find(t => t._id == ticket._id);
                if(t && t.status)  t.status = ticket.status;
                if(t && t.assignedUserId)  t.assignedUserId = ticket.assignedUserId;
            }
        },

    }
}
</script>