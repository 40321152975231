<template>
  <div class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true" style="padding-top: 0px;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" v-if="!isReady"><Spinner></Spinner></div>
      <div class="modal-content" v-if="isReady">
        <p class="text-muted text-center d-gray p-x-sm p-b p-t-sm" style="margin-bottom: 0px;">
          <span>{{ $t('mails.modalComposeMail.write') }}: {{ draft.subject || '('+$t('mails.modalComposeMail.noSubject')+')' }}</span>
          <a class="pull-right" v-on:click="isActive = false"><i class="material-icons md-16">close</i></a>
        </p>
        <div class="box-divider m-a-0"></div>
        <div class="l-gray text-left p-x-sm p-t p-b-sm">
          <button class="btn btn-sm white none-radius" v-on:click="send" :disabled="isSaving || isSending">
            <span class="primary-color _400"><i class="material-icons md-16">send</i> {{ $t('mails.modalComposeMail.send') }}</span>
            <span v-if="isSending" style="display: inline-block;"><rotate-square2 style="width: 10px; height: 10px; margin-left: 10px"></rotate-square2></span>
          </button>&nbsp;
          <button class="btn btn-sm white none-radius" v-on:click="save" :disabled="isSaving || isSending">
            <span class="primary-color _400"><i class="material-icons md-16">save</i> {{ $t('mails.modalComposeMail.saveAsDrafts') }}</span>
            <span v-if="isSaving" style="display: inline-block;"><rotate-square2 style="width: 10px; height: 10px; margin-left: 10px"></rotate-square2></span>
          </button>&nbsp;
          <input type="file" name="file" ref="attachmentfile" multiple style="display: none;" v-on:change="onFilesInputChange">
          <button class="btn btn-sm white none-radius pull-right" v-on:click.prevent="$refs.attachmentfile.click" :disabled="isSaving || isSending">
            <span class="primary-color _400"><i class="material-icons md-16">attach_file</i> {{ $t('mails.modalComposeMail.attach') }}</span>
          </button>&nbsp;
        </div>
        <div class="box-divider m-a-0 m-b-xs"></div>
        <div class="row" style="margin: 0px;">
          
          <div class="col-lg-8 from-to-content">
            <form class="form-horizontal">
              <div class="row h-40">
                <label class="col-sm-2 form-control-label">{{ $t('mails.modalComposeMail.from') }}:</label>
                <div class="col-sm-10">
                  <select2 :options="accountsList" :value="accountId" v-on:input="onAccountSelected" disabled="true" class="form-control custom-input"></select2>
                </div>
              </div>
              <div class="row h-40 recipient-box" v-for="(to, key) in recipients">
                <label class="col-sm-2 form-control-label" style="padding: 0px 5px;">
                  <a href="#" class="remove-recipient" v-if="recipients.length > 1" @click.prevent="recipients.splice(key, 1)"><i class="material-icons md-16">close</i></a>
                  <select2 :options="optionsPriority" :value="to.type" @input="to.type = $event" class="form-control">
                  </select2>
                </label>
                <div class="col-sm-10">
                  <contact-select :value="to" @input="to.address = $event.address; to.name = $event.name" class="form-control"></contact-select>
                </div>
              </div>
              <div class="row h-40">
                <div class="col-sm-2"></div>
                <div class="col-sm-10">
                  <a href="#" @click.prevent="recipients.push({type:'to', address:''})">+ {{ $t('mails.modalComposeMail.addRecipient') }}</a>
                </div>
              </div>
              <div class="row h-40">
                <label class="col-sm-2 form-control-label">
                  {{ $t('mails.modalComposeMail.subject') }}:
                </label>
                <div class="col-sm-10">
                  <input class="form-control custom-input" v-model="draft.subject" type="text" tabindex="1">
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-4">
            <div class="box" style="min-height: 150px;" v-on:dragenter="isDrag=true" v-on:dragleave="isDrag=false" v-on:dragover.prevent="true" v-on:drop="onFilesDrop">
              <div class="text-drag-file" v-if="isDrag" style="pointer-events: none;">
                <span class="title-color">{{ $t('mails.modalComposeMail.drag') }}</span>
              </div>
              <div class="box-body p-y-xs">
                <span>{{ $t('mails.modalComposeMail.attachments') }}:</span><br/>
              <div class="box-divider m-a-0"></div>
                <span v-for="uFile in uploadedFiles">
                  {{ uFile.name | truncate(20) }}
                  <div class="progress">
                    <div class="progress-bar primary" v-bind:style="{ width: uFile.progress + '%' }">{{uFile.progress}} %</div>
                  </div>
                </span>
                <span v-for="(a, key) in draft.attachments">{{ a.filename | truncate(20) }} <a href="#" v-on:click.prevent="removeAttachmentByIdx(key)" title="Remove attachment"><i class="material-icons md-16">close</i></a><span class="text-muted text-xs m-r m-l-xs">{{Math.round(a.size/1024)}} KB</span><br/></span>
              </div>
            </div>
          </div>
        </div>

        <div class="post-agent-message" >
          <quill-editor v-model="draft.html"
                :options="editorOption">
          </quill-editor>
        </div>
        <div class="attachments">
            <div class="box-body d-gray p-y-xs">
              &nbsp; <span class="text-muted" v-if="isSaving">{{ $t('mails.modalComposeMail.saving') }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import $ from 'jquery'
import EventBus from '../../event-bus.js';
import Spinner from '../Spinner.vue';
import Api from '../../Api.js';
import UserManager from '../../UserManager.js'
import Select2 from '../Select2.vue';
import ContactSelect from './ContactSelect.vue';
import {RotateSquare2} from 'vue-loading-spinner'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'MailPage',
  props: {
    availableAccounts: Array
  },
  components: {
    Select2,
    ContactSelect,
    RotateSquare2,
    quillEditor,
    Spinner
  },
  data: function () {
    return {
      isActive: false,
      isReady: false,
      isSaving: false,
      isSending: false,
      isDrag: false,
      accountId: "",
      myHTML: '',
      draft: this.getEmptyDraftModel(),
      optionsPriority: [
        { id: 'to', text: 'To:' },
        { id: 'cc', text: 'Cc:' },
      ],
      recipients: [],
      uploadedFiles: [],
      editorOption: {
        tabIndex: 2,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean'] 
          ]
        }
      },
      accountsList: []
    }
  },
  created: function() {

    EventBus.$on('write-new-msg-click', this.onWriteMsgClick);
    EventBus.$on('write-msg-reply-click', this.onWriteReplyMsgClick);
    EventBus.$on('write-msg-reply-all-click', this.onWriteReplyAllMsgClick);
    EventBus.$on('write-msg-fwd-click', this.onWriteFwdMsgClick);
    EventBus.$on('write-msg-based-on-draft', this.onWriteBasedOnDraftClick);
  },
  destroyed: function() {
    EventBus.$off('write-new-msg-click', this.onWriteMsgClick);
    EventBus.$off('write-msg-reply-click', this.onWriteReplyMsgClick);
    EventBus.$off('write-msg-reply-all-click', this.onWriteReplyAllMsgClick);
    EventBus.$off('write-msg-fwd-click', this.onWriteFwdMsgClick);
    EventBus.$off('write-msg-based-on-draft', this.onWriteBasedOnDraftClick);
  },
  methods: {
    onWriteMsgClick: function(accountId) {
      this.accountId = accountId;
      this.showModal('new');
    },

    onWriteReplyAllMsgClick: function(message) {
      this.accountId = message.accountId;
      this.showModal('reply-all', message);
    },

    onWriteReplyMsgClick: function(message) {
      this.accountId = message.accountId;
      this.showModal('reply', message);
    },

    onWriteFwdMsgClick: function(message) {
      this.accountId = message.accountId;
      this.showModal('fwd', message);
    },

    onWriteBasedOnDraftClick: function(message) {
      this.accountId = message.accountId;
      this.showModal('draft', message);
    },

    showModal: function(mode, message) {
      this.draft = this.getEmptyDraftModel();
      this.uploadedFiles.length = 0;
      this.isActive = true;
      this.isReady = false;
      this.accountsList.length = 0;
      this.availableAccounts.forEach(a => {
        this.accountsList.push({
          id: a._id,
          text: a.name + ' <'+a.email+'>'
        });
      });

      if (!this.accountId) this.accountId = this.accountsList[0].id;

      let promise = null;
      if (mode == 'draft') promise = Api.mails.createNewDraftBasedOn(message.accountId, message.box, message.uid);
      else if (mode == 'reply') promise = Api.mails.createNewDraftAsReply(message.accountId, message.box, message.uid, false);
      else if (mode == 'reply-all') promise = Api.mails.createNewDraftAsReply(message.accountId, message.box, message.uid, true);
      else if (mode == 'fwd') promise = Api.mails.createNewDraftAsFwd(message.accountId, message.box, message.uid);
      else promise = Api.mails.createNewDraft(this.accountId);

      promise
        .then(draft => {
          // niektore wiadomosci nie maja wersji HTML, a nasz edytor z kolei operuje tylko na htmlu wiec, tworzymy psedudo
          // htmla na podstawie wersji textowej
          if (!draft.html && draft.text) draft.html = '<p>'+draft.text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br/>')+'</p>';
          let signature = this.getSignatureForAccountId(this.accountId);

          // niektore programy pocztowe dodaja jakies dziewne oznaczenia do naszych maili
          if (!draft.html) draft.html = "";
          draft.html = draft.html.replace(/<pre>(&nbsp;)*\s+<o:p><\/o:p><\/pre>/g, '');

          if (draft.html.length > 100000) {
            console.log("Tniemy");
            draft.html = draft.html.slice(0, 100000) + "Wiadomość skrócona</body></html>";
          }
          
          // jestli jest to odpowiedz to musimy dokleic odpowiedni naglowek
          if (mode == 'reply') {
            draft.html = '<blockquote type="cite" style="margin: 0 0 0 0 0.75ex; border-left: 1px solid #808080; padding-left: 0.75ex;">'+draft.html+'</blockquote>';
            draft.html = '<br/><p style="color: black; font-size: 10pt; font-family: sans-serif; margin: 8pt 0;">W '+(new Date(message.date).toLocaleString())+' '+message.from.text+' napisał:<br/></p>' + draft.html;
            if (signature) draft.html = '<br/><br/><pre class="moz-signature" cols="72">'+signature+'</pre>' + draft.html;
          }

          else if (mode == 'fwd') {
            draft.html = '<blockquote type="cite" style="margin: 0 0 0 0 0.75ex; border-left: 1px solid #808080; padding-left: 0.75ex;">'+draft.html+'</blockquote>';
            draft.html = '<br/><p style="color: black; font-size: 10pt; font-family: sans-serif; margin: 8pt 0;">Forwarded Message: W '+(new Date(message.date).toLocaleString())+' '+message.from.text+' napisał:<br/></p>' + draft.html;
            if (signature) draft.html = '<br/><br/><pre class="moz-signature" cols="72">'+signature+'</pre>' + draft.html;
          }

          else {
            if (signature) draft.html = '<br/><br/><pre class="moz-signature" cols="72">'+signature+'</pre>' + (draft.html ? draft.html : '');
          }

          Object.assign(this.draft, draft);
          this.saveDraftContacts();
          this.mapDraftRecipients();
          this.isReady = true;
          $('.ql-editor').prop('tabIndex', 5);
        })
        .catch(err => {
          console.error(err);
          this.isReady = true;
        })
      ;
    },

    getSignatureForAccountId: function(accountId) {
      let idx = this.availableAccounts.map(a => a._id).indexOf(accountId);
      if (idx == -1) return null;
      let account = this.availableAccounts[idx];
      if (account.usersSignatures && account.usersSignatures[UserManager.user.userId]) return account.usersSignatures[UserManager.user.userId];

      return null;
    },

    mapDraftRecipients: function() {
      this.recipients = [];
      if (this.draft && Array.isArray(this.draft.to)) {
        let c = this.draft.to.map(c => {
          return { name: c.name, address: c.address, type: 'to'};
        });
        this.recipients = this.recipients.concat(c);
      }

      if (this.draft && Array.isArray(this.draft.cc)) {
        let c = this.draft.cc.map(c => {
          return { name: c.name, address: c.address, type: 'cc'};
        });
        this.recipients = this.recipients.concat(c);
      }
    },

    mapRecipientsToDraft: function() {
      this.draft.to = this.recipients.filter(r => r.type == 'to').map(r => { return {name: r.name, address: r.address}; });
      this.draft.cc = this.recipients.filter(r => r.type == 'cc').map(r => { return {name: r.name, address: r.address}; });
    },

    onAccountSelected: function(accountId) {
      this.accountId = accountId;
    },

    saveDraftContacts: function() {
      if (this.draft && Array.isArray(this.draft.to)) {
        let c = this.draft.to.map(c => c.name+" "+c.address).join(', ');
        Api.mails.saveContact(c);
      }
    },

    send: function() {
      this.isSending = true;
      // przed wyslaniem musimy zapisac szkic, zeby na pewno wylsac aktualna wersje
      this.save().then(() => {
        Api.mails.sendDraft(this.draft, this.accountId).then(res => {
          this.isSending = false;
          this.$notify({group: 'global', type: 'notification-success', text: this.$i18n.t('mails.modalComposeMail.notification.sendSuccess') });
          this.isActive = false;
        }).catch(err => {
          this.isSending = false;
          this.$notify({group: 'global', type: 'notification-error', text: this.$i18n.t('mails.modalComposeMail.notification.sendError')+' '+err });
        })
      });
    },

    save: function() {
      this.isSaving = true;
      this.mapRecipientsToDraft();
      // zamieniamy HTML na txt, zeby byly dostepne dwie wersje maila
      this.draft.text = this.draft.html.replace(/<\s*\/?br\s*[\/]?>/gi, '\n');
      this.draft.text = this.draft.text.replace(/<(?:.|\n)*?>/gm, '');
      return Api.mails.saveDraft(this.draft)
        .then(draft => {
          Object.assign(this.draft, draft);
          this.isSaving = false;
          return draft;
        })
        .catch(err => {
          this.isSaving = false;
        })
      ;
      
    },

    getEmptyDraftModel: function() {
      return {
        id: null,
        to: [{
          address: '',
          name: '',
        }],
        cc: [],
        subject: '',
        html: '',
        text: '',
        attachments: []
      }
    },

    onFilesDrop: function(e) {
      this.isDrag = false;
      e.preventDefault();
      let files = e.dataTransfer.files;
      for (let i = files.length - 1; i >= 0; i--) {
        this.handleFileUpload(files[i]);
      }
    },

    onFilesInputChange: function() {
      let files = this.$refs.attachmentfile.files;
      for (let i = files.length - 1; i >= 0; i--) {
        this.handleFileUpload(files[i]);
      }
    },

    handleFileUpload: function(file) {
      let f = {file: file, progress: 0, name: file.name};
      this.uploadedFiles.push(f);
      Api.mails.addAttachment(this.draft, f.file, (progressEvent) => {
        f.progress = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
      }).then(draft => {
        let idx = this.uploadedFiles.indexOf(f);
        if (idx != -1) this.uploadedFiles.splice(idx, 1);
        this.draft.attachments = draft.attachments;
      });
    },

    removeAttachmentByIdx: function(idx) {
      if (!this.draft.attachments[idx] || !this.draft.attachments[idx].filename) 
        return this.$notify({group: 'global', type: 'notification-error', text: this.$i18n.t('mails.modalComposeMail.notification.attachError')+' '+idx });

      let filename = this.draft.attachments[idx].filename;
      Api.mails.removeAttachment(this.draft, filename)
      .then(draft => {
        this.draft.attachments = draft.attachments;
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.from-to-content {
  label {
    padding-left: 15px;
    padding-right: 0px;
    text-align: right;
  }
  .h-40 {
    height: 40px;
  }
}
.editr {
  min-height: 400px;
}
.editr--content {
  max-height: 400px; 
}
.attachments {

}
.text-drag-file {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #fafbfc;
  border: 3px dashed #58666e;
  opacity: .8;
  width: 95%;
  height: 95%;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.progress {
  display: inline-block;
  width: 100px;
  border-radius: 0px;
  margin-bottom: 0px;
}
.progress-bar {
  border-radius: 0px;
}
.recipient-box {
  .remove-recipient {
    display: block;
    position: absolute;
    z-index: 100;
    left: 10px;
    top: 3px;
  }
}
.quill-editor {
  .ql-snow .ql-editor pre.ql-syntax {
    background-color: #eeeeee;
    color: #000000;
  }
}
.ql-container {
  overflow-y: auto;
  max-height: 450px;

  .ql-editor {
    min-height: 200px;
  }
}
</style>
