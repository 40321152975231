<template>
<div>
  <div class="checkbox m-r inline">
    <label class="ui-check text-xs">
      <input type="checkbox">
      <i class="dark-white"></i>
    </label>
  </div>
  <button class="btn btn-sm white none-radius btn-group-c" v-on:click="EventBus.$emit('write-new-msg-click', infoMessages.idBox);">
    <span class="primary-color _400"><i class="material-icons md-16">edit</i> {{ $t('mails.write') }}</span>
  </button>&nbsp;
  <button class="btn btn-sm white none-radius btn-group-c" v-on:click="getMessages">
    <span class="primary-color _400"><i class="material-icons md-16">get_app</i> {{ $t('mails.getMessages') }}</span>
  </button>&nbsp;
  <button class="btn btn-sm white none-radius btn-group-c" v-on:click="moveToSelectedFolder('Trash')">
    <span class="primary-color _400"><i class="material-icons md-16">delete</i> {{ $t('btnNames.delete') }}</span>
  </button>&nbsp;
  <div class="btn-group dropdown btn-group-c">
    <button class="btn btn-sm white none-radius dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
      <span class="primary-color _400">{{ $t('mails.moveTo') }}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-scale" v-if="userMailboxes.length > 0">
      <span v-for="m in userMailboxes" v-if="m.boxes && infoMessages.idBox && m._id == infoMessages.idBox">
        <a class="dropdown-item" href="#" v-if="m.boxes.INBOX" v-on:click.prevent="moveToSelectedFolder('INBOX')">INBOX</a>
        <a class="dropdown-item" href="#" v-if="m.boxes.Sent" v-on:click.prevent="moveToSelectedFolder('Sent')">{{ $t('mails.sent') }}</a>
        <a class="dropdown-item" href="#" v-if="m.boxes.Trash" v-on:click.prevent="moveToSelectedFolder('Trash')">{{ $t('mails.trash') }}</a>
        <a class="dropdown-item" href="#" v-if="m.boxes.Drafts" v-on:click.prevent="moveToSelectedFolder('Drafts')">{{ $t('mails.drafts') }}</a>
        <a class="dropdown-item" href="#" v-for="(b, key) in m.boxes" v-if="key != 'INBOX' && key != 'Sent' && key != 'Trash' && key != 'Drafts' && b.children == null" v-on:click.prevent="moveToSelectedFolder(key)">
          {{key}}
        </a>
        <span v-for="(b, key) in m.boxes" v-if="key != 'INBOX' && key != 'Sent' && key != 'Trash' && key != 'Drafts' && b.children != null">
          <a href="" class="dropdown-item" v-on:click.prevent="moveToSelectedFolder(key)">{{key}}</a>
          <a class="dropdown-item" href="" v-for="(c, childrenKey) in b.children" v-on:click.prevent="moveToSelectedFolder(key+b.delimiter+childrenKey)"><i class="material-icons md-16">keyboard_arrow_right</i> {{childrenKey}}</a>
        </span>
      </span>
    </div>

  </div>
  &nbsp;
  <div class="btn-group dropdown">
    <button class="btn btn-sm white none-radius dropdown-toggle btn-group-c" data-toggle="dropdown" aria-expanded="false">
      <span class="primary-color _400">{{ $t('mails.more') }}</span>
    </button>&nbsp;
    <div class="dropdown-menu dropdown-menu-scale">
      <a class="dropdown-item" href="#" v-on:click.prevent="markAsRead()">{{ $t('mails.markAsRead') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="markAsUnread()">{{ $t('mails.markAsUnread') }}</a>
      <a class="dropdown-item" href="#" v-on:click.prevent="emptyBox()">{{ $t('mails.emptyBox') }}</a>
    </div>
  </div>

  <div class="inline float-r mails-on-page c-rwd">

    <form class="navbar-form form-inline inline pull-none-sm v-m m-r c-form" role="search">
      <div class="form-group l-h m-a-0">
        <div class="input-group search-input-mails">
          <input class="form-control form-control-sm p-x b-a" :placeholder="[[ placeholderText ]]" type="text" v-model="search" v-on:input="onFilterChange">
          <a class="close-btn" href="#" v-on:click.prevent="clearSearch" v-if="search.length > 0 || placeholderText == $t('mails.searchInput.searchAdvance')">
            <i class="material-icons md-16 text-muted">close</i>
          </a>
          <a class="more-option" href="#" title="Show search options" v-on:click="isActive = true">
            <i class="material-icons md-16 text-muted" >arrow_drop_down</i>
          </a>
        </div>
      </div>
    </form>

    <div class="box extended-search-box m-a" v-if="isActive">
      <div class="box-body">
        <form class="form-horizontal primary-color">
          <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label">{{ $t('mails.searchInput.from') }}</label>
            <div class="col-sm-8 col-md-10">
              <input class="form-control" type="email" v-model="extendedSearch.from">
            </div>
          </div>
          <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label">{{ $t('mails.searchInput.to') }}</label>
            <div class="col-sm-8 col-md-10">
              <input class="form-control" type="email" v-model="extendedSearch.to">
            </div>
          </div>
          <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label">{{ $t('mails.searchInput.subject') }}</label>
            <div class="col-sm-8 col-md-10">
              <input class="form-control" type="text" v-model="extendedSearch.subject">
            </div>
          </div>
          <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label">{{ $t('mails.searchInput.content') }}</label>
            <div class="col-sm-8 col-md-10">
              <input class="form-control" type="text" v-model="extendedSearch.body">
            </div>
          </div>

          <!-- <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label">{{ $t('mails.searchInput.where') }}</label>
            <div class="col-sm-8 col-md-10">
              <select2 :options="optionsEngine" class="form-control" v-model="extendedSearch.selectedFolders">
              </select2>
            </div>
          </div> -->

          <div class="form-group row m-b-xs">
            <label class="col-sm-4 col-md-2 form-control-label"></label>
            <div class="col-sm-8 col-md-10">
              <div class="checkbox m-b-xs">
                <label class="ui-check">
                  <input type="checkbox" value="" v-model="extendedSearch.unseen">
                  <i class="dark-white"></i>
                  {{ $t('mails.searchInput.onlyUnread') }} 
                </label>
              </div>
            </div>
          </div>

          <a class="m-t-xs nav-link btn btn-xs white none-radius" v-on:click="advancedSearch" v-bind:class="{ disabled : procesing }">
            <span class="primary-color _400">{{ $t('btnNames.search') }}</span>
          </a>
          <a class="m-t-xs nav-link btn btn-xs white none-radius m-l-sm" v-on:click="isActive = false" 
          v-bind:class="{ disabled: procesing }">
            <span class="primary-color _400">{{ $t('btnNames.cancel') }}</span>
          </a>
        </form>
      </div>
    </div>

    <span class="m-l text-muted text-xs c-span">{{ $t('mails.show') }}:</span>
    <div class="btn-group dropdown">
      <button class="btn dropdown-toggle btn-sm custom-dropdown primary-color" data-toggle="dropdown" aria-expanded="false">{{mailsOnPage}}</button>
      <div class="dropdown-menu dropdown-menu-scale">
        <a class="dropdown-item" href="#" v-on:click.prevent="showMailsOnPage('50')">50</a>
        <a class="dropdown-item" href="#" v-on:click.prevent="showMailsOnPage('100')">100</a>
        <a class="dropdown-item" href="#" v-on:click.prevent="showMailsOnPage('150')">150</a>
        <a class="dropdown-item" href="#" v-on:click.prevent="showMailsOnPage('200')">200</a>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
import EventBus from '../../event-bus.js';
import Api from '../../Api.js';
import Select2 from '../Select2.vue';

var timeoutHandler = null;

export default {
  name: 'BtnGroup',
  props: {
    userMailboxes: Array,
    infoMessages: Object, //informacja nt obenego folderu, konta i wiadomosciach
  },
  components: {
    Select2,
  },
  data: function() {
    return {
      EventBus: EventBus,
      myHTML:'',
      mailsOnPage: 50,
      placeholderText: this.$i18n.t('mails.searchInput.search'),
      search: '',
      extendedSearch: {},
      optionsEngine: [
        { id: '1', text: 'Search only in this folder' },
        { id: '2', text: 'Search in all folders' },
        { id: '3', text: 'Search in all boxes and folders' },
      ],
      procesing: false,
      isActive: false,
    }
  },
  created: function() {
    window.addEventListener('keyup', this.handleKeyUp);
    EventBus.$on('newSearchResultLoaded', this.handleAdvencedSearch);
  },
  destroyed: function() {
    window.removeEventListener('keyup', this.handleKeyUp);
    EventBus.$off('newSearchResultLoaded', this.handleAdvencedSearch);
  },
  methods: {
    markAsRead: function() {
      EventBus.$emit('mark-messages-as-read');
    },
    markAsUnread: function() {
      EventBus.$emit('mark-messages-as-unread');
    },
    moveToSelectedFolder: function(to) {
      EventBus.$emit('move-mails-to-selected-folder', {moveTo:to});
    },
    getMessages: function() {
      EventBus.$emit('getMessages');
    },
    showMailsOnPage: function(count) {
      this.mailsOnPage = count;
      EventBus.$emit('showSelectedCoutMsgOnPage', {mailsOnPage:count});
    },
    emptyBox: function() {
      let message = 'Are you sure you want to clear this mailbox? This action is irreversible. Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        EventBus.$emit('actionEmptyBox');
      }).catch(r => r);
    },

    onFilterChange: function() {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        EventBus.$emit('filterMailsChange', {
          search: this.search,
        });
      }, 1200);
    },
    clearSearch: function() {
      if (this.search.length == 0 && !this.extendedSearch) return;
      this.search = '';
      this.placeholderText = this.$i18n.t('mails.searchInput.search');
      this.onFilterChange();
    },
    advancedSearch: function() {
      this.procesing = true;
      EventBus.$emit('filterMailsChange', {extendedSearch: this.extendedSearch,});
    },
    handleAdvencedSearch: function() {
      this.procesing = false;
      this.isActive = false;
      this.placeholderText = this.$i18n.t('mails.searchInput.searchAdvance');
      this.extendedSearch = {};
    },
    handleKeyUp: function(e) {
      if (e.keyCode == 27 && this.isActive) {
        this.isActive = false;
      }
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.search-input-mails {
  position: relative;
  min-width: 300px;
  a {
    padding: 1px 4px;
  }
  .close-btn {
    position: absolute;
    top: 4px;
    z-index: 1000;
    right: 28px;
  }
  .close-btn:hover {
    background-color: rgba(158, 158, 158, 0.1);
  }
  .more-option {
    @extend .close-btn;
    right: 5px;
  }
  .more-option:hover {
    background-color: rgba(158, 158, 158, 0.1);
  }
}
.extended-search-box {
  position: absolute;
  z-index: 1000;
  min-width: 350px;
  right: 122px;
  top: 30px;
  input {
    height: 26px;
    max-height: 26px;
    min-height: 10px;
  }
  label {
    padding: .1rem .75rem;
  }
  .checkbox {
    margin-left: 10px;
  }
}
@media (max-width: 1304px) {
  .search-input-mails {
    min-width: 240px;
  }
}
@media (max-width: 1240px) {
  .c-rwd {
    display: block;
    float: none;
    margin-left: 2.25rem;
    margin-top: 1rem;
  }
}
@media (max-width: 633px) {
  .btn-group-c {
    margin-bottom: 10px;
  }
  .c-rwd {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media (max-width: 543px) {
  .c-span {
    margin-left: 4px!important;
    margin-top: 10px;
    
  }
  .c-form {
    margin-right: 0px!important;
  }
}
</style>
