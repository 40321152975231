<template>
  <div class="box" id="messages-column-box">
    <div class="box-header d-gray">
      <div class="input-group pull-left title-input" v-if="isChangeTitle">
        <input class="form-control inline" type="text" v-model="ticket.subject">
        <span class="input-group-btn">
          <button class="btn white b-a no-shadow primary-color none-radius btn-xs" type="button" 
          v-on:click="changeTicketTitle" :disabled="procesing == 1">{{ $t('btnNames.save') }}</button>
        </span>
      </div>

      <h6 class="text-muted _400 inline" >
        <span v-if="!isChangeTitle">{{ticket.subject}}</span>&nbsp;
      </h6>
      <div class="pull-right c-rwd">
        <div class="btn-group primary-color ticket-btn-group">
          <a class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.btnGroup.edit')" v-bind:class="{ 'active': isChangeTitle}"
              v-on:click="isChangeTitle = !isChangeTitle">
            <i class="material-icons md-18">edit</i>
          </a>
          <a v-if="isPinned" class="nav-link btn btn-xs white none-radius active" :title="$t('ticket.ticketTabs.btnGroup.unpin')" 
              v-on:click="removeFromPinTickets($event)">
            <i class="material-icons md-18">pin_drop</i>
          </a>
          <a v-else class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.btnGroup.pin')" v-on:click="addToPinTickets($event)">
            <i class="material-icons md-18">pin_drop</i>
          </a>
          <a class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.btnGroup.pin')" v-on:click="EventBus.$emit('openNewQuickChat', ticket.id)">
            <i class="material-icons md-18">photo_size_select_small</i>
          </a>
          <!-- <a class="nav-link btn btn-xs white none-radius" title="merge ticket">
            <i class="material-icons md-18"></i>
          </a> -->
          <a class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.btnGroup.split')" v-on:click="splitTicket($event)">
            <i class="material-icons md-18"></i>
          </a>
          <a v-if="isRemove" class="nav-link btn btn-xs white none-radius active disabled" :title="$t('ticket.ticketTabs.btnGroup.close')" >
            <i class="material-icons md-18">delete</i>
          </a>
          <a v-else class="nav-link btn btn-xs white none-radius" :class="{active: ticket.isRemoved, 'disabled': !UserManager.permissions.find(element => element == 'tickets.delete')}" :title="$t('ticket.ticketTabs.btnGroup.remove')" v-on:click="removeTicket($event)" >
            <i class="material-icons md-18"></i>
          </a>
          <a v-if="isClose" class="nav-link btn btn-xs white none-radius active disabled" :title="$t('ticket.ticketTabs.btnGroup.close')" >
            <i class="material-icons md-18">check</i>
          </a>
          <a v-else class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.btnGroup.close')" v-on:click="closeTicket($event)">
            <i class="material-icons md-18"></i>
          </a>
          <a v-if="isMessangerConversations" class="nav-link btn btn-xs white none-radius" :title="$t('ticket.ticketTabs.messanger.title')" v-on:click="openConversation(conversations[0])">
            <!-- <i class="fab fa-facebook-messenger"></i> -->
            <img style="width: 15px; margin-bottom: 2px; filter: opacity(61%);" src="../../../assets/images/m5.svg">
          </a>
        </div>
      </div>
    </div>
    <div class="box-divider m-a-0"></div>
    <div class="l-gray p-b">
      <ul class="btn-group nav nav-pills p-t p-l">
        <li class="nav-item">
          <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'reply'" 
          v-bind:class="{ 'active': currentTab == 'reply'}">
            <span class="_400">{{ $t('ticket.ticketTabs.reply.reply') }}</span>
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-sm white none-radius primary-color" v-on:click="currentTab = 'timeline'" 
          v-bind:class="{ 'active': currentTab == 'timeline'}">
            <span class="_400">{{ $t('ticket.ticketTabs.timeline.timeline') }}</span>
          </button>
        </li>
        <!-- <li class="nav-item">
          <button class="btn btn-sm white none-radius active primary-color" data-toggle="tab" data-target="#tab-message"><span class="_400">Reply</span></button>
        </li>
        <li class="nav-item">
          <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#tab-task"><span class="_400">Tasks</span></button>
        </li>
        <li class="nav-item">
          <button class="btn btn-sm white none-radius primary-color" data-toggle="tab" data-target="#tab-timeline"><span class="_400" >Timeline</span></button>
        </li> -->
      </ul>
      <!-- <span v-for="c in conversations" v-if="isMessangerConversations">
        <a class="pull-right m-t m-r-sm msg-blue" :title="$t('ticket.ticketTabs.messanger.title')" v-on:click="openConversation(c)">
          <span style="font-size: 20px;" >
            <i class="fab fa-facebook-messenger"></i>
          </span>
        </a>
      </span> -->
    </div>
    <div class="box-divider m-a-0 m-b"></div>
    <div class="tab-content p-a m-b-md">
      <div class="tab-pane animated fadeIn active">
        <TicketTabMessage v-if="ticket.id && currentTab == 'reply'" v-bind:ticket="ticket"></TicketTabMessage>
        <TimelineTab v-if="ticket.id && currentTab == 'timeline'" v-bind:ticket="ticket"></TimelineTab>
      </div>
      <!-- <div class="tab-pane animated fadeIn active" id="tab-message">
        <TicketTabMessage v-if="ticket.id" v-bind:ticket="ticket"></TicketTabMessage>
      </div>
      <div class="tab-pane animated fadeIn text-muted" id="tab-task">
      </div>
      <div class="tab-pane animated fadeIn text-muted" id="tab-timeline">
        <TimelineTab v-if="ticket.id" v-bind:ticket="ticket"></TimelineTab>
      </div> -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import TicketTabMessage from './TicketTabMessage.vue'
import TimelineTab from './TimelineTab.vue'
import TicketManager from '../../../TicketManager.js';
import Api from '../../../Api.js';
import EventBus from '../../../event-bus.js';
import UserManager from '../../../UserManager.js';
import EmployeeManager from '../../../EmployeeManager.js';

export default {
  name: 'TicketTabs',
  props: {
    ticket: Object
  },
  components: {
    TicketTabMessage,
    TimelineTab

  },
  data: function () {
    return {
      isMessangerConversations: false,
      conversations: [],
      isPinned: false,
      isClose: false,
      isRemove: false,
      isChangeTitle: false,
      procesing: false,
      textPermission: 'You do not have permission to perform this action.',
      textError: 'There was a problem with ',
      textSuccess: 'Ticket has been successfully ',
      currentTab: 'reply',
      UserManager: UserManager,
      EventBus
    }
  },
  created: function() {
    this.isPinned = TicketManager.isTicketPinned(this.ticket.id);
    this.searchMessangerCoversation();
  },
  methods: {
    openConversation(c) {
      EventBus.$emit('openConversation', c)
    },
    searchMessangerCoversation() {
      let data = {
        "relations": [{
            "objectType": "ticket",
            "objectId": this.ticket.id
        }]
      }
      Api.fb.searchConversations(data).then((conversations) => {
        if (conversations.length  == 0) return;
        this.conversations = conversations;
        this.isMessangerConversations = true;
      });
    },
    changeTicketTitle: function() {
      if (this.ticket.subject.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
        return;
      }

      this.procesing = true;

      Api.changeTicketTitle(this.ticket.id, this.ticket.subject).then((ticket)=> {
        this.procesing = false;
        this.isChangeTitle = false;
        this.showNotify('notification-success',this.$i18n.t('notifications.changeSuccess'));
      });

    },
    removeFromPinTickets: function(event) {
      event.preventDefault();
      TicketManager.unPinTicket(this.ticket);
      this.isPinned = false;
      this.showNotify('notification-success', this.$i18n.t('notifications.actionSuccess'));
    },
    addToPinTickets: function(event) {
      event.preventDefault();
      TicketManager.pinTicket(this.ticket);
      this.isPinned = true;
      this.showNotify('notification-success', this.$i18n.t('notifications.actionSuccess'));
    },
    splitTicket: function (event) {
      event.preventDefault();

      let message = 'Are you sure you want to split selected messages? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        EventBus.$emit('split-selected-messages');
      }).catch(r => r);
    },
    closeTicket: function (event) {
      event.preventDefault();

      let message = 'Are you sure you want to close this ticket? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        Api.closeTicket(this.ticket.id).then((ticket) => {
          this.ticket.status = ticket.status;
          this.isClose = true;
          this.showNotify('notification-success', this.$i18n.t('notifications.actionSuccess'));
        }).catch((reson) => {
          if (reson.response.status == 403) {
            this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
          } else {
            this.showNotify('notification-error', this.$i18n.t('notifications.error'));
          } 
        });
      }).catch(r => r);
    },
    removeTicket: function (event) {
      event.preventDefault();
      
      let message = 'Are you sure you want to remove this ticket from your collection? Please confirm to continue.';
      this.confirmation(message).then(dialog => {
        Api.removeTicket(this.ticket.id).then((ticket) => {
          this.isRemove = true;
          this.showNotify('notification-success', this.$i18n.t('notifications.actionSuccess'));
        }).catch((reson) => {
          if (reson.response.status == 403) {
            this.showNotify('notification-error', this.$i18n.t('notifications.permission'));
          } else {
            this.showNotify('notification-error', this.$i18n.t('notifications.error'));
          } 
        });
      }).catch(r => r);
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      });
    },
    confirmation: function (message) {
      let options = {
        view: 'my-unique-confirmation',
        customClass: 'custom-confirmation',
        message: message
      };

      return this.$dialog.confirm(options.message, options);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ticket-btn-group {
  .active {
    color: rgba(255, 255, 255, 0.87);
    background-color: #0cc2aa;
  }
}
.title-input {
  width: 55%;
  input {
    min-height: 1.6rem; 
    padding: 0rem .75rem;
  }
}
.msg-blue {
  color: #0078ff;
}
.msg-blue:hover {
  color: #0060cc;
}
@media (max-width: 1200px) {
.c-rwd {
  display: block;
  float: none;
  margin-top: 10px;
}
}
</style>
