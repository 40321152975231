<template>
    <div>
        <form>
            <div class="form-group row">
                <!-- employee avatar -->
                <label class="col-sm-1 form-control-label text-center">
                    <span class="avatar w-32"><gravatar :email="user.email" default-img="identicon"/></span>
                    <!-- <span class="avatar w-32"><img src="../../../assets/images/a2.jpg" alt="..."> <i class="busy b-white right"></i></span> -->
                </label>

                <div class="col-sm-11">
                    <!-- loader -->
                    <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);" v-if="inProgress">
                        <div style="position: relative;">
                            <rotate-square5/>
                        </div>
                    </div>
                    <div class="position: relative;">
                        <!-- drag area -->
                        <div class="text-drag-file" v-if="isDrag" style="pointer-events: none;">
                            <span class="title-color">{{ $t('ticket.ticketTabs.reply.drag') }}</span>
                        </div>
                        <!-- textarea for message -->
                        <textarea ref="fileform" class="form-control new-message-area" rows="5" v-model="newMessage"
                                  @dragenter="isDrag=true" @dragleave="isDrag=false" @dragover.prevent="true" @drop="onFilesDrop"/>
                    </div>
                </div>

            </div>

            <div class="form-group row">
                <label class="col-sm-1 form-control-label"/>
                <div class="col-sm-11">
                    <!-- files list -->
                    <div class="list-group list-group-gap m-b none-radius" v-for="(file, key) in files" :key="key">
                        <div class="none-radius l-gray p-y-xs p-l-sm m-b-sm single-file" >
                            <a class="file-link" target="_blank" :href="file.fileUrl"
                               :class="{ 'text-info': file.isUrlActive, 'disabled': file.isDisabled}">{{ file.fileName | truncate(20)}}</a>
                            <span class="text-muted p-l-xs p-r-md"> ({{ file.formatedSize }})</span>

                            <div class="progress" v-if="file.fileUrl == null"
                                 :class="{ 'none-display': isErrorUpload}">
                                <div class="progress-bar primary" :style="{ width: file.progress + '%' }">{{file.progress}} %</div>
                            </div>
                            <div v-if="isErrorUpload" class="upload-error">
                                <span class="text-red">{{ $t('ticket.ticketTabs.reply.erUpload') }}</span>
                            </div>

                            <a class="text-muted pull-right p-r-sm" href="#" @click="removeFile( key )"><i class="material-icons md-18"></i></a>
                        </div>
                    </div>
                    <!-- btn uplad files -->
                    <div class="form-file">
                        <input type="file" ref="files" multiple @change="onFilesInputChange" style="display: none;">
                        <button class="btn white btn-sm none-radius primary-color" :title="$t('ticket.ticketTabs.reply.titleFileUpload')" @click.prevent="addFiles()">
                            <i class="material-icons md-20"></i>
                        </button>
                    </div>
                    <!-- btns canned respons and send message -->
                    <div class="pull-right c-rwd">
                        <div class="btn-group dropdown primary-color m-r-xs">
                            <button class="btn white dropdown-toggle btn-sm none-radius _400" data-toggle="dropdown" aria-expanded="false">{{ $t('ticket.ticketTabs.reply.cannedRes') }}</button>
                            <div class="dropdown-menu dropdown-menu-scale pull-right">
                                <a class="dropdown-item" href="#"
                                   v-for="response in evenCannedResponses"
                                   :key="response._id"
                                   @click="setResponse(response.content, $event)">{{response.title}}</a>
                            </div>
                        </div>

                        <button v-if="ticket.channels.length == 1" class="btn white btn-sm primary-color none-radius _400" aria-expanded="false" @click.prevent="sendMessage(ticket.channels[0].type)"
                                :class="{'disabled': !UserManager.permissions.find(element => element == 'tickets.reply') && ticket.assignedUserId != user.userId}">
                            {{ $t('ticket.ticketTabs.reply.send') }} <TicketChannelIcon style="margin-bottom: 0.1rem;" :type="ticket.channels[0].type" />
                        </button>

                        <div class="btn-group dropdown primary-color m-r-xs" v-if="ticket.channels.length > 1">
                            <button class="btn white dropdown-toggle btn-sm none-radius _400" data-toggle="dropdown" aria-expanded="false">
                                {{ $t('ticket.ticketTabs.reply.send') }}:</button>
                            <div class="dropdown-menu dropdown-menu-scale pull-right">
                                <a class="dropdown-item" href="#" v-for="channel in ticket.channels" :key="channel.value" @click.prevent="sendMessage(channel.type)">
                                    <TicketChannelIcon :type="channel.type" :withTitle="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- ./END btns canned respons and send message -->
                </div>
            </div>
        </form>

        <ul class="list inset m-t-md">
            <TicketMessage
                v-for="m in evenMessages"
                :message="m"
                :key="m.id"
            />
        </ul>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import { RotateSquare5 } from 'vue-loading-spinner';
import TicketMessage from './TicketMessage.vue';
import EventBus from '../../../event-bus.js';
import SystemSocket from '../../../SystemSocket.js';
import UserManager from '../../../UserManager.js';
import EmployeeManager from '../../../EmployeeManager.js';
import TicketChannelIcon from '../../TicketChannelIcon.vue';
import Common from '../../../Common';
import Gravatar from 'vue-gravatar';

const LS_DRAFT_KEY = "ticket_drafts_";
const LS_FILE_DRAFT_KEY = "ticket_draft_files_";

export default {
    name: 'TicketTabMessage',
    props: {
        ticket: Object
    },
    components: {
        RotateSquare5,
        TicketMessage,
        TicketChannelIcon,
        Gravatar
    },
    data() {
        return {
            textSuccess: 'Message has been sent correctly.',
            textError: 'There was a problem with ',
            textPermission: 'You do not have permission to perform this action.',
            files: [],
            uploadPercentage: 0,
            isDrag: false,
            inProgress: false,
            isErrorUpload: false,
            messages: [],
            newMessage: '',
            cannedResponses: [],
            user: UserManager.user,
            EmployeeManager: EmployeeManager,
            UserManager: UserManager
        }
    },
    created: function() {
        if (this.ticket && this.ticket._id) {
            const draft = localStorage.getItem(LS_DRAFT_KEY + this.ticket._id);
            if (draft) this.newMessage = draft;
            const filesJSON = localStorage.getItem(LS_FILE_DRAFT_KEY + this.ticket._id);
            if (filesJSON) {
                try {
                    const f = JSON.parse(filesJSON);
                    if (Array.isArray(f)) {
                        this.files = f;
                    }
                } catch (error) { console.warn(error); }
            }
        }

        Api.getTicketMessages(this.ticket.id).then((messages) => {
            this.messages = messages;
            EventBus.$emit('messagesChange', this.messages);
        });

        SystemSocket.socket.on('object', this.onMessageChange);
        EventBus.$on('split-selected-messages', this.spiltSelectedMessage);

        this.cannedResponses.length = 0;
        UserManager.cannedResposnes.forEach(c => this.cannedResponses.push(c));
        UserManager.events.on('canned_resposnes_changed', this.onUserManagerChangeEvent);
    },
    destroyed: function() {
        if (this.ticket && this.ticket._id) localStorage.setItem(LS_DRAFT_KEY + this.ticket._id, this.newMessage);

        if (this.files.length > 0) {
            const filesJSON = JSON.stringify(this.files);
            localStorage.setItem(LS_FILE_DRAFT_KEY + this.ticket._id, filesJSON);
        }

        SystemSocket.socket.off('object', this.onMessageChange);
        EventBus.$off('split-selected-messages', this.spiltSelectedMessage);
        UserManager.events.removeListener('canned_resposnes_changed', this.onUserManagerChangeEvent);
    },
    computed: {
        evenMessages: function () {
            this.addEmpEmails();
            return [...this.messages].sort(function(a, b) {
                var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },
        evenCannedResponses: function () {
            return [...this.cannedResponses].sort(function(a, b) {
                var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        }
    },
    methods: {
        onUserManagerChangeEvent: function(cannedRes) {
            this.cannedResponses.length = 0;
            cannedRes.forEach(c => this.cannedResponses.push(c));
        },
        spiltSelectedMessage: function () {
            const msgIds = [];
            for (var i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].selected) msgIds.push(this.messages[i].id)
            }
            if (msgIds.length == 0) {
                this.showNotify('notification-error', this.$i18n.t('notifications.noSelected'));
                return;
            }

            Api.splitMessages(this.ticket, msgIds).then((ticket) => {
                this.showNotify('notification-success', this.$i18n.t('notifications.splitSuccess'));
                // console.log('Zrobić metode do aby po splicie dodawał się nowy ticket do powiązanych - Wiktor!!!');
            }).catch((reson) => {
                if (reson.response.status == 403) {
                    this.showNotify('notification-error', this.textPermission);
                } else {
                    this.showNotify('notification-error', this.$i18n.t('notifications.error'));
                }
            });
        },
        onMessageChange: function (msg) {
            if (msg.object == 'message' && msg.verb == 'create') {
                if (this.ticket.id + "" == msg.data.ticketId + "") {
                    const idx = this.messages.map(m => m.id + "").indexOf(msg.data.id + "");
                    if (idx == -1) this.messages.push(msg.data);
                }
                // EventBus.$emit('addFileToFilesContent', msg.data);
                EventBus.$emit('messagesChange', this.messages);
            }

            if (msg.object == 'attachment' && msg.verb == 'create') {
                const idx = this.messages.map(m => m.id + "").indexOf(msg.data.ticketMessageId + "");
                if (idx == -1) return;

                const message = Object.assign({}, this.messages.splice(idx, 1)[0]);

                if (!message.attachments) message.attachments = [];
                message.attachments.push(msg.data);
                this.messages.push(message);
                // EventBus.$emit('addFileToFilesContent', message);
                EventBus.$emit('messagesChange', this.messages);
            }

            if (msg.object == 'message' && msg.verb == 'update') {
                const idx = this.messages.map(m => m.id + "").indexOf(msg.data.id + "");

                if (idx != -1) {
                    this.$set(this.messages, idx, msg.data);
                }

                // forwar wiadmości z innego ticketa w dwie strony do nas i od nas
                if (idx != -1 && this.ticket.id + "" != msg.data.ticketId + "") {
                    this.messages.splice(idx, 1);
                    EventBus.$emit('messagesChange', this.messages);
                }
                if (idx == -1 && this.ticket.id + "" == msg.data.ticketId + "") {
                    this.messages.push(msg.data);
                    EventBus.$emit('messagesChange', this.messages);
                }
                // this.messages.splice(idx, 1);
                // EventBus.$emit('messagesChange', this.messages);
            }
        },
        addFiles() {
            this.$refs.files.click();
        },
        onFilesDrop(e) {
            this.isDrag = false;
            e.preventDefault();
            this.handleFilesUpload(e.dataTransfer.files);
        },
        onFilesInputChange(e) {
            const uploadedFiles = this.$refs.files.files;
            this.handleFilesUpload(uploadedFiles);
        },

        handleFilesUpload(uploadedFiles) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                const formatSize = Common.formatBytes(uploadedFiles[i].size);

                const fObj = {
                    progress: 0,
                    formatedSize: formatSize,
                    fileName: uploadedFiles[i].name,
                    fileUrl: null,
                    isUrlActive: false,
                    isDisabled: true
                };

                this.files.push(fObj);

                const formData = new FormData();
                formData.append('file', uploadedFiles[i]);

                this.isErrorUpload = false;

                Api.uploadFile(formData, (progressEvent) => {
                    fObj.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                })
                    .then((ob) => {
                        fObj.fileUrl = ob.fileUrl;
                        fObj.isUrlActive = true;
                        fObj.isDisabled = false;
                    }).catch((reson) => {
                        this.isErrorUpload = true;
                    });
            }
        },
        removeFile(key) {
            this.files.splice(key, 1);
        },
        sendMessage: function(channelType) {
            if (!this.newMessage.length) {
                this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
                return;
            }
            const filesUrl = [];
            for (var i = this.files.length - 1; i >= 0; i--) {
                filesUrl.push(this.files[i].fileUrl);
            }
            this.inProgress = true;
            const data = { content: this.newMessage, attachments: filesUrl, channel: channelType };
            Api.tickets.sendReply(this.ticket.id, data).then((message) => {
                // this.messages.push(message);
                this.newMessage = '';
                localStorage.removeItem(LS_DRAFT_KEY + this.ticket._id);
                localStorage.removeItem(LS_FILE_DRAFT_KEY + this.ticket._id);
                this.inProgress = false;
                this.files = [];
                this.showNotify('notification-success', this.$i18n.t('notifications.sendSuccess'));
                // EventBus.$emit('addFileToFilesContent', message);
            }).catch((reson) => {
                this.inProgress = false;
                this.showNotify('notification-error', this.$i18n.t('notifications.error'));
            });
        },
        setResponse: function (response, event) {
            event.preventDefault();
            if (this.newMessage.length) {
                this.newMessage += "\n\n";
                this.newMessage += response;
            } else {
                this.newMessage = response;
            }
        },
        addEmpEmails: function() {
            this.messages.forEach((m) => {
                if (m.userId) {
                    m.empEmail = EmployeeManager.getEmail(m.userId);
                }
            });
        },
        showNotify (type, text) {
            this.$notify({
                group: 'global',
                type: type,
                text: text
            });
        }

    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .dropdown-menu {
    padding: 0px;
    .dropdown-item {
      padding: 10px;
      border-bottom: 1px dashed #ccc;
      &:last-child {
        border-bottom: 0px dashed #ccc;
      }
    }
  }
  .none-display {
    display: none!important;
  }
  .upload-error {
    display: inline-block;
    width: 100px;
  }
  .form-file {
    width: 100px;
    display: inline-block;
  }
  .text-drag-file {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #fafbfc;
    border: 3px dashed #58666e;
    opacity: .8;
    width: 95%;
    height: 95%;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .single-file {
    width: 400px;
  }
  .progress {
    display: inline-block;
    width: 100px;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .progress-bar {
    border-radius: 0px;
  }
  .disabled {
    pointer-events: none;
    cursor: default;
  }
@media (max-width: 866px) {
  .c-rwd {
    display: block;
    float: none;
    margin-top: 10px;
  }
}
</style>
