<template>
  <div class="email-settings">
    <div>
      <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">Obsługa wiadomosci</h6>
      </div>
      <div class="row m-b-1">
        <div class="col-lg-4 col-md-4">
          <p class="m-a">
            <strong>Adresy, na które Twoi klienci mogą wysyłać wiadomości</strong><br/>
            Każda wiadomość, która trafi na tą skrzynkę zostanie automatycznie zamieniona na zgłoszenie
          </p>
        </div>
        <div class="col-lg-8 col-md-8">
          <p class="m-a">
            <ul class="list inset" v-if="Array.isArray(internalBoxes) && internalBoxes.length">
              <li class="list-item" v-for="box in internalBoxes">
                <div class="list-body">
                  <div>
                    <strong><i class="material-icons text-success md-18">check</i> {{ box.address }}</strong>@{{ host }} <a class="text-info text-sm" @click.prevent="editInternalBox(box)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-success md-18" v-if="box.autoAssign">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Auto. przypisywanie |

                    <i class="material-icons text-success md-18" v-if="box.sendReceiveConfirm">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Potwierdzenie otrzymania wiadomosci
                  </small>
                </div>
              </li>
              <li class="list-item text-info">
                <a href="#" @click.prevent="addInternalBox"><i class="material-icons  md-18">add</i> Dodaj kolejną</a>
              </li>
            </ul>
            <ul class="list inset" v-else>
              <li class="list-item p-a-0"><i class="material-icons text-warn md-18">cancel</i> Nie masz jeszcze żadnej wewnętrznej skrzynki</li>
              <li class="list-item p-a-0 text-info"><a href="#" @click.prevent="addInternalBox"><i class="material-icons  md-18">add</i> Dodaj pierwszą</a></li>
            </ul>
          </p>
        </div>  
      </div>

      <div class="row m-b-1">
        <div class="col-lg-4 col-md-4" style="border-top: 1px dashed #ddd;">
          <p class="m-a">
            <strong>Widget LiveChat</strong><br/>
            Rozmawiaj ze swoimi klientami online, udzielaj im natychmiastowej odpowiedzi. Możesz dodać dowolną ilość stron, które
            będą wyświetlać dla klientów okienko do rozmowy "na chacie". Każda strona może mieć swoje indywidualne ustawienia takie jak
            godziny dostępności, wiadomości powitalne, czy styl graficzny widget'u.
          </p>
        </div>
        <div class="col-lg-8 col-md-8" style="border-top: 1px dashed #ddd;">
          <p class="m-a">
            <ul class="list inset" v-if="Array.isArray(livechatPages) && livechatPages.length">
              <li class="list-item" v-for="page in livechatPages" :key="page._id">
                <div class="list-body">
                  <div>
                    <strong><i class="material-icons text-success md-18">check</i> {{ page.name }}</strong>&nbsp;<a class="text-info text-sm" @click.prevent="editLiveChatPage(page)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-success md-18" v-if="page.autoAssign">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Auto. przypisywanie |

                    <i class="material-icons text-success md-18" v-if="page.leaveMessageIfNoAgents">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Wiadomości offline
                  </small>
                </div>
              </li>
              <li class="list-item text-info">
                <a href="#" @click.prevent="editLiveChatPage({})"><i class="material-icons  md-18">add</i> Dodaj kolejny widget</a>
                </li>
            </ul>
            <ul class="list inset" v-else>
              <li class="list-item p-a-0"><i class="material-icons text-warn md-18">cancel</i> Nie masz jeszcze żadnego LiveChat'u</li>
              <li class="list-item p-a-0 text-info"><a href="#" @click.prevent="editLiveChatPage({})"><i class="material-icons  md-18">add</i> Dodaj pierwszy widget</a></li>
            </ul>
          </p>
        </div>  
      </div>

      <div class="row m-b-1">
        <div class="col-lg-4 col-md-4 " style="border-top: 1px dashed #ddd;">
          <p class="m-a">
            <strong>Integracje z innymi komunikatorami (beta*)</strong><br/>
            Możesz podłączyć Instagrama lub Facebooka, tak aby wiadomości z tych komunikatorów obsługiwać przez Swiftresponse<br/>
            <span class="text-muted">*Funkcjonalność w fazie testów beta - niektóre funkcje integracji mogą jeszcze nie działać prawidłowo.</span>
          </p>
        </div>
        <div class="col-lg-8 col-md-8" style="border-top: 1px dashed #ddd;">
          <p class="m-a">
            <ul class="list inset" v-if="Array.isArray(integrations) && integrations.length">
              <li class="list-item" v-for="int in integrations">
                <div class="list-body" v-if="int.type == 'ig'">
                  <div>
                    <strong>
                      <i class="material-icons text-success md-18" v-if="int.data.status == 'logged'">check</i>
                      <i class="material-icons text-warning md-18" v-else>cancel</i>
                      {{ int.data.username }}
                    </strong>
                    &nbsp;<small class="text-muted">(Instagram)</small>
                    &nbsp;<small class="text-muted" v-if="int.data._lastScanAt">Ostatnie sprawdzenie {{int.data._lastScanAt| moment('from')}}</small>
                    &nbsp;<a class="text-info text-sm" @click.prevent="editIntegration(int)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-success md-18" v-if="int.data.autoAssign">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Auto. przypisywanie |

                    <i class="material-icons text-success md-18" v-if="int.data.sendReceiveConfirm">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Potwierdzenie otrzymania wiadomosci
                  </small>
                </div>
                <div class="list-body" v-if="int.type == 'fb'">
                  <div>
                    <strong>
                      <i class="material-icons text-success md-18" v-if="int.data.status == 'ok'">check</i>
                      <i class="material-icons text-warning md-18" v-else>cancel</i>
                      {{ int.data.name }}
                    </strong>
                    &nbsp;<small class="text-muted">(Facebook)</small>
                    &nbsp;<small class="text-muted" v-if="int.data._lastScanAt">Ostatnie sprawdzenie {{int.data._lastScanAt| moment('from')}}</small>
                    &nbsp;<a class="text-info text-sm" @click.prevent="editIntegration(int)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-success md-18" v-if="int.data.autoAssign">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Auto. przypisywanie |

                    <i class="material-icons text-success md-18" v-if="int.data.sendReceiveConfirm">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Potwierdzenie otrzymania wiadomosci
                  </small>
                </div>
              </li>

              <li class="list-item text-info">
                <a href="#" @click.prevent="addIntegration"><i class="material-icons  md-18">add</i> Dodaj kolejną</a>
                </li>
            </ul>
            <ul class="list inset" v-else>
              <li class="list-item"><i class="material-icons text-warn md-18">cancel</i> Nie podłączyłes jeszcze żadnej integracji</li>
              <li class="list-item text-info"><a href="#" @click.prevent="addIntegration"><i class="material-icons  md-18">add</i> Dodaj pierwszą</a></li>
            </ul>
          </p>
        </div>  
      </div>
    </div>

    <div>
      <div class="box-header d-gray">

        <h6 class="text-muted _400 inline">{{$t('settings.tabEmailBoxes.subtitle')}}</h6>
        <div class="pull-right m-l-sm minus-margin c-rwd">
          <a class="btn btn-sm white none-radius" v-on:click="addAccount">
            <span class="primary-color _400">{{$t('btnNames.add')}}</span>
          </a>&nbsp;
          <a class="btn btn-sm white none-radius" v-on:click="deleteAccount">
            <span class="primary-color _400">{{$t('btnNames.delete')}}</span>
          </a>&nbsp;
          <a class="btn btn-sm white none-radius" v-on:click="refreshStatus" :title="$t('settings.tabEmailBoxes.refresh')">
            <span class="primary-color _400"><i class="material-icons md-18">refresh</i></span>
          </a>&nbsp;
        </div>
      </div>
      <div class="row m-b-1">
        <div class="col-lg-4 col-md-4">
          <p class="m-a">
            <strong>Automatyczna zamiana maili na zgłoszenia</strong><br/>
            W tej sekcji możesz podłączyć swoją skrzynkę firmową (np. biuro@twoja-firma.com) tak, aby wszystkie maile, które na nią przychodzą były automatycznie przechwytywane przez Swiftresponse i zamieniane na zgłoszenie.<br/>
            Więcej informacji na ten temat znajdziesz w naszej <a target="_blank" class="text-info" href="https://swiftresponse.pl/knowledge-base/automatyczna-zamiana-maili-na-zgloszenia/">Bazie wiedzy</a>
          </p>
        </div>
        <div class="col-lg-8 col-md-8">
          <p class="m-a" v-if="1 == 0">
            <ul class="list inset" v-if="Array.isArray(accounts) && accounts.length">
              <li class="list-item" v-for="box in accounts">
                <div class="list-body">
                  <div>
                    <strong><i class="material-icons text-success md-18">check</i> {{ box.address }}</strong>@{{ host }} <a class="text-info text-sm" @click.prevent="editInternalBox(box)">(Edytuj)</a>
                  </div>
                  <small class="text-muted text-ellipsis">
                    <i class="material-icons text-success md-18" v-if="box.autoAssign">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Auto. przypisywanie |

                    <i class="material-icons text-success md-18" v-if="box.sendReceiveConfirm">check_circle</i>
                    <i class="material-icons text-warning md-18" v-else>cancel</i>
                    Potwierdzenie otrzymania wiadomosci
                  </small>
                </div>
              </li>
              <li class="list-item text-info">
                <a href="#" @click.prevent="addInternalBox"><i class="material-icons  md-18">add</i> Dodaj kolejną</a>
                </li>
            </ul>
            <ul class="list inset" v-else>
              <li class="list-item p-a-0"><i class="material-icons text-warn md-18">cancel</i> Nie masz podłączonej jeszcze żadnej skrzynki do przechywtywania maili</li>
              <li class="list-item p-a-0 text-info"><a href="#" @click.prevent="addInternalBox"><i class="material-icons  md-18">add</i> Dodaj pierwszą</a></li>
            </ul>
          </p>

          <!-- Tutaj zaczyna sie stare -->
          <div class="row">
            <div class="col-lg-6 col-md-6" v-for="a in accounts" :key="a.id">
              <div class="box m-a">
                <div class="box-header d-gray">
                  <h6 class="text-muted _400 inline" v-if="a.user">{{ a.user }}</h6>
                  <h6 class="text-muted _400 inline" v-else>{{$t('settings.tabEmailBoxes.box')}}</h6>
                  <label class="md-check pull-right">
                      <input type="checkbox" v-bind:value="a.id" v-model="selectedAccounts">
                      <i class="primary m-r-xs"></i>
                  </label>
                </div>
                <div class="box-body">
                  <form class="form-horizontal">
                    <div class="form-group row">
                      <label class="col-sm-4 form-control-label">{{$t('settings.tabEmailBoxes.login')}}</label>
                      <div class="col-sm-8">
                        <input class="form-control" type="email" v-model="a.user">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 form-control-label">{{$t('settings.tabEmailBoxes.password')}}</label>
                      <div class="col-sm-8">
                        <input class="form-control" type="password" v-model="a.password">
                      </div>
                    </div>

                    
                    <div v-if="a.isNew && a.status == 'finding.conf'" style="margin-top: 15px;">
                      <rotate-square2 style="width: 20px; height:20px; float:left;"></rotate-square2>
                      <span style="padding-left: 15px;">Trwa wyszukiwanie ustawień u dostawcy poczty</span>
                    </div>

                    <div v-if="a.isNew && a.status == 'conf.authenticationfailed'" style="margin-top: 15px;">
                      <span style="">Znaleźliśmy ustawienia poczty, ale niestety podane hasło jest błędne</span>
                      <span v-if="a.user.split('@')[1] == 'gmail.com'"><br/>Aby moc podlaczyc Gmail musisz wlaczyc dostep dla "Mniej bezpiecznych aplikacji"</span>
                    </div>

                    <div v-if="a.isNew && a.status == 'conf.host.not-found'" style="margin-top: 15px;">
                      <span style="">Niestety nie udało nam się odpowiedniego serwera do {{ a.user.split('@')[1] }}</span>
                    </div>

                    <div v-if="a.isNew && a.status == 'conf.not-found'" style="margin-top: 15px;">
                      <span style="">Niestety nie udało nam się znaleźć ustawień dla Twojej skrzynki. Spróbuj ustawić je samodzielnie poniżej lub skontaktuj się ze swoim administratorem</span>
                    </div>

                    <div v-if="!a.manualConfig && a.isNew" style="margin-top: 15px;">
                      <a class="coral" href="#" @click.prevent="a.manualConfig = true; a.status = null;">Ręczna konfiguracja</a>
                    </div>

                    <div v-if="!a.manualConfig && a.isNew && a.status != 'finding.conf' && a.status != 'conf.not-found'">
                      <a class="m-r nav-link btn btn-sm white none-radius" v-on:click="cancelAdding(a)" 
                        v-bind:class="{ disabled: procesing }" >
                          <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
                      </a>

                      <a class="m-r nav-link btn btn-sm white none-radius" v-on:click="findConfiguration(a)" >
                        <span class="primary-color _400">{{$t('btnNames.save')}}</span>
                      </a>
                    </div>

                    <div v-if="!a.isNew || a.manualConfig">
                      <div class="form-group row">
                        <label class="col-sm-4 form-control-label">{{$t('settings.tabEmailBoxes.host')}}</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="text" v-model="a.host">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 form-control-label">{{$t('settings.tabEmailBoxes.port')}}</label>
                        <div class="col-sm-3">
                          <input class="form-control" type="text" v-model="a.port">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 form-control-label"></label>
                        <div class="col-sm-8">
                          <div class="checkbox">
                            <label class="ui-check">
                              <input type="checkbox" value="" v-model="a.tls">
                              <i class="dark-white"></i>
                              {{$t('settings.tabEmailBoxes.tls')}}<br/>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 form-control-label">{{$t('settings.tabEmailBoxes.operations')}}</label>
                        <div class="col-sm-8">
                          <select2 :options="optionsEmails" class="form-control" v-model="a.ackType">
                          </select2>
                        </div>
                      </div>
                      <div>
                        <a v-if="a.isNew" class="m-r nav-link btn btn-sm white none-radius" v-on:click="cancelAdding(a)" 
                        v-bind:class="{ disabled: procesing }" >
                          <span class="primary-color _400">{{$t('btnNames.cancel')}}</span>
                        </a>

                        <a class="m-r nav-link btn btn-sm white none-radius" v-on:click="saveAccount(a)" 
                        v-bind:class="{ disabled: procesing }" >
                          <span class="primary-color _400">{{$t('btnNames.save')}}</span>
                        </a>
                      </div>
                    </div>
                  </form>

                  <div v-if="!a.isNew">
                    <div v-if="statuses[a.user]">
                      <p class="conection-success text-white p-a-xs m-t m-b-xs" v-if="statuses[a.user].status == 'connected'">
                      {{$t('settings.tabEmailBoxes.status')}}: {{$t('settings.tabEmailBoxes.success')}}
                      </p>
                      <p class="conection-error text-white p-a-xs m-t m-b-xs" v-if="statuses[a.user].status == 'error'">
                      {{$t('settings.tabEmailBoxes.status')}}:  {{$t('settings.tabEmailBoxes.error')}} - {{statuses[a.user].lastError.textCode}}
                      </p>
                    </div>
                    <div v-else>
                      <p class="d-blue text-white p-a-xs m-t m-b-xs" >
                      {{$t('settings.tabEmailBoxes.noStatus')}}
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>  
  </div>
</template>

<script>
import $ from 'jquery'
import Api from '../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';
import EmployeeManager from '../../EmployeeManager.js';
import Select2 from '../Select2.vue';
import InternalBoxModal from './Modals/InternalBoxModal.vue'
import InternalBoxEditModal from './Modals/InternalBoxEditModal.vue'
import AddIntegrationModal from './Modals/AddIntegrationModal.vue'
import EditIntegrationModal from './Modals/EditIntegrationModal.vue'
import EditLiveChatModal from './Modals/EditLiveChatModal.vue'

export default {
  name: 'EmailSettingPage',
  props: {
    
  },
  components: {
    RotateSquare2,
    Select2
  },
  data: function () {
    return {
      optionsEmails: [
        { id: 'folder', text: this.$i18n.t('settings.tabEmailBoxes.optionsEmails.move') },
        { id: 'seen', text: this.$i18n.t('settings.tabEmailBoxes.optionsEmails.mark') },
        { id: 'delete', text: this.$i18n.t('settings.tabEmailBoxes.optionsEmails.remove') },
        { id: 'nothing', text: this.$i18n.t('settings.tabEmailBoxes.optionsEmails.nothing') },
      ],
      host: window.location.host,
      internalBoxes: [],
      livechatPages: [],
      integrations: [],
      accounts: [],
      selectedAccounts: [],
      statuses: [],
      procesing: false,
      isLoaded: false,
      textError: 'There was a problem with ',
    }
  },
  created: function() {
    this.getAccounts();
    this.refreshStatus();

    Api.tickets.getMailsConfiguration().then(res => {
      this.internalBoxes = res;
    }).catch(e => {});

    this._loadIntegrations();
    this._loadLiveChatPages();
  },
  destroyed: function() {
  },
  computed: {
    
  },
  methods: {
    _loadLiveChatPages: async function() {
      try {
        this.livechatPages = await Api.tickets.livechat.get();
      } catch (error) { console.error(error); }
    },

    _loadIntegrations: function() {
      this.integrations = [];
      Api.tickets.getIGIntegrations().then(res => {
        if (!Array.isArray(res)) return;
        this.integrations = this.integrations.concat(res.map(r => {
          return {
            type: 'ig',
            data: r
          }
        }));
      }).catch(e => {});

      Api.tickets.getFBIntegrations().then(res => {
        if (!Array.isArray(res)) return;
        this.integrations = this.integrations.concat(res.map(r => {
          return {
            type: 'fb',
            data: r
          }
        }));
      }).catch(e => {});
    },

    editInternalBox: function(box) {
      this.$modal.show(InternalBoxEditModal, {
        box
      }, {
        draggable: true,
        clickToClose: false,
        resizable: true,
        height: '600px'
      }, {
        'closed': (event) => {
          Api.tickets.getMailsConfiguration().then(res => {
            this.internalBoxes = res;
          }).catch(e => {});
        }
      });
    },

    editIntegration: function(int) {
      this.$modal.show(EditIntegrationModal, {
        type: int.type,
        conf: int.data
      }, {
        draggable: true,
        clickToClose: false,
        // resizable: true,
        scrollable: true,
        height: 'auto'
      }, {
        'closed': (event) => {
          this._loadIntegrations();
        }
      });
    },
    
    addIntegration: function() {
      this.$modal.show(AddIntegrationModal, {
      }, {
        height: 'auto',
        scrollable: true
      }, {
        'closed': (event) => {
          this._loadIntegrations();
        }
      });
    },

    addInternalBox: function() {
      this.$modal.show(InternalBoxModal, {
      }, {
        draggable: true
      }, {
        'closed': (event) => {
          Api.tickets.getMailsConfiguration().then(res => {
            this.internalBoxes = res;
          }).catch(e => {});
        }
      });
    },

    editLiveChatPage: function(page) {
      this.$modal.show(EditLiveChatModal, {
        page: page
      }, {
        clickToClose: false,
        scrollable: true,
        height: 'auto',
        width: '70%'
      }, {
        'closed': (event) => {
          this._loadLiveChatPages();
        }
      });
    },

    refreshStatus: function() {
      Api.mailboxScanner.getStatuses().then((statuses) => {
        this.statuses = statuses;
        if (this.statuses) {
          // this.showNotify('notification-success',this.$i18n.t('notifications.refreshSuccess')); 
        }
      }).catch((reson) => {
        // this.showNotify('notification-error', this.textError+'getting box accounts.');
      });
    },
    getAccounts: function() {
      Api.mailboxScanner.getAll().then((accounts) => {
        this.accounts = accounts;
        this.isLoaded = true;
      }).catch((reson) => {
        console.error(reson);
      });
    },
    
    /** Umożliwa przerwanie anulowanie dodawania  */
    cancelAdding: function(account) {
      let idx = this.accounts.indexOf(account);
      if (idx != -1) this.accounts.splice(idx, 1);
    },

    findConfiguration: async function(a) {
      this.$set(a, 'status', "finding.conf");
      try {
        let conf = await Api.mailboxScanner.findConf({
          user: a.user,
          password: a.password
        });
        conf.ackType = 'folder';
        let idx = this.accounts.indexOf(a);
        this.$set(this.accounts, idx, conf);
        this.saveAccount(this.accounts[idx]);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) this.$set(a, 'status', error.response.data.error);
        else this.$set(a, 'status', error);
      }
    },
    saveAccount: async function(a) {
      this.procesing = true;
      if (a.id) {
        try {
          let account = await Api.mailboxScanner.save(a.id, a);
          let idx = this.accounts.map(a => a.id+"").indexOf(account.id+"");
          if (idx != -1) this.accounts[idx] = account;
          this.showNotify('notification-success',this.$i18n.t('notifications.changeSuccess')); 
        } catch (error) {
          this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
        }
      } else {
        try {
          let account = await Api.mailboxScanner.add(a);
          a.id = account.id;
          this.showNotify('notification-success',this.$i18n.t('notifications.addSuccess')); 
        } catch (error) {
          this.showNotify('notification-error',this.$i18n.t('notifications.addError'));
        }
      }
      this.procesing = false;

      setTimeout(() => {
        this.refreshStatus();
      }, 1500);
    },
    addAccount: function() {
      this.accounts.push({user:'', password:'',host:'', port:'',tls:'',ackType:'', isNew: true, manualConfig: false});
    },
    deleteAccount: function() {
      if (this.selectedAccounts.length == 0) {
        this.showNotify('notification-error', this.$i18n.t('notifications.noSelected')); 
        return;
      }
      
      this.selectedAccounts.forEach((aId)=> {
        Api.mailboxScanner.delete(aId).then((account) => {
          let idx = this.accounts.map(a => a.id+"").indexOf(account.id+"");
          if (idx != -1) {this.accounts.splice(idx, 1);}
          this.showNotify('notification-success',this.$i18n.t('notifications.removeSuccess')); 
        }).catch((reson) => {
          this.showNotify('notification-error',this.$i18n.t('notifications.removeError'));
        });
      });
    },
    showNotify (type, text) {
      this.$notify({
        group: 'global',
        type: type,
        text: text,
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .conection {
    background-color: rgba(12,194,170, 0.7);
  }
  .conection-error {
    background-color: rgba(240,80, 80, 0.7);
  }
  .conection-success {
    background-color: rgba(144,200,68, 0.7);
  }
  .start-info {
    min-height: 130px;
    .box2 {
      right: 135px;
    }
  } 
  @media (max-width: 453px) {
    .c-rwd {
      float: none;
      display: block;
      margin-top: 10px;
      margin-left: 0px!important;
    }
  }
</style>
