import io from 'socket.io-client';
import config from './config.js';

function SystemSocket() {
    this.socket = io(config.push.host, config.push.options);

    console.log("Connecting alog", config.activitylog);
    this.activitySocket = io(config.activitylog.host, config.activitylog.options);

    this.token = null;

    this.socket.on('reconnect', (attemptNumber) => {
        if (this.token) this.socket.emit('login', this.token);
    });

    this.activitySocket.on('reconnect', (attemptNumber) => {
        if (this.token) this.activitySocket.emit('login', this.token);
    });
}

SystemSocket.prototype.setToken = function(token) {
    this.token = token;
    this.socket.emit('login', token);
    this.activitySocket.emit('login', token);
}

var instance = new SystemSocket();

export default instance;