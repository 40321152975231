<template>
    <select><slot></slot></select>
</template>

<script>
import $ from 'jquery';
import 'select2';
import Api from '../../Api.js';

export default {
    name: 'ContactSelect',
    props: ['value'],
    components: {},
    data: function () {
        return {}
    },

    mounted: function () {
        var vm = this;
        var options = {
            tags: true,
            minimumInputLength: 3,
            ajax: {
                // url: Api.mails.getSearchContactUrl(),
                delay: 250,
                transport: function (params, success, failure) {
                    Api.mails.searchContact(params.data.term).then(success).catch(failure);
                },
                processResults: function (data) {
                    let res = data.map(d => { return {id: d.address, text: d.name +" <"+ d.address +">", "contact-name": d.name}; });
                    return {results: res }; 
                }
            },
            createTag: function (params) {
                // Don't offset to create a tag if there is no @ symbol
                if (params.term.indexOf('@') === -1) {
                    return null;
                }

                // obejscie problemu kiedy to wklejanie adresu powodowali jego brak w recipents
                setTimeout(() => {
                    $(vm.$el).trigger('select2:select');
                }, 500);
                return {
                    id: params.term,
                    text: " <"+params.term+">"
                }
            }
        }

        $(this.$el)
            .select2(options)
            .on('select2:select', function () {
                var data = $(this).select2('data')[0];
                var r = {address: data.id, name: data["contact-name"]};
                vm.$emit('input', r);
                // this.value = r;
            })
        ;

        this.updateValue();
    },

    watch: {
        value: function (value) {
            this.updateValue();
        },
    },

    methods: {
        updateValue: function() {
            if (this.value && this.value.address) {
                if ($(this.$el).find("option[value='" + this.value.address + "']").length) {
                    $(this.$el).val(this.value.address).trigger('change');
                } else { 
                    var text = this.value.name +" <"+ this.value.address +">";
                    var newOption = new Option(text, this.value.address, true, true);
                    $(this.$el).append(newOption).trigger('change');
                } 
            }
        }
    },

    destroyed: function () {
        $(this.$el).off().select2('destroy')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
