import $ from 'jquery';
import EventBus from './event-bus.js';

function FilterStorage() {
    this.checkedAssignees = [];
    this.checkedAssigneesGroup = [];
    this.checkedStatus = [];
    this.checkedPriority = [];
    this.checkedType = [];
    this.checkedComunicationChannel = [];
    this.dateFrom = '';
    this.dateTo = '';
    this.selectedTags = '';
    this.search = '';
    this.searchContent = '';
    this.sortBy = 'Date:DESC';
    
    this.selectedColumns = null;
}

FilterStorage.prototype.filterChange = function() {
    EventBus.$emit('filterChangeNew', {
        checkedAssignees: this.checkedAssignees,
        checkedAssigneesGroup: this.checkedAssigneesGroup,
        checkedComunicationChannel: this.checkedComunicationChannel,
        checkedStatus: this.checkedStatus,
        checkedPriority: this.checkedPriority,
        checkedType: this.checkedType,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        selectedTags: this.selectedTags,
        search: this.search,
        searchContent: this.searchContent,
        sortBy: this.sortBy,
    });
};

FilterStorage.prototype.sortSelecteFilters = function() {
    this.filterChange();
    EventBus.$emit('sortByNew', [this.sortBy]);
};

FilterStorage.prototype.saveSelectedColumns = function(selectedColumns) {
    this.selectedColumns = selectedColumns;
};

FilterStorage.prototype.saveSelectedSortBy = function(sortBy) {
    this.sortBy = sortBy;
};

var instance = new FilterStorage();

export default instance;