<template>
    <div class="col-md-3">
        <div class="box live-chat-box p-x-0">
            <div class="p-a-sm">
                <span class="title-color text-strong">
                    <a @click="$router.push({ path: `/ticket/${chat.id}`})" class="text-primary text-bold text-u-c">#{{chat.id.substr(-6)}}  <i class="material-icons md-18">call_made</i></a>
                </span>

                <br>
                <span style="font-size: 0.7rem;" class="text-sm text-muted">{{chat.subject}}</span>

                <div class="form-group m-b-0 m-t-xs" v-if="UserManager.permissions.find(element => element == 'tickets.assignment.change')">
                    <label for="assignee" class="text-sm text-muted m-r" style="width:20%;">{{ $t('ticket.info.assignee') }}</label>
                    <select2  :options="optionsEmployee" v-model="chat.assignedUserId" class="form-control" @input="changeAssignee(chat)" style="width:70%;"/>
                </div>

                <div class="toolbox">
                    <a @click.prevent="closeChat">
                        <i class="material-icons md-18">clear</i>
                    </a>
                </div>
                <div class="box-divider m-a-0"/>
                <a class="nav-link btn btn-xs white none-radius text-green m-r m-t-xs" :title="$t('ticket.ticketTabs.btnGroup.close')" @click.prevent="statusClose">
                    Close <i class="material-icons md-18"></i>
                </a>
            </div>

            <div class="box-divider m-a-0"/>
            <div class="box-body p-l-0 single-box">
                <div class="row-body scrollable">
                    <div class="row-inner">
                        <ul class="list-unstyled">
                            <li class="list-item p-y-sm"
                                v-for="m in evenMessages"
                                :message="m"
                                :key="m.id">
                                <div class=" _600 m-b-xs" :class="{'text-primary': m.userId == null}">
                                    <i v-if="!m.userId" class="material-icons md-16 m-r-xs">person</i>
                                    <span v-if="m.author && m.author.indexOf('@') != -1">{{m.author.split("@")[0]}}</span>
                                    <span v-if="m.author && m.author.indexOf('@') == -1 && m.author.length < 16">{{m.author}}</span>
                                    <span v-else-if="m.userId">{{m.author}}</span>
                                    <span v-else>Klient</span>
                                    <span class="pull-right  _400">{{m.createdAt | moment("HH:mm") }}</span>
                                </div>
                                <span>
                                    <blockquote class="primary-color content-msg m-b-0" style="overflow-x: auto;">
                                        <nl2br tag="p" :text="m.content" v-if="m.content"/>
                                    </blockquote>
                                </span>

                                <div class="sl-footer" v-if="m.attachments && m.attachments.length > 0">
                                    <div v-for="a in m.attachments"
                                         :attach="a"
                                         :key="a.id" class="minature-container">
                                        <a :href="a.url" target="_blank" class="miniature">

                                            <div v-if="a.previewUrl" class="img-minature" :style="{'background-image': 'url('+a.previewUrl+')'}"/>
                                            <div v-else-if="a.size < 102400" class="img-minature" :style="{'background-image': 'url('+a.url+')'}"/>
                                            <div v-else class="img-minature">
                                                <i class="material-icons md-24 text-muted">insert_photo</i>
                                            </div>
                                            <div class="overlay"/>
                                            <div class="text-minature text-info">
                                                <span>{{a.name  | truncate(15)}}</span>
                                            </div>

                                        </a>
                                    </div>
                                </div>

                                <div class="box-divider m-a-0"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="msg-content dker">
                <div class="box-divider m-a-0"/>
                <div class="form-group l-h m-t m-x-sm m-b-0 p-b-sm">
                    <div class="form-file pull-left">
                        <input type="file" ref="files" multiple @change="onFilesInputChange" style="display: none;">
                        <a :title="$t('header.chat.upload')" @click.prevent="addFiles">
                            <i class="material-icons m-r-xs m-t-sm coral">attach_file</i>
                        </a>
                    </div>
                    <div class="input-group chat-content">
                        <textarea  ref="live-chat-msg-area" :ticket="chat._id" class="live-chat-msg form-control form-control-sm"
                                   @keyup="autoGrow" @keyup.enter.prevent="" @keyup.enter.shift="sendMessage" v-model="msg"  :class="{ disabled: isSending }"
                        />
                        <span class="text-muted text-xs">Shift + Enter żeby wysłać</span>

                        <div class="btn-group dropdown dropup primary-color pull-right">
                            <a class="text-primary dropdown-toggle _400" data-toggle="dropdown" aria-expanded="false">
                                szablon
                            </a>
                            <div class="dropdown-menu dropdown-menu-scale pull-right">
                                <a class="dropdown-item" href="#" v-for="response in cannedResponses" :key="response._id"
                                   @click.prevent="setResponse(response.content)">
                                    {{response.title}}
                                </a>
                            </div>
                        </div>

                    </div>
                    <div class="list-group list-group-gap">
                        <div v-for="(file, key) in files" :key="key">

                            <a class="file-link" target="_blank" :href="file.fileUrl"
                               :class="{ 'text-info': file.isUrlActive, 'disabled': file.isDisabled}">
                                {{ file.file.name | truncate(25)}}
                            </a>
                            <span class="primary-color p-l-xs text-xs">({{ file.file.formatSize }})</span>
                            <a class="primary-color pull-right p-r-sm" href="#" @click="removeFile( key )">
                                <i class="material-icons md-14"></i>
                            </a>

                            <div class="progress m-b-xs none-radius" v-if="file.fileUrl == null"
                                 :class="{ 'none-display': isErrorUpload}" style="width: 50%; height: 0.7rem;">
                                <div class="progress-bar primary none-radius" style="font-size: 0.55rem;"
                                     :style="{ width: file.progress + '%' }">
                                    {{file.progress}} %
                                </div>
                            </div>
                            <div v-if="isErrorUpload"  class="upload-error text-xs">
                                <span class="text-red">{{$t('header.chat.errOccured')}}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from '../../event-bus.js';
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import Nl2br from 'vue-nl2br';
import UserManager from '../../UserManager.js';
import Select2 from '../Select2.vue';
import SystemSocket from '../../SystemSocket.js';
import Common from '../../Common';

const LS_DRAFT_KEY = "ticket_drafts_";
const LS_FILE_DRAFT_KEY = "ticket_draft_files_";

export default {
    name: "LiveChat",
    props: ['chat'],
    components: {
        Nl2br,
        Select2
    },
    data: function () {
        return {
            messages: [],
            msg: "",
            files: [],
            isErrorUpload: false,
            height: null,
            isSending: false,
            EmployeeManager: EmployeeManager,
            UserManager: UserManager,
            optionsEmployee: [],
            cannedResponses: []
        }
    },
    created: function() {
        if (this.chat && this.chat._id) {
            const draft = localStorage.getItem(LS_DRAFT_KEY + this.chat._id);
            if (draft) this.msg = draft;
            const filesJSON = localStorage.getItem(LS_FILE_DRAFT_KEY + this.chat._id);
            if (filesJSON) {
                try {
                    const f = JSON.parse(filesJSON);
                    if (Array.isArray(f)) {
                        this.files = f;
                    }
                } catch (error) { console.warn(error); }
            }
        }

        Api.getTicketMessages(this.chat._id).then((messages) => {
            this.messages = messages.filter(m => m.status != "closed")
            // EventBus.$emit('messagesChange', this.messages);
        });
        EmployeeManager.getEmployees().then((employees) => {
            this.optionsEmployee = employees.map(e => {
                return { id: e.id, text: e.name };
            })
        });

        UserManager.cannedResposnes.forEach(c => this.cannedResponses.push(c));

        SystemSocket.socket.on('object', this.onSocketUpdate);
    },
    destroyed() {
        SystemSocket.socket.off('object', this.onSocketUpdate);
        if (this.chat && this.chat._id) localStorage.setItem(LS_DRAFT_KEY + this.chat._id, this.msg);

        if (this.files.length > 0) {
            const filesJSON = JSON.stringify(this.files);
            localStorage.setItem(LS_FILE_DRAFT_KEY + this.chat._id, filesJSON);
        }
    },
    computed: {
        evenMessages: function () {
            this.addEmpEmails();
            return [...this.messages].sort(function(a, b) {
                var dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        }
    },
    methods: {
        setResponse: function(response) {
            if (this.msg.length) {
                this.msg += "\n\n";
                this.msg += response;
            } else {
                this.msg = response;
            }

            setTimeout(() => {
                this.autoGrow();
            }, 100);
        },
        onSocketUpdate: function(event) {
            if (event.object == 'message' && event.verb == 'create') {
                if (this.chat._id + "" != event.data.ticketId + "") return;

                const idx = this.messages.map(m => m.id + "").indexOf(event.data.id + "");
                if (idx == -1) {
                    this.messages.push(event.data);
                }
            }
        },
        closeChat: function() {
            EventBus.$emit('closeLiveChat', {
                chat: this.chat
            });
        },
        statusClose: function() {
            Api.closeTicket(this.chat._id).then((t) => {
                this.chat = t;
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
                this.closeChat();
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        addEmpEmails: function() {
            this.messages.forEach((m) => {
                if (m.userId) {
                    m.empEmail = EmployeeManager.getEmail(m.userId);
                }
            });
        },
        goToTicket: function(chat) {
            this.$router.push({ path: `/ticket/${chat.id}` });
        },
        autoGrow: function() {
            const element = this.$refs['live-chat-msg-area'];
            element.style.height = "5px";
            element.style.height = (element.scrollHeight) + "px";
        },
        sendMessage: function() {
            const contentMsg = this.msg.trim();
            if (contentMsg.length == 0) {
                this.showNotify('notification-error', this.$i18n.t('notifications.empty'));
                return;
            }

            const filesUrl = [];
            for (var i = this.files.length - 1; i >= 0; i--) {
                filesUrl.push(this.files[i].fileUrl);
            }

            const data = { content: contentMsg, attachments: filesUrl, channel: "chat" };
            this.isSending = true;
            Api.setNotificationsSeenForTicket(this.chat._id);
            Api.tickets.sendReply(this.chat._id, data).then((message) => {
                this.msg = '';
                this.isSending = false;
                this.files = [];
                localStorage.removeItem(LS_DRAFT_KEY + this.chat._id);
                localStorage.removeItem(LS_FILE_DRAFT_KEY + this.chat._id);
                this.showNotify('notification-success', this.$i18n.t('notifications.sendSuccess'));
            }).catch((reson) => {
                this.isSending = false;
                this.showNotify('notification-error', this.$i18n.t('notifications.error'));
            });
        },
        onFilesInputChange(e) {
            const uploadedFiles = this.$refs.files.files;
            this.handleFilesUpload(uploadedFiles);
        },
        addFiles: function() {
            this.$refs.files.click();
        },
        handleFilesUpload(uploadedFiles) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                const formatSize = Common.formatBytes(uploadedFiles[i].size);

                const fObj = {
                    progress: 0,
                    formatedSize: formatSize,
                    fileName: uploadedFiles[i].name,
                    fileUrl: null,
                    isUrlActive: false,
                    isDisabled: true
                };

                this.files.push(fObj);

                const formData = new FormData();
                formData.append('file', uploadedFiles[i]);

                this.isErrorUpload = false;

                Api.uploadFile(formData, (progressEvent) => {
                    fObj.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                })
                    .then((ob) => {
                        fObj.fileUrl = ob.fileUrl;
                        fObj.isUrlActive = true;
                        fObj.isDisabled = false;
                    }).catch((reson) => {
                        this.isErrorUpload = true;
                    });
            }
        },
        removeFile(key) {
            this.files.splice(key, 1);
        },
        changeAssignee: function (ticket) {
            Api.changeTicketAssignee(ticket.id, ticket.assignedUserId).then((t) => {
                this.showNotify('notification-success', this.$i18n.t('notifications.changeSuccess'));
            }).catch((reson) => {
                this.showNotify('notification-error', this.$i18n.t('notifications.changeError'));
            });
        },
        showNotify (type, text) {
            this.$notify({
                group: 'global',
                type: type,
                text: text
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.live-chat-box {
    height: calc(100vh - 100px);
    width:100%;
    display: table;
    table-layout: fixed;
    border-spacing: 0;

    .single-box {
        display: table-row;
        height: 100%;

        .scrollable {
            scroll-behavior: smooth;
        }
    }
    .toolbox {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .disabled {
        background-color: #efefef;
    }
    #live-chat-msg-area {
        resize: none;
        overflow: hidden;
        height: 10px;
    }
}
</style>
